import * as d3 from 'd3'

export default {
  data: () => ({
    selectedItems: null,
    getItems: null,
  }),

  watch: {
    selectedItems() {
      this.$emit('selectedItems', this.selectedItems)
    },
  },

  methods: {
    set_selectedItems() {
      this.selectedItems = {
        ...this.selectedItems,
        current: this.current
      }
    },

    draw_PDF() {
      this.current.phase = this.getItems.current.phase
      this.current.group = this.getItems.current.group
      this.current.subcont = this.getItems.current.subcont

      if (this.current.phase) d3.select(`#DROP_TITLE_PHASE`).text(this.current.phase)
      if (this.current.group) d3.select(`#DROP_TITLE_GROUP`).text(this.current.group)
      if (this.current.subcont) d3.select(`#DROP_TITLE_SUBCONT`).text(this.current.subcont)

      d3.select(`#DROPDOWN_CHARTS`).remove()
      d3.select(`#DROPDOWN_LEGENDS`).remove()
      this.chart_Router()
    },
  }
}