import * as d3 from 'd3'
import moment from 'moment'
export default {

  methods: {

    set_LocalValues(){

      // Get Data from data.js
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql2 = JSON.parse(JSON.stringify(this.Queries.SQL2[0]))

      // Timeline Properties
      this.timeline.cutoff = sql2.CUTOFF
      this.timeline.startDate = sql2.START_DATE
      this.timeline.endDate = sql2.END_DATE


      
      this.chartData = this.rawData.filter(f=> f.CDATE >= sql2.START_DATE && f.CDATE <= sql2.END_DATE)
      this.chartData.forEach(d=> {
        d.month = d.CDATE.substr(0,7)
      })




      this.set_Timeline_Month(this.timeline)
    },


    set_Timeline_Month(d_) {

      let sYear= parseInt(d_.startDate.substr(0,4))
      let sMonth = parseInt(d_.startDate.substr(5,2))

      let eYear = parseInt(d_.endDate.substr(0,4))
      let eMonth = parseInt(d_.endDate.substr(5,2))

      this.timeline.month_count = (eYear - sYear)* 12 + (eMonth - sMonth)
      this.timeline.monthWidth = this.chart.width / (this.timeline.month_count+1) // this.timeline.width -> Month Width

      // Set Month Values
      let m_ = sMonth, y_ = sYear
      for(let i=0; i<= this.timeline.month_count; i++) {
        this.timeline.month.push(
          { 
            code: d3.timeFormat('%Y-%m')(moment(d_.startDate).add(i, 'months')), 
            name: d3.timeFormat('%b')(moment(d_.startDate).add(i, 'months')), 
            month: m_,
            year: y_,
          }
        )
        if(m_ == 12) {m_ = 1; y_++} else m_++
      }

      // Set Year Values
      let year_ = [ ...new Set(this.timeline.month.map(d => d.year)) ]
      year_.forEach(d=> {
        this.timeline.year.push({name: d})
      })

      let x_ = 0
      this.timeline.year.forEach(d=> {
        let count = this.timeline.month.filter(f=> f.year == d.name).length
        x_ = x_ + (this.timeline.monthWidth * count)
        d.eDate = x_
        d.mDate = x_ - (this.timeline.monthWidth * count)/2
      })
    },



  }
}