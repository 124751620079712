import * as d3 from 'd3'
import moment from 'moment'

export default {

  methods: {
    
    draw_Timeline_Month() {   

      this.canvasTimeline = this.canvasForms
      .append('g')
      .attr('id', 'TIMELINE')
      .attr('transform', `translate(10.5, 490)`)

      /////////// draw the timeline background without the text
      this.canvasTimeline
      .append('path') // shadow
      .attr('d', `M50,${this.timeline.height } H ${this.timeline.length-50} L ${this.timeline.length},${this.timeline.height + 5} H0 Z`)
      .attr('fill', `url(#shadowTimeline)`) 

      this.canvasTimeline
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('width', this.timeline.length).attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`).attr('opacity', .5)
      
      this.canvasTimeline
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.length).attr('height', 2)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`)



      // Month Text & Timeline Seperator for the Month
      this.canvasTimeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 22)
      .style('font-size', 8.5)
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name.toUpperCase())

      this.canvasTimeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.month.length-1) return `M${this.timeline.scale(d.eDate)}, 19 V31`
      })
      .attr('stroke', '#BCBCBC')
      .attr('stroke-width', 0.5)

      // Year Text & Timeline Seperator for the Year
      this.canvasTimeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 7)
      .style('font-size', 10.5)
      .attr('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      this.canvasTimeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate)}, ${2} V${28}`
      })
      .attr('stroke', '#BCBCBC')
      .attr('stroke-width', 0.5)


      // cutoff month highlight
      let cutoff = moment(this.timeline.cutoff).format('YYYY-MM')

      this.timeline.month.forEach(d => {
        if (moment(d.eDate).format('YYYY-MM') == cutoff) {
          this.canvasTimeline
          .append('rect')
          .attr('x',this.timeline.scale(d.sDate) + 1)
          .attr('y',19)
          .attr('width', this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate)-1)
          .attr('height', 11)
          .style('opacity', 0.3)
          .attr('fill','#00b0f0')
          .attr('stroke','#bcbcbc')
          .attr('stroke-width',0.5)   
        }
      })
    }, 


  }
}
