import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'


// Locally declared common Data-Props & SVG Drawing Support
import lmx_localData            from '../primitives/localData'

import lmx_LocalValues          from './set_LocalValues'
import lmx_Watching             from './Watching'
import lmx_Events               from './Events'

// Summary Table
import lmx_draw_Forms           from './draw_Forms'
import lmx_draw_Table           from './draw_Table'

// Trend Chart
import lmx_draw_TredChart        from './draw_TredChart'

// Histogram Chart Chart
import lmx_chart_Router         from './chart_Router'
import lmx_draw_Timeline        from './draw_Timeline'
import lmx_draw_ChartCanvas     from './draw_ChartCanvas'
import lmx_draw_Chart           from './draw_Chart'



export default {
  mixins: [
    gmx_zMixins, 
    lmx_localData,

    // Local Mixins
    lmx_LocalValues,
    lmx_Watching,
    lmx_Events,
    
    lmx_draw_Forms,
    lmx_draw_Table,

    lmx_draw_TredChart,

    lmx_chart_Router,
    lmx_draw_ChartCanvas,
    lmx_draw_Timeline,
    lmx_draw_Chart,
    
  ],
}
