
export default {
  data: () => ({


      rawData:      [],
      schList:      [],

      canvasForms:  null,

      columns: [
        {name: 'Area', code: 'AREA',  width: 80, head: ''},
        {name: 'Zone', code: 'ZONE',  width: 110, head: ''},
        
        {name: 'Total',  code: 'SC_TOT', width: 40, head: ''},
        {name: 'Compl.', code: 'SC_CP',  width: 40, head: ''},
        {name: 'Ahead',  code: 'SC_AD',  width: 40, head: 'Cutting'},
        {name: 'Behind', code: 'SC_BH',  width: 40, head: ''},
        {name: 'Total',  code: 'FB_TOT', width: 40, head: ''},
        {name: 'Compl.', code: 'FB_CP',  width: 40, head: ''},
        {name: 'Ahead',  code: 'FB_AD',  width: 40, head: 'Fab.'},
        {name: 'Behind', code: 'FB_BH',  width: 40, head: ''},
        {name: 'Total',  code: 'AS_TOT', width: 40, head: ''},
        {name: 'Compl.', code: 'AS_CP',  width: 40, head: ''},
        {name: 'Ahead',  code: 'AS_AD',  width: 40, head: 'Assembly'},
        {name: 'Behind', code: 'AS_BH',  width: 40, head: ''},
        {name: 'Total',  code: 'PE1_TOT',width: 40, head: ''},
        {name: 'Compl.', code: 'PE1_CP', width: 40, head: ''},
        {name: 'Ahead',  code: 'PE1_AD', width: 40, head: '1st PE'},
        {name: 'Behind', code: 'PE1_BH', width: 40, head: ''},
        {name: 'Total',  code: 'PT_TOT', width: 40, head: ''},
        {name: 'Compl.', code: 'PT_CP',  width: 40, head: ''},
        {name: 'Ahead',  code: 'PT_AD',  width: 40, head: 'Painting'},
        {name: 'Behind', code: 'PT_BH',  width: 40, head: ''},
        {name: 'Total',  code: 'PE2_TOT',width: 40, head: ''},
        {name: 'Compl.', code: 'PE2_CP', width: 40, head: ''},
        {name: 'Ahead',  code: 'PE2_AD', width: 40, head: '2nd PE'},
        {name: 'Behind', code: 'PE2_BH', width: 40, head: ''},
        {name: 'Total',  code: 'ER_TOT', width: 40, head: ''},
        {name: 'Compl.', code: 'ER_CP',  width: 40, head: ''},
        {name: 'AheadA', code: 'ER_AD',  width: 40, head: 'Setting'},
        {name: 'Behind', code: 'ER_BH',  width: 40, head: ''},
        
      ],
      tblLen: 0,
      





  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

}