import Defs from '../../../../../../includes/primitives/colorDefs'
import * as d3 from 'd3'

export default {
  methods: {

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))
      this.masterList = JSON.parse(JSON.stringify(this.Queries.SQL1))

      // to be deleted - start
      // this.FilterValues.STAGE = 'EREC' 
      // this.FilterValues.KEY = 'EN112'
      // to be deleted - end

      this.Props.stage = this.FilterValues.STAGE
      this.Props.title = this.FilterValues.KEY

      this.Props.key = this.FilterValues.KEY
      this.dataSet = this.masterList.find(f=> f.EREC == this.FilterValues.KEY)
      this.Props.file = `/EREC/${this.dataSet.AREA}/EREC_${this.FilterValues.KEY}`
 
 
      // Get Weight Factor
      this.filterData = this.masterList.filter(f=> f.G_EREC == this.Props.key)

      this.filterData.forEach(d=> {

        this.activity.forEach(act=> {
          d[`${act.code}_PW`] = (d[`${act.code}_PP`]/100)*d[`${act.code}_WF`]
          d[`${act.code}_AW`] = (d[`${act.code}_AP`]/100)*d[`${act.code}_WF`]
        })
      
      })
      


      // Timeline data preprocessing -------------------------------------------
      this.timeline = {
        cutoff : this.rawData.CUTOFF,
        startDate : '2024-06-07',
        endDate : '2027-04-30',
        weekEnd : 'friday',
        day: [], week: [], month: [], year: [],
        scale : null,
        height: 32, length: 790,
      }
      this.init_gmx_TimelineValues(this.timeline)
    },



    set_Gradients() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 'TL', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 'plan', 'LinearA4', 'LightBlue', [.5, .8, .5, 0], this.localId)
    },
  },




}