
import * as d3 from 'd3'

export default {

  data: () => ({
    colorRef: [ 'SC_AS', 'FB_AS', 'AS_AF', 'PT_AF', 'P3_AS', 'ER_AS'],
    
    colorRefs: [ 
      {code: 'SC_AS', color: '#e0e0df'},
      {code: 'FB_AS', color: '#ffe092'},
      {code: 'AS_AS', color: '#d7f7ff'},
      {code: 'AS_AF', color: '#83d2f5'},
      {code: 'PT_AF', color: '#f7bacf'},
      {code: 'ER_AS', color: '#8bc248'},
    ],
  }),


  methods: {

    // To be deleted Function
    func_setBlockColoring(list_) {
      list_.forEach(d=> {
        d.color = '#fff'
        this.colorRef.forEach(c=> {
          if (d[c] !== '') d.color = d[c]
        })
      })
    },

    // To be deleted Function
    func_exeBlockColoring(selection, list_, spare) {
      list_.forEach(d=> {
        selection.select(`#${d.BLK}`).select('rect').style('fill', d.color)
        selection.select(`#${d.BLK}`).select('path').style('fill', d.color)
      })
    },



    func_blockColoring(selection, area, group, _blk) {

      // --------------------------- EREC
      if (group == 'EREC') {
        this.masterList.filter(f=> f.AREA == area && f.EREC != '').forEach(d=>{
          
          this.colorRefs.forEach(ref=>{
            let count = this.masterList.filter(f=> f.G_EREC == d.EREC && f[ref.code]).length
            if (count > 0) {
              selection.select(`#${d.EREC}`).select('rect').style('fill', ref.color)
              selection.select(`#${d.EREC}`).select('path').style('fill', ref.color)
            }
          })
          
        })
      }


      // --------------------------- ASSEMBLY
      if (group == 'ASSEMBLY') {
        let dataSet =  this.masterList
        if(_blk) dataSet = this.masterList.filter(f=> f.G_EREC == _blk)
          
        dataSet.forEach(d=>{
          this.colorRefs.forEach(ref=>{

            // FAB. Block
            if(d[ref.code] && ref.code != 'PT_AF' && ref.code != 'ER_AS') {
              selection.select(`#${d.BLK}`).select('rect').style('fill', ref.color)
              selection.select(`#${d.BLK}`).select('path').style('fill', ref.color)
            }

            // Painting Block
            if(ref.code == 'PT_AF' && d.PT_AF) { 
              selection.select(`#${d.PBLK}`).select('rect').style('fill', ref.color)
              selection.select(`#${d.PBLK}`).select('path').style('fill', ref.color)
            }

            // Setting Block
            if(ref.code == 'ER_AS' && d.ER_AS) { 
              selection.select(`#${d.EREC}`).select('rect').style('fill', ref.color)
              selection.select(`#${d.EREC}`).select('path').style('fill', ref.color)
            }
            
          })
        })
      }

    },

  }
}






