import gmx_zMixins                    from '../../../../../includes/Jmixins/zMixins'
import eq_zMixins                     from '../Equip_Layout/zMixins'

// Locally declared common Data-Props & SVG Drawing Support
import fnc_zMixins                    from '../../../vFunctions/zMixins'
import lmx_localData                  from '../primitives/localData'


import lmx_Events                     from './Events'
import lmx_actions                    from './actions'
import lmx_Watching                   from './Watching'

import lmx_LocalValues                from './set_LocalValues'
import lmx_draw_Forms                 from './draw_Forms'
import lmx_draw_PDF                   from './draw_PDF'

import lmx_draw_Overall               from './draw_Overall'
import lmx_draw_Area                  from './draw_Area'
import lmx_draw_Erection              from './draw_Erection'
import lmx_draw_2PE                   from './draw_2PE'
import lmx_draw_Block                 from './draw_Block'
import lmx_draw_BlockStatus           from './draw_BlockStatus'

import lmx_canvas_Overall             from './canvas_Overall'
import lmx_canvas_Area                from './canvas_Area'
import lmx_canvas_Erection            from './canvas_Erection'
import lmx_canvas_2PE                 from './canvas_2PE' 
import lmx_canvas_Block               from './canvas_Block'

import lmx_status_Progress            from './status_Progress'
import lmx_status_Structure           from './status_Structure'
import lmx_status_Outfitting          from './status_Outfitting'



export default {
  
  mixins: [
    gmx_zMixins, 
    fnc_zMixins,
    lmx_localData,
    eq_zMixins, // for Equipment


    // Local Mixins
    lmx_Events,
    lmx_actions,
    lmx_Watching,
    
    lmx_LocalValues,
    lmx_draw_Forms,
    lmx_draw_PDF,
    
    lmx_draw_Overall,
    lmx_draw_Area,
    lmx_draw_Erection,
    lmx_draw_2PE,
    lmx_draw_Block,
    lmx_draw_BlockStatus,

    lmx_canvas_Overall,
    lmx_canvas_Area,
    lmx_canvas_Erection,
    lmx_canvas_2PE,
    lmx_canvas_Block,

    lmx_status_Progress,
    lmx_status_Structure,
    lmx_status_Outfitting,
  ],

}
