import * as d3 from 'd3'

export default {
  methods: {

    draw_Legends(chtData, groupArr) {

      let thisWeek = chtData.filter(f=> f.CDATE == this.timeline.cutoff)
      let cols = [...new Set(this.legends.map(d => d.code))]
      let total = this.sumByKeys(thisWeek, cols)
      total = {
        ...total,
        text: 'Total'
      }

      let item = this.canvasForms
      .append('g')
      .attr('id', `DROPDOWN_LEGENDS`)
      .attr('transform', `translate(75, 82)`)

      item
      .append('text')
      .attr('x', 2).attr('y', 3).attr('font-size', 12).attr('fill', '#000')
      .text('Legends')

      item
      .append('text')
      .attr('x', this.legends[0].x).attr('y', -3).attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'end')
      .text('Planned')

      item
      .append('text')
      .attr('x', this.legends[0].x - 6).attr('y', 6).attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'end')
      .text('Total')

      item
      .append('text')
      .attr('x', this.legends[2].x +12).attr('y', -5).attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'end')
      .text('This Week')

      item
      .append('text')
      .attr('x', this.legends[5].x+12).attr('y', -5).attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end')
      .text('Cumulative')

      this.legends.forEach((d,i)=> {
        if(i==0) return
        item
        .append('text')
        .attr('x', d.x).attr('y', 6)
        .attr('font-size', 9).attr('fill', '#757575')
        .attr('text-anchor', 'end')
        .text(d.title)
      })

      item
        .append('line')
        .attr('x1', 0).attr('y1', 10.5)
        .attr('x2', this.legends[this.legends.length - 1].x).attr('y2', 10.5)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5).attr('opacity', 1)

      let y_ = 20
      thisWeek.forEach((d,i)=> {
        item
        .append('rect')
        .attr('x', 3).attr('y', y_-4.5)
        .attr('width', 7).attr('height', 7).attr('fill', this.chart.colors[i])

        item
        .append('text')
        .attr('x', 14).attr('y', y_)
        .attr('font-size', 10).attr('fill', '#333').attr('alignment-baseline', 'middle')
        .text(groupArr[i])

        item
        .append('line')
        .attr('x1', 0).attr('y1', y_ + 8.5)
        .attr('x2', this.legends[this.legends.length - 1].x).attr('y2', y_ + 8.5)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5).attr('opacity', .5)

        this.legends.forEach(col=> {
          item
          .append('text')
          .attr('id', `STATUS_${col.code}_${i}`)
          .attr('x', col.x).attr('y', y_)
          .attr('font-size', 10).attr('fill', (col.title=='Delta') ? this.setDeltaColor(d[col.code]) : '#333')
          .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text(isNaN(d[col.code]) ? d[col.code] : this.AddComma(d[col.code]))
        })
        
        y_ = y_ + 18
      })

      item
      .append('text')
      .attr('x', 3).attr('y', y_)
      .attr('font-size', 10).attr('fill', '#333').attr('alignment-baseline', 'middle')
      .text(total.text)

      item
      .append('line')
      .attr('x1', 0).attr('y1', y_ + 8.5)
      .attr('x2', 420).attr('y2', y_ + 8.5)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5).attr('opacity', .5)

      this.legends.forEach(col => {
        item
        .append('text')
        .attr('id', `TOT_STATUS_${col.code}`)
        .attr('x', col.x).attr('y', y_)
        .attr('font-size', 10).attr('fill', (col.title=='Delta') ? this.setDeltaColor(total[col.code]) : '#333')
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(isNaN(total[col.code]) ? total[col.code] : this.AddComma(total[col.code]))
      })
    },


    change_Status(thisWeek) {
      let cols = [...new Set(this.legends.map(d => d.code))]
      let total = this.sumByKeys(thisWeek, cols)
      total = {
        ...total,
        text: 'Total'
      }

      thisWeek.forEach((d,i)=> {
        this.legends.forEach(col=> {
        if(col.title == 'Total') return
          d3.select(`#STATUS_${col.code}_${i}`)
          .attr('fill', (col.title=='Delta') ? this.setDeltaColor(d[col.code]) : '#333')
          .text(d[col.code])
        })
      })
      
      this.legends.forEach(col => {
        if(col.title == 'Total') return
        d3.select(`#TOT_STATUS_${col.code}`)
        .attr('fill', (col.title=='Delta') ? this.setDeltaColor(total[col.code]) : '#333')
        .text(total[col.code])
      })

    },

    sumByKeys(arr, keys) {
      return keys.reduce((result, key) => {
        result[key] = Math.round(arr.reduce((sum, obj) => sum + (obj[key] || 0), 0) * 100) / 100
        return result
      }, {})
    }
  }
}