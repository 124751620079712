
import * as d3 from 'd3'

export default {
  methods: {

    draw_BlockData() {

      let filterData = this.masterList.filter(f=> f.G_1PE == this.Props.key)

      this.activity.forEach((d,i)=> {

        d[`${d.code}_TOT`] = filterData.filter(f=> f[`${d.code}_PS`]).length
        d[`${d.code}_PLAN`] = filterData.filter(f=> f[`${d.code}_PF`] <= this.timeline.cutoff).length
        d[`${d.code}_ACT`] = filterData.filter(f=> f[`${d.code}_AF`] <= this.timeline.cutoff).length
        d[`${d.code}_DTL`] = d[`${d.code}_ACT`] - d[`${d.code}_PLAN`]
      })
      // console.log(this.activity) //////////////

      let x = 0
      this.status.forEach(d=> {
        d.mx = x + d.width/2
        if(d.align == 'start') d.tx = x
        if(d.align == 'middle') d.tx = x + d.width/2
        if(d.align == 'end') d.tx = x + d.width
        x = x + d.width
      })
      this.status_Length = x

      this.draw_BlockChart() // -----> below
    },


    draw_BlockChart() {

      let block = this.canvasForms
      .append('g')
      .attr('transform', `translate(22.5, 80.5)`)
      .attr('font-family', 'roboto')

      // Header
      block
      .append('text')
      .attr('x',0).attr('y', -17)
      .attr('font-size', 12).attr('fill', '#333')
      .text('Block Status')

      block
      .append('line')
      .attr('x1', 0).attr('y1', -12).attr('x2', this.status_Length).attr('y2', -12)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      this.status.forEach(d=> {
        block
        .append('text')
        .attr('x', d.tx).attr('y', 0).attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end')
        .text(d.name)
      })

      block
      .append('line')
      .attr('x1', 0).attr('y1', 5).attr('x2', this.status_Length).attr('y2', 5)
      .attr('stroke', '#757575').attr('stroke-width', .5)


      // DataGrid
      let y = 20
      this.activity.forEach((d,i)=> {
        
        if(d[`${d.code}_TOT`] == 0) return 

        block
        .append('text')
        .attr('x',this.status[0].tx).attr('y', y).attr('font-size', 9).attr('fill', this.status[0].color).attr('text-anchor', this.status[0].align)
        .text(d.name)

        this.status.filter(f=> f.name != 'Stage').forEach(s=> {
          block
          .append('text')
          .attr('x', s.tx-3).attr('y', y)
          .attr('fill', (s.name == 'Delta') ? this.setDeltaColor(d[`${d.code}${s.code}`]) : s.color)
          .attr('font-size', 9).attr('text-anchor', s.align)
          .text(d[`${d.code}${s.code}`])
        })
        y = y + 16
      })
      
    },


  }
}