
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvas_Form = this.svg
      .append('g')
      .attr('id', `CANVAS_FROM`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.canvas_Form
      .append('text')
      .attr('transform', `translate(20, 30)`)
      .attr('font-size', 18).attr('fill', '#000')
      .text(this.Props.key)

      this.canvas_Form
      .append('text')
      .attr('transform', `translate(20, 45)`)
      .attr('font-size', 11).attr('fill', '#757575')
      .text('Outfitting Status')


      let array = [ ...new Set(this.dataSet.map(d => d.DISC)) ]

      // Future
      array.push('E & I')
      array.push('HVAC/ARCH.')

      let y = 0
      array.forEach((d, i)=> {

        let data = this.dataSet.filter(f=>f.DISC == d)

        this.draw_Status(data, d, y, this.barColor[i])
        
        y = y + (data.length * 25) + 100
      })

    },
  }
}