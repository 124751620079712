import * as d3 from 'd3'
import moment from 'moment'

export default {

  methods: {

    set_LocalValues(){
     
      this.tableData = JSON.parse(JSON.stringify(this.Queries.SQL1)) 
      this.trendData = JSON.parse(JSON.stringify(this.Queries.SQL2))
      this.chartData = JSON.parse(JSON.stringify(this.Queries.SQL3)) 

      this.chartData.forEach(d=> {
        let eDate = moment(`${d.CDATE} 23:59:59`).toDate()
        let sDate = moment(d.CDATE).add(-6, 'days').toDate()
        d.mDate = new Date(d3.mean([sDate, eDate]))
      })


      // Data Preprocessing for the Table Summary
      let x_ = 0
      this.table.column.forEach(d=> {
        if(d.align == 'start') d.tx = x_ + this.table.padding
        if(d.align == 'middle') d.tx = x_ + (d.width/2)
        if(d.align == 'end') d.tx = x_ + d.width - this.table.padding
        x_ = x_ + d.width

        d.ex = x_          
      })

      this.table.width = x_   
      let area = [...new Set(this.tableData.map(m => m.AREA))]  
      area.forEach((a) => { 
        let area_Len = this.tableData.filter(f => f.AREA == a).length
        let zone = [...new Set(this.tableData.filter(f => f.AREA == a).map(m=>m.ZONE))]
        if(area_Len == 2) {
          let find_1stIndex = this.tableData.findIndex(f => f.AREA == a && f.ZONE == 'Sub-Total')
          this.tableData.splice(find_1stIndex, 1)
        }
        zone.forEach((z)=>{
          let zone_Len = this.tableData.filter(f=>f.AREA ==a && f.ZONE ==z ).length
          if(zone_Len ==2){
            let find_2ndIndex = this.tableData.findIndex(f=>f.AREA == a && f.ZONE ==z && f.YARD == 'Sub-Total')
            this.tableData.splice(find_2ndIndex, 1)    
          }
        })        
      })

      this.tableData.forEach((d,i)=>{     
        if(i<this.tableData.length-3){ 
          if( d.AREA == this.tableData[i+1].AREA) d.sx = this.table.column[0].ex
          if( d.ZONE == this.tableData[i+1].ZONE) d.sx = this.table.column[1].ex
        }           
      }) 


      


      // Data Preprocessing for the History Chart
      this.timeline.cutoff = this.DataItems[0].CUTOFF
      this.timeline.startDate = this.DataItems[0].START_DATE
      this.timeline.endDate = this.DataItems[0].END_DATE
      this.timeline.length = this.chart.width

      
    },    
  }
}