
import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_Overall() {
      this.current.stage = 'OVERALL'
      this.current.zone = null
      this.current.svg = null
      this.current.sn = null

      this.model_Overall = this.svg
      .append('g')
      .attr('id', `MODEL_OVERALL`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      // Load a Legend SVG
      this.loadSvg(
        this.model_Overall, {
        x: 0, y: 0,
        url : '/Visualization/Layout_Equipment/OVERALL.svg'
      }).then(() => {
        d3.selectAll('text').style('font-family', 'roboto')

        this.areaSummary.filter(f=> f.MODULE =='Y').forEach(d=> {
          d3.select(`#MASK-${d.ZONE}`).attr('cursor', 'pointer')
          .style('opacity', 0).call(this.call_Area, d)
        })

        this.draw_Overall_Canvas() // ----->> canvas_Overall.js
      })

      this.set_selectedItems()
    },


    draw_Overall_Canvas(_d) {

      this.canvas_Overall = this.svg
      .append('g')
      .attr('id', `CANVAS_OVERALL`).attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      d3.select(`#TITLE_MAIN`).text(`FPU OVERALL`)
      d3.select(`#TITLE_SUB`).attr('x', this.getNode('#TITLE_MAIN','width') + 35).text(' STATUS')

      this.bar_Summary(this.canvas_Overall, this.areaSummary.find(f=> f.ZONE == 'OVERALL'), {idx: 'OV', size: 'L', x: 90,   y: 270, bar: 250})
      this.bar_Summary(this.canvas_Overall, this.areaSummary.find(f=> f.ZONE == 'FLARE'),   {idx: 'FL', size: 'M', x: 300,  y: 730, bar: 150})
      this.bar_Summary(this.canvas_Overall, this.areaSummary.find(f=> f.ZONE == 'HULL'),    {idx: 'HU', size: 'M', x: 1070, y: 860, bar: 150})
      this.bar_Summary(this.canvas_Overall, this.areaSummary.find(f=> f.ZONE == 'NORTH'),   {idx: 'NO', size: 'M', x: 630,  y: 630, bar: 150})
      this.bar_Summary(this.canvas_Overall, this.areaSummary.find(f=> f.ZONE == 'CENTRAL'), {idx: 'CN', size: 'M', x: 830,  y: 520, bar: 150})
      this.bar_Summary(this.canvas_Overall, this.areaSummary.find(f=> f.ZONE == 'SOUTH'),   {idx: 'SO', size: 'M', x: 1020, y: 410, bar: 150})
      this.bar_Summary(this.canvas_Overall, this.areaSummary.find(f=> f.ZONE == 'LQ'),      {idx: 'LQ', size: 'M', x: 1120, y: 200, bar: 150})

    },

  }
}