
export default {
  data: () => ({

    localData: {
      JSON1: [
        { SN: '1',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN-S',   BLK: 'CC410', TITLE: 'Caission', },
        { SN: '2',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS-S',   BLK: 'CC420', TITLE: 'Caission', },
        { SN: '3',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EN-S',   BLK: 'CC430', TITLE: 'Caission', },
        { SN: '4',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES-S',   BLK: 'CC440', TITLE: 'Caission', },
        { SN: '5',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-UCFC',   BLK: 'CF112', TITLE: 'UCF CENTER', },
        { SN: '6',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EN',     BLK: 'EC112', TITLE: 'Lower Column (East-North)', },
        { SN: '7',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EN',     BLK: 'EC1G2', TITLE: 'Upper Column (East-North)', },
        { SN: '8',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EN',     BLK: 'EC1K2', TITLE: 'Middle Column (East-North)', },
        { SN: '9',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES',     BLK: 'EC212', TITLE: 'Lower Column (East-South)', },
        { SN: '10',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES',     BLK: 'EC2G2', TITLE: 'Upper Column (East-South)', },
        { SN: '11',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES',     BLK: 'EC2K2', TITLE: 'Middle Column (East-South)', },
        { SN: '12',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EN',     BLK: 'EC3A0', TITLE: 'Column Support', },
        { SN: '13',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES',     BLK: 'EC4A0', TITLE: 'Column Support', },
        { SN: '14',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EN',     BLK: 'ECP10', TITLE: 'Access P/F (East-North)', },
        { SN: '15',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EN',     BLK: 'ECP30', TITLE: 'Access P/F (East-North)', },
        { SN: '16',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES',     BLK: 'ECP40', TITLE: 'Access P/F (East-South)', },
        { SN: '17',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES',     BLK: 'ECP50', TITLE: 'Access P/F (East-South)', },
        { SN: '18',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES',     BLK: 'ECP60', TITLE: 'Access P/F (East-South)', },
        { SN: '19',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES',     BLK: 'ECP70', TITLE: 'Access P/F (East-South)', },
        { SN: '20',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-UCFE',   BLK: 'EF212', TITLE: 'UCF East', },
        { SN: '21',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-UCFE',   BLK: 'EF2B0', TITLE: 'UCF East', },
        { SN: '22',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-UCFE',   BLK: 'EF2C0', TITLE: 'UCF East', },
        { SN: '23',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EN',     BLK: 'EN112', TITLE: 'Node (East-North)', },
        { SN: '24',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ENC',    BLK: 'EN161', TITLE: 'Diagonal Node (East-North)', },
        { SN: '25',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES',     BLK: 'EN212', TITLE: 'Node (East-South)', },
        { SN: '26',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ESC',    BLK: 'EN261', TITLE: 'Diagonal Node (East-South)', },
        { SN: '27',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EN',     BLK: 'EN410', TITLE: 'Push Knee', },
        { SN: '28',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EN',     BLK: 'EN420', TITLE: 'Push Knee', },
        { SN: '29',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES',     BLK: 'EN510', TITLE: 'Push Knee', },
        { SN: '30',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES',     BLK: 'EN520', TITLE: 'Push Knee', },
        { SN: '31',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EAST',   BLK: 'EP112', TITLE: 'Pontoon (East)', },
        { SN: '32',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-UCFN',   BLK: 'NF212', TITLE: 'UCF North', },
        { SN: '33',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-NORTH',  BLK: 'NP112', TITLE: 'Pontoon (North)', },
        { SN: '34',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-NORTH',  BLK: 'NP151', TITLE: 'Pontoon (North)', },
        { SN: '35',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-NORTH',  BLK: 'NPB10', TITLE: 'Porch Basket', },
        { SN: '36',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN-S',   BLK: 'RC410', TITLE: 'Riser Pipe', },
        { SN: '37',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS-S',   BLK: 'RC420', TITLE: 'Riser Pipe', },
        { SN: '38',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-EN-S',   BLK: 'SC410', TITLE: 'Sump Pile', },
        { SN: '39',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-ES-S',   BLK: 'SC420', TITLE: 'Sump Pile', },
        { SN: '40',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-UCFS',   BLK: 'SF212', TITLE: 'UCF South', },
        { SN: '41',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-SOUTH',  BLK: 'SP112', TITLE: 'Pontoon (South)', },
        { SN: '42',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-SOUTH',  BLK: 'SP151', TITLE: 'Pontoon (South)', },
        { SN: '43',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WC112', TITLE: 'Lower Column (West-North)', },
        { SN: '44',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WC1G2', TITLE: 'Upper Column (West-North)', },
        { SN: '45',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WC1K2', TITLE: 'Middle Column (West-North)', },
        { SN: '46',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS',     BLK: 'WC212', TITLE: 'Lower Column (West-South)', },
        { SN: '47',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS',     BLK: 'WC2G2', TITLE: 'Upper Column (West-South)', },
        { SN: '48',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS',     BLK: 'WC2K2', TITLE: 'Middle Column (West-South)', },
        { SN: '49',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WC3A0', TITLE: 'Column Support', },
        { SN: '50',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS',     BLK: 'WC4A0', TITLE: 'Column Support', },
        { SN: '51',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WCG10', TITLE: 'Middle Column (West-North)', },
        { SN: '52',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WCG20', TITLE: 'Middle Column (West-North)', },
        { SN: '53',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WCP10', TITLE: 'Access P/F (West-North)', },
        { SN: '54',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WCP20', TITLE: 'Access P/F (West-North)', },
        { SN: '55',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WCP30', TITLE: 'Access P/F (West-North)', },
        { SN: '56',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WCP40', TITLE: 'Access P/F (West-North)', },
        { SN: '57',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WCP50', TITLE: 'Access P/F (West-North)', },
        { SN: '58',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WCP60', TITLE: 'Access P/F (West-North)', },
        { SN: '59',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS',     BLK: 'WCP70', TITLE: 'Access P/F (West-South)', },
        { SN: '60',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS',     BLK: 'WCP80', TITLE: 'Access P/F (West-South)', },
        { SN: '61',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS',     BLK: 'WCP90', TITLE: 'Access P/F (West-South)', },
        { SN: '62',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-UCFW',   BLK: 'WF212', TITLE: 'UCF West', },
        { SN: '63',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-UCFW',   BLK: 'WF2B0', TITLE: 'UCF West', },
        { SN: '64',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-UCFW',   BLK: 'WF2C0', TITLE: 'UCF West', },
        { SN: '65',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WN112', TITLE: 'Node (West-North)', },
        { SN: '66',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WNC',    BLK: 'WN161', TITLE: 'Diagonal Node (West-North)', },
        { SN: '67',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS',     BLK: 'WN212', TITLE: 'node (West-South)', },
        { SN: '68',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WSC',    BLK: 'WN261', TITLE: 'Diagonal Node (West-South)', },
        { SN: '69',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WN410', TITLE: 'Push Knee', },
        { SN: '70',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WN',     BLK: 'WN420', TITLE: 'Push Knee', },
        { SN: '71',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS',     BLK: 'WN510', TITLE: 'Push Knee', },
        { SN: '72',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WS',     BLK: 'WN520', TITLE: 'Push Knee', },
        { SN: '73',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WEST',   BLK: 'WP111', TITLE: 'Pontoon (West)', },
        { SN: '74',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WEST',   BLK: 'WP112', TITLE: 'Pontoon (West)', },
        { SN: '75',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WEST',   BLK: 'WPB10', TITLE: 'Porch Basket', },
        { SN: '76',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',               KP: 'KP-WEST',   BLK: 'WPG10', TITLE: 'Pontoon (West)', },
        { SN: '77',  AREA: 'Topside', ZONE: 'Central', M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL',        KP: 'KP-CD11',   BLK: 'Central', TITLE: 'Topside Module (Central)', },
        { SN: '78',  AREA: 'LQ',      ZONE: 'LQ',      M_AREA: 'LQ',      M_ZONE: 'Living Quarter', KP: 'KP-LD11',   BLK: 'LQ',    TITLE: 'Living Qauter', },
        { SN: '79',  AREA: 'LQ',      ZONE: 'Heli',    M_AREA: 'LQ',      M_ZONE: 'Heli Deck',      KP: 'KP-LH11',   BLK: 'Heli',  TITLE: 'Heli Deck', },
        { SN: '80',  AREA: 'LQ',      ZONE: 'House',   M_AREA: 'LQ',      M_ZONE: 'Stair House',    KP: 'KP-LV51',   BLK: 'House', TITLE: 'Stair House', },
        { SN: '81',  AREA: 'Topside', ZONE: 'North',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH',          KP: 'KP-ND11',   BLK: 'North', TITLE: 'Topside Module (North)', },
        { SN: '82',  AREA: 'Topside', ZONE: 'Flare',   M_AREA: 'TOPSIDE', M_ZONE: 'FLARE',          KP: 'KP-NF11',   BLK: 'Flare', TITLE: 'Flare Tower', },
        { SN: '83',  AREA: 'Topside', ZONE: 'South',   M_AREA: 'TOPSIDE', M_ZONE: 'SOUTH',          KP: 'KP-SD11',   BLK: 'South', TITLE: 'Topside Module (South)', },
        { SN: '84',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-ND11-B', BLK: 'KD110', TITLE: 'Compressor Platform', },
        { SN: '85',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-ND11-B', BLK: 'ND110', TITLE: 'Production Deck', },
        { SN: '86',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-ND11-T', BLK: 'ND120', TITLE: 'Production Deck', },
        { SN: '87',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-ND11-B', BLK: 'ND210', TITLE: 'Mezz. Deck', },
        { SN: '88',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-ND11-B', BLK: 'ND220', TITLE: 'Mezz. Deck', },
        { SN: '89',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-ND11-T', BLK: 'ND230', TITLE: 'Mezz. Deck', },
        { SN: '90',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-ND11-T', BLK: 'ND250', TITLE: 'Mezz. Deck', },
        { SN: '91',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-ND11-B', BLK: 'ND310', TITLE: 'Main Deck', },
        { SN: '92',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-ND11-T', BLK: 'ND330', TITLE: 'Main Deck', },
        { SN: '93',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-ND11-S', BLK: 'NX110', TITLE: 'Stair Tower', },
        { SN: '94',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-SD11-T', BLK: 'ER310', TITLE: 'Erectrical Building', },
        { SN: '95',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-SD11-B', BLK: 'GD110', TITLE: 'Generator Building', },
        { SN: '96',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-SD11-B', BLK: 'SD110', TITLE: 'Production Deck', },
        { SN: '97',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-SD11-T', BLK: 'SD120', TITLE: 'Production Deck', },
        { SN: '98',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-SD11-B', BLK: 'SD310', TITLE: 'Main Deck', },
        { SN: '99',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-SD11-T', BLK: 'SD330', TITLE: 'Main Deck', },
        { SN: '100', AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-SD11-S', BLK: 'SX110', TITLE: 'Stair Tower', },
        { SN: '101', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-B', BLK: 'CD110', TITLE: 'Production Deck', },
        { SN: '102', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-T', BLK: 'CD130', TITLE: 'Production Deck', },
        { SN: '103', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-B', BLK: 'CD210', TITLE: 'Mezz. Deck', },
        { SN: '104', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-B', BLK: 'CD220', TITLE: 'Mezz. Deck', },
        { SN: '105', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-B', BLK: 'CD230', TITLE: 'Mezz. Deck', },
        { SN: '106', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-B', BLK: 'CD240', TITLE: 'Mezz. Deck', },
        { SN: '107', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-T', BLK: 'CD250', TITLE: 'Mezz. Deck', },
        { SN: '108', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-T', BLK: 'CD260', TITLE: 'Mezz. Deck', },
        { SN: '109', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-T', BLK: 'CD270', TITLE: 'Mezz. Deck', },
        { SN: '110', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-B', BLK: 'CD310', TITLE: 'Main Deck', },
        { SN: '111', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-T', BLK: 'CD340', TITLE: 'Main Deck', },
        { SN: '112', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-B', BLK: 'CK110', TITLE: 'Pedestal', },
        { SN: '113', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-T', BLK: 'CK140', TITLE: 'Pedestal', },
        { SN: '114', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',               KP: 'KP-CD11-S', BLK: 'CX110', TITLE: 'Stair Tower', },
        { SN: '115', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',               KP: 'KP-LD11',   BLK: 'LD110', TITLE: 'Level-1 Deck', },
        { SN: '116', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',               KP: 'KP-LD11',   BLK: 'LD210', TITLE: 'Level-2 Deck', },
        { SN: '117', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',               KP: 'KP-LD11',   BLK: 'LD310', TITLE: 'Level-3 Deck', },
        { SN: '118', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',               KP: 'KP-LD11',   BLK: 'LD410', TITLE: 'Level-4 Deck', },
        { SN: '119', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',               KP: 'KP-LD11',   BLK: 'LHS10', TITLE: 'Heli Deck Support', },
        { SN: '120', AREA: 'Flare',   ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',               KP: 'KP-NF11',   BLK: 'NF110', TITLE: 'Flare Tower', },
        { SN: '121', AREA: 'House',   ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',               KP: 'KP-LV51',   BLK: 'LV510', TITLE: 'Stair House', },
        { SN: '122', AREA: 'Heli',    ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',               KP: 'KP-LH11',   BLK: 'LH110', TITLE: 'Heli Deck', },
        ],

      
    }

  }),
}