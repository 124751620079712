import CONST from '../../../../../includes/primitives/_constant_trion'

export default {

  data: () => ({  

    HOST: CONST.HOST_NAME_RESOURCE + '/resources',
    
    canvasForm:     null,
    canvasTimeline: null,
    canvasSkyline:  null,

    rawData:        null,
    skyData:        null,
    filteredData:   null,
    stageCodes:       [],

    Toggle:           'on',

    timeline: {
      baseX           : 70.5,
      baseY           : 1000.5,
      length          : 1200,
      refColumn       : 'ETA', // ***
      weekEnd         : 'friday',
      week            : [],
      month           : [],
      year            : [],
      scale           : null,
      height          : 35,
    },
   
    skyline: {
      refColumn     : 'ETA',
      skyBox: {
        width       : 20,
        height      : 6,
        gap         : 1,
        radius      : 0,
        tSize       : 10,
      },
    },

  }),


  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

}