// Locally declared common Data-Props & SVG Drawing Support

import lmx_eqData                     from '../primitives/eq_Data'

import lmx_draw_Equipment             from './draw_Equipment'
import lmx_draw_Popup                 from './draw_Popup'
import lmx_draw_search                from './draw_search'
import lmx_draw_Summary               from './draw_Summary'
import lmx_draw_Detail               from './draw_Detail'

export default {
  
  mixins: [

    lmx_eqData,

    lmx_draw_Equipment,
    lmx_draw_Popup,
    lmx_draw_search,
    lmx_draw_Summary,
    lmx_draw_Detail,

  ],

}
