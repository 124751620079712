import * as d3 from 'd3'

export default {
  methods: {

    summary_Controller(_phase) {

      this.WBS.area.forEach((d,i)=> {

        let data = this.rawData.find(f=> // ##### dataSet -> rawData
          f.CDATE == this.timeline.cutoff && 
          f.LEV   == _phase && 
          f.AREA  == d.name && 
          f.DISC  == 'Overall'
        )

        if(this.current.phase.name == 'RFQ' && d.name == 'LQ') {
          d3.selectAll(`.AREA_LQ`).attr('visibility', 'hidden')
        }
        else {
          d3.selectAll(`.AREA_LQ`).attr('visibility', 'display')
          this.draw_Summary(d, data)
        }
      })
    },

    
    draw_Summary(_d, _data) {

      let gTexts = ['AC_WK','AC_CUM','PE_CUM', 'PL_CUM', 'DE_CUM', 'DL_CUM']
      let gIcons = ['DE_CUM','DL_CUM']
      
      // Text
      gTexts.forEach(d=> {
        let element = d3.select(`#${_d.id}_${d}`)
        element
        .transition().duration(500)
        .attr('fill', (d == 'DE_CUM' || d == 'DL_CUM') ? this.set_DeltaColor(_data[`${d}`], 'no') : '#000')
        .tween("text", function() {
          var i = d3.interpolateNumber(Number(element.text().replace('%', '')), _data[`${d}`])
          return function(t) {
          d3.select(this).text( `${Number(i(t).toFixed(1))}%` )
          }
        })

        if (d == 'AC_CUM') {
          let actY = 130 - _data.AC_CUM * (120/100)
          element
          .transition().duration(500)
          .attr('y', _data.AC_CUM > 70 ? actY + 12 : actY - 2)
          .text(`${_data.AC_CUM}%`)
        }
      })
      
      // Icon
      d3.select(`#ICON${_d.id}_DE_CUM`)
      .attr('d', this.set_DeltaColor(_data.DE_CUM, 'icon'))
      .attr('fill', this.set_DeltaColor(_data.DE_CUM, 'no'))

      d3.select(`#ICON${_d.id}_DL_CUM`)
      .attr('d', this.set_DeltaColor(_data.DL_CUM, 'icon'))
      .attr('fill', this.set_DeltaColor(_data.DL_CUM, 'no'))

      // Bar Chart
      let actY = 130 - _data.AC_CUM * (120/100)

      d3.select(`#BAR_${_d.id}`)
      .transition().duration(500)
      .attr('y', actY).attr('height', _data.AC_CUM * (120/100))

      d3.select(`#LINE_${_d.id}_H`).transition().duration(500)
      .attr('y1', 130 - _data.AC_CUM * (120/100)).attr('y2', 130 - _data.AC_CUM * (120/100))
      d3.select(`#LINE_${_d.id}_V`).transition().duration(500)
      .attr('y1', 130 - _data.AC_CUM * (120/100))

      d3.select(`#ADDRESS`)
      .text(`${this.current.phase.name == 'RFQ' ? 'Package' : this.current.phase.name} > ${this.current.area.name} > ${this.current.disc.name}`)

      this.WBS.disc.forEach((d,i)=> {
        this.draw_Disc_Summary(d)
      })
    },



    draw_Disc_Summary(_wbs) {
      
      d3.select(`#ADDRESS`)
      .text(`${this.current.phase.name == 'RFQ' ? 'Package' : this.current.phase.name} > ${this.current.area.name} > ${this.current.disc.name}`)

      let data = this.rawData.find(f=> // ##### dataSet -> rawData
        f.CDATE == this.timeline.cutoff && 
        f.LEV == this.current.phase.name && 
        f.AREA == this.current.area.name
        && f.DISC == _wbs.name
      )
      
      this.table.Depth3.filter(f=> f.code !== '').forEach((tbl,i)=> {

        let element = d3.select(`#DISC_${_wbs.id}_${tbl.code}_VALUE`)
        if (data == undefined) {
          element.text('')
          d3.select(`#DISC_${_wbs.id}`).transition().duration(500).attr('visibility', 'hidden')
          d3.select(`#DISC_GROUP_${_wbs.id}`).transition().duration(500).attr('opacity', 0)
          return
        } else {

          let val = data[`${tbl.code}`]
          let getVlaue = element.text()

          d3.select(`#DISC_${_wbs.id}`).transition().duration(500).attr('visibility', 'display')
          d3.select(`#DISC_GROUP_${_wbs.id}`).transition().duration(500).attr('opacity', 1)

          if(tbl.code == 'AC_CUM') {
            element
            .transition().duration(500)
            .attr('transform', (val>70) ? `translate(${tbl.tX-55 + val - 1}, 0)`: `translate(${tbl.tX-55 + val + 1}, 0)`)
            .attr('text-anchor', (val>70) ? 'end' : 'start')
            .tween("text", function() {
              var i = d3.interpolateNumber(Number(element.text().replace('%', '')), val)
              return function(t) {
              d3.select(this).text(`${Number(i(t).toFixed(1))}%`)
              }
            })
          } else {
            element
            .transition().duration(500)
            .attr('fill', (tbl.delta) ? this.set_DeltaColor(val, 'no') : '#000')
            .tween("text", function() {
              var i = d3.interpolateNumber(Number(getVlaue), Number(data[`${tbl.code}`]))
              return function(t) {
              d3.select(this).text(`${Number(i(t).toFixed(1))}`)
              }
            })
          }

          if(tbl.code == 'AC_CUM') {
            // Bar Chart
            d3.select(`#DISC_${_wbs.id}_${tbl.code}_BAR`).transition().duration(500)
            .attr('width', val).attr('height', 10)
  
         
          }
          
          if(tbl.code == 'DE_CUM' || tbl.code == 'DL_CUM') {
            // Icon
            d3.select(`#DISC_${_wbs.id}_${tbl.code}_ICON`)
            .attr('d', this.set_DeltaColor(val, 'icon'))
            .attr('fill', this.set_DeltaColor(val, 'no'))
          }
        }
      })
      
      
    },
  }
}