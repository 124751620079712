import * as d3 from 'd3'
import moment from 'moment'
export default {

  methods: {

    set_LocalValues(){

      // Get Data from data.js
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))

      // Filtering rawData
      this.chartData = this.rawData.filter(f=> f.CDATE >= sql1.START_DATE && f.CDATE <= sql1.END_DATE)
      this.chartData.forEach(d=> {
        d.month = d.CDATE.substr(0,7)
      })

      // add properties to chart
      this.timeline.cutoff = sql1.CUTOFF
      this.timeline.startDate = sql1.START_DATE
      this.timeline.endDate = sql1.END_DATE

      // Combine the WBS ---------------------------------
      let combine1 = []
      this.chartData.forEach(d=> {
        combine1.push(`${d.PHASE}/${d.GROUP}/${d.SUBCONT}`)
      })
      let combine2 = [ ...new Set(combine1.map(d => d)) ]

      combine2.forEach(d=>{
        if(!d.includes('OVERALL')) {
          this.combine.push({
            phase:    d.split('/')[0],
            group:    d.split('/')[1],
            subcont:  d.split('/')[2],
          })
        }
      })
      // console.log(this.combine)

      // Legends
      let x_ = 140 // width size of title column for the Legends 
      this.legends.forEach(d=> {
        d.x = x_
        x_ = x_ + d.width
      })

      this.set_Timeline_Month(this.timeline)
    },


    set_Timeline_Month(d_) {

      let sYear= parseInt(d_.startDate.substr(0,4))
      let sMonth = parseInt(d_.startDate.substr(5,2))

      let eYear = parseInt(d_.endDate.substr(0,4))
      let eMonth = parseInt(d_.endDate.substr(5,2))

      this.timeline.month_count = (eYear - sYear)* 12 + (eMonth - sMonth)
      this.timeline.monthWidth = this.chart.width / (this.timeline.month_count+1) // this.timeline.width -> Month Width

      // Set Month Values
      let m_ = sMonth, y_ = sYear
      for(let i=0; i<= this.timeline.month_count; i++) {
        this.timeline.month.push(
          { 
            code: d3.timeFormat('%Y-%m')(moment(d_.startDate).add(i, 'months')), 
            name: d3.timeFormat('%b')(moment(d_.startDate).add(i, 'months')), 
            month: m_,
            year: y_,
          }
        )
        if(m_ == 12) {m_ = 1; y_++} else m_++
      }

      // Set Year Values
      let year_ = [ ...new Set(this.timeline.month.map(d => d.year)) ]
      year_.forEach(d=> {
        this.timeline.year.push({name: d})
      })

      let x_ = 0
      this.timeline.year.forEach(d=> {
        let count = this.timeline.month.filter(f=> f.year == d.name).length
        x_ = x_ + (this.timeline.monthWidth * count)
        d.eDate = x_
        d.mDate = x_ - (this.timeline.monthWidth * count)/2
      })
    },



  }
}