
import * as d3 from 'd3'

export default {

  data: () => ({
    
    blocks: [
      {id: 'SC', name: 'Cutting Commenced',   ref: 'FAB',  pCode: 'SC_PS', aCode: 'SC_AS', opacity: .25},
      {id: 'FB', name: 'Fab. Commenced',      ref: 'FAB',  pCode: 'FB_PS', aCode: 'FB_AS', opacity: .40},
      {id: 'AS', name: 'Assembly Commenced',  ref: 'FAB',  pCode: 'AS_PS', aCode: 'AS_AS', opacity: .55},
      {id: 'AF', name: 'Assembly 100%',       ref: 'FAB',  pCode: 'AS_PF', aCode: 'AS_AF', opacity: .70},
      {id: 'PT', name: 'Block Painting 100%', ref: 'PE1',  pCode: 'PT_PF', aCode: 'PT_AF', opacity: .85},
      {id: 'ER', name: 'Block Setting',       ref: 'EREC', pCode: 'ER_PS', aCode: 'ER_AS', opacity: 1.0},
    ],
 
  }),

  methods: {

    status_Structure(selection, props) {
      // console.log(props)
      let textLen = 110, barLen = 120
      
      let filterData = this.setFilterData('Structure', props) // -----> set_LocalValues.js

      this.blocks.forEach(d=> {
        d.total = filterData.filter(f=> f[`${d.pCode}`] !== undefined).length
        d.actual = filterData.filter(f=> f[`${d.aCode}`] !== undefined).length

        d.pro = (d.total>0) ? d.actual / d.total : 0
        d.remain = d.total - d.actual
      })

      let barChart = selection
      .append('g')
      .attr('transform', `translate(${props.x}, ${props.y})`)
      .attr('font-family', 'roboto')

      barChart
      .append('text')
      .attr('id', 'TITLE')
      .attr('transform', `translate(0, -30)`)
      .attr('font-size', 18).attr('fill', '#F35E90')
      .text((props.head=='Y') ? props.filter : null)

      if(props.head=='Y' && props.stage == 'EREC') {
        let _level = this.masterList.find(f=>f.EREC == props.filter).LEVEL

        barChart
        .append('text')
        .attr('id', 'SUB')
        .attr('transform', `translate(${this.getNode(`#TITLE`, 'width') + 5}, -30)`)
        .attr('font-size', 11).attr('fill', '#757575')
        .text(_level)
      }

      barChart
      .append('text')
      .attr('transform', `translate(0, -7)`)
      .attr('font-size', 14).attr('fill', '#333')
      .text('Block Status')

      this.blocks.forEach((d,i) => {
        let y = i * 18

        barChart
        .append('text')
        .attr('transform', `translate(0, ${y + 10})`)
        .attr('font-size', 10).attr('fill', '#757575')
        .text(d.name)

        barChart
        .append('rect')
        .attr('id', `${d.id}_BBAR`)
        .attr('x', textLen).attr('y', y)
        .attr('width', barLen).attr('height', 13)
        .attr('fill', '#E0E0DF').attr('opacity', .2)

        barChart
        .append('text')
        .attr('id', `${d.id}_TOT`)
        .attr('transform', `translate(${textLen + barLen + 2}, ${y + 10})`)
        .attr('font-size', 10).attr('fill', '#757575')
        .text(d.total)
        .call(this.call_modal_Index, {
          req: 'BlockStatus',
          stage: props.stage,
          filter: props.filter,
          col: d.pCode,
        })

        barChart
        .append('rect')
        .attr('id', `${d.id}_ABAR`)
        .attr('x', textLen).attr('y', y)
        .attr('width', barLen * d.pro).attr('height', 13)
        .attr('fill', '#44A9DF').attr('opacity', d.opacity)

        barChart
        .append('text')
        .attr('id', `${d.id}_ACT`)
        .attr('x', d.pro < 0.8 ? textLen + barLen * d.pro + 2 : textLen + barLen * d.pro - 2)
        .attr('y', y + 10)
        .attr('text-anchor', d.pro < 0.8 ? 'start' : 'end')
        .attr('font-size', 10).attr('fill', '#333')
        .text(d.actual)
        .call(this.call_modal_Index, {
          req: 'BlockStatus',
          stage: props.stage,
          filter: props.filter,
          col: d.aCode,
        })
      })
    },


    redraw_Structure(props) {
      let textLen = 110, barLen = 120
      
      let filterData = this.setFilterData('Structure', props) // -----> set_LocalValues.js

      this.blocks.forEach(d=> {
        d.total = filterData.filter(f=> f[`${d.pCode}`] !== undefined).length
        d.actual = filterData.filter(f=> f[`${d.aCode}`] !== undefined).length

        d.pro = (d.total>0) ? d.actual / d.total : 0
        d.remain = d.total - d.actual
      })

      d3.select('#TITLE').text((props.head=='Y') ? props.filter : null)
      // if(props.head=='Y' && props.stage == 'EREC') {
      //   let _level = this.masterList.find(f=>f.EREC == props.filter).LEVEL
      //   d3.select('#SUB').text(_level)
      // }

      this.blocks.forEach((d,i) => {
        let num = d3.select(`#${d.id}_TOT`).text()
        d3.select(`#${d.id}_TOT`).transition().duration(200)
        .tween("text", function() {
          var i = d3.interpolateNumber(Number(num).toFixed(0), Number(d.total).toFixed(0))
          return function(t) {
          d3.select(this).text(`${i(t).toFixed(0)}`)
          }
        })
        d3.select(`#${d.id}_ABAR`).transition().duration(200).attr('width', barLen * d.pro)

        d3.select(`#${d.id}_ACT`).transition().duration(200)
        .attr('x', d.pro < 0.8 ? textLen + barLen * d.pro + 2 : textLen + barLen * d.pro - 2)
        .attr('text-anchor', d.pro < 0.8 ? 'start' : 'end')
        
        num = d3.select(`#${d.id}_ACT`).text()
        d3.select(`#${d.id}_ACT`).transition().duration(200)
        .attr('x', d.pro < 0.8 ?  textLen + barLen * d.pro + 2 :  textLen + barLen * d.pro - 2)
        .attr('text-anchor', d.pro < 0.8 ? 'start' : 'end')
        .tween("text", function() {
          var i = d3.interpolateNumber(Number(num).toFixed(0), Number(d.actual).toFixed(0))
          return function(t) {
          d3.select(this).text(`${i(t).toFixed(0)}`)
          }
        })
      })
    },


  }
}