import gmx_zMixins              from '../../../../../../includes/Jmixins/zMixins'

import fnc_zMixins              from '../../../../../Visualization/vFunctions/zMixins'

// Locally declared common Data-Props & SVG Drawing Support
import lmx_localData            from '../primitives/localData'

import lmx_Events               from './Events'
import lmx_Watching             from './Watching'


import lmx_LocalValues          from './set_LocalValues'
import lmx_draw_Forms           from './draw_Forms'

import lmx_draw_Stage           from './draw_Status'
import lmx_draw_BarChart        from './draw_BarChart'

export default {
  
  mixins: [
    gmx_zMixins,
    fnc_zMixins,
    lmx_localData,
    
    // Local Mixins
    lmx_Events,
    lmx_Watching,
    
    lmx_LocalValues,
    lmx_draw_Forms,
    lmx_draw_Stage,
    lmx_draw_BarChart,

  ],

}
