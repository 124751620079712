import CONST from '../../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    rawData:      [],
    chartData:    [],
    tableData:    [],
    canvasForms:  null,
    canvasTable:  null,
    canvasChart:  null,

    table: {
      width: null,
      height: null,
      lineheight: 20,
      style: [
        {name: 'Zone',    code: 'ZONE',   width: 90, align: 'start'},
        {name: 'Total',   code: 'TOT',    width: 45,  align: 'end'},
        {name: 'WO',      code: 'FAB_WO', width: 45,  align: 'end'},
        {name: 'Fab.',    code: 'FAB',    width: 45,  align: 'end'},
        {name: 'WO',      code: 'IST_WO', width: 45,  align: 'end'},
        {name: 'Install', code: 'IST',    width: 45,  align: 'end'},
      ],
      style2: [
        {name: 'Level',   code: 'LEVEL',  width: 105,  align: 'start'},
        {name: 'Total',   code: 'TOT',    width: 45,  align: 'end'},
        {name: 'WO',      code: 'FAB_WO', width: 45,  align: 'end'},
        {name: 'Fab.',    code: 'FAB',    width: 45,  align: 'end'},
        {name: 'WO',      code: 'IST_WO', width: 45,  align: 'end'},
        {name: 'Install', code: 'IST',    width: 45,  align: 'end'},
      ]
    },

    chart: {
      id: 'EQ',
      name: null,
      width:  340,
      height: 200,
      style: [
        {name: 'Fab. Remain',    code: 'FAB_REM', type: 'LINE', size: 1, color: '#bcbcbc'},
        {name: 'Install Remain', code: 'IST_REM', type: 'LINE', size: 1, color: '#83D2F5'},
        {name: 'Fab.-wk.',      code: 'FAB_WK',  type: 'BAR',  size: .5, color: '#bcbcbc'},
        {name: 'Install-wk',    code: 'IST_WK',  type: 'BAR',  size: .5, color: '#83D2F5'},
      ]
    },
    timeline:     {},
    primary:      {axis: null, max: null},
    secoundary:   {axis: null, max: null},

  }),


  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}