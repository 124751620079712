import * as d3 from 'd3'

export default {
  methods: {

    draw_SummaryTable() {

      this.canvasTable = this.svg
      .append('g')
      .attr('transform', `translate(20.5, 0.5)`)

      this.canvasTable
      .append('text')
      .attr('x', -10).attr('y', 20)
      .attr('font-size', 15).attr('fill', '#000')
      .text('Equipment Status')
      
      this.canvasTable
      .append('text')
      .attr('x', 0).attr('y', 50)
      .attr('font-size', 14).attr('fill', '#00bbf9')
      .text(this.chart.name)

      let refCol = 'ZONE'
      if (this.FilterValues.STAGE == 'ZONE') refCol = 'LEVEL'

      let data = this.tableData.find(f=> f[refCol] == 'TOTAL')

      let eq = [
        {name: 'PO', total: data.TOT, actual: data.PO,  color: '#ffb400', x: 0, y: 70},
        {name: 'FAT', total: data.TOT, actual: data.FAT, color: '#f6511d', x: 85, y: 70},
        {name: 'On-ATA', total: data.TOT, actual: data.ATA, color: '#00a6ed', x: 170, y: 70},
        {name: 'Install', total: data.TOT, actual: data.IST, color: '#7fb800', x: 255, y: 70},
      ]

      let summaryBar = this.canvasTable
      .append('g')
      .attr('transform', `translate(0, 0)`)

      eq.forEach(d=> {
        this.SummaryBar(summaryBar, d)
      })


      this.draw_TableHeader(95)
      this.draw_dataGrid(120)
    },

    SummaryBar(selection, _d) {
      let pro = 0
      let barLen = 75
      if(_d.actual > 0) pro = _d.actual/_d.total

      let barChart = selection
      .append('g')
      .attr('transform', `translate(${_d.x}, ${_d.y})`)

      barChart
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('font-size', 11).attr('fill', '#333')
      .text(_d.name)

      barChart
      .append('rect')
      .attr('x', 0).attr('y', 4)
      .attr('width', barLen).attr('height', 13).attr('fill', '#E0E0DF').attr('opacity', .3)

      barChart
      .append('rect')
      .attr('x', 0).attr('y', 4)
      .attr('width', barLen * pro).attr('height', 13).attr('fill',  _d.color).attr('opacity', .5)

      barChart
      .append('text')
      .attr('x', (pro>70) ? barLen * pro - 2 : barLen * pro + 2).attr('y', 14)
      .attr('font-size', 11).attr('fill', _d.color).attr('text-anchor', (pro>70) ? 'end' : 'start')
      .text(_d.actual)
    },


    draw_TableHeader(_y) {

      let header = this.canvasTable
      .append('g')
      .attr('transform', `translate(0, ${_y})`)

      header
      .append('line')
      .attr('x1', 0).attr('y1', 0).attr('x2', this.table.width).attr('y2', 0)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      header
      .append('line')
      .attr('x1', 0).attr('y1', 25).attr('x2', this.table.width).attr('y2', 25)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      this.table.style.forEach((d,i)=> {
        header
        .append('text')
        .attr('x', d.cx).attr('y', 8)
        .attr('font-size', 11).attr('alignment-baseline', 'hanging')
        .attr('fill', '#333')
        .attr('text-anchor', 'middle')
        .text(d.name)

        if(i < this.table.style.length-1) {
          header
          .append('line')
          .attr('x1', d.ex).attr('y1', 0).attr('x2', d.ex).attr('y2', 25)
          .attr('stroke-width', .5).attr('stroke', (i==0) ? '#757575' : '#bcbcbc')
        }
      })

    },



    draw_dataGrid(_y) {

      let dataGrid = this.canvasTable
      .append('g')
      .attr('transform', `translate(0, ${_y})`)
      .attr('cursor', 'default')

      let y = 0
      this.tableData.forEach((row,r)=> {

        this.table.style.forEach((col,c)=> {

          dataGrid
          .append('text')
          .attr('x', (r == this.tableData.length-1 && c == 0) ? col.cx : col.tx).attr('y', y+5)
          .attr('font-size', 10).attr('alignment-baseline', 'hanging')
          .attr('fill', (r == this.tableData.length-1) ? '#333' : '#757575')
          .attr('text-anchor', (r == this.tableData.length-1 && c == 0) ? 'middle' : col.align)
          .attr('font-weight', 400)
          .text(row[col.code])
          .call(this.call_LINK, row, col)

          if(c < this.table.style.length-1) {
            dataGrid
            .append('line')
            .attr('x1', col.ex).attr('y1', y).attr('x2', col.ex).attr('y2', y + this.table.lineheight)
            .attr('stroke-width', .5).attr('stroke', (c==0) ? '#757575' : '#bcbcbc')
          }
        })

        if(y > 0) {
          dataGrid
          .append('line')
          .attr('x1', 0).attr('y1', y).attr('x2', this.table.width).attr('y2', y)
          .attr('stroke-width', .5)
          .attr('stroke', (r == this.tableData.length-1) ? '#757575' : '#bcbcbc')
        }

        y = y + 20
      })

      dataGrid
      .append('line')
      .attr('x1', 0).attr('y1', y).attr('x2', this.table.width).attr('y2', y)
      .attr('stroke', '#757575').attr('stroke-width', .5)
    },


  }
}
           