export default {
  watch: {
    ChartType:  { handler(val) { this.register(this.draw); }, deep: true },
    Database:   { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Canvas:     { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Common:     { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    CTypes:     { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    DataCols:   { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    DataItems:  { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },  
    Text:       { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
  },
}