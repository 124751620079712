
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForm = this.svg
      .append('g')
      .attr('id', `CANVAS_FORM`)
      .attr('transform', `translate(0.5, 0.5)`)
      .attr('font-family', 'roboto')
      
      this.canvasForm
      .append('image')
      .attr('xlink:href', this.HOST + '/icon/Logo/TrionFPU_r1.svg').attr('x', 30).attr('y', 30).attr('width', 230)

      this.canvasForm
      .append('text')
      .attr('id', 'SKY_TITLE')
      .attr('transform', `translate(30, 118)`)
      .attr('font-size', 24)
      .attr('fill', '#000')
      .text(this.rawData.TITLE_SUB)

      let legends = this.canvasForm
      .append('g')
      .attr('transform', `translate(32, 150)`)

      legends
      .append('text')
      .attr('transform', `translate(0, 0)`)
      .attr('font-size', 14)
      .attr('fill', 'black')
      .text('Legend')

      legends
      .append('text')
      .attr('transform', `translate(3, 25.5)`)
      .attr('font-size', 10)
      .attr('fill', 'black')
      .text(`Total (${this.filteredData.length})`)

      legends
      .append('rect')
      .attr('transform', `translate(69, 14.5)`)
      .attr('fill', 'transparent').attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      .attr('rx', this.skyline.skyBox.radius).attr('ry', this.skyline.skyBox.radius)
      .attr('height', 15).attr('width', 62)

      legends
      .append('text')
      .attr('transform', `translate(71.5, 25)`)
      .attr('font-size', 10)
      .attr('fill', 'black')
      .text(`Package No.`)
      
      legends
      .append('text')
      .attr('transform', `translate(136, 25)`)
      .attr('font-size', 10)
      .attr('fill', '#333')
      .text(`NONE (${this.filteredData.filter(f => f.STATUS_CODE == 'NONE').length})`)

      legends
      .append('rect')
      .attr('transform', `translate(199, 15)`)
      .attr('fill', `#FEED57`)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      .attr('rx', this.skyline.skyBox.radius).attr('ry', this.skyline.skyBox.radius)
      .attr('height', 15).attr('width', 62)

      legends
      .append('text')
      .attr('transform', `translate(202, 25.5)`)
      .attr('font-size', 10)
      .attr('fill', 'black')
      .text(`Package No.`)

      legends
      .append('text')
      .attr('transform', `translate(268, 25.5)`)
      .attr('font-size', 10)
      .attr('fill', '#333')
      .text(`ETA > Early SRD (${this.filteredData.filter(f => f.STATUS_CODE == 'ETA > SRD').length})`)


      legends
      .append('rect')
      .attr('transform', `translate(372, 15)`)
      .attr('fill', `#F7BACF`)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      .attr('rx', this.skyline.skyBox.radius).attr('ry', this.skyline.skyBox.radius)
      .attr('height', 15).attr('width', 62)

      legends
      .append('text')
      .attr('transform', `translate(375, 25.5)`)
      .attr('font-size', 10)
      .attr('fill', 'black')
      .text(`Package No.`)

      legends
      .append('text')
      .attr('transform', `translate(440, 25.5)`)
      .attr('font-size', 10)
      .attr('fill', '#333')
      .text(`ETA > Late SRD (${this.filteredData.filter(f => f.STATUS_CODE == 'ETA > LATE SRD').length})`)


      legends
      .append('rect')
      .attr('transform', `translate(537, 15)`)
      .attr('fill', `#99ff99`)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      .attr('rx', this.skyline.skyBox.radius).attr('ry', this.skyline.skyBox.radius)
      .attr('height', 15).attr('width', 62)

      legends
      .append('text')
      .attr('transform', `translate(540, 25.5)`)
      .attr('font-size', 10)
      .attr('fill', 'black')
      .text(`Package No.`)

      legends
      .append('text')
      .attr('transform', `translate(605, 25.5)`)
      .attr('font-size', 10)
      .attr('fill', '#333')
      .text(`ATA (${this.filteredData.filter(f => f.STATUS_CODE == 'ATA').length})`)
    },


    

    

   

  }
}