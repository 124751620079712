
import * as d3 from 'd3'

export default {

  data: () => ({
  
    outfitting: [
      // Spool
      {id: 'S1', item: 'Spool', act: 'Fab. W/O', ref: 'PS_WO',   opacity: .3},
      {id: 'S2', item: 'Spool', act: 'Fab.',     ref: 'PS_SO',   opacity: .6},
      {id: 'S3', item: 'Spool', act: 'Install',  ref: 'PS_IST',  opacity: .9},

      // Equipment
      {id: 'E1', item: 'EQ',    act: 'PO',       ref: 'EQ_PO',   opacity: .3},
      {id: 'E2', item: 'EQ',    act: 'FAT',      ref: 'EQ_FAT',  opacity: .3},
      {id: 'E3', item: 'EQ',    act: 'ATA',      ref: 'EQ_SITE', opacity: .3},
      {id: 'E4', item: 'EQ',    act: 'Install',  ref: 'EQ_IST',  opacity: .3},
    ]
    
  }),

  methods: {
    status_Outfitting(selection, props) {
      let dataSet = null

      // Piping ------------------------------------------------------------------
      dataSet = this.setFilterData('Spool', props) // -----> set_LocalValues.js

      this.outfitting.filter(f=> f.item == 'Spool').forEach(d=> {
        d.total = dataSet.map(m=> {return m.PS_TOT}).reduce((a, b) => (a + b))
        d.actual = dataSet.map(m=> {return m[d.ref]}).reduce((a, b) => (a + b))
        d.pro = (d.total>0) ? d.actual / d.total : 0
      })

      // Equipment ------------------------------------------------------------------
      dataSet = this.setFilterData('EQ', props) // -----> set_LocalValues.js
      
      this.outfitting.filter(f=> f.item == 'EQ').forEach(d=> {
        d.total = dataSet.map(m=> {return m.EQ_TOT}).reduce((a, b) => (a + b))
        d.actual = dataSet.map(m=> {return m[d.ref]}).reduce((a, b) => (a + b))
        d.pro = (d.total>0) ? d.actual / d.total : 0
      })

      let textLen = 100,  barLen = 100

      let barChart = selection
      .append('g')
      .attr('transform', `translate(${props.x}, ${props.y})`)
      .attr('font-family', 'roboto')

      barChart
      .append('text')
      .attr('transform', `translate(0, -7)`)
      .attr('font-size', 14).attr('fill', '#333')
      .text('Outfitting Status')

      let lastItem = null,  y = 0

      this.outfitting.forEach((d,i) => {
        if(lastItem == null || lastItem != d.item) y += 10

        barChart
        .append('text')
        .attr('transform', `translate(0, ${y + 10})`)
        .attr('font-size', 11).attr('fill', '#333')
        .text((lastItem == null || lastItem != d.item) ? d.item : '')

        barChart
        .append('text')
        .attr('transform', `translate(50, ${y + 10})`)
        .attr('font-size', 10).attr('fill', '#333')
        .text(d.act)

        barChart
        .append('rect')
        .attr('id', `${d.id}_BBAR`)
        .attr('x', textLen).attr('y', y)
        .attr('width', barLen).attr('height', 13)
        .attr('fill', '#E0E0DF').attr('opacity', .2)

        barChart
        .append('text')
        .attr('id', `${d.id}_TOT`)
        .attr('x', textLen + barLen + 2).attr('y', y + 10)
        .attr('font-size', 10).attr('fill', '#757575')
        .text(d.total)
        .call(this.call_modal_Index, {
          req: `${d.item}Status`,
          id: d.id,
          stage: props.stage,
          filter: props.filter,
          customKey: `${d.id}_T`
        })

        barChart
        .append('rect')
        .attr('id', `${d.id}_ABAR`)
        .attr('x', textLen).attr('y', y)
        .attr('width', barLen * d.pro).attr('height', 13)
        .attr('fill', '#44A9DF').attr('opacity', d.opacity)

        barChart
        .append('text')
        .attr('id', `${d.id}_ACT`)
        .attr('x', d.pro < 0.8 ?  textLen + barLen * d.pro + 2 :  textLen + barLen * d.pro - 2)
        .attr('y', y + 10)
        .attr('text-anchor', d.pro < 0.8 ? 'start' : 'end')
        .attr('font-size', 10).attr('fill', '#333')
        .text(d.actual)
        .call(this.call_modal_Index, {
          req: `${d.item}Status`,
          id: d.id,
          stage: props.stage,
          filter: props.filter,
          customKey: `${d.id}_A`,
          sep: 'WO'
        })

        y += 18
        lastItem = d.item
      })
    },

    redraw_Outfitting(props) {
      let dataSet = null
      
      // Piping ------------------------------------------------------------------
      dataSet = this.setFilterData('Spool', props) // -----> set_LocalValues.js

      this.outfitting.filter(f=> f.item == 'Spool').forEach((d,i)=> {
        d.total = dataSet.map(m=> {return m.PS_TOT}).reduce((a, b) => (a + b))
        d.actual = dataSet.map(m=> {return m[d.ref]}).reduce((a, b) => (a + b))
        d.pro = (d.total>0) ? d.actual / d.total : 0
      })

      // Equipment ------------------------------------------------------------------
      dataSet = this.setFilterData('EQ', props) // -----> set_LocalValues.js
      this.outfitting.filter(f=> f.item == 'EQ').forEach((d,i)=> {
        d.total = dataSet.map(m=> {return m.EQ_TOT}).reduce((a, b) => (a + b))
        d.actual = dataSet.map(m=> {return m[d.ref]}).reduce((a, b) => (a + b))
        d.pro = (d.total>0) ? d.actual / d.total : 0
      })

      let textLen = 100,  barLen = 100
      this.outfitting.forEach((d,i) => {

        let num = d3.select(`#${d.id}_TOT`).text()
        d3.select(`#${d.id}_TOT`).transition().duration(200)
        .tween("text", function() {
          var i = d3.interpolateNumber(Number(num).toFixed(0), Number(d.total).toFixed(0))
          return function(t) {
          d3.select(this).text(`${i(t).toFixed(0)}`)
          }
        })

        d3.select(`#${d.id}_ABAR`).transition().duration(200)
        .attr('width', barLen * d.pro)
        
        num = d3.select(`#${d.id}_ACT`).text()
        d3.select(`#${d.id}_ACT`).transition().duration(200)
        .attr('x', d.pro < 0.8 ?  textLen + barLen * d.pro + 2 :  textLen + barLen * d.pro - 2)
        .attr('text-anchor', d.pro < 0.8 ? 'start' : 'end')
        .tween("text", function() {
          var i = d3.interpolateNumber(Number(num).toFixed(0), Number(d.actual).toFixed(0))
          return function(t) {
          d3.select(this).text(`${i(t).toFixed(0)}`)
          }
        })

      })
    },

  }
}