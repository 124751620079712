import * as d3 from 'd3'

export default {
  
  methods: {

    draw_Block_Canvas(_d) {

      // console.log('BLOCK-> ', _d) /////

      this.canvas_Block = this.svg
      .append('g')
      .attr('id', `CANVAS_BLOCK`).attr('transform', `translate(0,0)`).attr('font-family', 'roboto')

      this.gmx_btn_Navigation(
        this.canvas_Block, 
        {type: 'home', x: 450, y: 78, scale: 0.8,}, 
        {
          remove: [this.model_Block, this.canvas_Block,],
          exeFunc: this.draw_Model_Overall,
          data: null
        }
      )

      if (this.current.area == 'Hull') {
        this.gmx_btn_Navigation(
          this.canvas_Block, 
          {type: 'back', x: 490, y: 78, scale: 0.8,}, 
          {
            remove: [this.model_Block, this.canvas_Block,],
            exeFunc: this.back_Model_Erection,
            data: _d
          }
        )
      }

      if (this.current.area == 'Topside' || this.current.area == 'LQ') {
        this.gmx_btn_Navigation(
          this.canvas_Block, 
          {type: 'back', x: 490, y: 78, scale: 0.8,}, 
          {
            remove: [this.model_Block, this.canvas_Block,],
            exeFunc: this.back_Model_2PE,
            data: _d
          }
        )
      }

      // Load a Legend SVG
      let KeyplanArea = this.current.area
      if(this.current.area == 'LQ') KeyplanArea = 'Topside'
      this.loadSvg(
        this.canvas_Block, {
        x: 30, y: 140,
        url : `/Visualization/includes/Keyplan_${KeyplanArea}.svg`
      }).then(() => {

        this.keyPlan.forEach(d => {
          if(_d.KP !== d) d3.select(`#${d}`).style('opacity', 0)
        })
        d3.selectAll("text").style('font-family', 'roboto')
      })


      // console.log(_d) //////////
      let _blk = _d.BLK.toUpperCase()
      this.status_Structure(this.canvas_Block, { x: 30, y: 380, stage: 'EREC', filter: _blk, head: 'Y'})
      this.status_Outfitting(this.canvas_Block, {x: 30, y: 530, stage: 'EREC', filter: _blk,})

    },
  }
}