import * as d3 from 'd3'

export default {

  methods: {
    
    draw_Timeline() {
      this.timeline.scale = d3.scaleTime()
      .domain([this.timeline.week[0].sDate, this.timeline.week[this.timeline.week.length - 1].eDate])
      .range([0, this.chart.width])

      let Timeline = this.canvasChart
      .append('g')
      .attr('class', 'TIMELINES')
      .attr('transform', `translate(${0}, ${this.chart.height + .5})`)
      .style('font-family', 'roboto')

      // Week -----------------------------------------------------------
      Timeline
      .append('g')
      .selectAll('rect')
      .data(this.timeline.week) // *** week
      .enter()
      .append('rect')
      .attr('id', (d,i) => `TIME_${i}`)
      .attr('x', (d) => this.timeline.scale(d.sDate)).attr('y', 0)
      .attr('width', d => this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate)).attr('height', 12)
      .attr('fill', '#83D2F5')
      .attr('opacity', d => (d.formatDate == this.timeline.cutoff) ? 1 : 0)
      
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.week) // *** Month
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate)).attr('y', 2)
      .style('font-size', 9)
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text((d,i) => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.week) // *** week
      .enter()
      .append('path')
      .attr('d', (d,i) => i == this.timeline.week.length - 1 ? '' : `M${this.timeline.scale(d.eDate)}, -0 V 11`)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      
      // Month -----------------------------------------------------------
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 16)
      .style('font-size', 9).attr('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.month) // *** week
      .enter()
      .append('path')
      .attr('d', (d,i) => (i==this.timeline.month.length-1) ? '' : `M${this.timeline.scale(d.eDate)}, 11 V 27`)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .3)
      
      // Year -----------------------------------------------------------
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 30)
      .style('font-size', 10).attr('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year) // *** week
      .enter()
      .append('path')
      .attr('d', (d,i)=> (i==this.timeline.year.length-1) ? '' : `M${this.timeline.scale(d.eDate)}, 27 V 43`)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .3)
    }, 



  }
}
