
export default {
  data: () => ({

    localData: {
      
      JSON1: [
        {
          // Timeline
          WEEKEND        : 'friday',

          // Chart
          CHART_X       : 60,
          CHART_Y       : 40,
          CHART_WIDTH   : 550,
          CHART_HEIGHT  : 185, 
  
          // Chart Properties
          NAME          : 'Plan-Cuml. / Actual-Cuml. / Plan-wk    / Actual-wk ',
          CHT_TYPE      : 'LINE       / LINE         / BAR        / BAR       ',
          LINE_TYPE     : 'PLAN       / ACT          / PLAN       / ACT       ',
          LINE_SIZE     : '.5         / 1.5          /            / 1.5       ',
          LINE_COLOR    : '#A7A7A7    / #44A9DF      / #bcbcbc    / #83D2F5   ',
          OPACITY       : '           /              / .2         / .7        ',
        },
      ],

      STAGE: [
        {
          id:     'SC',
          text:   'Cutting',
          stage:  'CUTTING',
          codes:  'PS_CUM / AS_CUM / PS_WK / AS_WK',
          codes2: 'PF_CUM / AF_CUM / PF_WK / AF_WK',
          start:  '2024-10-01',
          end:    '2025-09-26',
        },
        {
          id:     'FB',
          text:   'Fabrication',
          stage:  'FABRICATION',
          codes:  'PS_CUM / AS_CUM / PS_WK / AS_WK',
          codes2: 'PF_CUM / AF_CUM / PF_WK / AF_WK',
          start:  '2024-10-01',
          end:    '2025-09-26',
        },
        {
          id:     'AS',
          text:   'Assembly',
          stage:  'ASSEMBLY',
          codes:  'PS_CUM / AS_CUM / PS_WK / AS_WK',
          codes2: 'PF_CUM / AF_CUM / PF_WK / AF_WK',
          start:  '2024-11-01',
          end:    '2025-10-31',
        },
        {
          id:     'PT',
          text:   'Painting',
          stage:  'PAINT',
          codes:  'PS_CUM / AS_CUM / PS_WK / AS_WK',
          codes2: 'PF_CUM / AF_CUM / PF_WK / AF_WK',
          start:  '2025-02-07',
          end:    '2026-01-30',
        },
      ],

      AREA: [
        {text: 'Hull',    code: 'HULL',    yard: 'HHI',   x:15,  y:40  },
        {text: 'Hull',    code: 'HULL',    yard: 'COSCO', x:700, y:40  },
        {text: 'LQ',      code: 'LQ',      yard: 'HHI',   x:15,  y:300 },
        {text: 'Topside', code: 'TOPSIDE', yard: 'HHI',   x:700, y:300 },
      ],
      




    }
  }),
}