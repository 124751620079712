import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'


export default {

  methods: {

    set_LocalValues(){

      // EPC Progress
      this.progress = JSON.parse(JSON.stringify(this.DataItems[0]))

      // master Block List
      this.masterList = JSON.parse(JSON.stringify(this.Queries.SQL1))

      // Erection Block List with Coordinates for animation
      this.erectionList = this.localData.JSON1
      this.keyPlan = [ ...new Set(this.erectionList.map(d => d.KP)) ]


      // Outfitting -------------------------------------------------------------------------------------------------
      this.outfittingList = JSON.parse(JSON.stringify(this.Queries.SQL2))
      this.eqList = JSON.parse(JSON.stringify(this.Queries.SQL3))

      // link-filterText
      if (this.Queries.SQL4) this.filterText = this.Queries.SQL4[0]
    },

    setFilterData(_item, _d) {
      // console.log(_d.stgCode)
      let dataSet = null

      if(_item == 'Structure') dataSet = this.masterList
      else dataSet = this.outfittingList

      let data_ = null
      switch (_d.stage) {
        case 'OVERALL': data_ = dataSet 
        break;
        case 'AREA': data_ = dataSet.filter(f=> f.AREA == _d.filter)
        break;
        case 'ZONE': data_ = dataSet.filter(f=> f.ZONE == _d.filter)
        break;
        case 'EREC': data_ = dataSet.filter(f=> f.G_EREC == _d.filter)
        break;
      }
      return data_
    },



    loadSvg(selection, data) {
      return d3.xml(`${this.HOST}${data.url}`).then(source => {
 
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)
        .attr('width', data.width)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },

    set_Gradients() {

      // set Gradient color for the Timeline
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'Pink', [.5, .8, .5, -1], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'LightBlue', [.5, 1, .7, -.1], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'Yellow', [.5, .8, .5, 0], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'YellowGreen', [.5, .8, .5, 0], this.localId)

      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'Pink', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'LightBlue', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'Yellow', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'YellowGreen', [.8, .5, .0, .5], this.localId)
    },

  }
}