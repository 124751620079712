import * as d3 from 'd3'

export default {

  methods: {
    
    draw_Timeline() {    
      
      this.timeline.scale = d3.scaleBand()
      .domain(this.timeline.month.map(d => d.code))
      .range([0, this.chart.width])
      .padding(0.0)

      let Timeline = this.canvasChart
      .append('g')
      .attr('class', 'TIMELINES')
      .attr('transform', `translate(${0}, ${this.chart.height + .5})`)
      .style('font-family', 'roboto')

      // Month -----------------------------------------------------------
      Timeline
      .append('g')
      .selectAll('rect')
      .data(this.timeline.month) // *** week
      .enter()
      .append('rect')
      .attr('id', d => `TIME_${d.code}`)
      .attr('x', (d)=> this.timeline.scale(d.code)).attr('y', 0)
      .attr('width', this.timeline.monthWidth).attr('height', 12)
      .attr('fill', '#83D2F5')
      .attr('opacity',d=> (d.code == this.timeline.cutoff.substr(0,7)) ? 1 : 0)
      
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month) // *** Month
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.code) + (this.timeline.monthWidth/2)).attr('y', 2)
      .style('font-size', 9)
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text((d,i) => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.month) // *** week
      .enter()
      .append('path')
      .attr('d', (d)=> `M${this.timeline.scale(d.code)}, -0 V 11`)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      
      // Year -----------------------------------------------------------
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => d.mDate)
      .attr('y', 16)
      .style('font-size', 10).attr('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year) // *** week
      .enter()
      .append('path')
      .attr('d', (d,i)=> (i==this.timeline.year.length-1) ? '' : `M${d.eDate}, 11 V 27`)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .3)
    }, 



  }
}
