<template>
  <div class="j_page_layout_builder_svg__" :id='localId'>
    <component 
      v-for="(svg, i) in chartItems"
      v-bind="vBinds[svg.ChartNo]"
      :key="svg.index"
      :getSelectItems ="getSelectItems"
      :ref="`__ref__chart_item_${i}___${localId}`"
      :id="`__chart_item_${i}___${localId}`"
      :is="CAT_CODE_CHART_COMPONENT(svg.CatCode, svg)"
      :movable="false"
      :chart-type="svg.ChartType"
      :filter-values="filters"
      :callback="callback"
      @complete="(v) => onComplete(v, svg)"
      @request-action="(v) => onRequestedAction(v, svg)"
      @resize="onResized"
      @selectedItems ="selectedItems"
      @exportItems="exportItems"
    />
  </div>
</template>
<script>
import '@/assets/stylus/ui/component/_jDashboardLayoutBuilder.styl'

import __C from '@/primitives/_constant_'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import JSvgChartNComponents from '@/components/JLibChartComponents'
import { mapState, mapGetters } from "vuex"
export default {
  name: 'j-page-layout-builder-svg',
  mixins: [
    SafeIdMixin
  ],
  components: {
    ...JSvgChartNComponents
  },
  props: {
    mode: { type: String, default: 'layout' },
    chartMetaSupply: { type: Object, default: () => ({}) },
    chartItems: { type: Array, default: () => ([]) },
    chartItemUpdated: { type: Object, default: () => ({}) },
    filters: { type: Object, default: () => ({}) },
    callback: { type: Object, default: () => ({}) },
  },
  data: () => ({
    vBinds: {},
    dimention: {}
  }),
  computed: {
    __C_() { return __C },
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, [
      'getSelectItems'
    ]),  
  },
  watch: {
    chartItems: { 
      handler(val) {
        if(!val) return

        setTimeout(() => {
          this.onChartItemsChanged()
        })
      }, 
      deep: true 
    },
  },
  created() {
    this.localId = this.id || 'j-page-layout-builder__' + this.safeId('')
  },
  methods: {
    CAT_CODE_CHART_COMPONENT(catCode, chart) {
      let componentName__ = {
        HIS: () => 'JLibChartHistogram',
        SKY: () => 'JLibChartSkyline',
        TSM: () => 'JLibChartTableSummary',
        LIBSVGTB: (chart) => this.getCustomComponent(chart),
        LIBSVGPG: (chart) => this.getCustomComponent(chart)
      }
      return componentName__[catCode](chart)
    },
    onChartItemsChanged() {
      let vBinds__ = {}

      this.chartItems.forEach((item_, i) => {
          let props_ = this.setChartPropsData(item_, i)
          // if([
          //   __C.CHART.CAT_CODE_CUSTOM,
          //   __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER,
          //   __C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION,
          //   __C.CHART.CAT_CODE_COMPLIB_SVG_TAB,
          //   __C.CHART.CAT_CODE_COMPLIB_SVG_PAGE,
          //  ].includes(item_.catCode)) props_.FilterValues = this.filters

          vBinds__[item_.ChartNo] = props_
      })

      this.vBinds = vBinds__
    },
    onComplete(v, svg) { 
      this.$emit('complete', { id: v, chart: svg }) 
    },
    onRequestedAction(request, pagesvg) {
      let props_ = pagesvg.JsonProps ? JSON.parse(pagesvg.JsonProps) : null
      let prop_ = props_ ? props_.find(prop => prop.export) : null
      let export_ = prop_ ? prop_.export : null

      this.$emit('request-action', {
        export: export_,
        parent: {
          ...pagesvg.ActionAttrs
        },
        sender: {
          ...request
        }
      })
    },
    onRequestEmpty(req) {
      this.$emit('empty-check', req)
    },
    onResized(d) {
      this.dimention = d
      this.$emit('resize', d)
    },
    exportItems(items) {
      this.$emit('exportItems', items)
    },
    selectedItems(items) {
      this.$emit('selectedItems', items)
    },    
    parseJsonProps(chart) {
      return chart.JsonProps ? JSON.parse(chart.JsonProps) : []
    },
    extractDataItems(chart) {
      let dataItem_ = (
        chart.QApplied == __C.DATABASE.QUERY_APPLIED_JSON ? 
        (chart.JsonString ? JSON.parse(chart.JsonString) : []) :
        (chart.QResultSummary ? chart.QResultSummary : [])
      )

      return dataItem_
    },
    extractData(chart, typeName) {
      // check the data one column attributes (attributes-in-a-column type)
      if(chart[typeName]) return chart[typeName]

      let keyNames = Object.keys(chart).filter(k => k.toLowerCase().indexOf(typeName.toLowerCase()) === 0)
      let data = {}
      keyNames.forEach(k => { data[k] = chart[k] })

      return data
    },
    extractStyleStatus(chart) {
      return chart.StyleAttrs ? chart.StyleAttrs : chart.Status
    },
    extractValue(chart) {
      if(chart.CatCode == __C.CHART.CAT_CODE_DONUT) return chart.Values

      let keyNames = Object.keys(chart).filter(k => k.toLowerCase().indexOf('value') === 0)
      let data = {}
      keyNames.forEach(k => { data[k] = chart[k] })

      return data
    },
    extractSqlValues(chart) {
      let values_ = { 
        milestone: (
          chart.MilestoneQApplied == 'J' ? 
          (chart.MilestoneQJson ? JSON.parse(chart.MilestoneQJson) : []) : 
          chart.QResultMilestone
        ), 
        // note: '2019-11-01' // deprecated
      }
      // sql-result 'Note' is changed to Title Sql, and its result 
      // is stored in a specified column 'TitleSqlText' at the point
      // of getting the chart data.
      return values_
    },
    findData(chart, attrName, default_) {
      return chart[attrName] || default_
    },
    findTabAttributes(chart, el) {
      return chart.TabAttrs ? chart.TabAttrs[el] : {}
    },
    getCustomComponent(chart) {
      return chart.UrlEntrance
    },
    setChartPropsData(chart_, i) {
      let ref_ = `__ref__chart_item_${i}___${this.localId}`
      // Why undefined ?, Why .$options undefined... ????? Check it...
      if(!this.$refs[ref_]) return null  
      if(typeof this.$refs[ref_][0] == 'undefined') return null
      if(typeof this.$refs[ref_][0].$options == 'undefined') return null 
      let props__ = {
        ColumnProps : () => this.parseJsonProps(chart_)                                     , 
        DataItems   : () => this.extractDataItems(chart_)                                   ,
        Queries     : () => this.findData(chart_, __C.CHART.ATTR_NAME_Q_RESULT_EXTRAS, {})  ,
        Axis        : () => this.extractData(chart_, __C.CHART.ATTR_NAME_AXIS)              ,
        AxisY       : () => this.extractData(chart_, __C.CHART.ATTR_NAME_AXIS)              ,
        Bar         : () => this.extractData(chart_, __C.CHART.ATTR_NAME_BAR)               ,
        Boxes       : () => this.extractData(chart_, __C.CHART.ATTR_NAME_BOXES)             ,
        Canvas      : () => this.extractData(chart_, __C.CHART.ATTR_NAME_CANVAS)            ,
        Circle      : () => this.extractData(chart_, __C.CHART.ATTR_NAME_CIRCLE)            ,
        Compare     : () => this.extractData(chart_, __C.CHART.ATTR_NAME_COMPARE)           ,
        Delta       : () => this.extractData(chart_, __C.CHART.ATTR_NAME_DELTA)             ,
        Guideline   : () => this.extractData(chart_, __C.CHART.ATTR_NAME_GUIDELINE)         ,
        Legends     : () => this.extractData(chart_, __C.CHART.ATTR_NAME_LEGENDS)           ,
        Line        : () => this.extractData(chart_, __C.CHART.ATTR_NAME_LINE)              ,
        Milestone   : () => this.extractData(chart_, __C.CHART.ATTR_NAME_MILESTONE)         ,
        Note        : () => this.extractData(chart_, __C.CHART.ATTR_NAME_NOTE)              ,
        Package     : () => this.extractData(chart_, __C.CHART.ATTR_NAME_PACKAGE)           ,
        Timeline    : () => this.extractData(chart_, __C.CHART.ATTR_NAME_TIMELINE)          ,
        Status      : () => this.extractStyleStatus(chart_)                                 ,
        Value       : () => this.extractValue(chart_)                                       ,
        Values      : () => this.extractSqlValues(chart_)                                   ,
        Text        : () => this.findData(chart_, __C.CHART.ATTR_NAME_TEXT, {})             ,
        Table       : () => this.findData(chart_, __C.CHART.ATTR_NAME_TABLE, {})            ,
        Styles      : () => this.findData(chart_, __C.CHART.ATTR_NAME_STYLE, [])            ,
        Columns     : () => this.findData(chart_, __C.CHART.ATTR_NAME_COLUMNS, [])          ,
        Header      : () => this.findData(chart_, __C.CHART.ATTR_NAME_HEADER, [])           ,
        Footer      : () => this.findData(chart_, __C.CHART.ATTR_NAME_FOOTER, [])           ,
        Border      : () => this.findData(chart_, __C.CHART.ATTR_NAME_BORDER, [])           ,
        ChartData   : () => this.findData(chart_, __C.CHART.ATTR_NAME_CHARTDATA, [])        ,
        ModalDraw   : () => this.findTabAttributes(chart_, 'draw')                          ,
        ModalTaps   : () => this.findTabAttributes(chart_, 'tabs')                          ,
        TabStatus   : () => this.findData(chart_, __C.CHART.ATTR_NAME_TAB_STATUS, [])       ,
        Common      : () => this.findData(chart_, __C.CHART.ATTR_NAME_CHTCOMM, {})          , 
        CTypes      : () => this.findData(chart_, __C.CHART.ATTR_NAME_CHTTYPE, {})          , 
        DataCols    : () => this.findData(chart_, __C.CHART.ATTR_NAME_CHTCOL, {})           ,
        JQueries    : () => this.findData(chart_, __C.CHART.ATTR_NAME_JQUERIES, {})         ,
      }
      let propKeys__ = Object.keys(props__)
      let propData_ = {}

      this.$refs[ref_][0].$options._propKeys.forEach(k => {
        if(propKeys__.indexOf(k) < 0) return
        propData_[k] = props__[k]()
      })

      return propData_
    },
  }
}
</script>
