
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      let title = this.canvasForms
      .append('g')
      .attr('transform', `translate(0,0)`)

      title
      .append('text')
      .attr('transform', `translate(20, 25)`)
      .attr('font-size', 16).attr('fill', '#F35E90')
      .text(this.Props.title)

      title
      .append('text')
      .attr('transform', `translate(20, 39)`)
      .attr('font-size', 11).attr('fill', '#757575')
      .text(`${this.dataSet.AREA} / ${this.dataSet.ZONE} / ${this.dataSet.LEVEL} / ${this.dataSet.NAME}`)

      let imgX = 300, imgY = 100
      title
      .append('image')
      .attr('xlink:href', `${this.HOST}/Visualization/Modal_Window/${this.Props.file}.jpg`)
      .attr('x', imgX).attr('y', imgY).attr('width', 200)
      
      // Block Number
      title
      .append('ellipse')
      .attr('cx', imgX+100).attr('cy', imgY + 200 + 29)
      .attr('rx', 25).attr('ry', 9)
      .attr('fill', this.ellipse()).attr('stroke', '#757575').attr('stroke-width', .5)

      title
      .append('text')
      .attr('transform', `translate(400, ${imgY + 200 + 30})`)
      .attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(this.Props.key)

      title
      .append('text')
      .attr('transform', `translate(400, ${imgY + 200 + 50})`)
      .attr('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(`${this.filterData.L} x ${this.filterData.B} x ${this.filterData.H}`)


      this.loadSvg(
        this.canvasForms, {
        x: 10, y: 365, width: 810, height: 100,
        url : `${this.HOST}/Visualization/Modal_Window/Legend_Modal.svg`
      }).then(() => {})


      this.draw_Timeline_Month()
      // this.draw_Gantte_Block({blk: this.Props.key, y: 530})
      this.draw_singleGantt({data: this.filterData, y: 530, lineH: 50})
    },


    ellipse() {
  
      let colorRefs = [ 
        {code: 'SC_AS', color: '#e0e0df'},
        {code: 'FB_AS', color: '#ffe092'},
        {code: 'AS_AS', color: '#d7f7ff'},
        {code: 'AS_AF', color: '#83d2f5'},
        {code: 'PT_AF', color: '#f7bacf'},
        {code: 'ER_AS', color: '#8bc248'},
      ]
      let data = this.masterList.find(f=> f.BLK == this.Props.key)

      let color = 'none'
      colorRefs.forEach(d=> {
        if(data[d.code]) color = d.color
      })
      return color
    },

  }
}