import * as d3 from 'd3'

export default {
  methods: {

    chart_Trend() {

      let activity = [
        {name: 'Spool Fabrication',  code: 'FAB_WK', y: 10},
        {name: 'Spool Installation', code: 'INS_WK', y: 230},
      ]

      this.canvasTrend = this.svg

      let trend = this.canvasTrend
      .append('g')
      .attr('transform', `translate(810.5, 60.5)`)
      .style('font-family', 'roboto')

      // Data Preprocessing for the Trend Chart
      activity.forEach(d=> {
        
        this.trend.maxValue = 0

        let max = Math.max(...this.trendData.map(m => m[d.code]))
        if (max > this.trend.maxValue) this.trend.maxValue = max
        this.trend.maxValue = this.getMaxBound_v2(this.trend.maxValue)

        this.trend.scaleY = d3.scaleLinear()
        .domain([0, this.trend.maxValue])
        .range([this.trend.height, 0])

        this.trend.scaleX = d3.scaleBand()
        .domain(this.trendData.filter(f=> f.AREA == 'HULL').map(d => d.AREA == 'HULL' && d.CDATE ))
        .range([0, this.trend.width + ((this.trend.width/8)/2)]).padding(.5)

        this.trend_Canvas(trend, d)
        this.trend_Grids(trend, d)
        this.trend_Axis(trend, d)
        this.trend_Legend(trend, d)
        this.trend_Chart(trend, d)
      })
    },


    trend_Canvas(selection, act) {
      let canvas = selection
      .append('g')
      .attr('transform', `translate(0, ${act.y})`)

      canvas
      .append('text')
      .attr('x', 0).attr('y', -25)
      .style('font-size', 13).style('fill', '#333')
      .text(act.name)

      canvas
      .append('text')
      .attr('x', 110).attr('y', -25)
      .style('font-size', 10).style('fill', '#757575')
      .text('10 weeks Trends')

      canvas
      .append('line')
      .attr('x1', 0).attr('y1', 0).attr('x2', 0).attr('y2', this.trend.height)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      canvas
      .append('line')
      .attr('x1', 0).attr('y1', this.trend.height).attr('x2', this.trend.width).attr('y2', this.trend.height)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      canvas
      .append('line')
      .attr('x1', this.trend.width).attr('y1', 0).attr('x2', this.trend.width).attr('y2', this.trend.height)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
    },


    trend_Grids(selection, act) {

      let grid = selection
      .append('g')
      .attr('transform', `translate(0, ${act.y})`)


      let height_ = this.trend.height / 5
      for(let i=0; i<6; i++) {
        grid
        .append('line')
        .attr('x1', 0).attr('x2', this.trend.width)
        .attr('y1', height_ * i).attr('y2', height_ * i)
        .attr('stroke', '#E0E0DF').attr('stroke-width', 0.5)
        .attr('opacity', 0.5)

        grid
        .append('line')
        .attr('x1', 0).attr('x2', -2)
        .attr('y1', height_ * i).attr('y2', height_ * i)
        .attr('stroke', '#757575').attr('stroke-width', 0.5)

        grid
        .append('text')
        .attr('x', -5).attr('y', height_ * i)
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .text(`${(this.trend.maxValue-(i*(this.trend.maxValue/5))).toFixed(0)}`)
      }

      grid
      .append('text')
      .attr('transform', `translate(${-30}, ${this.trend.height/2}) rotate(-90)`)
      .style('font-size', 11).style('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('No. of Spool')
    },


    trend_Axis(selection, act){

      let axis = selection
      .append('g')
      .attr('transform', `translate(0, ${act.y})`)

      this.trendData.filter(f=> f.AREA == 'HULL').forEach((d,i)=> {
        let date_ = new Date(d.CDATE)

        axis
        .append('line')
        .attr('x1', this.trend.scaleX(d.CDATE)).attr('x2', this.trend.scaleX(d.CDATE))
        .attr('y1', this.trend.height).attr('y2', this.trend.height + 3)
        .attr('stroke', '#757575').attr('stroke-width', 0.5)
        .attr('opacity', 1)

        axis
        .append('text')
        .attr('x', this.trend.scaleX(d.CDATE))
        .attr('y', this.trend.height + 10)
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(`${date_.getDate()}/${date_.getMonth()+1}`)
      })
    },


    trend_Legend(selection, act) {

      let legends = selection
      .append('g')
      .attr('transform', `translate(${0}, ${act.y - 5})`)

      let x_ = 0
      this.trend.style.forEach((d,i)=> {

        let item = legends
        .append('g')
        .attr('id', `ITEM${i}`)
        .attr('transform', `translate(${0}, ${-5})`)
        .attr('font-family', 'roboto')

        item
        .append('rect')
        .attr('x', x_).attr('y', -4)
        .attr('width', 7).attr('height', 7)
        .attr('fill', d.color)

        item
        .append('text')
        .attr('id', `ITEM${i}_TEXT`)
        .attr('x', x_ + 10)
        .attr('y', 0)
        .style('font-size', 9).style('fill', '#757575')
        .attr('alignment-baseline', 'middle')
        .text(d.area)

        let len = d3.select(`#ITEM${i}`).node().getBBox().width
        x_ = x_ + len + 10
      })
    },


    trend_Chart(selection, act) {

      let size = 10
      let barChart = selection
      .append('g')
      .attr('transform', `translate(0,${act.y})`)

      this.trend.style.forEach((st,s)=> {

        barChart
        .selectAll(`rect_${s}`)
        .data(this.trendData.filter(f=> f.AREA == st.area))
        .enter()
        .append('rect')
        .attr('x', (d,i)=> {
          let x_ = this.trend.scaleX(d.CDATE)
          if(s==0) return x_ - size - (size/2) - 2
          if(s==1) return x_ - (size/2)
          if(s==2) return x_ + (size/2) + 2
        })
        .attr('y', d => this.trend.scaleY(d[act.code]))
        .attr('height', d => this.trend.height - this.trend.scaleY(d[act.code]))
        .attr('width', size)
        .attr('fill', st.color)
        .attr('stroke', st.color)
        .attr('stroke-width', 0)

        barChart
        .selectAll(`text_${s}`)
        .data(this.trendData.filter(f=> f.AREA == st.area))
        .enter()
        .append('text')
        .attr('x', (d,i)=> {
          let x_ = this.trend.scaleX(d.CDATE)
          if(s==0) return x_ - size - 2
          if(s==1) return x_
          if(s==2) return x_ + size + 2
        })
        .attr('y', d => this.trend.scaleY(d[act.code]))
        .attr('fill', st.color).attr('font-size', 9)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'ideographic')
        .text(d => d[act.code])
      })

    },


  }
}