
import fnc_draw_SingleGantt            from './draw_SingleGantt'
import fnc_draw_BlockStatus            from './draw_BlockStatus'




export default {
  
  mixins: [
    fnc_draw_SingleGantt,
    fnc_draw_BlockStatus,
    
  ],

}
