export default {
  data: () => ({

    search: {
      input: '',
      results: [],
      mod: null,
      lev: null,
    },
    Toggle:       'on',

    eq_status: [
      {code: 'PO',      name: 'PO Actual',  color: '#E1F4FD'},
      {code: 'FAT',     name: 'FAT Actual', color: '#90D1F3'},
      {code: 'SITE',    name: 'On-Site',    color: '#00AEEF'},
      {code: 'INSTALL', name: 'Installed',  color: '#0071BC'},
      {code: 'ETA_SRD', name: 'ETA > SRD',  color: '#F7BACF'},
    ],

    eq_Popup: [
      {id: 'TAG',       code: 'TAG',        center: '', },
      {id: 'DESC',      code: 'DESC',       center: '', },
      {id: 'PO-NO',     code: 'PO_NO',      center: '', },
      {id: 'PO-DATE',   code: 'PO_DATE',    center: '', },
      {id: 'INCOTERMS', code: 'INCOTERMS',  center: 'Y',},
      {id: 'VENDOR',    code: 'VENDOR',     center: '', },
      {id: 'COUNTRY',   code: 'COUNTRY',    center: '', },
      {id: 'FAT-PLAN',  code: 'FAT_PLAN',   center: '', },
      {id: 'FAT-FC',    code: 'FAT_FC',     center: '', },
      {id: 'FAT-ACT',   code: 'FAT_ACT',    center: '', },
      {id: 'SHIP-MODE', code: 'SHIP_MODE',  center: '', },
      {id: 'SHIP-ETD',  code: 'SHIP_ETD',   center: '', },
      {id: 'SHIP-ETA',  code: 'SHIP_ETA',   center: '', },
      {id: 'ETA',       code: 'ETA',        center: '', },
      {id: 'SRD',       code: 'SRD',        center: '', },
      {id: 'ATA',       code: 'ON_SITE',    center: '', },
      {id: 'INSTALL',   code: 'INSTALL',    center: '', },
      {id: 'ETA-SRD',   code: 'ETA_SRD',    center: 'Y',},
      {id: 'BLK',       code: 'BLK',        center: 'Y',},
      {id: 'ER-BLK',    code: 'G_EREC',     center: 'Y',},
      {id: 'ER-PS',     code: 'ER_PS',      center: '', },
      {id: 'ER-PF',     code: 'ER_PF',      center: '', },
      {id: 'DT-ETA',    code: 'DT_ETA',     center: 'Y',},
      {id: 'DT-SRD',    code: 'DT_SRD',     center: 'Y',},
      {id: 'ER-AS',     code: 'ER_AS',      center: '', },
      {id: 'ER-AF',     code: 'ER_AF',      center: '', },
    ],
    
    eq_svg: [
      { ZONE: 'SOUTH',   SVG: 'PD',  NAME: 'Production Deck',        x: 1030, y: 950 },
      { ZONE: 'SOUTH',   SVG: 'MD',  NAME: 'Main Deck',              x: 1030, y: 750 },
      { ZONE: 'SOUTH',   SVG: 'GD',  NAME: 'Generator Building',     x: 1030, y: 550 },
      { ZONE: 'SOUTH',   SVG: 'ER1', NAME: 'Erectrical B/D Level-1', x: 980,  y: 290 },
      { ZONE: 'SOUTH',   SVG: 'ER2', NAME: 'Erectrical B/D Level-2', x: null, y: null},
      { ZONE: 'SOUTH',   SVG: 'ER3', NAME: 'Erectrical B/D Level-3', x: null, y: null},
      { ZONE: 'NORTH',   SVG: 'PD',  NAME: 'Production Deck',        x: 1000, y: 900 },
      { ZONE: 'NORTH',   SVG: 'MZ',  NAME: 'Mezzanine Deck',         x: 1000, y: 720 },
      { ZONE: 'NORTH',   SVG: 'MD',  NAME: 'Main Deck',              x: 1000, y: 510 },
      { ZONE: 'NORTH',   SVG: 'KD',  NAME: 'Compressor Platform',    x: 1000, y: 290 },
      { ZONE: 'CENTRAL', SVG: 'PD',  NAME: 'Production Deck',        x: 1050, y: 930 },
      { ZONE: 'CENTRAL', SVG: 'MZ',  NAME: 'Mezzanine Deck',         x: 1050, y: 700 },
      { ZONE: 'CENTRAL', SVG: 'MD',  NAME: 'Main Deck',              x: 1050, y: 500 },
      { ZONE: 'FLARE',   SVG: 'FL',  NAME: 'Flare Tower',            x: 970, y: 630 }, 
      { ZONE: 'LQ',      SVG: 'F1',  NAME: 'Level-1',                x: 1000, y: 950 },
      { ZONE: 'LQ',      SVG: 'F2',  NAME: 'Level-2',                x: 1000, y: 750 },
      { ZONE: 'LQ',      SVG: 'F3',  NAME: 'Level-3',                x: 1000, y: 550 },
      { ZONE: 'LQ',      SVG: 'F4',  NAME: 'Level-4',                x: 1000, y: 320 },
      { ZONE: 'HULL',    SVG: 'NE',  NAME: 'NORTH-EAST',             x: 450, y: 530 },
      { ZONE: 'HULL',    SVG: 'NW',  NAME: 'NORTH-WEST',             x: 980, y: 1000 },
      { ZONE: 'HULL',    SVG: 'SE',  NAME: 'SOUTH-EAST',             x: 970, y: 480 },
      { ZONE: 'HULL',    SVG: 'SW',  NAME: 'SOUTH-WEST',             x: 1280, y: 650 },
      { ZONE: 'HULL',    SVG: 'UCF', NAME: 'UCF',                    x: 1050, y: 260 },
    ],

    eq_detail: [
      {item: 'TAG No',          code: 'TAG',        x: 10,},
      {item: 'Description',     code: 'DESC',       x: 10,},
      {item: 'PO Number',       code: 'PO_NO',      x: 10,},
      {item: 'PO Date',         code: 'PO_DATE',    x: 10,},
      {item: 'FAT Plan Date',   code: 'FAT_PLAN',   x: 10,},
      {item: 'FAT Atual',       code: 'FAT_ACT',    x: 10,},
      {item: 'ETA',             code: 'ETA',        x: 10,},
      {item: 'SRD',             code: 'SRD',        x: 10,},
      {item: 'On Site',         code: 'ON_SITE',    x: 10,},
      {item: 'Install',         code: 'INSTALL',    x: 10,},
    ],

  }),


}