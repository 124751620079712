
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {
      this.canvas_Form = this.svg
      .append('g')
      .attr('id', `CANVAS_FROM`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')
      .attr('cursor', 'default')
     
      this.canvas_Form
      .append('rect')
      .attr('x', 0).attr('y', 1110)
      .attr('width', 1600).attr('height', 10)
      .attr('fill', '#D71638')
      
      this.canvas_Form
      .append('image')
      .attr('xlink:href', this.HOST + '/icon/Logo/TrionFPU_r1.svg')
      .attr('x', 30).attr('y', 30).attr('width', 230)

      this.canvas_Form
      .append('text')
      .attr('transform', `translate(30, 108)`)
      .attr('font-size', 24)
      .attr('fill', '#000')
      .text('Timeline for Block Layout in the Yard')

      this.canvas_Form
      .append('text')
      .attr('id', 'SOURCE')
      .attr('transform', `translate(30, 125)`)
      .attr('font-size', 12)
      .attr('fill', '#757575')
      .text(this.timeline.source)

      this.draw_Navigation()
    },


    draw_Navigation() {
     
      // Back Button
      let back = this.canvas_Form
      .append('g')
      .attr('id', `BTN_BACK`)
      .attr('transform', `translate(${this.getNode(`#SOURCE`, 'width') + 80}, 113)`)
      .attr('cursor', 'pointer').attr('opacity', 1).attr('visibility', 'display')
      .on('mouseover', () => {
        d3.select('#BTN_BACK').transition().duration(200).attr('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select('#BTN_BACK').transition().duration(200).attr('opacity', 1)
      })
      .on('mousedown', () => {
        const event = d3.event
        event.preventDefault()
      })
      .on('click', () => {
        d3.select('#BTN_BACK').attr('visibility', 'display')
        let getData = this.rawData.find(f=> f.SN == this.selectedStep-1)
        this.action_Step(getData, this.selectedStep -1) // <=== Event.js
      }) 

      back
      .append('rect')
      .attr('x', -5).attr('y', -5).attr('width', 20).attr('height', 25)
      .attr('fill', '#D71638').attr('opacity', 0)

      back
      .append('path')
      .attr('transform', `translate(0, 0)`)
      .attr('fill', '#44A9DF').attr('d', this.icon.left)
      

      // Next Button
      let next = this.canvas_Form
      .append('g')
      .attr('id', `BTN_NEXT`)
      .attr('transform', `translate(${this.getNode(`#SOURCE`, 'width') + 110}, 113)`)
      .attr('cursor', 'pointer').attr('opacity', 1).attr('visibility', 'display')
      .on('mouseover', () => {
        d3.select('#BTN_NEXT').transition().duration(200).attr('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select('#BTN_NEXT').transition().duration(200).attr('opacity', 1)
      })
      .on('mousedown', () => {
        const event = d3.event
        event.preventDefault()
      })
      .on('click', () => {
        d3.select('#BTN_NEXT').attr('visibility', 'display')
        let getData = this.rawData.find(f=> f.SN == this.selectedStep + 1)
        this.action_Step(getData, this.selectedStep + 1) // <=== Event.js
      }) 

      next
      .append('rect')
      .attr('x', -5).attr('y', -5).attr('width', 20).attr('height', 25)
      .attr('fill', '#D71638').attr('opacity', 0)

      next
      .append('path')
      .attr('transform', `translate(0, 0)`)
      .attr('fill', '#44A9DF').attr('d', this.icon.right)
    },

    

   

  }
}