import * as d3 from 'd3'

export default {
  methods: {

    call_BLK(selection, _blk) {
      
      selection
      .on('mouseover', () => {
        d3.select(`#M-PNG-${_blk}`).transition().duration(50).style('opacity', .5)
        d3.select(`#M-${_blk}`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#M-PNG-${_blk}`).transition().duration(50).style('opacity', 1)
        d3.select(`#M-${_blk}`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        this.filter_GantteData('block', _blk)
      }) 
    },


    call_PE1(selection, _blk) {
      
      selection
      .on('mouseover', () => {
        d3.select(`#M-PNG-PE1-${_blk}`).transition().duration(50).style('opacity', .5)
        d3.select(`#M-PE1-${_blk}`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#M-PNG-PE1-${_blk}`).transition().duration(50).style('opacity', 1)
        d3.select(`#M-PE1-${_blk}`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        this.filter_GantteData('pe1', _blk)
      }) 
    },


    call_EREC(selection, _blk) {
      
      selection
      .on('mouseover', () => {
        d3.select(`#M-PNG-EREC-${_blk}`).transition().duration(50).style('opacity', .5)
        d3.select(`#M-EREC-${_blk}`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#M-PNG-EREC-${_blk}`).transition().duration(50).style('opacity', 1)
        d3.select(`#M-EREC-${_blk}`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        this.filter_GantteData('erec', _blk)
      }) 
    },
  


  }
}