import __C from '../../includes/primitives/_constant_'   // Global Defs
import BarDeclares from './Text_Declares.mixin'

export default {
  mixins: [BarDeclares],
  methods: {
    drawTexts() {
      if(Object.keys(this.Text).length === 0) return

      let gText = this.svg
      .append('g')
      .attr('class', '_global_elements__text__')
      .attr('transform', `translate(0,0)`)

      this.drawTextText(gText)
      this.drawFilterText(gText)
      this.drawSqlText(gText)
    },
    drawTextText(parent_) {
      if(this.Text.Text.length === 0) return
      console.log(this.Text)

      parent_
      .append('g')
      .attr('class', '_global_elements__text__text_')
      .attr('transform', `translate(0,0)`)
      .selectAll('text')
      .data(this.Text.Text)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${d.x},${d.y})${d.direction == 'Vertical' ? ' rotate(-90) ' : ''}`)
      .attr(__C.CHART.MOVED_EL_TYPE_TEXT, (d, i) => `Text$Text$${i}$x$y`)
      .call(this.el2Drag)
      .call(this.setCursorMovable)
      .style('font-family', d => d.font)
      .attr('class', d => d.class)
      .attr('font-size', d => d.size)
      .attr('text-anchor', d => d.align)
      .attr('alignment-baseline', 'hanging')
      .attr('fill', d => d.color)
      // ### For displaying the multi-line text ### ---
      .selectAll('tspan')
      .data(d => (d.text || '').split('\n').map(t_ => ({ text: t_, size: d.size })))
      .enter()
      .append("tspan")
      .attr("x", 0)
      .attr("dy", d => Math.round(d.size * 1.2))
      .style('font-family', (d, i, j) => j[0].parentElement.style.fontFamily)
      .text(d => d.text)
      // ### ---------------------------------- ### ---
    },
    drawFilterText(parent_) {
      if(!this.FilterValues || this.Text.Filter.length === 0) return

      parent_
      .append('g')
      .attr('class', '_global_elements__text__filter_')
      .attr('transform', `translate(0,0)`)
      .selectAll('text')
      .data(this.Text.Filter)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${d.x},${d.y})${d.direction == 'Vertical' ? ' rotate(-90) ' : ''}`)
      .attr(__C.CHART.MOVED_EL_TYPE_TEXT, (d, i) => `Text$Filter$${i}$x$y`)
      .call(this.el2Drag)
      .call(this.setCursorMovable)
      .style('font-family', d => d.font)
      .attr('class', d => d.class)
      .attr('font-size', d => d.size)
      .attr('text-anchor', d => d.align)
      .attr('alignment-baseline', 'hanging')
      .attr('fill', d => d.color)
      // ### For displaying the multi-line text ### ---
      .selectAll('tspan')
      .data(d => (this.FilterValues[d.name] ? this.FilterValues[d.name] : `[${d.name}]` || '').split('\n').map(t_ => ({ text: t_, size: d.size })))
      .enter()
      .append("tspan")
      .attr("x", 0)
      .attr("dy", d => Math.round(d.size * 1.2))
      .style('font-family', (d, i, j) => j[0].parentElement.style.fontFamily)
      .text(d => d.text == '[FILTER]' ? 'All Area' : d.text)
      // ### ---------------------------------- ### ---
    },
    drawSqlText(parent_) {
      if(this.Text.Sql.length === 0) return

      parent_
      .append('g')
      .attr('class', '_global_elements__text__sql_')
      .attr('transform', `translate(0,0)`)
      .selectAll('text')
      .data(this.Text.Sql)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${d.x},${d.y})${d.direction == 'Vertical' ? ' rotate(-90) ' : ''}`)
      .attr(__C.CHART.MOVED_EL_TYPE_TEXT, (d, i) => `Text$Sql$${i}$x$y`)
      .call(this.el2Drag)
      .call(this.setCursorMovable)
      .style('font-family', d => d.font)
      .attr('class', d => d.class)
      .attr('font-size', d => d.size)
      .attr('text-anchor', d => d.align)
      .attr('alignment-baseline', 'hanging')
      .attr('fill', d => d.color)
      // ### For displaying the multi-line text ### ---
      .selectAll('tspan')
      .data(d => (d.text || '').split('\n').map(t_ => ({ text: t_, size: d.size })))
      .enter()
      .append("tspan")
      .attr("x", 0)
      .attr("dy", d => Math.round(d.size * 1.2))
      .style('font-family', (d, i, j) => j[0].parentElement.style.fontFamily)
      .text(d => d.text)
      // ### ---------------------------------- ### ---
    },
  }
}
