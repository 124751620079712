
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvas_Form = this.svg
      .append('g')
      .attr('id', `CANVAS_FROM`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')
     
      this.canvas_Form
      .append('rect')
      .attr('x', 0).attr('y', 1110)
      .attr('width', 1600).attr('height', 10)
      .attr('fill', '#D71638')
      
      this.canvas_Form
      .append('image')
      .attr('xlink:href', this.HOST + '/icon/Logo/TrionFPU_r1.svg')
      .attr('x', 30).attr('y', 30).attr('width', 230)


      this.canvas_Form
      .append('text')
      .attr('transform', `translate(30, 108)`)
      .attr('font-size', 24)
      .attr('fill', '#000')
      .text('HHI Yard-Layout')

      this.canvas_Form
      .append('text')
      .attr('transform', `translate(30, 125)`)
      .attr('font-size', 12)
      .attr('fill', '#44A9DF')
      .text('Block Division Rev.2 (29 Aug 2024)  / Block Schedule Rev.0 (29 Aug 2024)')

      let weekData = [
        {WEEK:"LAST", VALUE:1}, // 'LAST' 수정하지말것
        {WEEK:"SAT",  VALUE:2},
        {WEEK:"SUN",  VALUE:0},
        {WEEK:"MON",  VALUE:4},
        {WEEK:"TUE",  VALUE:5},
        {WEEK:"WEN",  VALUE:6},
        {WEEK:"TUE",  VALUE:7},
        {WEEK:"FRI",  VALUE:8},
        {WEEK:"THIS", VALUE:9}, // 'THIS' 수정하지말것
      ]

      this.gmx_WeekPerformance(this.canvas_Form, {
        x: 400,
        y: 178,
        weekGap: 3,
        width: [35, 25],
        tColor: 'red',
        sColor: 'red',
        data: weekData
      })

      this.gmx_Single_BarChart(this.canvas_Form, {
        x: 1000,
        y: 100,
        width: 100,
        col: 20,
        tColor: ['#757575', '#fff'],
        bColor: '#83D2F5',
        value: [250, 150, 100, .75, 70]
    })

    

      // Load a JGS logo SVG
      this.loadSvg(
        this.canvas_Form, {
        x   : 1460, y   : 1060,
        url : '/icon/Logo/JGS.svg'
      })


      this.gmx_btn_Navigation(
        this.canvas_Form, 
        {type: 'home', x: 450, y: 97, scale: 0.8,}, 
        {
          remove: [this.model_Equipment, this.canvas_Equipment],
          dur: 500, timeout: 1000,
          exeFunc: this.draw_Model_Overall,
          data: {}
        }
      )
    },




    

   

  }
}