import * as d3 from 'd3'

export default {
  methods: {
    call_Link(selection, d_) {
      if (d_.type == 'LINK') {
        selection
        .on('mouseover', function() {
          d3.select(this).transition().duration(50).attr('opacity', 0.5).attr('cursor', 'pointer')
        })
        .on('mouseout', function() {
          d3.select(this).transition().duration(50).attr('opacity', 1)
        })
        .on('click', () => {
          // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
          let dataSource = {} // Do not Remark if possible 

          // For Filter chain Key map
          this.filters.forEach((d, i) => {
            if (d.jMap !== '') dataSource = {...dataSource, [d.jMap]: d_.data[d.ref]}
          })
          console.log('dataSource: ', dataSource)

          // set for request from A/G jsonProps
          let request_ = this.getRequestColumnProps('request', dataSource, this.ColumnProps)
          if(!request_) return

          // set for filterString 
          let filterString_ = ''

          filterString_ += d_.filter

          // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
          if(request_.iFilters.filterString) {
            request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
          } else {
            request_.iFilters.filterString = filterString_||''
          }

          // combine filterString and inputFilter &&& emit request-action
          request_ = {...request_,
            filters : {
              ...this.FilterValues,
              ...request_.filters,
            },
            iFilters: { 
              filterString : request_.iFilters.filterString||'',
              inputFilter : request_.iFilters.inputFilter,},
          }
          this.$emit('request-action', request_)
        })
      }
    },
  }
}