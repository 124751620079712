/* 
  ### Chart Default Data ### 
  Predefined component's behaviors for the Filter & Action
*/

// Dashboard

import TR_DP_MAN_OVERALL        from '../10_Trion/Dashboard/Planning/Man_Overall/index.vue'
import TR_DP_MAN_DROPDOWN       from '../10_Trion/Dashboard/Planning/Man_Dropdown/index.vue'
import TR_DP_MAN_DISC           from '../10_Trion/Dashboard/Planning/Man_Discipline/index.vue'
import TR_DP_MAN_OVERALL_WEEK   from '../10_Trion/Dashboard/Planning/Man_Overall_Week/index.vue'
import TR_DP_MAN_DROPDOWN_WEEK  from '../10_Trion/Dashboard/Planning/Man_Dropdown_Week/index.vue'
import TR_DP_MAN_DISC_WEEK      from '../10_Trion/Dashboard/Planning/Man_Discipline_Week/index.vue'
import TR_DP_MAN_OVERALL_MH       from '../10_Trion/Dashboard/Planning/Man_Overall_MH/index.vue'
import TR_DP_MAN_DROPDOWN_MH      from '../10_Trion/Dashboard/Planning/Man_Dropdown_MH/index.vue'
import TR_DP_MAN_DISC_MH          from '../10_Trion/Dashboard/Planning/Man_Discipline_MH/index.vue'
import TR_DP_MAN_OVERALL_WEEK_MH  from '../10_Trion/Dashboard/Planning/Man_Overall_Week_MH/index.vue'
import TR_DP_MAN_DROPDOWN_WEEK_MH from '../10_Trion/Dashboard/Planning/Man_Dropdown_Week_MH/index.vue'
import TR_DP_MAN_DISC_WEEK_MH     from '../10_Trion/Dashboard/Planning/Man_Discipline_Week_MH/index.vue'

import TR_DC_DISCIPLINE_AREA    from '../10_Trion/Dashboard/Construction/Discipline_Area/index.vue'
import TR_DC_STR_STAGE          from '../10_Trion/Dashboard/Construction/STR_Stage/index.vue'
import TR_DC_STR_SCH            from '../10_Trion/Dashboard/Construction/STR_Schedule/index.vue'
import TR_DC_PIP_AREA           from '../10_Trion/Dashboard/Construction/PIP_Area/index.vue'
import TR_DC_PIP_TABLE          from '../10_Trion/Dashboard/Construction/PIP_Table/index.vue'

import TR_DE_SHOP_OVERALL       from '../10_Trion/Dashboard/Engineering/Shop_Overall/index.vue'
import TR_DE_SHOP_AREA          from '../10_Trion/Dashboard/Engineering/Shop_Area/index.vue'

// 3D Visualization
import TR_Erection_Network      from '../10_Trion/Visualization/Erection_Network/index.vue'
import TR_Yard_Timeline         from '../10_Trion/Visualization/Yard_Timeline/index.vue'
import TR_Block_Division        from '../10_Trion/Visualization/Block_Division/index.vue'
import TR_Layout_Equipment      from '../10_Trion/Visualization/Layout_Equipment/index.vue'
import TR_Layout_Yard           from '../10_Trion/Visualization/Layout_Yard/index.vue'

// Skyline Charts
import TR_SKYLINE_BLOCK         from '../10_Trion/Skylines/Blocks/index.vue'
import TR_SKYLINE_RFQ           from '../10_Trion/Skylines/RFQ/index.vue'
import TR_SKYLINE_RFQ_TAG       from '../10_Trion/Skylines/RFQ_tag/index.vue'

// Special Charts
import TR_SCURVE_ENG            from '../10_Trion/CustomChart/SCurve_ENG/index.vue'
import TR_SCURVE_PRO            from '../10_Trion/CustomChart/SCurve_PRO/index.vue'
import TR_SCURVE_CON            from '../10_Trion/CustomChart/SCurve_CON/index.vue'

// Modal
import TR_MW_SCH_AREA            from '../10_Trion/ModalWindow/Schedule/Area/index.vue'
import TR_MW_SCH_ZONE            from '../10_Trion/ModalWindow/Schedule/Zone/index.vue'
import TR_MW_SCH_EREC            from '../10_Trion/ModalWindow/Schedule/Erection/index.vue'
import TR_MW_SCH_PE1             from '../10_Trion/ModalWindow/Schedule/PE1/index.vue'
import TR_MW_SCH_BLOCK           from '../10_Trion/ModalWindow/Schedule/Block/index.vue'

import TR_MW_OUT_AREA_SPOOL      from '../10_Trion/ModalWindow/Outfitting/Area_Spool/index.vue'
import TR_MW_OUT_AREA_EQ         from '../10_Trion/ModalWindow/Outfitting/Area_EQ/index.vue'
import TR_MW_OUT_OVERALL         from '../10_Trion/ModalWindow/Outfitting/Overall_Disc/index.vue'

export default {
  
  // Dashboard
  TR_DP_MAN_OVERALL,
  TR_DP_MAN_DROPDOWN,
  TR_DP_MAN_DISC,
  TR_DP_MAN_OVERALL_WEEK,
  TR_DP_MAN_DROPDOWN_WEEK,
  TR_DP_MAN_DISC_WEEK,
  TR_DP_MAN_OVERALL_MH,
  TR_DP_MAN_DROPDOWN_MH,
  TR_DP_MAN_DISC_MH,
  TR_DP_MAN_OVERALL_WEEK_MH,
  TR_DP_MAN_DROPDOWN_WEEK_MH,
  TR_DP_MAN_DISC_WEEK_MH,

  TR_DC_DISCIPLINE_AREA,
  TR_DC_STR_STAGE,
  TR_DC_STR_SCH,
  TR_DC_PIP_AREA,
  TR_DC_PIP_TABLE,
  TR_DE_SHOP_OVERALL,
  TR_DE_SHOP_AREA,

  // 3D Visualization
  TR_Erection_Network,
  TR_Yard_Timeline,
  TR_Block_Division,
  TR_Layout_Equipment,
  TR_Layout_Yard,

  // Skyline Charts
  TR_SKYLINE_BLOCK,
  TR_SKYLINE_RFQ,
  TR_SKYLINE_RFQ_TAG,

  // Custom Charts
  TR_SCURVE_ENG,
  TR_SCURVE_PRO,
  TR_SCURVE_CON,

  // Modal
  TR_MW_SCH_AREA,
  TR_MW_SCH_ZONE,
  TR_MW_SCH_EREC,
  TR_MW_SCH_PE1,
  TR_MW_SCH_BLOCK,
  
  TR_MW_OUT_AREA_SPOOL,
  TR_MW_OUT_AREA_EQ,
  TR_MW_OUT_OVERALL


}
