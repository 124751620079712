import * as d3 from 'd3'

export default {
  methods: {

    draw_ChartCanvas(selection, d_, sn_) {

      this.canvasChart = this.canvasGroup
      .append('g')
      .attr('class', `FORMS`)
      .attr('transform', `translate(${this.Chart.x +.5 + d_.x}, ${this.Chart.y + .5 + d_.y})`)

      this.canvasChart
      .append('text')
      .attr('id', `TITLE_${sn_}`)
      .attr('x', 0) .attr('y', -25)
      .style('font-size', 13).style('fill', '#000')
      .text(d_.text)

      this.canvasChart
      .append('text')
      .attr('x', this.getNode(`#TITLE_${sn_}`, 'width') + 5) .attr('y', -25)
      .style('font-size', 9).style('fill', '#757575')
      .text(`(${d_.yard})`)

      this.draw_ChartAxis(this.canvasChart) //-----> below
      this.draw_Legends(this.canvasChart) //-----> below
    },

    
    draw_ChartAxis(selection) {
      // Draw Chart Border
      selection
      .append('line')
      .attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', this.Chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', 0).attr('x2', this.Chart.width).attr('y1', this.Chart.height).attr('y2', this.Chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', this.Chart.width).attr('x2', this.Chart.width).attr('y1', 0).attr('y2', this.Chart.height)
      .attr('stroke', '#757575' ).attr('stroke-width', .5).attr('opacity', 1)

      // Primary Axis
      selection
      .append('text')
      .attr('transform', `translate(${-23}, ${this.Chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('No. of Block (Weekly)')

      // Secoundary Axis
      selection
      .append('text')
      .attr('transform', `translate(${this.Chart.width + 30}, ${this.Chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('No. of Block (Cumulative)')
    },


    draw_Legends(selection) {

      let item = selection
        .append('g')
        .attr('transform', `translate(0,-8)`)

      let x_ = 3
      this.ChartStyle.forEach((d,i)=> {
        item
        .append('rect')
        .attr('x', x_)
        .attr('y', -1.5)
        .attr('width', 8).attr('height', 2).attr('fill', d.sColor)

        item
        .append('text')
        .attr('id', `ITEM_${i}`)
        .attr('x', x_ + 11)
        .attr('y', 0)
        .style('font-size', 9).style('fill', '#333')
        .attr('alignment-baseline', 'middle')
        .text(d.name)
        x_ = x_ + this.getNode(`#ITEM_${i}`, 'width') + 20
      })
    },
  }
}
           