import * as d3 from 'd3'

export default {

  methods: {

    draw_Detail() {
      
      if(this.detail.visible !== 'Y') return

      this.canvasDetail = this.svg
      .append('g')
      .attr('id', 'DETAIL')
      .attr('transform', `translate(${this.detail.x}, ${this.detail.y})`)
      .attr('font-family', 'roboto')
      .attr('opacity', 1)
      
      this.canvasDetail
      .append('text')
      .attr('transform', `translate(-10, 0)`)
      .attr('font-size', 13).attr('fill', '#000')
      .text('Selected Block Information')

      this.canvasDetail
      .append('text')
      .attr('id', `Detail_Address`)
      .attr('transform', `translate(0, 18)`).attr('font-size', 11).attr('fill', '#44A9DF')
      .text('')

      this.canvasDetail
      .append('text')
      .attr('id', `Detail_BLK`)
      .attr('transform', `translate(0, 40)`).attr('font-size', 13).attr('fill', '#000')
      .text('')

      this.canvasDetail
      .append('rect')
      .attr('x', 0.5).attr('y', 45.5)
      .attr('width', 170.5).attr('height', 110.5)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .1)
      .attr('fill', 'none')

      this.canvasDetail
      .append('image')
      .attr('id','Detail_IMAGE')
      .attr('x', 5).attr('y', 50)
      .attr('height', 100)
      .attr('xlink:href', `${this.HOST}/Visualization/BlockDivision/BlockImage/Hull/NA.jpg`)
      .attr('opacity', 1)

      // PLAN & ACTUAL
      this.canvasDetail
      .append('text')
      .attr('id', `Detail_PLAN_TITLE`)
      .attr('transform', `translate(0, 170)`).attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(` Plan`)

      this.canvasDetail
      .append('text')
      .attr('id', `Detail_PLAN_SCH`)
      .attr('transform', `translate(5, 185)`).attr('font-size', 11).attr('fill', '#757575')
      .text('')

      this.canvasDetail
      .append('text')
      .attr('id', `Detail_ACT_TITLE`)
      .attr('transform', `translate(0, 200)`).attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(`Actual`)

      this.canvasDetail
      .append('text')
      .attr('id', `Detail_ACT_SCH`)
      .attr('transform', `translate(5, 215)`).attr('font-size', 11).attr('fill', '#757575')
      .text('')

      // Progress bar
      this.canvasDetail
      .append('rect')
      .attr('x', 0).attr('y', 223).attr('width', 160).attr('height', 12)
      .attr('fill', '#F4F5F5')

      this.canvasDetail
      .append('rect')
      .attr('id', `Detail_ACT_BAR`)
      .attr('x', 0).attr('y', 223).attr('width', 0).attr('height', 12)
      .attr('fill', this.currentStage.color)

      this.canvasDetail
      .append('text')
      .attr('id', `Detail_ACT_PRO`)
      .attr('x', 0).attr('y', 232)
      .attr('font-size', 11).attr('fill', '#757575')
      .attr('text-anchor', 'start')
      .text(`0%`)
    },
    

    mouseOver_Detail(blk_) {
      let getAS = null
      let getAF = null

      let stg = JSON.parse(JSON.stringify(this.currentStage))
      d3.select(`#Detail_Address`)
      .text(`${blk_.AREA} > ${blk_.ZONE} > ${blk_.LEVEL} > ${blk_.NAME}`)

      d3.select(`#Detail_BLK`).text(blk_[stg.col])

      getAS = blk_[`${stg.id}_AS`] !== undefined ? getAS = blk_[`${stg.id}_AS`] : ''
      getAF = blk_[`${stg.id}_AF`] !== undefined ? getAF = blk_[`${stg.id}_AF`] : ''

      d3.select(`#Detail_PLAN_SCH`).text(`${blk_[`${stg.id}_PS`]} ~ ${blk_[`${stg.id}_PF`]}`)
      d3.select(`#Detail_ACT_SCH`).text(`${getAS} ~ ${getAF}`)
      
      if(!blk_[stg.col]) stg.col = 'BLK'
      d3.select(`#Detail_IMAGE`)
      .attr('xlink:href', `${this.HOST}/Visualization/BlockDivision/BlockImage/${blk_.AREA}/${stg.jpg}${blk_[stg.col]}.jpg`)
      // console.log(`${blk_.AREA}/${stg.jpg}${blk_[stg.col]}`)

      // Progress
      let getPro = blk_[`${stg.id}_AP`]
      let len = (getPro/100) * 160
      let old = d3.select(`#Detail_ACT_PRO`).text()
      old = Number(old.slice(0, -1))

      d3.select(`#Detail_ACT_BAR`).transition().duration(500)
      .attr('width', len)

      d3.select(`#Detail_ACT_PRO`).transition().duration(500)
      .attr('text-anchor', getPro > 70 ? 'end' : 'start')
      .attr('x', getPro > 70 ? len-2 : len+2)
      .tween("text", function() {
        var i = d3.interpolateNumber(old, getPro)
        return function(t) {
        d3.select(this).text(`${parseInt(i(t).toFixed(0))}%`)
        }
      })
    },

  }
}
