export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)
      .style('font-family', 'roboto')

      if(this.title.main.visible == 'Y') {
        this.canvasForms
        .append('text')
        .attr('x', this.title.main.x) .attr('y', this.title.main.y)
        .attr('font-size', this.title.main.tSize)
        .attr('fill', this.title.main.tColor)
        .attr('alignment-baseline', 'hanging')
        .text(this.title.main.name)
      }

      if(this.title.sub.visible == 'Y') {
        this.canvasForms
        .append('text')
        .attr('x', this.title.sub.x) .attr('y', this.title.sub.y)
        .attr('font-size', this.title.sub.tSize)
        .attr('fill', this.title.sub.tColor)
        .attr('alignment-baseline', 'hanging')
        .text(this.title.sub.name)
      }
      
      this.draw_ChartCanvas() // -----> draw_Elements.js
      this.draw_Timeline() // -----> draw_Timeline.js
      this.chart_Controller() // -----> draw_Chart.js

    },

  }
}
           