import * as d3 from 'd3'
import moment from 'moment'


export default {

  methods: {

    set_LocalValues(){
      // raw data
      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))
      this.chartData = JSON.parse(JSON.stringify(this.Queries.SQL1))
      

      this.chartData.forEach(d=> {
        let eDate = moment(`${d.CDATE} 23:59:59`).toDate()
        let sDate = moment(d.CDATE).add(-6, 'days').toDate()
        d.mDate = new Date(d3.mean([sDate, eDate]))
      })
   
      this.chart.name = this.FilterValues.KEY
      
      this.timeline = {
        cutoff    : this.rawData.CUTOFF,
        startDate : d3.min(this.chartData, c =>  c.CDATE),
        endDate   : d3.max(this.chartData, c =>  c.CDATE),
        weekEnd   : 'friday',

        day        : [],
        week       : [],
        month      : [],
        year       : [],
        scale      : null,
        length     : this.chart.width,
      }
      this.init_gmx_TimelineValues(this.timeline, 'day')


      this.tableData = JSON.parse(JSON.stringify(this.Queries.SQL2))
      if (this.FilterValues.STAGE == 'ZONE') this.table.style = this.table.style2

      let x_ = 0
      this.table.style.forEach(d=> {
        d.ex = x_ + d.width
        d.cx = x_ + (d.width/2)
        if(d.align == 'start') d.tx = x_ + 5
        if(d.align == 'middle') d.tx = x_ + (d.width/2)
        if(d.align == 'end') d.tx = x_ + d.width - 5
        x_ = x_ + d.width
      })
      this.table.width = x_

      // console.log(this.table)

      
    },


  }
}