export default {
  
  methods: {

    // Area Stage
    draw_Area_Canvas() {
      
      this.canvas_Area = this.svg
      .append('g')
      .attr('id', `CANVAS_AREA`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      
      this.gmx_btn_Navigation(
        this.canvas_Area, 
        {type: 'home', x: 450, y: 78, scale: 0.8,}, 
        {
          remove: [this.canvas_Area,],
          dur: 0, 
          timeout: 0,
          exeFunc: this.action_Area_to_Overall,
          data: null
        }
      )

      this.status_Progress(this.canvas_Area, {id: 'LQ', x: 1070, y: 200, area: 'LQ', icon: 'Y'})
      this.draw_BlockStatus(this.canvas_Area, this.masterList.filter(f=> f.AREA == 'LQ'), {x: 1070, y: 270})

      this.status_Progress(this.canvas_Area, {id: 'TOP', x: 960, y: 490, area: 'TOPSIDE', icon: 'Y'})
      this.draw_BlockStatus(this.canvas_Area, this.masterList.filter(f=> f.AREA == 'TOPSIDE'), {x: 960, y: 560})

      this.status_Progress(this.canvas_Area, {id: 'HULL', x: 1000, y: 780, area: 'HULL', icon: 'Y'})
      this.draw_BlockStatus(this.canvas_Area, this.masterList.filter(f=> f.AREA == 'HULL'), {x: 1000, y: 850})


      this.status_Structure(this.canvas_Area, { x: 40, y: 210, stage: 'OVERALL', filter: 'OVERALL', head: 'Y'})
      this.status_Outfitting(this.canvas_Area, {x: 40, y: 360, stage: 'OVERALL', filter: 'OVERALL', head: 'N'})

    }
  }
}