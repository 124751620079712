import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')


      let overall = this.canvasForms
      .append('g')
      .attr('transform', `translate(30, 25)`)

      // overall
      // .append('text')
      // .attr('x', 0) .attr('y', 0).style('font-size', 13).style('fill', '#000')
      // .text(`Summary`)

      // overall
      // .append('text')
      // .attr('x', 90) .attr('y', 0).style('font-size', 9).style('fill', '#858585')
      // .text(`Fabrication / Installation`)    

      
    },

  }
}
           