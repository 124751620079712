import * as d3 from 'd3'

export default {

  methods: {

    draw_Legends() {
      let title = this.Chart_Area
      .append('g')
      .attr('transform', `translate(.5, ${-45.5})`)
      .style('font-family', 'roboto').style('cursor', 'default')

      title
      .append('text')
      .attr('transform',`translate(${this.canvas.chart.width/2}, 3)`)
      .attr('font-size', 18).attr('fill', '#000').attr('text-anchor', 'middle')
      .text(`Progress S-Curve for Procurement`)

      title
      .append('text')
      .attr('id', `ADDRESS`)
      .attr('transform',`translate(${this.canvas.chart.width/2}, 20)`)
      .attr('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'middle')
      .text(`${this.current.phase.name == 'RFQ' ? 'Package' : this.current.phase.name} > ${this.current.area.name} > ${this.current.disc.name}`)



      let Legend = this.Chart_Area
      .append('g')
      .attr('transform', `translate(.5, ${-10.5})`)
      .style('font-family', 'roboto').style('cursor', 'default')

      let x_ = 0
      this.lineChart.forEach((d,i)=> {
        x_ = i*75
        Legend
        .append('line')
        .attr('x1', x_).attr('x2', x_ + 15)
        .attr('y1', 0).attr('y2', 0)
        .attr('stroke', d.sColor).attr('stroke-width', 2)
        .style("stroke-dasharray", (d.type == 'DASH') ? (3,3) : (0,0))

        Legend
        .append('text')
        .attr('transform',`translate(${x_+18}, 3)`)
        .attr('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'start')
        .text(d.name)

      })



    },
    
  }
}