import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('font-family', 'roboto')
      .attr('cursor', 'default')


      // Draw Drondown
      this.canvasDropdown = this.canvasForms
      .append('g')
      .attr('transform', `translate(70.5, 40.5)`)

      // Phase
      let data_ = [ ...new Set(this.combine.map(d => d.phase)) ]
      data_.unshift('- Phase -')
      this.draw_Dropdown(data_, 'PHASE') //-----> Event.js

      // Group
      data_ = ['- Group -']
      this.draw_Dropdown(data_, 'GROUP') //-----> Event.js

      // SUBCONT
      data_ = ['- Subcont. name -']
      this.draw_Dropdown(data_, 'SUBCONT') //-----> Event.js


      this.draw_ChartCanvas() // -----> draw_ChartCanvas.js
      this.draw_Timeline() // -----> draw_Timeline.js
      this.chart_Router() //-----> chart_Router.js
    },

  }
}
           