import * as d3 from 'd3'

export default {
  
  methods: {

    draw_2PE_Canvas(_d) {
      
      // console.log(this.current.stage) //////////

      this.canvas_2PE = this.svg
      .append('g')
      .attr('id', `CANVAS_2PE`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.gmx_btn_Navigation(
        this.canvas_2PE, 
        {type: 'home', x: 450, y: 78, scale: 0.8,}, 
        {
          remove: [this.model_2PE, this.canvas_2PE,],
          exeFunc: this.draw_Model_Overall,
          data: null
        }
      )

      this.gmx_btn_Navigation(
        this.canvas_2PE, 
        {type: 'back', x: 490, y: 78, scale: 0.8,}, 
        {
          remove: [this.model_2PE, this.canvas_2PE,],
          exeFunc: this.back_Model_Erection,
          data: null
        }
      )

      // Load a Legend SVG
      this.loadSvg(
        this.canvas_2PE, {
        x: 30, y: 140,
        url : '/Visualization/includes/Keyplan_Topside.svg'
      }).then(() => {

        this.keyPlan.forEach(d => {
          if(_d.KP.substring(0,7) !== d) d3.select(`#${d}`).style('opacity', 0)
        })
        d3.selectAll("text").style('font-family', 'roboto')
      })
      
      // console.log('ZONE->', _d) //////////
      let zone_ = (_d.M_ZONE) ? _d.M_ZONE : this.current.zone
      this.status_Structure(this.canvas_2PE, { x: 30, y: 380, stage: 'ZONE', filter: zone_, head: 'Y'})
      this.status_Outfitting(this.canvas_2PE, {x: 30, y: 530, stage: 'ZONE', filter: zone_,})

      this.current.zone = zone_
    },
  }
}