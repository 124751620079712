import * as d3 from 'd3'

export default {
  methods: {

    draw_Legends(chtData, groupArr) {
      let thisMonth = chtData.filter(f => f.month == this.timeline.cutoff.substr(0,7))
      let contGroup = [...new Set(thisMonth.map(d => d.CONT))]
      let cols = [...new Set(this.legends.map(d => d.code))]
      let contTotal = []
      contGroup.forEach((d,i) => {
        contTotal[i] = this.sumByKeys(thisMonth.filter(f => f.CONT == d), cols)
        contTotal[i] = {
          ...contTotal[i],
          DISC: `${d ? d : 'Sub'} Total`,
          CONT: d
        }
      })
      contTotal.push(this.chartData.find(f => f.month == this.timeline.cutoff.substr(0,7) && f.DISC == 'OVERALL'))

      let item = this.canvasForms
      .append('g')
      .attr('id', `LEGENDS`)
      .attr('transform', `translate(1010, 80)`)

      item
      .append('text')
      .attr('x', 2).attr('y', 3).attr('font-size', 12).attr('fill', '#000')
      .text('Legends')

      item
      .append('text')
      .attr('x', this.legends[0].x).attr('y', -4).attr('font-size', 9).attr('fill', '#000').attr('text-anchor', 'end')
      .text('Planned')

      item
      .append('text')
      .attr('x', this.legends[0].x - 6).attr('y', 5).attr('font-size', 9).attr('fill', '#000').attr('text-anchor', 'end')
      .text('Total')

      item
      .append('text')
      .attr('x', this.legends[2].x +12).attr('y', -5).attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'end')
      .text('This Month')

      item
      .append('text')
      .attr('x', this.legends[5].x+12).attr('y', -5).attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end')
      .text('Cumulative')

      this.legends.forEach((d,i) => {
        if(i==0) return
        item
        .append('text')
        .attr('x', d.x).attr('y', 6)
        .attr('font-size', 9).attr('fill', '#757575')
        .attr('text-anchor', 'end')
        .text(d.title)
      })

      let legendLen = this.legends[this.legends.length - 1].x
      item
      .append('line')
      .attr('x1', 0).attr('y1', 10.5)
      .attr('x2', legendLen).attr('y2', 10.5)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5).attr('opacity', 1)

      let y_ = 20
      let contNum = 0
      thisMonth.forEach((d,i) => {
        item
        .append('rect')
        .attr('x', 3).attr('y', y_-4.5)
        .attr('width', 7).attr('height', 7).attr('fill', this.chart.colors[i])

        item
        .append('text')
        .attr('x', 14).attr('y', y_)
        .attr('font-size', 10).attr('fill', '#333').attr('alignment-baseline', 'middle')
        .text(d.DISC)

        // item
        // .append('line')
        // .attr('x1', 0).attr('y1', y_ + 8.5)
        // .attr('x2', legendLen).attr('y2', y_ + 8.5)
        // .attr('stroke', '#bcbcbc').attr('stroke-width', .5).attr('opacity', .5)

        this.legends.forEach(col => {
          item
          .append('text')
          .attr('id', `DISC_STATUS_${col.code}_${i}`)
          .attr('x', col.x).attr('y', y_)
          .attr('font-size', 10).attr('fill', (col.title=='Delta') ? this.setDeltaColor(d[col.code]) : '#333')
          .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text(isNaN(d[col.code]) ? d[col.code] : this.AddComma(d[col.code]))
        })
        
        y_ = y_ + 18

        if (i == thisMonth.length - 1 || d.CONT != thisMonth[i+1].CONT) {
          item
          .append('text')
          .attr('x', 3).attr('y', y_)
          .attr('font-size', 10).attr('fill', '#333').attr('alignment-baseline', 'middle')
          .text(contTotal[contNum].DISC)

          item
          .append('line')
          .attr('x1', 0).attr('y1', y_ + 8.5)
          .attr('x2', legendLen).attr('y2', y_ + 8.5)
          .attr('stroke', '#bcbcbc').attr('stroke-width', .5).attr('opacity', .5)

          this.legends.forEach(col => {
            item
            .append('text')
            .attr('id', `TOT_DISC_STATUS_${col.code}_${contNum}`)
            .attr('x', col.x).attr('y', y_)
            .attr('font-size', 10).attr('fill', (col.title=='Delta') ? this.setDeltaColor(contTotal[contNum][col.code]) : '#333')
            .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(isNaN(contTotal[contNum][col.code]) ? contTotal[contNum][col.code] : this.AddComma(contTotal[contNum][col.code]))
          })

          contNum += 1
          y_ = y_ + 18
        }

        if (contNum == contTotal.length - 1) {
          item
          .append('text')
          .attr('x', 3).attr('y', y_)
          .attr('font-size', 10).attr('fill', '#333').attr('alignment-baseline', 'middle')
          .text('Overall Total')

          item
          .append('line')
          .attr('x1', 0).attr('y1', y_ + 8.5)
          .attr('x2', legendLen).attr('y2', y_ + 8.5)
          .attr('stroke', '#bcbcbc').attr('stroke-width', .5).attr('opacity', .5)

          this.legends.forEach(col => {
            item
            .append('text')
            .attr('id', `TOT_DISC_STATUS_${col.code}_${contNum}`)
            .attr('x', col.x).attr('y', y_)
            .attr('font-size', 10).attr('fill', (col.title=='Delta') ? this.setDeltaColor(contTotal[contNum][col.code]) : '#333')
            .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(isNaN(contTotal[contNum][col.code]) ? contTotal[contNum][col.code] : this.AddComma(contTotal[contNum][col.code]))
          })
        }
      })
    },


    change_Status(mouse, thisMonth) {
      let contGroup = [...new Set(this.chartData.filter(f => f.month == thisMonth[0].month).map(d => d.CONT))]
      let cols = [...new Set(this.legends.map(d => d.code))]
      let contTotal = []
      contGroup.filter(f => f != '').forEach((d,i) => {
        contTotal[i] = this.sumByKeys(thisMonth.filter(f => f.CONT == d), cols)
        contTotal[i] = {
          ...contTotal[i],
          DISC: `${d ? d : 'Sub'} Total`,
          CONT: d
        }
      })
      contTotal.push(this.chartData.find(f => f.month == thisMonth[0].month && f.DISC == 'OVERALL'))

      thisMonth.forEach((d,i) => {
        this.legends.forEach(col => {
          if(col.title == 'Total') return
          d3.select(`#DISC_STATUS_${col.code}_${i}`)
          .attr('fill', (col.title=='Delta') ? this.setDeltaColor(d[col.code]) : '#333')
          .text(d[col.code])
        })
      })

      contTotal.forEach((d,i) => {
        this.legends.forEach(col => {
          if(col.title == 'Total') return
          d3.select(`#TOT_DISC_STATUS_${col.code}_${i}`)
          .attr('fill', (col.title=='Delta') ? this.setDeltaColor(d[col.code]) : '#333')
          .text(d[col.code])
        })
      })

    },

    sumByKeys(arr, keys) {
      return keys.reduce((result, key) => {
        result[key] = Math.round(arr.reduce((sum, obj) => sum + (obj[key] || 0), 0) * 100) / 100
        return result
      }, {})
    }
  }
}