import * as d3 from 'd3'

export default {
  methods: {

    chart_Router() {

      // Canvas Group
      this.canvasChart = this.canvasForms
      .append('g')
      .attr('transform', `translate(0, 0)`)

      this.init_gmx_TimelineValues(this.timeline, 'day')


      this.chartData = this.chartData.filter(f=> f.CDATE >= this.timeline.startDate && f.CDATE <= this.timeline.endDate)

      this.primary.max = d3.max([d3.max(this.chartData, c => c[this.chart.style[0].code]), d3.max(this.chartData, c => c[this.chart.style[1].code])])
      this.primary.max = this.getMaxBound_v2(this.primary.max)
      this.primary.axis = d3.scaleLinear()
      .domain([0, this.primary.max])
      .range([0, this.chart.height])
      
      this.secoundary.max = this.DataItems[0].SPOOL_QTY
      this.secoundary.axis = d3.scaleLinear()
      .domain([0, this.secoundary.max])
      .range([this.chart.height, 0])
      
      this.draw_ChartCanvas() // -----> draw_Elements.js
      this.draw_Timeline() // -----> draw_Timeline.js
      this.chart_Controller() // -----> draw_Chart.js

    },

  }
}
           