
import * as d3 from 'd3'

export default {
  methods: {

    filter_GantteData(type, _blk) {
      
      let chtData = {}
      let data = null
      let desc = null
      let lineH = null

      switch(type) {
        case 'erec': 
          data = this.filterData
          desc = 'Setting Block'
          lineH = 50
          break;

        case 'pe1': 
          data = this.filterData.filter(f=> f.G_1PE == _blk)
          desc = '1st PE Block'
          lineH = 50
          break;

        case 'block': 
          data = this.filterData.filter(f=> f.BLK == _blk)
          desc = 'Fab. Block'
          lineH = 50
          break;
      }

      chtData.BLOCk = _blk
      chtData.DESC = desc

      this.activity.forEach(act=> {
        chtData[`${act.code}_PS`] = d3.min(data, m =>  m[`${act.code}_PS`])
        chtData[`${act.code}_PF`] = d3.max(data, m =>  m[`${act.code}_PF`])
        chtData[`${act.code}_AS`] = d3.min(data, m =>  m[`${act.code}_AS`])
        chtData[`${act.code}_AF`] = d3.max(data, m =>  m[`${act.code}_AF`])
        chtData[`${act.code}_PP`] = (data.map(m=> {return m[`${act.code}_PW`]}).reduce((a, b) => (a + b)) / data.map(m=> {return m[`${act.code}_WF`]}).reduce((a, b) => (a + b)))*100
        chtData[`${act.code}_AP`] = (data.map(m=> {return m[`${act.code}_AW`]}).reduce((a, b) => (a + b)) / data.map(m=> {return m[`${act.code}_WF`]}).reduce((a, b) => (a + b)))*100
      })
      
      this.removeSvg(this.canvasGantte, 200, this.draw_singleGantt, {data: chtData, y: 580, lineH: lineH})
    },

  }
}