import * as d3 from 'd3'

export default {
  methods: {

    set_Axes(data_) {
      // Set primary & secoundary Axis
      let maxValue = d3.max([d3.max(data_, c => c.P_WK), d3.max(data_, c => c.A_WK),])
      this.primary.max = this.getMaxBound_v2(maxValue)
      this.primary.axis = d3.scaleLinear()
      .domain([0, this.primary.max])
      .range([0, this.chart.height])

      maxValue = d3.max([d3.max(data_, c => c.P_CUM), d3.max(data_, c => c.A_CUM)])
      this.secoundary.max = this.getMaxBound_v2(maxValue)
      this.secoundary.axis = d3.scaleLinear()
      .domain([0, this.secoundary.max])
      .range([this.chart.height, 0])
    },


    draw_Grid(selection) {

      let gridHeight = this.chart.height / 5
      for(let i=0; i<6; i++) {
        selection // Grid
        .append('line')
        .attr('x1', 0).attr('x2', this.chart.width)
        .attr('y1', gridHeight * i).attr('y2', gridHeight * i)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
        .attr('opacity', .5)

        selection // Primary Axis
        .append('text')
        .attr('x', -2)
        .attr('y', this.chart.height - (gridHeight * i))  
        .attr('font-size', 9).attr('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(
          (this.primary.max * (i*.2) < 10000) ? 
          (this.primary.max * (i*.2)).toFixed(0) : 
          ((this.primary.max * (i*.2))/1000).toFixed(1)+'k'
        )

        selection // Secoundary Axis
        .append('text')
        .attr('x', this.chart.width + 2)
        .attr('y', this.chart.height - (gridHeight * i))
        .attr('font-size', 9).attr('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(
          (this.secoundary.max * (i*.2) < 10000) ? 
          (this.secoundary.max * (i*.2)).toFixed(0) : 
          ((this.secoundary.max * (i*.2)/1000)).toFixed(0)+'k'
        )
      }
    },


    draw_BarChart(selection, chtData, st_) {
      let barWidth = 5
      
      let barChart = selection
      .append('g')
      .attr('transform', `translate(0.5,0)`)
      
      chtData.forEach((d,i)=> {
        let x_ = this.timeline.scale(d.month) + (this.timeline.monthWidth/2)
        let value = d[st_.code]
        if(value <= 0) value = 0

        barChart
        .append('rect')
        .attr('x', (st_.type == 'PLAN') ? x_ - barWidth - .25 : x_ + .25)
        .attr('y', this.chart.height - this.primary.axis(value))
        .attr('width', barWidth).attr('height', this.primary.axis(value))
        .attr('fill', st_.sColor).attr('opacity', st_.opacity)
      })

    },


    draw_LineChart(selection, lineData, line) {

      let dev = null
      let lastOne = lineData.find(f=> f.month == this.timeline.cutoff.substr(0,7))
      if(lastOne) {
        dev = lastOne[this.chart.line[1].code]-lastOne[this.chart.line[0].code]
      }
      let lineFunc = d3.line()
      .x(d => this.timeline.scale(d.month) + (this.timeline.monthWidth/2))
      .y(d => this.secoundary.axis(d[line.code]))

      let lineChart = selection
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      lineChart
      .append('path')
      .attr('d', lineFunc(lineData))
      .attr('stroke', (line.type == 'ACT' && dev < 0) ? '#F35E90': line.sColor)
      .attr('stroke-dasharray', (line.type == 'PLAN') ? (3,3) : (0,0))
      .attr('stroke-width', line.sWidth).attr('fill', 'none')

      // draw the final cumulative value
      // if(line.type == 'ACT') {
      //   if(!lastOne) return
      //   lineChart
      //   .append('text')
      //   .attr('x', this.timeline.scale(lastOne.month) + (this.timeline.monthWidth/2))
      //   .attr('y', this.secoundary.axis(lastOne[line.code]))
      //   .attr('font-size', 11)
      //   .attr('fill', (dev < 0) ? '#F35E90' : '#44A9DF')
      //   .attr('alignment-baseline', 'middle')
      //   .text(lastOne[line.code].toFixed(0))
      // }
    },

    draw_Legends(legendData) {
  
      let item = this.canvasForms
      .append('g')
      .attr('transform', `translate(${this.chart.x}, ${this.chart.y - 8})`)

      let x_ = 0
      legendData.forEach((d,i)=> {
        item
        .append('rect')
        .attr('x', x_)
        .attr('y', -1.5)
        .attr('width', 8).attr('height', (i<2) ? 1 : 3).attr('fill', d.sColor)

        item
        .append('text')
        .attr('id', `ITEM_${i}`)
        .attr('x', x_ + 11)
        .attr('y', 0)
        .style('font-size', 9).style('fill', '#333')
        .attr('alignment-baseline', 'middle')
        .text(d.name)
        x_ = x_ + this.getNode(`#ITEM_${i}`, 'width') + 20
      })
    },
  }
}