import * as d3 from 'd3'
import moment from 'moment'

export default {
  data: () => ({
    rawData:      [],

    canvasForms:  null,
    canvasGroup:  null,
    canvasChart:  null,

    timeline:     {},
    Chart:        {},
    ChartStyle:   [],

    stageList:    null,
    areaList:     null,
    current:  {
      stage: {},
    },

    primary:      {axis: null, max: null},
    secoundary:   {axis: null, max: null},
    Toggle:       'on',
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      // raw data
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))

      this.rawData.forEach(d=> {
        let eDate = moment(`${d.CDATE} 23:59:59`).toDate()
        let sDate = moment(d.CDATE).add(-6, 'days').toDate()
        d.mDate = new Date(d3.mean([sDate, eDate]))
      })

      this.stageList = this.localData.STAGE
      this.current.stage = this.stageList[0] // set Current Stage

      this.areaList = this.localData.AREA
      this.current.area = this.areaList[0] // set Current Area

      // General Styles
      let s_ = this.localData.JSON1[0]

      this.timeline = {
        cutoff          : this.Queries.SQL1[0].TL_CUTOFF,
        length          : s_.CHART_WIDTH,
        startDate       : this.current.stage.start,
        endDate         : this.current.stage.end,
        weekEnd         : s_.WEEKEND,

        day             : [],
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
      }

      // Get properties of chart size & coordinate 
      this.Chart.x                = s_.CHART_X
      this.Chart.y                = s_.CHART_Y
      this.Chart.width            = s_.CHART_WIDTH
      this.Chart.height           = s_.CHART_HEIGHT
      this.Chart.grid             = s_.CHART_HEIGHT / 5


      // Set properties of line charts & bar charts
      let codes = s_.NAME.replace(/\s/g, '').split('/')
      codes.forEach((d,i)=> {
        this.ChartStyle.push({
          name    : s_.NAME.replace(/\s/g, '').split('/')[i],
          // code    : this.stageList[0].codes.replace(/\s/g, '').split('/')[i],
          cType   : s_.CHT_TYPE.replace(/\s/g, '').split('/')[i],
          lType   : s_.LINE_TYPE.replace(/\s/g, '').split('/')[i],
          sWidth  : s_.LINE_SIZE.replace(/\s/g, '').split('/').map(Number)[i],
          sColor  : s_.LINE_COLOR.replace(/\s/g, '').split('/')[i],
          opacity : s_.OPACITY.replace(/\s/g, '').split('/').map(Number)[i],
        })
      })
      
    },
  }
}