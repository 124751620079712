import * as d3 from 'd3'

export default {
  methods: {

    chart_Controller() {

      let sChart = this.canvasChart
      .append('g')
      .attr('class', 'CHARTS')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      this.draw_Grid(sChart) // -----> draw_Elements.js
      
      // Call Chart
      let chtData = this.chartData.filter(f=> f.CDATE <= this.timeline.cutoff)

      this.chart.style.filter(f=> f.type == 'BAR').forEach((d,i)=> {
        this.draw_BarChart(sChart, chtData, d, i)
      })

      this.chart.style.filter(f=> f.type == 'LINE').forEach(d=> {
        this.draw_LineChart(sChart, chtData, d)
      })

    },


    draw_Grid(selection) {
      let grid = this.chart.height / 5

      for(let i=0; i<6; i++) {
        selection // Grid
        .append('line')
        .attr('x1', 0).attr('x2', this.chart.width)
        .attr('y1', grid * i).attr('y2', grid * i)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
        .attr('opacity', .5)

        selection // Primary Axis
        .append('text')
        .attr('x', -2)
        .attr('y', this.chart.height - (grid * i))  
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text((this.primary.max * (i*.2)).toFixed(0))

        selection // Secoundary Axis
        .append('text')
        .attr('x', this.chart.width + 2)
        .attr('y', this.chart.height - (grid * i))
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text((this.secoundary.max * (i*.2)).toFixed(0))
      }
    },


    draw_BarChart(selection, chtData, st_, sn_) {

      let barWidth = 3
      let barChart = selection
      .append('g')
      .attr('transform', `translate(0.5, 0)`)

      chtData.forEach((d,i)=> {

        let value = d[st_.code]
        if(value < 0) value = 0
        
        barChart
        .append('rect')
        .attr('x', (sn_ == 0) ? this.timeline.scale(d.mDate) - barWidth-1 : this.timeline.scale(d.mDate))
        .attr('y', this.chart.height-this.primary.axis(value))
        .attr('width', barWidth).attr('height', this.primary.axis(value))
        .attr('fill', st_.sColor).attr('opacity', st_.opacity)
      })
    },


    draw_LineChart(selection, chtData, st_) {

      let lastOne = chtData.find(f=> f.CDATE == this.timeline.cutoff)

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(new Date(d.mDate)))
      .y(d => this.secoundary.axis(d[st_.code]))

      let lineChart = selection
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      lineChart
      .append('path')
      .attr('d', lineFunc(chtData))
      .style('stroke', st_.sColor)
      .style('stroke-width', st_.sWidth).style('fill', 'none')

      // draw the final cumulative value
      if(!lastOne) return

      lineChart
      .append('text')
      .attr('x', this.timeline.scale(new Date(lastOne.CDATE)))
      .attr('y', this.secoundary.axis(lastOne[st_.code]))
      .style('font-size', 10)
      .style('fill', st_.sColor)
      .attr('alignment-baseline', 'middle')
      .text(lastOne[st_.code])

    },


  }
}