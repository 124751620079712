// import JLibChartCustoms from '../../lib/jin/svgchartlib-v2/00_System/00_Customs'
// import JLibChartDonut from '../../lib/jin/svgchartlib-v2/00_System/01_Donut'
// import JLibChartPie from '../../lib/jin/svgchartlib-v2/00_System/02_Pie'
// import JLibChartBarAxis from '../../lib/jin/svgchartlib-v2/00_System/03_Bar_Normal'
// import JLibChartBarSolid from '../../lib/jin/svgchartlib-v2/00_System/04_Bar_Solid'
// import JLibChartBarProgress from '../../lib/jin/svgchartlib-v2/00_System/Progress'
// import JLibChartSummary from '../../lib/jin/svgchartlib-v2/00_System/Summary'
// import JLibChartWeekly from '../../lib/jin/svgchartlib-v2/00_System/Week'
// import JLibChartBarDelta from '../../lib/jin/svgchartlib-v2/00_System/06_Bar_Delta'
// import JLibChartHistogram from '../../lib/jin/svgchartlib-v2/00_System/06_Histogram'
// import JLibChartRadar from '../../lib/jin/svgchartlib-v2/00_System/07_Radar'
// import JLibChartTableSummary from '../../lib/jin/svgchartlib-v2/00_System/Summary_Table_Dep2'
import JLibComponentSvg from '../../lib/jin/svgchartlib-v2/00_System/Demo/exporter'

// svg2 Trion Project
import JLibV2ComponentSvg from '../../lib/jin/svgchartlib-v2/export/Trion_index'
// svg2 Component
import JLibV2ChartCustoms from '../../lib/jin/svgchartlib-v2/export/Component_index'

export default {
  // ...JLibChartCustoms,
  ...JLibV2ChartCustoms,
  // JLibChartDonut,
  // JLibChartPie,
  // JLibChartBarAxis,
  // JLibChartBarSolid,
  // JLibChartBarProgress,
  // JLibChartSummary,
  // JLibChartWeekly,
  // JLibChartBarDelta,
  // JLibChartHistogram,
  // JLibChartRadar,
  // JLibChartTableSummary,
  ...JLibComponentSvg,
  ...JLibV2ComponentSvg,
}
