import * as d3 from 'd3'
import moment from 'moment'

export default {
  methods: {

    redraw_Skyline() {

      this.reset_Data()
      this.canvasSkyline.transition().duration(500).style('opacity',0)

      d3.select('#SKY_TITLE')
      .text(`Skyline Chart for ${this.currentStage.title} Blocks of ${this.currentArea.text}`)

      setTimeout(() => { 
        this.canvasSkyline.remove()
        d3.select('#EXCEPTION').remove()
        this.draw_SkylineCharts()
        this.draw_exception()
      }, 500)
      
    },


    redraw_Skyline_Pdf() {

      this.reset_Data()
      this.canvasSkyline.transition().duration(500).style('opacity',0)

      d3.select('#SKY_TITLE')
      .text(`Skyline Chart for ${this.currentStage.title} Blocks of ${this.currentArea.text}`)

      this.canvasSkyline.remove()
      d3.select('#EXCEPTION').remove()
      this.draw_SkylineCharts()
      this.draw_exception()
      
    },


    redraw_Timeline() {

      this.reset_Data()
      this.canvasTimeline.transition().duration(500).style('opacity',0)
      this.canvasSkyline.transition().duration(500).style('opacity',0)

      d3.select('#SKY_TITLE')
      .text(`Skyline Chart for ${this.currentStage.title} Blocks of ${this.currentArea.text}`)

      setTimeout(() => { 
        this.canvasTimeline.remove()
        this.canvasSkyline.remove()
        d3.select('#EXCEPTION').remove()

        this.draw_Timeline_Week()
        this.draw_SkylineCharts()
        this.draw_exception()
      }, 500)

    },


    redraw_Timeline_Pdf() {

      this.reset_Data()
      this.canvasTimeline.transition().duration(500).style('opacity',0)
      this.canvasSkyline.transition().duration(500).style('opacity',0)

      d3.select('#SKY_TITLE')
      .text(`Skyline Chart for ${this.currentStage.title} Blocks of ${this.currentArea.text}`)

      this.canvasTimeline.remove()
      this.canvasSkyline.remove()
      d3.select('#EXCEPTION').remove()

      this.draw_Timeline_Week()
      this.draw_SkylineCharts()
      this.draw_exception()

    },


    reset_Data() {
      // Reset timeline
      this.timeline.refColumn       = this.currentStage.ref, // ***
      this.timeline.refPro          = this.currentStage.pro, // ***
      this.timeline.startDate       = this.currentStage.start,
      this.timeline.endDate         = this.currentStage.end,
      this.timeline.week            = [],
      this.timeline.month           = [],
      this.timeline.year            = [],
      this.timeline.scale           = null
      this.skyline.skyBox.width     = this.currentStage.width

      if(this.currentArea.status == 'OVERALL') {
        this.area_Data = this.rawData.filter(f => 
          f.EXCEPT == '' && 
          (f[`${this.currentStage.ref}`] !== null && f[`${this.currentStage.ref}`] !== undefined)
        )
      } else {
        if (this.currentArea.status == 'HULL' && this.currentYard.status !== 'ALL') {
          this.area_Data = this.rawData.filter(f =>
            f.AREA ==  this.currentArea.status &&
            f.YARD ==  this.currentYard.status &&
            f.EXCEPT == '' && 
            (f[`${this.currentStage.ref}`] !== null && f[`${this.currentStage.ref}`] !== undefined)
          )

          if (this.currentStage.id == 'ER' && this.currentYard.status == 'HHI') {
            let modData = this.rawData.filter(f => f.AREA ==  this.currentArea.status && f.YARD == 'COSCO' && f.EXCEPT == '' && f[`ER_PF`] !== undefined)
            this.area_Data = this.area_Data.concat(modData)
          } else if (this.currentStage.id == 'ER' && this.currentYard.status == 'COSCO') {
            this.area_Data = []
          }
        } else {
          this.area_Data = this.rawData.filter(f =>
            f.AREA ==  this.currentArea.status &&
            f.EXCEPT == '' && 
            (f[`${this.currentStage.ref}`] !== null && f[`${this.currentStage.ref}`] !== undefined)
          )
        }
      }
      this.set_SkylineData()
    },


    set_Count(_area) {
      
      this.stageCodes.forEach((d,i)=> {
        if (this.currentArea.status == 'OVERALL') {
          d.total = this.rawData.filter(f => f[`${d.ref}`] !== undefined).length
          d.completed = this.rawData.filter(f => f[`${d.ref2}`] !== undefined).length
          this.redraw_Legend(d)
        } else {
          d.total = this.rawData.filter(f => f.AREA == _area && f[`${d.ref}`] !== undefined).length
          d.completed = this.rawData.filter(f => f.AREA == _area && f[`${d.ref2}`] !== undefined).length
          this.redraw_Legend(d)
        }
      })
      this.redraw_Skyline()      
    },


    set_Count_Pdf(_area) {

      this.stageCodes.forEach((d,i)=> {
        if (this.currentArea.status == 'OVERALL') {
          d.total = this.rawData.filter(f => f[`${d.ref}`] !== undefined).length
          d.completed = this.rawData.filter(f => f[`${d.ref2}`] !== undefined).length
          this.redraw_Legend(d)
        } else {
          d.total = this.rawData.filter(f => f.AREA == _area && f[`${d.ref}`] !== undefined).length
          d.completed = this.rawData.filter(f => f.AREA == _area && f[`${d.ref2}`] !== undefined).length
          this.redraw_Legend(d)
        }
      })
      this.redraw_Skyline_Pdf()      
    },


    set_Count_Yard(_area, _yard) {
      
      this.stageCodes.forEach((d,i)=> {
        if (this.currentYard.status == 'ALL') {
          d.total = this.rawData.filter(f => f.AREA == _area && f[`${d.ref}`] !== undefined).length
          d.completed = this.rawData.filter(f => f.AREA == _area && f[`${d.ref2}`] !== undefined).length
          this.redraw_Legend(d)
        } else {
          d.total = this.rawData.filter(f => f.AREA == _area && f.YARD == _yard && f[`${d.ref}`] !== undefined).length
          d.completed = this.rawData.filter(f => f.AREA == _area && f.YARD == _yard && f[`${d.ref2}`] !== undefined).length

          if (_yard == 'HHI' && d.id == 'ER') {
            d.total += this.rawData.filter(f => f.AREA == _area && f.YARD == 'COSCO' && f[`${d.ref}`] !== undefined).length
            d.completed += this.rawData.filter(f => f.AREA == _area && f.YARD == 'COSCO' && f[`${d.ref2}`] !== undefined).length
          } else if (_yard == 'COSCO' && d.id == 'ER') {
            d.total = 0
            d.completed = 0
          }

          this.redraw_Legend(d)
        }
      })
      this.redraw_Skyline()      
    },


    set_Count_Yard_Pdf(_area, _yard) {
      
      this.stageCodes.forEach((d,i)=> {
        if (this.currentYard.status == 'ALL') {
          d.total = this.rawData.filter(f => f.AREA == _area && f[`${d.ref}`] !== undefined).length
          d.completed = this.rawData.filter(f => f.AREA == _area && f[`${d.ref2}`] !== undefined).length
          this.redraw_Legend(d)
        } else {
          d.total = this.rawData.filter(f => f.AREA == _area && f.YARD == _yard && f[`${d.ref}`] !== undefined).length
          d.completed = this.rawData.filter(f => f.AREA == _area && f.YARD == _yard && f[`${d.ref2}`] !== undefined).length

          if (_yard == 'HHI' && d.id == 'ER') {
            d.total += this.rawData.filter(f => f.AREA == _area && f.YARD == 'COSCO' && f[`${d.ref}`] !== undefined).length
            d.completed += this.rawData.filter(f => f.AREA == _area && f.YARD == 'COSCO' && f[`${d.ref2}`] !== undefined).length
          } else if (_yard == 'COSCO' && d.id == 'ER') {
            d.total = 0
            d.completed = 0
          }

          this.redraw_Legend(d)
        }
      })
      this.redraw_Skyline_Pdf()      
    },


    redraw_Legend(d_) {
      let height = this.legends.height
      let completed = 0

      let old_Total = d3.select(`#STAGE_TOTAL_${d_.id}`).text()
      let old_completed = d3.select(`#STAGE_COMPLETED_${d_.id}`).text()

      if(d_.total > 0) {
        completed = (d_.completed / d_.total) * height
      }

      d3.select(`#STAGE_TOTAL_${d_.id}`).transition().duration(500)
      .attr('transform', `translate(15, ${height+11})`)
      .tween("text", function() {
        var i = d3.interpolateNumber(old_Total, d_.total)
        return function(t) {
        d3.select(this).text(`${parseInt(i(t).toFixed(0))}`)
        }
      })

      d3.select(`#STAGE_COMPLETED_${d_.id}`).transition().duration(500)
      .attr('transform', `translate(15, ${height-completed})`)
      .tween("text", function() {
        var i = d3.interpolateNumber(old_completed, d_.completed)
        return function(t) {
        d3.select(this).text(`${parseInt(i(t).toFixed(0))}`)
        }
      })

      d3.select(`#BAR_COMPLETED_${d_.id}`).transition().duration(500)
      .attr('transform', `translate( 0, ${height-completed})`)
      .attr('height', completed)

      if (d_.total == 0) {
        d3.select(`#STAGE_${d_.id}`).transition().duration(500).attr('opacity', 0.4).style('pointer-events', 'none')
      } else {
        d3.select(`#STAGE_${d_.id}`).attr('opacity', 1).style('pointer-events', 'auto')
      }
    },

    toggle_Action() {
      if (this.Toggle == 'off') {
        this.stageCodes = this.stageCodes2
        d3.selectAll(`.STAGE_SCH`).text('Start')
      } else {
        this.stageCodes = this.stageCodes1
        d3.selectAll(`.STAGE_SCH`).text('Finish')
      }
      this.currentStage = this.stageCodes.find(f => f.id == this.currentStage.id)

      this.redraw_Timeline()
      this.set_Count(this.currentArea.status)
      if (this.currentArea.status == 'HULL') this.set_Count_Yard(this.currentArea.status, this.currentYard.status)
    },

  }
}
