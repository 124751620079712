import * as d3 from 'd3'

export default {

  methods: {

    draw_Header(){
      let tbl = this.table
      let h1 = 25, h2 = 44
      let y1 = 26, y2 = 37
      
      let header = this.canvasForms
      .append('g')
      .attr('transform','translate(30.5, 25.5)')
      .attr('text-alignment', 'central')

      tbl.column.forEach((c,i)=> {

        header
        .append('text')
        .attr('x',c.ex - c.width/2 ).attr('y',(i>3) ? y2 : y1)
        .attr('fill','black').attr('font-size', i<4 ? 11 :9)
        .attr('text-anchor', 'middle')
        .text(c.title)

        if(tbl.column.length-1 == i) return
        if (!(c.title=='Completed' || c.title =='Remain')){       
          header 
          .append('line') 
          .attr('x1', c.ex).attr('x2', c.ex) 
          .attr('y1',0).attr('y2',h1)
          .attr('stroke', (i==3 || i==6) ? '#757575' : '#bcbcbc').attr('stroke-width',.5) 
        }

        header 
        .append('line') 
        .attr('x1', c.ex).attr('x2', c.ex) 
        .attr('y1', h1).attr('y2', h2)
        .attr('stroke',(i==3 || i==6) ? '#757575' : '#bcbcbc').attr('stroke-width',.5) 
      })

      header
      .append('text')
      .attr('x', tbl.column[3].ex + ((tbl.column[6].ex - tbl.column[3].ex)/2)).attr('y', 16)
      .attr('fill','black').attr('font-size',11).attr('text-anchor','middle')
      .text('Spool Fabrication')

      header
      .append('text')
      .attr('x', tbl.column[6].ex + ((tbl.column[9].ex - tbl.column[6].ex)/2)).attr('y', 16)
      .attr('fill','black').attr('font-size',11).attr('text-anchor','middle')
      .text('Spool Installation')
     
      header //Horizontal bold lines
      .append('line') 
      .attr('x1',0).attr('x2',tbl.width).attr('y1', 0 ).attr('y2', 0)
      .attr('stroke', '#333').attr('stroke-width', .5) 

      header //Horizontal bold lines
      .append('line') 
      .attr('x1',0).attr('x2',tbl.width).attr('y1', h2).attr('y2', h2)
      .attr('stroke','#333').attr('stroke-width', .5) 

      header //Horizontal inner lines
      .append('line') 
      .attr('x1',tbl.column[4].tx + tbl.padding - tbl.column[4].width).attr('x2',tbl.width)
      .attr('y1',h1).attr('y2',h1)
      .attr('stroke','#bcbcbc').attr('stroke-width',.5) 

      this.draw_Table()     
    },


    draw_Table(){

      let tbl = this.table
      let tableGrid  = this.canvasForms
      .append('g')
      .attr('transform','translate(30.5, 84.5)')
      
      this.tableData.forEach((row,r)=> {
       
        tbl.column.forEach((col,c)=> {

        let vertY = r*tbl.lineHeight -14 

        if(!(
          row.YARD=='Sub-Total' &&  c == 1 || 
          row.AREA =='Grand Total' && c<2 || 
          row.ZONE =='Sub-Total' && c<2 || 
          row.AREA == 'LQ' && c<1 || 
          row.ZONE == 'COMMON' && c==1 || 
          this.table.column.length-1 == c)
        ) { 
          tableGrid // Vertical lines
          .append('line')
          .attr('x1', col.ex ).attr('x2',col.ex)
          .attr('y1', vertY).attr('y2',vertY+tbl.lineHeight)
          .attr('stroke', (c==3 || c==6 ) ? '#757575' : '#bcbcbc').attr('stroke-width',.5)
        }

        if (r>0 && col.group == 'Y' && row[col.code] == this.tableData[r-1][col.code] ) return

          if(col.title =='Progress') {
            this.draw_progBar(tableGrid, col.ex-col.width +.5, r * tbl.lineHeight - 9.5, row[col.code])
          } else {
            tableGrid
            .append('text')
            .attr('x', ()=>{
            let moveX = col.tx
              if(row.ZONE == 'Sub-Total' && c<3) moveX =  this.table.column[2].tx
              if(row.AREA == 'Grand Total'&& c<3) moveX = this.table.column[1].tx
              return moveX
            })
            .attr('y', r * tbl.lineHeight)
            .attr('fill', row[col.code]==0 ? '#bcbcbc' : '#333').attr('font-size',10).attr('text-anchor', col.align)
            .text(()=>{
              let name = row[col.code]
              if(row.AREA == row.ZONE) row.ZONE =''
              return name
            })
            .call(this.call_LINK, row, row[col.code], col)
          }
        })          
         
        tableGrid // Horizontal lines
        .append('line') 
        .attr('x1', row.sx).attr('x2', tbl.width)
        .attr('y1',r * tbl.lineHeight + 6).attr('y2', r * tbl.lineHeight + 6)
        .attr('stroke', r>this.tableData.length-3 ? '#333' : '#bcbcbc').attr('stroke-width', .5)    
      })   
    },


    draw_progBar(selection, _x, _y, _pro) {

      let barLen = 120
      let proLen = (_pro/100) * barLen

      selection
      .append('rect')
      .attr('x', _x).attr('y', _y)
      .attr('width', barLen).attr('height', 11).attr('fill', '#bcbcbc').attr('opacity', .15)

      selection
      .append('rect')
      .attr('x', _x).attr('y', _y)
      .attr('width', proLen).attr('height', 11).attr('fill', '#83D2F5').attr('opacity', .5)

      selection
      .append('text')
      .attr('x', _pro > 70 ? _x + proLen - 2 : _x + proLen + 2).attr('y', _y + 9)
      .attr('font-size',10)
      .attr('fill', _pro == 0 ? '#bcbcbc' : '#333')
      .attr('text-anchor', _pro > 70 ? 'end' : 'start')
      .text(_pro+'%')
    }

    
  }
}