
import * as d3 from 'd3'

export default {
  data: () => ({
    selectedItems:    null,
    getItems:         null,
  }),

  methods: {
    set_selectedItems() {
      this.selectedItems = { 
        ...this.selectedItems,
        stage:  this.current.stage,
        area:   this.current.area,
        pe:     this.current.pe,
        block:  this.current.block,
        sn:     this.current.sn,
        eq:     this.current.eq,
        detail: this.current.detail,
        toggle: this.current.toggle,
        search: this.current.search,
      }
    },


    draw_PDF() {
      this.current.stage = this.getItems.stage
      this.current.area = this.getItems.area
      this.current.pe = this.getItems.pe
      this.current.block = this.getItems.block
      this.current.sn = this.getItems.sn
      this.current.eq = this.getItems.eq
      this.current.detail = this.getItems.detail
      this.current.toggle = this.getItems.toggle
      this.current.search = this.getItems.search

      switch(this.current.stage) {
        case 'OVERALL':  
          this.pdf_overall()
          break
        case 'AREA':         
          this.pdf_Area()
          break
        case 'ERECTION':         
          this.pdf_Erection()
          break
        case '2PE':         
          this.pdf_2PE()
          break
        case 'BLOCK':     
          this.pdf_Block()
          break
        case 'EQ':     
          this.pdf_EQ()
          break
      }
    },

    
    pdf_overall() {
      this.draw_Model_Overall()
    },
    

    pdf_Area() {
      this.model_Area = this.svg
      .append('g')
      .attr('id', `MODEL_AREA`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_Area, {
        x   : 300,
        y   : 0,
        url : `/Visualization/BlockDivision/Area.svg`
      }).then(() => {

        d3.select(`#MODEL_AREA`).attr('transform', `translate(0, 0)`)

        d3.select(`#Hull`).attr('transform', `translate(0, 150)`).style('opacity', 1)
        d3.select(`#Topside`).attr('transform', `translate(0, -100)`).style('opacity', 1)
        d3.select(`#LQ`).attr('transform', `translate(150,-200)`).style('opacity', 1)

        this.draw_Area_Canvas() // ----->
      })

      d3.selectAll(`.Nav_btn`).remove()
    },

    
    pdf_Erection() {
      this.model_Erection = this.svg
      .append('g')
      .attr('id', `MODEL_ERECTION`)
      .attr('transform', `translate(340,80)`)
      .attr('font-family', 'roboto')
    
      this.loadSvg(
        this.model_Erection, {
        x   : 0,
        y   : -30,
        url : `/Visualization/BlockDivision/Rev1/${this.current.area}.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')

        // ########################################################### HULL ###########################################################
        if (this.current.area == 'Hull') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-700,-1500) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(750).style('opacity', 1).attr('transform', `translate(300,0) scale(1)`)

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)
            d3.select(`#LINE-4TH`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(500).delay(750).attr('transform', `translate(${d.X2},${d.Y2}) scale(.7)`)
            d3.select(`#LINE-2ND`).transition().duration(300).delay(1500).style('opacity', 1)
          })

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(750).delay(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(.7)`)
            d3.select(`#LINE-3RD`).transition().duration(300).delay(750).style('opacity', 1)
          })

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(500).delay(500).attr('transform', `translate(${d.X4},${d.Y4}) scale(.7)`)
            d3.select(`#LINE-4TH`).transition().duration(300).delay(750).style('opacity', 1)
          })

          this.draw_Erection_Canvas() // ----->

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`)
            .attr('opacity', 1)
            .attr('cursor', 'pointer')
            .call(this.call_madal_Window, {STAGE: 'EREC', KEY: d.BLK}) // -----> call Madal Window
            d3.select(`#MASK-${d.BLK}`)
            .attr('cursor', 'pointer').call(this.call_Erection_Block, d, this.draw_Model_Block)
          })
          this.func_blockColoring(this.model_Erection, this.current.area.toUpperCase(), 'EREC') // -----> vFunction/block_Coloring.js

          // Equipment Layout ---------------------------------------------------------------------------
          this.eq_svg.filter(f=> f.ZONE == 'HULL').forEach(d=> {
            d3.select(`#EQ-${d.SVG}`)
            .attr('cursor', 'pointer')
            .call(this.call_Equip_Hull, d, d, this.back_Model_Erection)
          })

        }

        // ########################################################### TOPSIDE ###########################################################
        if (this.current.area == 'Topside') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-1000,-1300) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(750).style('opacity', 1).attr('transform', `translate(250,30) scale(1)`)
  
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {

            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(750).delay(750).attr('transform', `translate(${d.X2},${d.Y2}) scale(1)`)
            d3.select(`#LINE-2ND`).transition().duration(300).delay(1500).style('opacity', 1)
          })

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(500).delay(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(1)`)
            d3.select(`#LINE-3RD`).transition().duration(300).delay(1000).style('opacity', 1)
          })

          this.draw_Erection_Canvas() // ----->
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`)
            .attr('opacity', 1)
            .attr('cursor', 'pointer')
            .call(this.call_madal_Window, {STAGE: 'ZONE', KEY: d.ZONE}) // -----> call Madal Window
            d3.select(`#MASK-${d.BLK}`)
            .attr('cursor', 'pointer').call(this.call_Erection_Block, d, this.draw_Model_2PE)
          })
            
        }


        // ########################################################### LQ ###########################################################
        if (this.current.area == 'LQ') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-1000,-1300) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(750).style('opacity', 1).attr('transform', `translate(100,30) scale(1)`)
  
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {

            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(750).delay(750).attr('transform', `translate(${d.X2},${d.Y2}) scale(1)`)
            d3.select(`#LINE-3RD`).transition().duration(300).delay(1800).style('opacity', 1)
          })

          this.draw_Erection_Canvas() // ----->
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {

            if(d.BLK == 'LQ') {
              d3.select(`#${d.BLK}`)
              .attr('opacity', 1).attr('cursor', 'pointer')
              .call(this.call_madal_Window, {STAGE: 'ZONE', KEY: 'LQ'}) // -----> call Madal Window
            }
            if(d.BLK == 'Heli') {
              d3.select(`#${d.BLK}`)
              .attr('opacity', 1).attr('cursor', 'pointer')
              .call(this.call_madal_Window, {STAGE: 'EREC', KEY: 'LH110'}) // -----> call Madal Window
            }
            if(d.BLK == 'House') {
              d3.select(`#${d.BLK}`)
              .attr('opacity', 1).attr('cursor', 'pointer')
              .call(this.call_madal_Window, {STAGE: 'EREC', KEY: 'LV510'}) // -----> call Madal Window
            }
            d3.select(`#MASK-${d.BLK}`)
            .attr('cursor', 'pointer').call(this.call_Erection_Block, d, this.draw_Model_2PE)
          })
            
        }

        d3.selectAll(`.Nav_btn`).remove()
      })
    },


    pdf_2PE() {
      let _d = this.erectionList.find(f=> f.SN == this.current.sn)
      
      this.model_2PE = this.svg
      .append('g')
      .attr('id', `MODEL_2PE`)
      .attr('transform', `translate(340,80)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_2PE, {
        x   : 250,
        y   : 0,
        url : `/Visualization/BlockDivision/Rev1/Topside/${this.current.pe}.svg`
      }).then(() => {

        this.draw_2PE_Canvas(_d) // ----->
        d3.selectAll("text").style('font-family', 'roboto')
        d3.select(`#LINES`).style('opacity', 0)
        d3.select(`#TEXTS`).style('opacity', 0)
        d3.select(`#MODEL_2PE`).style('opacity', 1).attr('transform', `translate(0,0) scale(1)`)

        this.erectionList.filter(f=> f.AREA == this.current.pe).forEach(d => {
          d3.select(`#MASK-${d.BLK}`).style('opacity', 0).attr('visibility', 'hidden')
          d3.select(`#PNG-${d.BLK}`).transition().duration(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(0.75)`)
          d3.select(`#${d.BLK}`).style('opacity', 0)
        })
        
        this.erectionList.filter(f=> f.AREA == this.current.pe).forEach(d => {
          d3.select(`#${d.BLK}`).transition().duration(500).style('opacity', 1)
          d3.select(`#LINES`).transition().duration(500).style('opacity', 1)
          d3.select(`#TEXTS`).transition().duration(500).style('opacity', 1)
        })

        this.func_blockColoring(this.model_2PE, this.current.area.toUpperCase(), 'EREC') // -----> vFunction/block_Coloring.js

        this.erectionList.filter(f=> f.AREA == this.current.pe).forEach(d => {
          d3.select(`#${d.BLK}`)
          .attr('opacity', 1)
          .attr('cursor', 'pointer').call(this.call_madal_Window, {STAGE: 'EREC', KEY: d.BLK}) // -----> call Madal Window
          d3.select(`#MASK-${d.BLK}`)
          .attr('visibility', 'display').attr('cursor', 'pointer')
          .call(this.call_2PE_Block, d)
        }) 
        
        // Equipment Layout ---------------------------------------------------------------------------
        let mod = this.current.pe.toUpperCase()

        this.eq_svg.filter(f=> f.ZONE == mod).forEach(d=> {
          d3.select(`#EQ-${d.SVG}`)
          .attr('cursor', 'pointer')
          .call(this.call_Equip_Topside, _d, d, this.draw_Model_2PE)
        })
      })

      d3.selectAll(`.Nav_btn`).remove()
    },


    pdf_Block() {
      let _d = this.erectionList.find(f=> f.SN == this.current.sn)
      
      this.model_Block = this.svg
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      let model = this.model_Block
      .append('svg')
      .attr('x', 0)
      .attr('width', 1100).attr('height', 700)
      
      .append('g')
      .attr('id', `MODEL_BLOCK`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      if (this.current.area == 'Hull') {
        this.loadSvg(
          model, {
          x   : 100,
          y   : 0,
          url : `/Visualization/BlockDivision/Rev1/${this.current.area}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(250,250) scale(1)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.set_Modal_Blocks(_d.BLK) // -----> below
          this.func_blockColoring(model, this.current.area.toUpperCase(), 'ASSEMBLY', _d.BLK) // -----> vFunction/block_Coloring.js
          this.draw_Block_Canvas(_d) // ----->
        })
      }
      
      if (this.current.area == 'Topside' || this.current.area == 'LQ') {
        this.loadSvg(
          model, {
          x   : 120,
          y   : 0,
          url : `/Visualization/BlockDivision/Rev1/Topside/${_d.AREA}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(250,290) scale(1)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.set_Modal_Blocks(_d.BLK) // -----> below
          this.func_blockColoring(model, this.current.area.toUpperCase(), 'ASSEMBLY', _d.BLK) // -----> vFunction/block_Coloring.js
          this.draw_Block_Canvas(_d) // ----->
        }) 
      }

      d3.selectAll(`.Nav_btn`).remove()
    },


    pdf_EQ() {
      let _d = this.eq_svg.find(f => f.ZONE == this.current.eq.ZONE && f.SVG == this.current.eq.SVG)

      this.model_Equipment = this.svg
      .append('g')
      .attr('id', `MODEL_EQUIPMENT`)
      .attr('transform', `translate(0,0)`)

      this.loadSvg(
      this.model_Equipment, {
      x: 30, y: -360, width: 500,
      url : `/Visualization/Layout_Equipment/EQ_Legends.svg`
      }).then(() => {
        d3.selectAll('text').style('font-family', 'roboto')
      })

      this.loadSvg(
        this.model_Equipment, {
        x: 0, y: 0,
        url : `/Visualization/Layout_Equipment/SVGs/${_d.ZONE}_${_d.SVG}.svg`
      }).then(() => {
        d3.selectAll('text').style('font-family', 'roboto')
        d3.select(`#TAGs`).style('opacity', 0)
        d3.select(`#IMAGE`).style('opacity', 0)
        d3.select(`#LINE`).style('opacity', 0)

        this.eqList.filter(f=> f.ZONE == _d.ZONE && f.SVG == _d.SVG).forEach(d=>{
          d3.select(`#C-${d.ID}`)
          .style('fill', ()=> {
            let color = '#fff'
            if(d.PO_DATE) color = '#85C7E5'
            if(d.FAT_ACT) color = '#F7BACE'
            if(d.INSTALL) color = '#8DBB3F'
            return color
          })
          if(d.ETA_SRD < 0) {
            let txt = d3.select(`#NO-${d.ID}`).selectAll('text').nodes()[1]
            d3.select(txt).style('fill', 'red')
          }
        })

        d3.select(`#TAGs`).style('opacity', 1)
        d3.select(`#IMAGE`).style('opacity', 1)
        d3.select(`#LINE`).style('opacity', 1)

        this.canvas_Equipment = this.svg
        .append('g')
        .attr('id', `CANVAS_EQUIPMENT`)
        .attr('transform', `translate(0,0)`)

        this.canvas_Equipment
        .append('text')
        .attr('id', `TITLE_MAIN`)
        .attr('transform', `translate(30, 155)`)
        .attr('font-size', 17).attr('fill', '#D71638').attr('alignment-baseline', 'bottom')
        .text(`${_d.ZONE} MODULE -`)

        this.canvas_Equipment
        .append('text')
        .attr('id', `TITLE_SUB`)
        .attr('x', this.getNode('#TITLE_MAIN','width') + 35)
        .attr('y', 155)
        .attr('font-size', 14).attr('fill', '#D71638').attr('alignment-baseline', 'bottom').attr('opacity', .5)
        .text(_d.NAME)

        this.bar_Summary(this.canvas_Equipment, this.eq_svg.find(f=> f.ZONE == _d.ZONE && f.SVG == _d.SVG), {idx: 'OV', x: 90,   y: 280, bar: 250})

        this.draw_detail()

        if (this.current.detail) {
          let data_ = this.eqList.find(f => f.SN == this.current.detail)

          this.update_detail(data_)
        }

        this.search.mod = _d.ZONE
        this.search.lev = _d.SVG

        // Toggle
        if (this.current.toggle == 'off') {
          this.eqList.filter(f=> f.ZONE == this.search.mod && f.SVG == this.search.lev).forEach(d=> {
    
            let selectText = d3.select(`#NO-${d.ID}`).select('text')
    
            let cx = Number(d3.select(`#C-${d.ID}`).attr('cx')), tx = 0, ty = 0
    
            if (selectText.attr(`transform`)) {
              tx = Number(this.matrixCoordinate(selectText).x)
              ty = Number(this.matrixCoordinate(selectText).y)
              selectText.attr(`transform`, null)
            } else {
              tx = selectText.attr('x')
              ty = selectText.attr('y')
            }
    
            selectText
            .attr('x', (cx > tx) ? cx-10 : cx+10)
            .attr('y', ty)
            .attr('text-anchor', (cx > tx) ? 'end' : 'start')
            .text(d.DESC)
          })
        }

        // Search box
        let data__ = {
          x         : 40,
          y         : 240,
          width     : 200,
          height    : 60.5,
          label     : 'Search',
          labelWidth: 40,
          targets: [
            { text: 'TAG No / ' },
            { text: 'Description'},
          ],
          callFunc: this.call_Search// --->  in Event.js
        }
        this.drawSearchToolFree(this.canvas_Equipment, data__) //---> Global function

        if (this.current.search) {
          d3.select('#search_input').text(this.current.search)
          this.call_Search(this.current.search)
        }
      }) 
    }

  }
}