import * as d3 from 'd3'

export default {
  methods: {

    chart_Router() {

      let sChart = this.canvasChart
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      let axisData = this.chartData.filter(f=> f.DISC == 'OVERALL')
      this.set_Axes(axisData) // -----> draw_Chart.js
      this.draw_Grid(sChart) // -----> draw_Chart.js

      let chtData = this.chartData.filter(f=> f.DISC != 'OVERALL')
      this.set_BarChart_Data(sChart, chtData) // -----> below
      this.set_LineChart_Data(sChart, axisData) // -----> below
      this.draw_Tooltip(sChart)
    },


    set_BarChart_Data(selection, chtData) {
      let groupArray = [ ...new Set(chtData.map(d => d.DISC)) ]
      this.draw_Legends(chtData, groupArray) // -----> draw_Legends.js
      
      let chartArray = new Array(this.timeline.week.length)
      for(let i=0; i<this.timeline.week.length; i++) {
        chartArray[i] = []
        groupArray.forEach(d => {
          let cData = chtData.filter(f=> f.DISC == d)[i]
          chartArray[i].push({
            mDate: cData.mDate,
            P_WK:  cData.P_WK,
            A_WK:  cData.A_WK,
            D_WK:  cData.D_WK,
            P_CUM: cData.P_CUM,
            A_CUM: cData.A_CUM,
            D_CUM: cData.D_CUM,
            CONT:  cData.CONT,
            DISC:  cData.DISC,
            formatDate: cData.CDATE,
            idx: i,
            id: `${d}_${i}`,
          })
        })
      }
      this.draw_BarChart(selection, chartArray, groupArray) // -----> draw_Chart.js
    },


    set_LineChart_Data(selection, axisData) {
      let lineData = null
      this.chart.line.forEach((d,i)=> {
        if (d.type == 'ACT') {
          lineData = axisData.filter(f=> f.CDATE <= this.timeline.cutoff)
          // console.log(lineData)
        } else lineData = axisData
        this.draw_LineChart(selection, lineData, d) // -----> draw_Chart.js
      })
    },


    



  }
}
           