
import * as d3 from 'd3'

export default {
  methods: {

    status_Progress(selection, props) {

      let ePlan = this.progress[`${props.area}_EP`]
      let lPlan = this.progress[`${props.area}_LP`]
      let act = this.progress[`${props.area}_A`]
      let eDelta = act - ePlan
      let lDelta = act - lPlan

      let actual = selection
      .append('g')
      .attr('transform', `translate(${props.x}, ${props.y})`)
      .attr('font-family', 'roboto').attr('cursor', (props.icon=='Y') ? 'pointer' : 'default')
      .on('mouseover', function() {
          if(props.icon=='Y') d3.select(this).transition().duration(50).style('opacity', .5)
        })
      .on('mouseout', function() {
        if(props.icon=='Y') d3.select(this).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        if(props.icon=='Y') {
          // console.log({STAGE: 'AREA', KEY: props.area, }) // ########## FILTER
          this.link_Modal({
            req: 'AREA',
            key: props.area,
          })
        }
      })

      // TITLE & Actual progress(%)
      actual
      .append('path')
      .attr('transform', `translate(-17,-13) scale(1)`)
      .attr('fill', '#44A9DF')
      .attr('d', (props.icon=='Y') ? this.modal_icon : null)

      actual
      .append('text')
      .attr('id', `${props.id}_ACT_TITLE`)
      .attr('transform', `translate(0, 0)`)
      .attr('font-size', 18).attr('fill', '#000')
      .text((props.area) ? props.area : props.zone)

      if(props.area == null) return

      actual
      .append('text')
      .attr('x', 0).attr('y', 12)
      .attr('font-size', 10).attr('fill', '#757575')
      .text('Construction Progress')
      
      actual
      .append('text')
      .attr('id', `${props.id}_ACT_PRO`)
      .attr('x', this.getNode(`#${props.id}_ACT_TITLE`, 'width') + 10)
      .attr('font-size', 10).attr('fill', '#bcbcbc')
      .text('Actual Cuml.')

      actual
      .append('text')
      .attr('x', this.getNode(`#${props.id}_ACT_TITLE`, 'width') + this.getNode(`#${props.id}_ACT_PRO`, 'width') + 15)
      .attr('font-size', 12).attr('fill', '#44A9DF')
      .text(act + '%')


      // Early & Delta progress(%)
      let early = selection
      .append('g')
      .attr('transform', `translate(${props.x}, ${props.y + 30})`)
      .attr('font-family', 'roboto')

      early
      .append('text')
      .attr('id', `${props.id}_EARLY_TITLE`)
      .attr('font-size', 10).attr('fill', '#bcbcbc')
      .text('Early Cuml.')

      early
      .append('text')
      .attr('id', `${props.id}_EARLY_PRO`)
      .attr('x', this.getNode(`#${props.id}_EARLY_TITLE`, 'width') + 5)
      .attr('font-size', 12).attr('fill', '#333')
      .text(ePlan + '%')

      early
      .append('text')
      .attr('x', this.getNode(`#${props.id}_EARLY_TITLE`, 'width') + this.getNode(`#${props.id}_EARLY_PRO`, 'width') + 15)
      .attr('font-size', 12).attr('fill', this.setDeltaColor(eDelta))
      .text(eDelta.toFixed(1) + '%')


      // Late & Delta progress(%)
      let late = selection
      .append('g')
      .attr('transform', `translate(${props.x + 130}, ${props.y + 30})`)
      .attr('font-family', 'roboto')

      late
      .append('text')
      .attr('id', `${props.id}_LATE_TITLE`)
      .attr('font-size', 10).attr('fill', '#bcbcbc')
      .text('Late Cuml.')

      late
      .append('text')
      .attr('id', `${props.id}_LATE_PRO`)
      .attr('x', this.getNode(`#${props.id}_LATE_TITLE`, 'width') + 5)
      .attr('font-size', 12).attr('fill', '#333')
      .text(lPlan + '%')

      late
      .append('text')
      .attr('id', `LATE_DELTA`)
      .attr('x', this.getNode(`#${props.id}_LATE_TITLE`, 'width') + this.getNode(`#${props.id}_LATE_PRO`, 'width') + 15)
      .attr('font-size', 12).attr('fill', this.setDeltaColor(lDelta))
      .text(lDelta.toFixed(1) + '%')
    },


    


  }
}