// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Functions
import gmx_Miscellaneous from '../../../../../includes/mixins/Miscellaneous.mixins'

// -------------------------------------------------------------------------------------- Local
// Locally declared common Data-Props & SVG Drawing Support
import lmx_Declares from './Declares.mixin'
import lmx_Taps from './Draw_Taps.mixin'

export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Miscellaneous,

    lmx_Declares,
    lmx_Taps,
  ],
}
