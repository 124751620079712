import * as d3 from 'd3'
import moment from 'moment'
export default {

  methods: {

    set_LocalValues(){

      // Get Data from data.js
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql2 = JSON.parse(JSON.stringify(this.Queries.SQL2[0]))

      // Timeline Properties
      this.timeline.cutoff = sql2.CUTOFF
      this.timeline.startDate = sql2.START_DATE
      this.timeline.endDate = sql2.END_DATE


      
      this.chartData = this.rawData.filter(f=> f.CDATE >= sql2.START_DATE && f.CDATE <= sql2.END_DATE)

      this.init_gmx_TimelineValues(this.timeline)

      this.timeline.week.forEach((d,i) => {
        d.formatDate = moment(d.eDate).format('YYYY-MM-DD')
      })

      this.chartData.forEach(d=> {
        let date__  = this.get_gmx_TimelineCDate_Week(d.CDATE)
        d.cDate = date__.eDate
        d.mDate = date__.mDate
      })
    },

  }
}