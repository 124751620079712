import * as d3 from 'd3'

export default {
  methods: {

    call_Gantte(selection, _zone) {
      
      selection
      .on('mouseover', () => {
        d3.select(`#M-PNG-${_zone.replace('/', '_x2F_').replace(' ', '_')}`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#M-PNG-${_zone.replace('/', '_x2F_').replace(' ', '_')}`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        
        this.filter_GantteData(_zone)
      }) 
    },


  


  }
}