import * as d3 from 'd3'

export default {
  methods: {

    chart_Router() {

      // Canvas Group
      this.canvasChart = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      this.primary.max = d3.max([d3.max(this.chartData, c => c[this.chart.style[2].code]), d3.max(this.chartData, c => c[this.chart.style[3].code])])
      if(this.primary.max == 0) this.secoundary.max = 25; else this.primary.max = this.getMaxBound_v2(this.primary.max)

      this.primary.max = this.getMaxBound_v2(this.primary.max)
      this.primary.axis = d3.scaleLinear()
      .domain([0, this.primary.max])
      .range([0, this.chart.height])

      this.secoundary.max = d3.max([d3.max(this.chartData, c => c[this.chart.style[0].code]), d3.max(this.chartData, c => c[this.chart.style[1].code])])
      if(this.secoundary.max == 0) this.secoundary.max = 25; else this.secoundary.max = this.getMaxBound_v2(this.secoundary.max)

      this.secoundary.axis = d3.scaleLinear()
      .domain([0, this.secoundary.max])
      .range([this.chart.height, 0])
      
      this.draw_ChartCanvas() // -----> below
      this.draw_ChartAxis() //-----> below
      this.draw_Legends() //-----> below
      this.draw_Grid() //-----> below
      this.draw_Timeline() // -----> draw_Timeline.js
      this.chart_Controller() // -----> draw_Chart.js
    },

    draw_ChartCanvas() {
      
      let canvas = this.canvasChart
      .append('g')
      .attr('id', `CHART_${this.chart.id}`)
      .attr('transform', `translate(410.5, 70.5)`)

      canvas
      .append('text')
      .attr('id', `TITLE_${this.chart.id}`)
      .attr('x', 0) .attr('y', -25)
      .style('font-size', 14).style('fill', '#00bbf9')
      .text(this.chart.name)

      canvas
      .append('text')
      .attr('id', `TITLE_${this.chart.id}`)
      .attr('x', this.getNode(`#TITLE_${this.chart.id}`, 'width') +5) .attr('y', -25)
      .style('font-size', 10).style('fill', '#bcbcbc')
      .text('Rundown Chart')
    },

    
    draw_ChartAxis(props) {
  
      let axis = d3.select(`#CHART_${this.chart.id}`)
      axis
      .append('line')
      .attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      axis
      .append('line')
      .attr('x1', 0).attr('x2', this.chart.width).attr('y1', this.chart.height).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      axis
      .append('line')
      .attr('x1', this.chart.width).attr('x2', this.chart.width).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575' ).attr('stroke-width', .5).attr('opacity', 1)

      // Primary Axis
      axis
      .append('text')
      .attr('transform', `translate(${-30}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('No. of Completed Spool (Weekly)')

      // Secoundary Axis
      axis
      .append('text')
      .attr('transform', `translate(${this.chart.width + 35}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('No. of Remainning Spool')
    },


    draw_Legends() {
      let data = this.chartData.find(f=> f.CDATE == this.timeline.cutoff)

      let item = d3.select(`#CHART_${this.chart.id}`)
      .append('g')
      .attr('id', `LEGEND_${this.chart.id}`)
      .attr('transform', `translate(0,-10)`)
      
      let x_ = 0
      this.chart.style.filter(f=> f.type == 'LINE').forEach((d,i)=> {
        item
        .append('rect')
        .attr('x', x_)
        .attr('y', (i>1) ? -4.5 : -1.5)
        .attr('width', 8).attr('height', (i>1) ? 8 : 2).attr('fill', d.color)

        item
        .append('text')
        .attr('id', `ITEM_${this.chart.id}_${i}`)
        .attr('x', x_ + 11)
        .attr('y', 0)
        .style('font-size', 9).style('fill', '#333')
        .attr('alignment-baseline', 'middle')
        .text(d.name)
        
        x_ = x_ + this.getNode(`#ITEM_${this.chart.id}_${i}`, 'width') + 12

        item
        .append('text')
        .attr('id', `VALUE_${this.chart.id}_${i}`)
        .attr('x', x_)
        .attr('y', 0)
        .style('font-size', 9).style('fill', '#757575')
        .attr('alignment-baseline', 'middle')
        .text(`(${data[d.code]})`)

        x_ = x_ + this.getNode(`#VALUE_${this.chart.id}_${i}`, 'width') + 10
      })
    },


    draw_Grid() {
      let grid = this.chart.height / 5

      let Grids = d3.select(`#CHART_${this.chart.id}`)

      for(let i=0; i<6; i++) {
        Grids // Grid
        .append('line')
        .attr('x1', 0).attr('x2', this.chart.width)
        .attr('y1', grid * i).attr('y2', grid * i)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
        .attr('opacity', .5)

        Grids // Primary Axis
        .append('text')
        .attr('x', -2)
        .attr('y', this.chart.height - (grid * i))  
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text((this.primary.max * (i*.2)).toFixed(0))

        Grids // Secoundary Axis
        .append('text')
        .attr('x', this.chart.width + 2)
        .attr('y', this.chart.height - (grid * i))
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text((this.secoundary.max * (i*.2)).toFixed(0))
      }
    },


  }
}
           