import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      this.canvasForms
      .append('text')
      .attr('transform', `translate(20, 20)`)
      .attr('font-size', 14).attr('fill', '#333')
      .text('Shop Drawing Progress by Area')

      let x_ = 28
      this.schedule.forEach(d => {
        
        let stg = this.canvasForms
        .append('g')
        .attr('id', `GROUP_${d.id}`)
        .attr('transform', `translate(${x_}, 35)`)
        .attr('opacity', d.id == this.current.sch.id ? 1 : 0.8)
        .attr('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#GROUP_${d.id}`).attr('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#GROUP_${d.id}`).attr('opacity', d == d ? 1 : 0.8) 
        })
        .on('click', ()=> {
          if(this.current.sch.id == d.id) return
          d3.select(`#GROUP_${this.current.sch.id}`).attr('opacity', 0.8) 
          d3.select(`#GROUP_text_${this.current.sch.id}`).attr('font-weight', 400).attr('fill', '#333')
          d3.select(`#GROUP_in_${this.current.sch.id}`).attr('fill', '#fff')

          this.current.sch = this.schedule.find(f=> f.id == d.id)
          d3.select(`#GROUP_${d.id}`).attr('opacity', 1) 
          d3.select(`#GROUP_text_${d.id}`).attr('font-weight', 500).attr('fill', '#000')
          d3.select(`#GROUP_in_${d.id}`).attr('fill', '#F35E90')

          this.change_Chart(d.id) //-----> below
        })
        
        stg
        .append('circle')
        .attr('id', `GROUP_Out_${d.id}`)
        .attr('transform', `translate(0,-0.5)`)
        .attr('r', 6).attr('stroke', '#F7BACF').attr('stroke-width', 1).attr('fill', '#fff')

        stg
        .append('circle')
        .attr('id', `GROUP_in_${d.id}`)
        .attr('transform', `translate(0,-0.5)`)
        .attr('r', 3).attr('stroke', '#F35E90').attr('stroke-width', 0).
        attr('fill', this.current.sch.id == d.id ? '#F35E90' : '#fff')

        stg
        .append('text')
        .attr('id', `GROUP_text_${d.id}`)
        .attr('transform', `translate(11, 0.5)`)
        .attr('font-size', 10).attr('fill', '#333')
        .attr('font-weight', this.current.sch.id == d.id ? 500 : 400)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(d.name)

        x_ += this.getNode(`#GROUP_text_${d.id}`, 'width') + 40
      })

      this.chart_Router() //-----> chart_Router.js
    },


    change_Chart(sch_) {

      let changedSch = this.chart[sch_]

       // Change the Box of Summary
       if(sch_ == 'start') {
        d3.selectAll(`.Issued`).transition().duration(300).attr('opacity', 0.2)
        d3.selectAll(`.Start`).transition().duration(500).attr('opacity', 1)
      } else {
        d3.selectAll(`.Start`).transition().duration(300).attr('opacity', 0.2)
        d3.selectAll(`.Issued`).transition().duration(500).attr('opacity', 1)
      }


      
      this.areaList.forEach((area, sn)=> {
        let chtData = this.rawData.filter(f=> f.AREA == area.code && f.DISC == area.disc)

        // Change Bar Chart
        changedSch.filter(f=> f.cType == 'BAR').forEach(st=> {

          chtData.forEach((d,i)=> {
            d3.select(`#BAR_${st.lType}_${sn}_${i}`).transition().duration(300)
            .attr('y', this.chart.height-this.primary[sn].axis(d[st.code]))
            .attr('height', (d[st.code] < 0) ? 0 : this.primary[sn].axis(d[st.code]))
          })

        })


        // Change the Line Chart
        changedSch.filter(f=> f.cType == 'LINE').forEach((st,i)=> {

          if (st.lType == 'ACT') chtData = chtData.filter(f=> f.CDATE <= this.timeline.cutoff)
          
          let dev = null
          let lastOne = chtData.find(f=> f.CDATE == this.timeline.cutoff)
          if(lastOne) {
            dev = lastOne[changedSch[1].code]-lastOne[changedSch[0].code]
          }

          let lineFunc = d3.line()
          .x(d => this.timeline.scale(new Date(d.mDate)))
          .y(d => this.secoundary[sn].axis(d[st.code]))

          d3.select(`#LINE_${st.lType}_${sn}`).transition().duration(300)
          .attr('d', lineFunc(chtData))
          .attr('stroke', (st.lType == 'ACT' && dev < 0) ? '#F35E90': st.sColor)

          // Change the final Value
          if(st.lType == 'ACT') {
            d3.select(`#LAST_${st.lType}_${sn}`).transition().duration(300)
            .attr('y', this.secoundary[sn].axis(lastOne[st.code]))
            .text(lastOne[st.code])
          }
        })

      })



    },
  }
}
           