import * as d3 from 'd3'

export default {
  methods: {

    chart_Controller(chtData, d_) {

      let sChart = this.canvasChart
      .append('g')
      .attr('class', 'CHARTS')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')  

      this.draw_Grid(sChart) // -----> draw_Elements.js
      
      // Call Chart
      this.ChartStyle.filter(f=> f.cType == 'BAR').forEach((d,i)=> {
        this.draw_BarChart(sChart, chtData, d)
      })

      this.ChartStyle.filter(f=> f.cType == 'LINE').forEach((d,i)=> {
        if (d.lType == 'ACT') chtData = chtData.filter(f=> f.CDATE <= this.timeline.cutoff)
        this.draw_LineChart(sChart, chtData, d)
      })

      this.draw_InfoBox(sChart, chtData)

    },

    draw_InfoBox(selection, d_){
      let lastOne = d_.find(f => f.CDATE == this.timeline.cutoff)

      let infoArea = selection
      .append('g')
      .attr('id', `info_area`)
      .attr('transform','translate(5,5)')

      infoArea
      .append('rect')
      .attr('width',120).attr('height',50)
      .attr('rx',3).attr('ry',3)
      .attr('stroke','#757575').attr('stroke-width',.5)
      .attr('fill','#fff').attr('opacity', 0.7)

      infoArea
      .append('text')
      .attr('transform','translate(70,5)')
      .attr('text-anchor','end').attr('alignment-baseline','hanging')
      .attr('fill','#757575').attr('font-size',9)
      .text('Plan')

      infoArea
      .append('text')
      .attr('transform','translate(110,5)')
      .attr('text-anchor','end').attr('alignment-baseline','hanging')
      .attr('fill','#44A9DF').attr('font-size',9)
      .text('Actual')

      infoArea
      .append('text')
      .attr('transform','translate(5,23)')
      .attr('alignment-baseline','central')
      .attr('fill','#757575').attr('font-size',9)
      .text('Weekly')

      infoArea
      .append('text')
      .attr('transform','translate(5,40)')
      .attr('alignment-baseline','central')
      .attr('fill','#757575').attr('font-size',9)
      .text('Cuml.')

      infoArea
      .append('text')
      .attr('transform','translate(70,23)')
      .attr('text-anchor','end').attr('alignment-baseline','central')
      .attr('fill','#757575').attr('font-size',9)
      .text(this.Toggle == 'off' ? lastOne.PF_WK : lastOne.PS_WK)

      infoArea
      .append('text')
      .attr('transform','translate(70,40)')
      .attr('text-anchor','end').attr('alignment-baseline','central')
      .attr('fill','#757575').attr('font-size',9)
      .text(this.Toggle == 'off' ? lastOne.PF_CUM : lastOne.PS_CUM)

      infoArea
      .append('text')
      .attr('transform','translate(110,23)')
      .attr('text-anchor','end').attr('alignment-baseline','central')
      .attr('fill','#44A9DF').attr('font-size',9)
      .text(this.Toggle == 'off' ? lastOne.AF_WK : lastOne.AS_WK)

      infoArea
      .append('text')
      .attr('transform','translate(110,40)')
      .attr('text-anchor','end').attr('alignment-baseline','central')
      .attr('fill','#44A9DF').attr('font-size',9)
      .text(this.Toggle == 'off' ? lastOne.AF_CUM : lastOne.AS_CUM)
    },
    
    draw_Grid(selection) {

      for(let i=0; i<6; i++) {
        selection // Grid
        .append('line')
        .attr('x1', 0).attr('x2', this.Chart.width)
        .attr('y1', this.Chart.grid * i).attr('y2', this.Chart.grid * i)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
        .attr('opacity', .5)

        selection // Primary Axis
        .append('text')
        .attr('x', -2)
        .attr('y', this.Chart.height - (this.Chart.grid * i))  
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text((this.primary.max * (i*.2)).toFixed(0))

        selection // Secoundary Axis
        .append('text')
        .attr('x', this.Chart.width + 2)
        .attr('y', this.Chart.height - (this.Chart.grid * i))
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text((this.secoundary.max * (i*.2)).toFixed(0))
      }
    },


    draw_BarChart(selection, data_, st_) {

      let barWidth = 3.5

      let barChart = selection
      .append('g')
      .attr('transform', `translate(0.5,0)`)

      data_.forEach((d,i)=> {

        barChart
        .append('rect')
        .attr('x', (st_.lType == 'PLAN') ? this.timeline.scale(d.mDate) - barWidth : this.timeline.scale(d.mDate))
        .attr('y', this.Chart.height-this.primary.axis(d[st_.code]))
        .attr('width', barWidth).attr('height', this.primary.axis(d[st_.code]))
        .attr('fill', st_.sColor).attr('opacity', st_.opacity)


        if (st_.lType =='PLAN' || d[st_.code] == 0) return
        barChart
        .append('text')
        .attr('x', this.timeline.scale(d.mDate) + (barWidth/2))
        .attr('y', this.Chart.height-this.primary.axis(d[st_.code]) -2)
        .attr('text-anchor', 'middle').attr('font-size', 8).attr('fill', '#83D2F5')
        .text(d[st_.code])
      })
    },


    draw_LineChart(selection, data_, st_) {
      let dev = null
      let lastOne = data_.find(f=> f.CDATE == this.timeline.cutoff)
      if(lastOne) {
        dev = lastOne[this.ChartStyle[1].code]-lastOne[this.ChartStyle[0].code]
      }

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(new Date(d.mDate)))
      .y(d => this.secoundary.axis(d[st_.code]))

      let lineChart = selection
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      lineChart
      .append('path')
      .attr('d', lineFunc(data_))
      .style('stroke', (st_.lType == 'ACT' && dev < 0) ? '#F35E90': st_.sColor)
      .style('stroke-dasharray', (st_.lType == 'PLAN') ? (3,3) : (0,0))
      .style('stroke-width', st_.sWidth).style('fill', 'none')

      // draw the final cumulative value
      if(st_.lType == 'ACT') {
        if(!lastOne) return
        lineChart
        .append('text')
        .attr('x', this.timeline.scale(new Date(lastOne.CDATE)))
        .attr('y', this.secoundary.axis(lastOne[st_.code]))
        .style('font-size', 10)
        .style('fill', (dev < 0) ? '#F35E90' : '#44A9DF')
        .attr('alignment-baseline', 'middle')
        .text(lastOne[st_.code])
      }
    },


  }
}