import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_Block(_d) {
      console.log(_d.BLK) //////////
      
      this.current.stage = 'BLOCK'
      this.current.sn = _d.SN

      this.model_Block = this.svg
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      let progress = this.model_Block
      .append('g')
      .attr('transform', `translate(40, 380)`)

      let model = this.model_Block
      .append('svg')
      .attr('x', 0)
      .attr('width', 1100).attr('height', 700)
      
      .append('g')
      .attr('id', `MODEL_BLOCK`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      if (this.current.area == 'Hull') {
        this.loadSvg(
          model, {
          x   : 100,
          y   : 0,
          url : `/Visualization/BlockDivision/Rev1/${this.current.area}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(250,250) scale(1)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.set_Modal_Blocks(_d.BLK) // -----> below
          this.func_blockColoring(model, this.current.area.toUpperCase(), 'ASSEMBLY', _d.BLK) // -----> vFunction/block_Coloring.js
          this.draw_Block_Canvas(_d) // ----->
        })
      }
      
      if (this.current.area == 'Topside' || this.current.area == 'LQ') {
        this.loadSvg(
          model, {
          x   : 120,
          y   : 0,
          url : `/Visualization/BlockDivision/Rev1/Topside/${_d.AREA}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(250,290) scale(1)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.set_Modal_Blocks(_d.BLK) // -----> below
          this.func_blockColoring(model, this.current.area.toUpperCase(), 'ASSEMBLY', _d.BLK) // -----> vFunction/block_Coloring.js
          this.draw_Block_Canvas(_d) // ----->
        }) 
      }
      

      this.set_selectedItems()
    },


    set_Modal_Blocks(_blk) {
      let _data = this.erectionList.find(f=> f.BLK == _blk)
      
      d3.select(`#EREC-${_blk}`)
      .attr('cursor', 'pointer').call(this.call_madal_Window, {STAGE: 'EREC', KEY: _blk}) // -----> call Madal Window
      
      
      this.masterList.filter(f=> f.G_EREC == _blk).forEach(d => {
        if (d.PE1) {
          d3.select(`#PE1-${d.PE1}`)
          .attr('cursor', 'pointer').call(this.call_madal_Window, {STAGE: 'PE1', KEY: d.PE1}) // -----> call Madal Window
        }
        d3.select(`#${d.BLK}`)
        .attr('cursor', 'pointer').call(this.call_madal_Window, {STAGE: 'BLK', KEY: d.BLK, KEY2: d.BLK_XXXX}) // -----> call Madal Window
      })
    }

  }
}