import * as d3 from 'd3'

export default {
  data: () => ({
    selectedItems: null,
    getItems: null,
  }),

  methods: {

    set_selectedItems() {  
      this.selectedItems = { 
        ...this.selectedItems,
        stage: this.current.stage,
        zone: this.current.zone,
        svg: this.current.svg,
        sn: this.current.sn,
        toggle: this.Toggle,
      }
    },

    draw_PDF() {
      let stage = this.getItems.stage
      let zone = this.getItems.zone
      let svg = this.getItems.svg
      let sn = this.getItems.sn
      let toggle = this.getItems.toggle
      
      if (stage == 'MODULE') {
        let data = this.areaSummary.find(f=> f.MODULE =='Y' && f.ZONE == zone)

        this.draw_Model_Module(data)
      } else if (stage == 'EQ') {
        let data = this.svgFile.find(f=> f.ZONE == zone && f.SVG == svg)

        this.draw_Model_Equipment(data)

        if (sn) {
          let detailData = this.eqList.find(f => f.SN = sn)

          this.update_detail(detailData)
        }

        if (toggle == 'off') {
          this.search.mod = zone
          this.search.lev = svg
          
          this.toggle_action()
        }
      } else {
        this.draw_Model_Overall()
      }
    },

  },

  watch: {
    selectedItems() {
      this.$emit('selectedItems', this.selectedItems)
    },
  },
}