import * as d3 from 'd3'

export default {
  methods: {
    
    dropdown_Controller(target_, item_) {

      // PHASE ---------------------------------------------------------------------------------------------------
      if(target_ == 'PHASE') {
        // Dropdown - Group
        this.current.phase = (item_.charAt(0) == '-') ? false : item_

        let getItem = this.combine.filter(f=> f.phase == item_)
        getItem = [ ...new Set(getItem.map(d => d.group)) ]
        getItem.unshift('- Group -')

        this.draw_DropdownItem(getItem, 'GROUP') //-----> below

        // Dropdown- GROUP
        this.current.group = false
        d3.select(`#DROP_TITLE_GROUP`).text('- Group -')
        d3.select(`#DROP_ITEM_GROUP`).attr('visibility', 'hidden')
        d3.select(`#ARROW_GROUP`).attr('transform', `translate(${this.dropdown[1].boxWidth - 12} ,7) rotate(0)`)

        //Dropdown - SUBCONT
        this.current.subcont = false
        d3.select(`#DROP_TITLE_SUBCONT`).text('- Subcont. name -')
        d3.select(`#DROP_ITEM_SUBCONT`).attr('visibility', 'hidden')
        d3.select(`#ARROW_SUBCONT`).attr('transform', `translate(${this.dropdown[2].boxWidth - 12} ,7) rotate(0)`)
      }

      // GROUP ---------------------------------------------------------------------------------------------------
      if(target_ == 'GROUP') {
        this.current.group = (item_.charAt(0) == '-') ? false : item_
      
        // Dropdown - SUBCONT
        this.current.subcont = false
        let getItem = this.combine.filter(f=> f.phase == this.current.phase && f.group == item_)
        getItem = [ ...new Set(getItem.map(d => d.subcont)) ]
        getItem.unshift('- Subcont. name -')

        d3.select(`#DROP_TITLE_SUBCONT`).text('- Subcont. name -')
        this.draw_DropdownItem(getItem, 'SUBCONT') //-----> below
      }

      // SUBCONT ---------------------------------------------------------------------------------------------------
      if(target_ == 'SUBCONT') {
        this.current.subcont = (item_.charAt(0) == '-') ? false : item_
      }

      d3.select(`#DROPDOWN_CHARTS`).remove()
      d3.select(`#DROPDOWN_LEGENDS`).remove()
      this.chart_Router() //-----> chart_Router.js

      this.set_selectedItems()
    }, 
 
    
  }
}