import * as d3 from 'd3'

export default {
  methods: {
    

    call_LINK(selection, d_) {
      selection
      .attr('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).attr('opacity', 0.5)
      })
      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', () => {
        this.link_Modal(d_)
      }) 
      
    }, 

    link_Modal(d_) {

      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {KEY_STAGE: d_.STAGE} // DO not Remark if possible 
      if (d_.AREA != 'OVERALL') dataSource = {...dataSource, KEY_AREA: d_.AREA}
      if (d_.ZONE != 'OVERALL') dataSource = {...dataSource, KEY_ZONE: d_.ZONE}
      console.log(dataSource)

      let req = 'request'

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(req, dataSource, this.ColumnProps)
      if(!request_) return

      // set for filterString 
      let filterString_ = ''

      if (d_.STATUS == 'CP') {
        filterString_ += `[AF] IS NOT NULL`
      } else if (d_.STATUS == 'AD') {
        filterString_ += `[AF] IS NULL AND ISNULL([AP],0) - ISNULL([PP],0) > 0`
      } else if (d_.STATUS == 'BH') {
        filterString_ += `[AF] IS NULL AND ISNULL([AP],0) - ISNULL([PP],0) < 0`
      }

      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },

  }
}