import CONST from '../../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    rawData:      [],
    chartData:    [],
    tableData:    [],
    canvasForms:  null,
    canvasTable:  null,
    canvasChart:  null,

    table: {
      width: null,
      height: null,
      lineheight: 20,
      style: [
        {name: 'Zone',    code: 'ZONE',  width: 105, align: 'start'},
        {name: 'Total',   code: 'TOT',   width: 45,  align: 'end'},
        {name: 'PO',      code: 'PO',    width: 45,  align: 'end'},
        {name: 'FAT.',    code: 'FAT',   width: 45,  align: 'end'},
        {name: 'ATA',     code: 'ATA',   width: 45,  align: 'end'},
        {name: 'Install', code: 'IST',   width: 45,  align: 'end'},
      ],
      style2: [
        {name: 'Level',   code: 'LEVEL', width: 105, align: 'start'},
        {name: 'Total',   code: 'TOT',   width: 45,  align: 'end'},
        {name: 'PO',      code: 'PO',    width: 45,  align: 'end'},
        {name: 'FAT.',    code: 'FAT',   width: 45,  align: 'end'},
        {name: 'ATA',     code: 'ATA',   width: 45,  align: 'end'},
        {name: 'Install', code: 'IST',   width: 45,  align: 'end'},
      ]
    },

    chart: {
      id: 'SP',
      name: null,
      width:  375,
      height: 200,
      style: [
        {name: 'PO',      code: 'PO',   type: 'LINE', size: 1, color: '#ffb400'},
        {name: 'FAT',     code: 'FAT',  type: 'LINE', size: 1, color: '#f6511d'},
        {name: 'ATA',     code: 'ATA',  type: 'LINE', size: 1, color: '#00a6ed'},
        {name: 'Install', code: 'IST',  type: 'LINE', size: 1, color: '#7fb800'},
      ]
    },
    timeline:     {},
    primary:      {axis: null, max: null},
    secoundary:   {axis: null, max: null},

  }),


  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}