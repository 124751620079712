import * as d3 from 'd3'

export default {
  
  methods: {

    draw_Overall_Canvas() {
      
      this.canvas_Overall = this.svg
      .append('g')
      .attr('id', `CANVAS_Overall`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')


      this.status_Progress(this.canvas_Overall, {id: 'OV', x: 40, y: 180, area: 'OVERALL',})
      this.status_Structure(this.canvas_Overall, {x: 40, y: 250, stage: 'OVERALL', filter: 'OVERALL',})
      this.status_Outfitting(this.canvas_Overall,{x: 40, y: 400, stage: 'OVERALL', filter: 'OVERALL',}) 
      

      this.draw_BlockStatus(this.canvas_Overall, this.masterList, {x: 1000, y: 600})


    },
    
  }
}