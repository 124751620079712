import * as d3 from 'd3'

export default {
  methods: {

    draw_ChartCanvas(props, _col) {

      let canvas = this.canvasChart
      .append('g')
      .attr('id', `CHART_${props.area}`)
      .attr('transform', `translate(${props.x +.5}, ${props.y + .5})`)

      canvas
      .append('text')
      .attr('id', `TITLE_${props.area}`)
      .attr('x', 0) .attr('y', -25)
      .style('font-size', 15)
      .style('fill', '#000')
      .text(`${props.area}`)

      canvas
      .append('text')
      .attr('x', this.getNode(`#TITLE_${props.area}`, 'width') + 3) .attr('y', -25)
      .style('font-size', 11).style('fill', '#858585')
      .text(`Work Order Qty:`)

      canvas
      .append('text')
      .attr('x', this.getNode(`#TITLE_${props.area}`, 'width') + 85) .attr('y', -25)
      .style('font-size', 11).style('fill', '#F35E90')
      .text(props.total)
      .call(this.call_LINK, {col: _col, area: props.area, type: 'total'})

      this.draw_ChartAxis(props) //-----> below
      this.draw_Legends(props, _col) //-----> below
      this.draw_Grid(props) //-----> below
    },

    
    draw_ChartAxis(props) {
      
      let axis = d3.select(`#CHART_${props.area}`)
      axis
      .append('line')
      .attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      axis
      .append('line')
      .attr('x1', 0).attr('x2', this.chart.width).attr('y1', this.chart.height).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      axis
      .append('line')
      .attr('x1', this.chart.width).attr('x2', this.chart.width).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575' ).attr('stroke-width', .5).attr('opacity', 1)

      // Primary Axis
      axis
      .append('text')
      .attr('transform', `translate(${-23}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('No. of Spool (Weekly)')

      // Secoundary Axis
      axis
      .append('text')
      .attr('transform', `translate(${this.chart.width + 30}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('No. of Spool (Cumulative)')
    },


    draw_Legends(props, _col) {

      let item = d3.select(`#CHART_${props.area}`)
      .append('g')
      .attr('id', `LEGEND_${props.area}`)
      .attr('transform', `translate(0,-15)`)
      

      // Fabrication
      let pro = (props.fab/props.total)*100
      item
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', 8).attr('height', 8).attr('fill', '#bcbcbc')
      
      item
      .append('text')
      .attr('x', 10).attr('y', 0)
      .style('font-size', 10).style('fill', '#757575').attr('alignment-baseline', 'hanging ')
      .text('Fab.')

      item
      .append('rect')
      .attr('x', 35).attr('y', 0)
      .attr('width', 100).attr('height', 10).attr('fill', '#F4F5F5')

      item
      .append('rect')
      .attr('x', 35).attr('y', 0)
      .attr('width', pro).attr('height', 8).attr('fill', '#bcbcbc')

      item
      .append('text')
      .attr('x', (pro < 70) ? 35 + pro + 2 : 35 + pro -2).attr('y', 0)
      .attr('text-anchor', (pro < 70) ? 'start' : 'end')
      .style('font-size', 10).style('fill', '#333').attr('alignment-baseline', 'hanging ')
      .text(props.fab)
      .call(this.call_LINK, {col: _col, area: props.area, type:'fab'})

      // Installation
      pro = (props.install/props.total)*100
      item
      .append('rect')
      .attr('x', 150).attr('y', 0)
      .attr('width', 8).attr('height', 8).attr('fill', '#83D2F5')

      item
      .append('text')
      .attr('x', 160).attr('y', 0)
      .style('font-size', 10).style('fill', '#757575').attr('alignment-baseline', 'hanging ')
      .text('Install')

      item
      .append('rect')
      .attr('x', 193).attr('y', 0)
      .attr('width', 100).attr('height', 10).attr('fill', '#F4F5F5')

      item
      .append('rect')
      .attr('x', 193).attr('y', 0)
      .attr('width', pro).attr('height', 8).attr('fill', '#83D2F5')

      item
      .append('text')
      .attr('x', (pro < 70) ? 193 + pro + 2 : 193 + pro -2).attr('y', 0)
      .attr('text-anchor', (pro < 70) ? 'start' : 'end')
      .style('font-size', 10).style('fill', '#333').attr('alignment-baseline', 'hanging ')
      .text(props.install)
      .call(this.call_LINK, {col: _col, area: props.area, type: 'install'})

    },


    draw_Grid(props) {
      let grid = this.chart.height / 5

      let Grids = d3.select(`#CHART_${props.area}`)

      for(let i=0; i<6; i++) {
        Grids // Grid
        .append('line')
        .attr('x1', 0).attr('x2', this.chart.width)
        .attr('y1', grid * i).attr('y2', grid * i)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
        .attr('opacity', .5)

        Grids // Primary Axis
        .append('text')
        .attr('x', -2)
        .attr('y', this.chart.height - (grid * i))  
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text((this.primary.max * (i*.2)).toFixed(0))

        Grids // Secoundary Axis
        .append('text')
        .attr('x', this.chart.width + 2)
        .attr('y', this.chart.height - (grid * i))
        .style('font-size', 9).style('fill', '#757575')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text((this.secoundary.max * (i*.2)).toFixed(0))
      }
    },


  }
}
           