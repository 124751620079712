
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvas_Form = this.svg
      .append('g')
      .attr('id', `CANVAS_FROM`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto').attr('cursor', 'default')
     
      this.canvas_Form
      .append('rect')
      .attr('x', 0).attr('y', 1110).attr('width', 1600).attr('height', 10).attr('fill', '#D71638')
      
      this.canvas_Form
      .append('image')
      .attr('xlink:href', this.HOST + '/icon/Logo/TrionFPU_r1.svg')
      .attr('x', 30).attr('y', 30).attr('width', 230)

      this.canvas_Form
      .append('text')
      .attr('transform', `translate(30, 108)`).attr('font-size', 24).attr('fill', '#000')
      .text('3D Equipment Layout')

      this.canvas_Form
      .append('text')
      .attr('id', `SUB_TITLE`)
      .attr('transform', `translate(30, 125)`).attr('font-size', 12).attr('fill', '#44A9DF')
      .text(this.source)

      this.canvas_Form
      .append('text')
      .attr('id', `TITLE_MAIN`)
      .attr('transform', `translate(30, 155)`)
      .attr('font-size', 17).attr('fill', '#D71638').attr('alignment-baseline', 'bottom')
      .text(`FPU OVERALL`)

      this.canvas_Form
      .append('text')
      .attr('id', `TITLE_SUB`)
      .attr('x', this.getNode('#TITLE_MAIN','width') + 35)
      .attr('y', 155)
      .attr('font-size', 14).attr('fill', '#D71638').attr('alignment-baseline', 'bottom').attr('opacity', .5)
      .text(' STATUS')

      this.loadSvg(
        this.canvas_Form, {
        x: 30, y: -360, width: 500,
        url : `/Visualization/Layout_Equipment/EQ_Legends.svg`
        }).then(() => {
          d3.selectAll('text').style('font-family', 'roboto')
        })

        // Load a JGS logo SVG
        this.loadSvg(
          this.canvas_Form, {
          x   : 1460, y   : 1060, 
          url : '/icon/Logo/JGS.svg'
        })

      // console.log(this.AddComma(1234))
      // this.draw_Model_Equipment({ ZONE: 'HULL', SVG: 'SE', NAME: 'PRODUCTION DECK'})
      // this.draw_Model_Equipment({ ZONE: 'SOUTH', SVG: 'MD', NAME: 'PRODUCTION DECK'})
    },


  }
}