
import * as d3 from 'd3'

export default {

  data: () => ({
    status: [
      {name: 'Stage', code: '',      width: 50, color: '#333333', align: 'end'},
      {name: 'Total', code: '_TOT',  width: 32, color: '#757575', align: 'end'},
      {name: 'Plan',  code: '_PLAN', width: 32, color: '#757575', align: 'end'},
      {name: 'Act',   code: '_ACT',  width: 32, color: '#757575', align: 'end'},
      {name: 'Delta', code: '_DTL',  width: 32, color: '#757575', align: 'end'},
    ],
    tblLen: 0,
    schedule: 'F',
  }),


  methods: {

    draw_BlockStatus(_d) {

      this.activity.forEach((d,i)=> {

        d[`S_${d.code}_TOT`] =   _d.data.filter(f=> f[`${d.code}_PS`]).length
        d[`F_${d.code}_TOT`] =   _d.data.filter(f=> f[`${d.code}_PS`]).length

        d[`S_${d.code}_PLAN`] =   _d.data.filter(f=> f[`${d.code}_PS`] <= this.timeline.cutoff).length
        d[`S_${d.code}_ACT`] =    _d.data.filter(f=> f[`${d.code}_AS`] <= this.timeline.cutoff).length
        d[`S_${d.code}_DTL`] =    d[`S_${d.code}_ACT`] - d[`S_${d.code}_PLAN`]

        d[`F_${d.code}_PLAN`] =  _d.data.filter(f=> f[`${d.code}_PF`] <= this.timeline.cutoff).length
        d[`F_${d.code}_ACT`] =   _d.data.filter(f=> f[`${d.code}_AF`] <= this.timeline.cutoff).length
        d[`F_${d.code}_DTL`] =   d[`F_${d.code}_ACT`] - d[`F_${d.code}_PLAN`]
      })

      let x = 0
      this.status.forEach(d=> {
        d.mx = x + d.width/2
        if(d.align == 'start') d.tx = x
        if(d.align == 'middle') d.tx = x + d.width/2
        if(d.align == 'end') d.tx = x + d.width
        x = x + d.width
      })
      this.tblLen = x

      this.draw_Status(_d.x, _d.y) // -----> below
    },


    draw_Status(_x, _y) {

      let block = this.canvasForms
      .append('g')
      .attr('id', `BLOCK_STATUS`)
      .attr('transform', `translate(${_x}, ${_y})`)
      .attr('font-family', 'roboto')

      // Header
      block
      .append('text')
      .attr('x',0).attr('y', -17)
      .attr('font-size', 11).attr('fill', '#333')
      .text('Block Status')

      block // Start
      .append('text')
      .attr('id', `BTN_S`)
      .attr('x',this.tblLen-60).attr('y', -16).attr('cursor', 'pointer')
      .attr('font-size', 10).attr('fill', (this.schedule == 'S') ? '#F35E90' : '#bcbcbc')
      .text('Start')
      .call(this.call_Schedule, 'S') // -----> below

      block 
      .append('text')
      .attr('x',this.tblLen-35).attr('y', -16)
      .attr('font-size', 10).attr('fill', '#333')
      .text('/')

      block // Finish
      .append('text')
      .attr('id', `BTN_F`)
      .attr('x',this.tblLen).attr('y', -16).attr('cursor', 'pointer')
      .attr('font-size', 10).attr('fill', (this.schedule == 'F') ? '#F35E90' : '#bcbcbc')
      .attr('text-anchor', 'end')
      .text('Finish')
      .call(this.call_Schedule, 'F') // -----> below

      block
      .append('line')
      .attr('x1', 0).attr('y1', -12).attr('x2', this.tblLen).attr('y2', -12)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      this.status.forEach(d=> {
        block
        .append('text')
        .attr('x', d.tx).attr('y', 0).attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end')
        .text(d.name)
      })

      block
      .append('line')
      .attr('x1', 0).attr('y1', 5).attr('x2', this.tblLen).attr('y2', 5)
      .attr('stroke', '#757575').attr('stroke-width', .5)


      // DataGrid
      let y = 20
      this.activity.forEach((d,i)=> {
        block
        .append('text')
        .attr('x',this.status[0].tx).attr('y', y).attr('font-size', 10).attr('fill', this.status[0].color).attr('text-anchor', this.status[0].align)
        .text(d.name)

        this.status.filter(f=> f.name != 'Stage').forEach(s=> {
          block
          .append('text')
          .attr('id', `${d.code}_${s.name}`)
          .attr('x', s.tx-3).attr('y', y)
          .attr('fill', (s.name == 'Delta') ? this.setDeltaColor(d[`${this.schedule}_${d.code}${s.code}`]) : s.color)
          .attr('font-size', 10).attr('text-anchor', s.align)
          .text(d[`${this.schedule}_${d.code}${s.code}`])
        })
        y = y + 16
      })
      
    },

    change_Status() {
      this.activity.forEach((d,i)=> {

        this.status.filter(f=> f.name != 'Stage').forEach(s=> {
 
          let txt = d3.select(`#${d.code}_${s.name}`)
          let before = Number(txt.text())
          let after = Number(d[`${this.schedule}_${d.code}${s.code}`])

          txt
          .transition().duration(500)
          .attr('fill', (s.name == 'Delta') ? this.setDeltaColor(after) : s.color)
          .tween("text", function() {
            var i = d3.interpolateNumber(before, after)
            return function(t) {
            d3.select(this).text( `${Number(i(t)).toFixed(0)}` )
            }
          })
        })

      })
    },

    call_Schedule(selection, _sch) {
      // if(this.schedule == _sch) return
      
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        d3.select(`#BTN_${this.schedule}`).attr('fill', '#bcbcbc')
        d3.select(`#BTN_${_sch}`).attr('fill', '#F35E90')
        this.schedule = _sch
        
        this.change_Status()
      }) 
    },
  }
}