import * as d3 from 'd3'

export default {
  methods: {

    draw_Status(_data, _disc, y, color) {
      
      let count = _data.length

      this.canvas_Status = this.svg
      .append('g')
      .attr('transform', `translate(40.5,80.5)`)
      .attr('font-family', 'roboto')

      this.canvas_Status
      .append('rect')
      .attr('x', 0).attr('y', y)
      .attr('width', 730).attr('height', (count*25) + 40)
      .attr('fill', 'none').attr('stroke', '#E0E0DF').attr('stroke-wisth', 1)

      this.canvas_Status
      .append('rect')
      .attr('x', 30).attr('y', y-10)
      .attr('width', 100).attr('height', 20)
      .attr('fill', '#F2F2F2').attr('stroke', '#757575').attr('stroke-wisth', 1)

      this.canvas_Status
      .append('text')
      .attr('x', 80).attr('y', y)
      .attr('font-size', 13).attr('fill', '#000')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(_disc)

  
      y = y + 30

      _data.forEach((d,i)=> {

        if(i == 0 || i>0 && d.ITEM != _data[i-1].ITEM) {
          this.canvas_Status
          .append('text')
          .attr('x', 30).attr('y', y)
          .attr('font-size', 12).attr('fill', '#000').attr('alignment-baseline', 'middle')
          .text(d.ITEM)

          // TOTAL
          this.canvas_Status
          .append('text')
          .attr('x', 130).attr('y', y)
          .attr('font-size', 14).attr('fill', '#44A9DF')
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(d.TOT)
          .call(this.call_Link, {STATUS:'TOTAL', DISC: d.DISC, ITEM: d.ITEM}) // -----> Event.js
        }

        this.canvas_Status
        .append('text')
        .attr('x', 200).attr('y', y)
        .attr('font-size', 11).attr('fill', '#333').attr('alignment-baseline', 'middle')
        .text(d.NAME)

        this.draw_barChart(d, y, color) // -----> below

        y = y + 25
      })

    },
  }
}