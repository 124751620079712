
import * as d3 from 'd3'

export default {

  data: () => ({

    activities: [
      {name: 'Steel Cutting',   code: 'SC', color: '#bcbcbc', },
      {name: 'Block Fab.',code: 'FB', color: '#FFDA00', },
      {name: 'Assembly',  code: 'AS', color: '#83D2F5', },
      {name: '1st PE',    code: 'PE1',color: '#83D2F5', },
      {name: 'Painting',  code: 'PT', color: '#F7BACF', },
      {name: '2nd PE',    code: 'PE2',color: '#83D2F5', },
      {name: 'Block Setting',   code: 'ER', color: '#8BC248', },
    ],

    blkStatus: [
      {name: 'Stage', code: '',     width: 90, color: '#333333', align: 'middle', head: 1},
      {name: 'Total', code: '_TOT', width: 50, color: '#757575', align: 'end', head: 1},
      {name: 'Plan',  code: '_SP',  width: 45, color: '#757575', align: 'end', head: 2},
      {name: 'Act',   code: '_SA',  width: 45, color: '#757575', align: 'end', head: 2},
      {name: 'Delta', code: '_SD',  width: 45, color: '#757575', align: 'end', head: 2},
      {name: 'Plan',  code: '_FP',  width: 45, color: '#757575', align: 'end', head: 2},
      {name: 'Act',   code: '_FA',  width: 45, color: '#757575', align: 'end', head: 2},
      {name: 'Delta', code: '_FD',  width: 45, color: '#757575', align: 'end', head: 2},
    ],
    tblLen: 0,
    schedule: 'F',
      
  }),


  methods: {

    draw_BlockStatus(selection, _d, _obj) {

      this.activities.forEach((d,i)=> {

        d[`${d.code}_TOT`] =   _d.filter(f=> f[`${d.code}_PS`]).length

        d[`${d.code}_SP`] =   _d.filter(f=> f[`${d.code}_PS`] <= this.progress.CDATE).length
        d[`${d.code}_SA`] =    _d.filter(f=> f[`${d.code}_AS`] <= this.progress.CDATE).length
        d[`${d.code}_SD`] =    d[`${d.code}_SA`] - d[`${d.code}_SP`]

        d[`${d.code}_FP`] =  _d.filter(f=> f[`${d.code}_PF`] <= this.progress.CDATE).length
        d[`${d.code}_FA`] =   _d.filter(f=> f[`${d.code}_AF`] <= this.progress.CDATE).length
        d[`${d.code}_FD`] =   d[`${d.code}_FA`] - d[`${d.code}_FP`]
      })
      // console.log(this.activity) //////////////

      let x = 0
      this.blkStatus.forEach(d=> {
        d.sx = x
        d.mx = x + d.width/2
        if(d.align == 'start') d.tx = x
        if(d.align == 'middle') d.tx = x + d.width/2
        if(d.align == 'end') d.tx = x + d.width - 5
        x = x + d.width
        d.ex = x
      })
      this.tblLen = x

      this.draw_StatusTable(selection, _obj) // -----> below
    },


    draw_StatusTable(selection, _obj) {

      let block = selection
      .append('g')
      .attr('id', `BLOCK_STATUS`)
      .attr('transform', `translate(${_obj.x + .5}, ${_obj.y + .5})`)
      .attr('font-family', 'roboto')

      // Header
      block
      .append('text')
      .attr('x',0).attr('y', -17)
      .attr('font-size', 14).attr('fill', '#000')
      .text('Block Status')

      block
      .append('line')
      .attr('x1', 0).attr('y1', -12).attr('x2', this.tblLen).attr('y2', -12)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      this.blkStatus.forEach((d,i) => {
        block
        .append('text')
        .attr('x', d.mx)
        .attr('y', (d.head == 1) ? 12 : 20)
        .attr('font-size', (d.head == 1) ? 12 : 11)
        .attr('fill', (d.head == 1) ? '#000' : '#333')
        .attr('text-anchor', 'middle')
        .text(d.name)
      })

      block
      .append('text')
      .attr('x', this.blkStatus[3].mx).attr('y', 1)
      .attr('font-size', 12).attr('fill', '#000').attr('text-anchor', 'middle')
      .text('Start')

      block
      .append('text')
      .attr('x', this.blkStatus[6].mx).attr('y', 1)
      .attr('font-size', 12).attr('fill', '#000').attr('text-anchor', 'middle')
      .text('Finish')

      block
      .append('line')
      .attr('x1', 0).attr('y1', 25).attr('x2', this.tblLen).attr('y2', 25)
      .attr('stroke', '#757575').attr('stroke-width', .5)

      block
      .append('line')
      .attr('x1', this.blkStatus[1].ex).attr('y1', 8).attr('x2', this.tblLen).attr('y2', 8)
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      // Vertical Line
      this.blkStatus.forEach((d,i)=> {
        if(i == 0) return
        block
        .append('line')
        .attr('x1', d.sx).attr('y1', (d.name == 'Act' || d.name == 'Delta') ? 7 : -11)
        .attr('x2', d.sx).attr('y2', 167)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)
      })

      // DataGrid
      let y = 40
      this.activities.forEach((d,i)=> {
        block
        .append('text')
        .attr('x',this.blkStatus[0].tx).attr('y', y).attr('font-size', 11).attr('fill', this.blkStatus[0].color).attr('text-anchor', this.blkStatus[0].align)
        .text(d.name)

        this.blkStatus.filter(f=> f.name != 'Stage').forEach(s=> {
          block
          .append('text')
          .attr('x', s.tx-3).attr('y', y)
          .attr('fill', (s.name == 'Delta') ? this.setDeltaColor(d[`${d.code}${s.code}`]) : s.color)
          .attr('font-size', 11).attr('text-anchor', s.align)
          .text(d[`${d.code}${s.code}`])
        })
        y = y + 20
      })

      block
      .append('line')
      .attr('x1', 0).attr('y1', y-13).attr('x2', this.tblLen).attr('y2', y-13)
      .attr('stroke', '#757575').attr('stroke-width', .5)
      
    },

    
  }
}