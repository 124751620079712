import * as d3 from 'd3'

export default {
  methods: {

    chart_Controller() {
      
      this.chart.style.filter(f=> f.type == 'LINE').forEach((d,i)=> {
        let data = this.chartData.filter(f=> f.CDATE <= this.timeline.cutoff)
        this.draw_LineChart(data, d)
      })

    },

    draw_LineChart(data, cs,) {

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(new Date(d.mDate)))
      .y(d => this.primary.axis(d[cs.code]))

      let lineChart = d3.select(`#CHART_${this.chart.id}`)
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      lineChart
      .append('path')
      .attr('d', lineFunc(data))
      .style('stroke', cs.color)
      .style('stroke-width', cs.size).style('fill', 'none')

      
    },


  }
}