import * as d3 from 'd3'

export default {
  methods: {

    chart_Controller(d) {

      let actData = this.chartData.filter(f=> f.CDATE <= this.cutoff)

      // Bar Chart 
      this.chart.styles.filter(f=> f.type == 'BAR').forEach((st,i)=> {
        let data = (st.act == 'Y') ? actData : this.chartData
        this.draw_BarChart(data, st, i) // -----> below
      })

      // Line Chart 
      this.chart.styles.filter(f=> f.type == 'LINE').forEach((st,i)=> {
        let data = (st.act == 'Y') ? actData : this.chartData
        this.draw_LineChart(data, st)
        if(this.chart.lastValue > 0) this.draw_lastValue(data, st) // -----> below
      })
    },


    draw_BarChart(_data, _st, _sn) {
      
      _data.forEach((d,i)=> {
        
        let value = (d[_st.code] < 0) ? 0 : d[_st.code]

        this.canvasChart
        .append('rect')
        .attr('x', d.bxs[_sn])
        .attr('y', this.chart.height-this.primary.axis(value))
        .attr('width', _st.size).attr('height', this.primary.axis(value))
        .attr('fill', _st.color).attr('opacity', _st.opacity)

        if(_st.vSize > 0 && value > 0) {
          this.canvasChart
          .append('text')
          .attr('x', d.bxs[_sn] + (_st.size/2))
          .attr('y', this.chart.height-this.primary.axis(value) -2)
          .attr('font-size', _st.vSize).attr('fill',  _st.vColor)
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'bottom')
          .text(value)
        }
      })
    },


    draw_LineChart(_data, _st) {
      let lineFunc = d3.line()
      .x(d => d.mx)
      .y(d => this.secoundary.axis(d[_st.code]))

      this.canvasChart
      .append('path')
      .attr('d', lineFunc(_data))
      .attr('stroke', _st.color).attr('stroke-width', _st.size)
      .attr('fill', 'none').attr('opacity', _st.opacity)

      _data.forEach((d,i)=> {
        // Node
        if(_st.node > 0) {
          this.canvasChart
          .append('circle')
          .attr('cx', d.mx)
          .attr('cy', this.secoundary.axis(d[_st.code]))
          .attr('r', _st.node)
          .attr('fill', _st.color).attr('opacity', _st.opacity)
        }
        // Value
        if(_st.vSize > 0 && d[_st.code] > 0) {
          this.canvasChart
          .append('text')
          .attr('x', d.mx)
          .attr('y', this.secoundary.axis(d[_st.code]) -4)
          .attr('font-size', _st.vSize).attr('fill',  _st.vColor)
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'bottom')
          .text(d[_st.code])
        }
      })
    },


    draw_lastValue(_data, _st) {
      // draw the final value
      let lastOne = _data.find(f=> f.CDATE == this.cutoff)
      if(_st.type == 'LINE') {
        if(!lastOne) return
        this.canvasChart
        .append('text')
        .attr('x', lastOne.mx + 5)
        .attr('y', this.secoundary.axis(lastOne[_st.code])+1)
        .attr('font-size', this.chart.lastValue).attr('fill',  _st.vColor)
        .attr('alignment-baseline', 'middle')
        .text(lastOne[_st.code])
      }
    },

  }
}