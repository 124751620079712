import * as d3 from 'd3'

export default {
  methods: {

    call_BLK() {
      
      // selection
      // .on('mouseover', () => {
      //   d3.select(`#PNG-${_blk}`).transition().duration(50).style('opacity', .5)
      //   d3.select(`#${_blk}`).transition().duration(50).style('opacity', .5)
      // })
      // .on('mouseout', () => {
      //   d3.select(`#PNG-${_blk}`).transition().duration(50).style('opacity', 1)
      //   d3.select(`#${_blk}`).transition().duration(50).style('opacity', 1)
      // })
      // .on('click', () => {
      //   this.filter_GantteData('block', _blk)
      // }) 
    },





  


  }
}