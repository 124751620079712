import * as d3 from 'd3'

export default {
  methods: {

    draw_barChart(_data, _y, _color) {
      let x = this.bar.x
      let barX = (_data.ACT > 0) ? (_data.PRO/100) * this.bar.len : 0

      let barChart = this.canvas_Status
      .append('g')
      .attr('transform', `translate(${this.bar.x}, ${_y + .5})`)
      .attr('font-family', 'roboto')

      barChart // Bar Shadow
      .append('polygon')
      .attr('transform', `translate(${barX - 20},${-4})`)
      .attr('points', '8,16 0,8 40,0 48,16')
      .style('fill', 'url(#BAR)')

      barChart
      .append('rect')
      .attr('x', 0).attr('y', -1)
      .attr('width', this.bar.len).attr('height', 2).attr('fill', '#bcbcbc').attr('opacity', .5)
      
      // REMAIN
      barChart
      .append('text')
      .attr('x', this.bar.len + 5).attr('y', 6)
      .attr('font-size', 10.5).attr('fill', '#757575').attr('alignment-baseline', 'ideographic')
      .text(_data.TOT - _data.ACT)
      .call(this.call_Link, {STATUS:'REMAIN', DISC: _data.DISC, ITEM: _data.ITEM}) // -----> Event.js

      // ACTUAL
      let bar = barChart
      .append('g')
      .attr('id', `BAR_${_data.SN}`)
      .attr('transform', `translate(${barX}, ${-4})`)
      .call(this.call_Link, {STATUS:'ACTUAL', DISC: _data.DISC, ITEM: _data.ITEM}) // -----> Event.js
      
      bar
      .append('text')
      .attr('x', 0).attr('y', 1)
      .attr('font-size', 10.5).attr('fill', '#333').attr('text-anchor', 'middle').attr('alignment-baseline', 'ideographic')
      .text(_data.ACT)

      bar
      .append('rect')
      .attr('x', -20).attr('y', 0)
      .attr('width', 40).attr('height', 8).attr('fill', _data.ACT == 0 ? '#E0E0DF' : _color)
      bar
      .append('rect')
      .attr('x', -20).attr('y', 4).
      attr('width', 40).attr('height', 4).attr('fill', '#757575').attr('opacity', .4)

    }

   

  }
}