import __C from '../../../../../includes/primitives/_constant_'
export default {
  // ----------------------------------------------- CANVAS
  Canvas: {
    CanvasWidth: 900,
    CanvasHeight: 110 ,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#757575',
    CanvasFillColor: '#fff',
    CanvasOpacity: 0.2,

    CanvasChartX: 30,
    CanvasChartY: 50,
    CanvasChartWidth: 900,
    CanvasChartHeight: 110,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'transparent',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 10,

    CanvasFontFamily: 'roboto'
  },

  // ----------------------------------------------- Filter Text
  Text: {
    Text: [
      { text: 'Module', x: 100, y: 15, font: 'roboto', class: 'bold', size: 22, color: '#333333', align: 'start' },
      { text: 'MC Completion', x: 730, y: 43, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'end' },
      { text: 'RFS of Module', x: 880, y: 43, font: 'roboto', class: 'regular', size: 11, color: '#757575', align: 'end' },
    ],
    Filter: [
      { name: 'MOD'   , x: 22, y: 15, font: 'roboto', class: 'bold', size: 22, color: '#333333', align: 'start' },
    ],
    Sql: [
      { text: 'PBF COMPESSOR AIR COOLER - TRAIN 4', x: 22, y: 38, font: 'roboto', style: 'regular', size: 14, color: '#BCBCBC', align: 'start' },
      { text: '3 Days', x: 730, y: 28, font: 'roboto', style: 'bold', size: 13, color: '#44A9DF', align: 'end' },
      { text: '123 Days', x: 880, y: 28, font: 'roboto', style: 'bold', size: 13, color: '#44A9DF', align: 'end' },
    ]
  },
  // -----------------------------------------------------------


  ModalDraw: {
    Width     : 900,
    Height    : 200,
    Color     : '#F4F5F5',
    Opacity   : .2,

    Lines: [
            {x1:0, x2:900, y1:60, y2:60 , stroke:1, color:'#bcbcbc'},
    ],
    Rects: [
            // {x:0, y:0, width:900, height:5, radius:0, strokeWidth:0, strokeColor:'#fff', fillColor:'#83D2F5'},
    ],
    Icons: [
            {x:100, y:100, size:20, name:'ICON'},
    ],
  },

  ModalTaps: {
    
    TapsX         : 20,
    TapsY         : 70,
    MenuSpace     : 50,
    DisplayTabMenu: 'Y',

    InitialTarget : '',

    Menus: [
      {
        mainTitle:'Construction',
        subTitle:'Progress(%)',
        action: {
          target: '',
          component: '',
          id: 0,
          no: '',
          filterString: ''
        }
      },
      {
        mainTitle:'Mechanical Completion', 
        subTitle:'Certificates | A1 QVDs | Punchs',
        action: {
          target: '',
          component: '',
          id: 0,
          no: '',
          filterString: ''
        }
      },
      {
        mainTitle:'Commissioning',
        subTitle:'B1 QVD',
        action: {
          target: '',
          component: '',
          id: 0,
          no: '',
          filterString: ''
        }
      },
    ],

    Style: [
      {x: 8,  y: 3,  font:'roboto',  class:'regular', size:13, outColor:'#BDBCBC', overColor:'#44A9DF', selectedColor: '#44A9DF',},
      {x: 10, y: 20, font:'roboto',  class:'regular', size:9,  outColor:'#E0E0DF', overColor:'#BCBCBC', selectedColor: '#BCBCBC',},
    ],   

    Bullet: {
      width             : 5, 
      height            : 28, 
      radius            : 0, 
      storke            : 0, 
      strokeColor       : 'transparent',
      colorType         : 'LinearA4',
      outColor          : 'Gray', 
      overColor         : 'SkyBlue', 
      selectedColor     : 'LightBlue',
    },
  },

  
  DataItems: [
    {column:'',},
  ],
}
