import * as d3 from 'd3'

export default {
  methods: {

    call_Overall(selection) {
      selection
      .on('mouseover', () => {
        d3.select(`#MODEL_AREA`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#MODEL_AREA`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        this.action_Overall_to_Area()
      }) 
    },


    call_Area(selection, _area) {
      selection
      .on('mouseover', () => {
        d3.select(`#${_area}-PNG`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#${_area}-PNG`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        this.currentArea = _area
        this.model_Area.transition().duration(500).style('opacity', 0)
        this.canvas_Area.transition().duration(500).style('opacity', 0)

        setTimeout(() => { 
          this.model_Area.remove()
          this.canvas_Area.remove()

          this.draw_Model_Block() // ----->
        }, 1000)
      })
    },


    call_Block(selection, _d) {
      selection
      .on('mouseover', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', .5)
        d3.select(`#${_d.ZONE}`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', 1)
        d3.select(`#${_d.ZONE}`).transition().duration(50).style('opacity', 0)
      })
      .on('click', () => {
        
      })
    },


    // Home & Back Button
    call_Home(selection, func) {
      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        selection.call(func)
      }) 
    },

    call_Back(selection, func) {
      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        selection.call(func)
      }) 
    },

    link_Modal(d_) {

      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {AREA: d_.M_AREA} // DO not Remark if possible 
      let req = 'request'

      if (this.selectMenu.AREA == 'OVERALL') {
        if (d_.AREA == 'Topside' || d_.ZONE == 'LQ') {
          dataSource = {...dataSource, STAGE: 'ZONE', KEY1: d_.M_ZONE}
          req = 'ZONE'
        } else {
          let blk = d_.BLK
          if (blk == 'Heli') blk = 'LH110'
          if (blk == 'House') blk = 'LV510'

          dataSource = {...dataSource, STAGE: 'EREC', KEY1: blk}
          req = 'EREC'
        }
      } else {
        dataSource = {...dataSource, STAGE: 'EREC', KEY1: d_.BLK}
        req = 'EREC'
      }
      console.log(req, dataSource)

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(req, dataSource, this.ColumnProps)
      if(!request_) return

      // set for filterString 
      let filterString_ = ''

      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },

  }
}