import * as d3 from 'd3'

export default {
  methods: {

    chart_Router() {

      this.timeline = {
        ...this.timeline,
        day       : [],
        week      : [],
        month     : [],
        year      : [],
        scale     : null,
        startDate : this.current.stage.start,
        endDate   : this.current.stage.end,
      }

      this.init_gmx_TimelineValues(this.timeline, 'day')
      

      // Change codes in the this.ChartStyle
      let getCode = this.stageList.find(f=> f.id == this.current.stage.id).codes.replace(/\s/g, '').split('/')
      if (this.Toggle == 'off') {
        getCode = this.stageList.find(f=> f.id == this.current.stage.id).codes2.replace(/\s/g, '').split('/')
      }

      this.ChartStyle.forEach((d,i)=> {
        d.code = getCode[i]
      }) 


      // Canvas Group
      this.canvasGroup = this.canvasForms
      .append('g')
      .attr('transform', `translate(0, 0)`)

      this.areaList.forEach((d,i)=> {

        this.draw_ChartCanvas(this.canvasChart, d, i) // -----> draw_Elements.js

        let chtData = this.rawData.filter(f=> 
          f.STAGE == this.current.stage.stage && 
          f.AREA == d.code && 
          f.YARD == d.yard && 
          f.CDATE >= this.timeline.startDate && f.CDATE <= this.timeline.endDate
        )

        this.primary.max = d3.max([d3.max(chtData, c => c[this.ChartStyle[2].code]), d3.max(chtData, c => c[this.ChartStyle[3].code])])
        this.primary.max = this.getMaxBound_v2(this.primary.max)
        this.primary.axis = d3.scaleLinear()
        .domain([0, this.primary.max])
        .range([0, this.Chart.height])

        this.secoundary.max = this.getMaxBound_v2(d3.max(chtData, c => c[this.ChartStyle[0].code]))
        this.secoundary.axis = d3.scaleLinear()
        .domain([0, this.secoundary.max])
        .range([this.Chart.height, 0])

        this.draw_Timeline(this.canvasChart, d, i) // -----> draw_Timeline.js
        this.chart_Controller(chtData, d) // -----> draw_Chart.js
      })

    },

  }
}
           