import * as d3 from 'd3'

export default {
  methods: {

    chart_Router() {

      // Canvas Group
      this.canvasChart = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)

      this.area.forEach((d,i)=> {
        this.primary.max = 0
        this.secoundary.max = 0
        
        let cData = this.chartData.find(f=> f.AREA == d.area && f.ZONE == 'OVERALL' && f.CDATE == this.timeline.cutoff)
        d.total = cData.TOT
        d.fab = cData.FAB_CUM
        d.install = cData.INS_CUM

        let chtData = this.chartData.filter(f=> f.AREA == d.area && f.ZONE == 'OVERALL' && f.CDATE <= this.timeline.cutoff)
        this.exec_Router(chtData, d, 'AREA')
      })

      this.module.forEach((d,i)=> {
        this.primary.max = 0
        this.secoundary.max = 0

        let cData = this.chartData.find(f=> f.ZONE == d.area && f.CDATE == this.timeline.cutoff)
        d.total = cData.TOT
        d.fab = cData.FAB_CUM
        d.install = cData.INS_CUM

        let chtData = this.chartData.filter(f=> f.ZONE == d.area && f.CDATE <= this.timeline.cutoff)
        this.exec_Router(chtData, d, 'ZONE')
      })
    },

    exec_Router(data, d, col) {

      this.primary.max = d3.max([d3.max(data, c => c[this.chart.style[2].code]), d3.max(data, c => c[this.chart.style[3].code])])
      if(this.primary.max == 0) this.secoundary.max = 25; else this.primary.max = this.getMaxBound_v2(this.primary.max)

      this.primary.max = this.getMaxBound_v2(this.primary.max)
      this.primary.axis = d3.scaleLinear()
      .domain([0, this.primary.max])
      .range([0, this.chart.height])

      this.secoundary.max = d3.max([d3.max(data, c => c[this.chart.style[0].code]), d3.max(data, c => c[this.chart.style[1].code])])
      if(this.secoundary.max == 0) this.secoundary.max = 25; else this.secoundary.max = this.getMaxBound_v2(this.secoundary.max)

      this.secoundary.axis = d3.scaleLinear()
      .domain([0, this.secoundary.max])
      .range([this.chart.height, 0])
      
      this.draw_ChartCanvas(d, col) // -----> draw_Elements.js
      this.draw_Timeline(d) // -----> draw_Timeline.js
      this.chart_Controller(data, d) // -----> draw_Chart.js
    }

  }
}
           