import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'


// Locally declared common Data-Props & SVG Drawing Support
import lmx_localData            from '../primitives/localData'

import lmx_LocalValues          from './set_LocalValues'
import lmx_Watching             from './Watching'

import lmx_Events               from './Events'

import lmx_Forms                from './draw_Forms'
import lmx_TableGrid_Group      from './func_TableGrid_Group'
import lmx_chart_Router         from './chart_Router'
import lmx_ChartCanvas          from './draw_ChartCanvas'
import lmx_Timeline             from './draw_Timeline'
import lmx_Chart                from './draw_Chart'

import lmx_Tooltip              from './draw_Tooltip'



export default {
  mixins: [
    gmx_zMixins, 
    lmx_localData,
    lmx_TableGrid_Group,

    // Local Mixins
    lmx_Watching,
    lmx_Events,
    
    lmx_LocalValues,
    lmx_Forms,
    
    lmx_chart_Router,
    lmx_ChartCanvas,
    lmx_Timeline,
    lmx_Chart,
    
    lmx_Tooltip,
  ],
}
