
import CONST from '../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({

    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    progress:           null,
    erectionList:       [],

    masterList:         [],
    outfittingList:     null,
    eqList:             null,
    deltaList:          null,
    filterText:         null,
   
    // Selections
    model_Overall:      null,
    model_Area:         null,
    model_Erection:     null,
    model_2PE:          null,
    model_Block:        null,

    canvas_Form:        null,
    canvas_Overall:     null,
    canvas_Area:        null,
    canvas_Erection:    null,
    canvas_2PE:         null,

    current: {
      stage:  null,
      area:   null,
      pe:     null,
      zone:   null,
      block:  null,
      sn:     null,
      eq:     null,
      detail: null,
      toggle: null,
      search: null,
    },

    pe_sn: null,

    modal_icon: 'M10.7,3C10.7,3,10.7,3,10.7,3H9.2V1.3C9.2,0.6,8.6,0,7.9,0H1.3C0.6,0,0,0.6,0,1.3v6.5  c0,0.7,0.6,1.3,1.3,1.3h0.9V8H1.2V1.2H8V3H4.1C3.4,3,2.8,3.6,2.8,4.4v6.5c0,0.7,0.6,1.3,1.3,1.3h6.5c0.7,0,1.3-0.6,1.3-1.3V4.4  C12,3.6,11.4,3,10.7,3z M10.8,11H4V4.2h6.8V11z',

    localData: {
      
      // Erection List for 3D animation
      JSON1: [
        { SN: '1',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN-S',   BLK: 'CC410', TITLE: 'Caission',                   X1: 528.84, Y1: 684.5,  X2: 507.18, Y2: 814.32, X3: 640.97,  Y3: 887.63, X4: 640.97,  Y4: 887.63, },
        { SN: '2',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS-S',   BLK: 'CC420', TITLE: 'Caission',                   X1: 706.36, Y1: 607.01, X2: 919.45, Y2: 600.86, X3: 1023.92, Y3: 662.12, X4: 1023.92, Y4: 662.12, },
        { SN: '3',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EN-S',   BLK: 'CC430', TITLE: 'Caission',                   X1: 383.06, Y1: 596.46, X2: 168.5,  Y2: 614.51, X3: 92,      Y3: 563.04, X4: 92,      Y4: 563.04, },
        { SN: '4',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES-S',   BLK: 'CC440', TITLE: 'Caission',                   X1: 529.61, Y1: 496.08, X2: 548.93, Y2: 380.41, X3: 448.81,  Y3: 326.45, X4: 448.81,  Y4: 326.45, },
        { SN: '5',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-UCFC',   BLK: 'CF112', TITLE: 'UCF CENTER',                 X1: 445.9,  Y1: 528.02, X2: 421.86, Y2: 570.94, X3: 751.86,  Y3: 72,     X4: 751.86,  Y4: 72, },
        { SN: '6',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EN',     BLK: 'EC112', TITLE: 'Lower Column (East-North)',  X1: 387.09, Y1: 640.85, X2: 172.53, Y2: 658.58, X3: 172.53,  Y3: 595.23, X4: 172.53,  Y4: 595.23, },
        { SN: '7',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EN',     BLK: 'EC1G2', TITLE: 'Upper Column (East-North)',  X1: 387.49, Y1: 581.33, X2: 173.27, Y2: 599.05, X3: 173.27,  Y3: 409.39, X4: 173.27,  Y4: 409.39, },
        { SN: '8',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EN',     BLK: 'EC1K2', TITLE: 'Middle Column (East-North)', X1: 387.09, Y1: 602.24, X2: 172.83, Y2: 619.93, X3: 173.13,  Y3: 494.33, X4: 173.13,  Y4: 494.33, },
        { SN: '9',   AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES',     BLK: 'EC212', TITLE: 'Lower Column (East-South)',  X1: 520.98, Y1: 560.64, X2: 540.1,  Y2: 444.71, X3: 540.43,  Y3: 375.19, X4: 540.43,  Y4: 375.19, },
        { SN: '10',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES',     BLK: 'EC2G2', TITLE: 'Upper Column (East-South)',  X1: 528.11, Y1: 497.81, X2: 547.57, Y2: 381.88, X3: 547.57,  Y3: 191.63, X4: 547.57,  Y4: 191.63, },
        { SN: '11',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES',     BLK: 'EC2K2', TITLE: 'Middle Column (East-South)', X1: 525.85, Y1: 519.87, X2: 545.27, Y2: 403.94, X3: 545,     Y3: 277.38, X4: 545,     Y4: 277.38, },
        { SN: '12',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EN',     BLK: 'EC3A0', TITLE: 'Column Support',             X1: 403.25, Y1: 672.57, X2: 188.69, Y2: 690.05, X3: 188.69,  Y3: 626.7,  X4: 158.93,  Y4: 643.73, },
        { SN: '13',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES',     BLK: 'EC4A0', TITLE: 'Column Support',             X1: 571.47, Y1: 562.53, X2: 590.92, Y2: 446.6,  X3: 590.92,  Y3: 377.08, X4: 610.92,  Y4: 365.08, },
        { SN: '14',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EN',     BLK: 'ECP10', TITLE: 'Access P/F (East-North)',    X1: 441.94, Y1: 580.75, X2: 227.38, Y2: 598.8,  X3: 227.38,  Y3: 408.82, X4: 242.79,  Y4: 398.77, },
        { SN: '15',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EN',     BLK: 'ECP30', TITLE: 'Access P/F (East-North)',    X1: 405,    Y1: 613.36, X2: 190.57, Y2: 630.72, X3: 190.57,  Y3: 441.06, X4: 163.53,  Y4: 456.32, },
        { SN: '16',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES',     BLK: 'ECP40', TITLE: 'Access P/F (East-South)',    X1: 533.74, Y1: 528.13, X2: 553.73, Y2: 412.2,  X3: 553.19,  Y3: 222.3,  X4: 524.49,  Y4: 240.98, },
        { SN: '17',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES',     BLK: 'ECP50', TITLE: 'Access P/F (East-South)',    X1: 522.99, Y1: 518.87, X2: 542.54, Y2: 402.69, X3: 542.54,  Y3: 212.44, X4: 522.92,  Y4: 220.7, },
        { SN: '18',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES',     BLK: 'ECP60', TITLE: 'Access P/F (East-South)',    X1: 522.99, Y1: 493.89, X2: 542.47, Y2: 377.67, X3: 542.47,  Y3: 187.42, X4: 529.25,  Y4: 182.73, },
        { SN: '19',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES',     BLK: 'ECP70', TITLE: 'Access P/F (East-South)',    X1: 577.86, Y1: 503.86, X2: 597.31, Y2: 387.58, X3: 597.52,  Y3: 197.33, X4: 611.52,  Y4: 190.33, },
        { SN: '20',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-UCFE',   BLK: 'EF212', TITLE: 'UCF East',                   X1: 443.48, Y1: 526.13, X2: 419.76, Y2: 567.18, X3: 343.95,  Y3: 288.86, X4: 344.94,  Y4: 287.57, },
        { SN: '21',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-UCFE',   BLK: 'EF2B0', TITLE: 'UCF East',                   X1: 438.42, Y1: 576.4,  X2: 413.59, Y2: 618.21, X3: 338.29,  Y3: 339.4,  X4: 251.16,  Y4: 423.79, },
        { SN: '22',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-UCFE',   BLK: 'EF2C0', TITLE: 'UCF East',                   X1: 513.75, Y1: 544.48, X2: 490.33, Y2: 587.51, X3: 415.43,  Y3: 308.69, X4: 540.12,  Y4: 256.32, },
        { SN: '23',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EN',     BLK: 'EN112', TITLE: 'Node (East-North)',          X1: 387.09, Y1: 682.04, X2: 172.54, Y2: 699.44, X3: 172.54,  Y3: 699.44, X4: 172.54,  Y4: 699.44, },
        { SN: '24',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ENC',    BLK: 'EN161', TITLE: 'Diagonal Node (East-North)', X1: 444.46, Y1: 684.12, X2: 229.9,  Y2: 702.35, X3: 261.33,  Y3: 702.35, X4: 261.33,  Y4: 702.35, },
        { SN: '25',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES',     BLK: 'EN212', TITLE: 'Node (East-South)',          X1: 524.06, Y1: 602.73, X2: 543.08, Y2: 487.07, X3: 543.08,  Y3: 487.07, X4: 543.08,  Y4: 487.07, },
        { SN: '26',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ESC',    BLK: 'EN261', TITLE: 'Diagonal Node (East-South)', X1: 529.15, Y1: 635.13, X2: 548.32, Y2: 519.2,  X3: 548.94,  Y3: 545.76, X4: 548.94,  Y4: 545.76, },
        { SN: '27',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EN',     BLK: 'EN410', TITLE: 'Push Knee',                  X1: 393.88, Y1: 672.67, X2: 179.32, Y2: 690.81, X3: 179.32,  Y3: 690.81, X4: 164.35,  Y4: 681.98, },
        { SN: '28',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EN',     BLK: 'EN420', TITLE: 'Push Knee',                  X1: 394.58, Y1: 699.31, X2: 180.02, Y2: 717.03, X3: 180.02,  Y3: 717.03, X4: 150.08,  Y4: 730.84, },
        { SN: '29',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES',     BLK: 'EN510', TITLE: 'Push Knee',                  X1: 532.89, Y1: 584.09, X2: 552.13, Y2: 468.35, X3: 552.13,  Y3: 468.35, X4: 540.07,  Y4: 461.84, },
        { SN: '30',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES',     BLK: 'EN520', TITLE: 'Push Knee',                  X1: 574.49, Y1: 588.1,  X2: 594.05, Y2: 472.17, X3: 594.05,  Y3: 472.17, X4: 606.62,  Y4: 466.1, },
        { SN: '31',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EAST',   BLK: 'EP112', TITLE: 'Pontoon (East)',             X1: 420.13, Y1: 620.78, X2: 321.93, Y2: 570.93, X3: 321.93,  Y3: 570.93, X4: 321.93,  Y4: 570.93, },
        { SN: '32',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-UCFN',   BLK: 'NF212', TITLE: 'UCF North',                  X1: 434.92, Y1: 606.71, X2: 410.34, Y2: 649.05, X3: 322.2,   Y3: 496.02, X4: 324.24,  Y4: 496.02, },
        { SN: '33',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-NORTH',  BLK: 'NP112', TITLE: 'Pontoon (North)',            X1: 455.14, Y1: 730.16, X2: 335.88, Y2: 801.47, X3: 348.08,  Y3: 809.88, X4: 348.08,  Y4: 809.88, },
        { SN: '34',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-NORTH',  BLK: 'NP151', TITLE: 'Pontoon (North)',            X1: 418.89, Y1: 708.49, X2: 300.36, Y2: 780.09, X3: 284.1,   Y3: 770.91, X4: 284.1,   Y4: 770.91, },
        { SN: '35',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-NORTH',  BLK: 'NPB10', TITLE: 'Porch Basket',               X1: 469.13, Y1: 758.69, X2: 348.31, Y2: 829.91, X3: 360.89,  Y3: 836.2,  X4: 335.68,  Y4: 851.2, },
        { SN: '36',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN-S',   BLK: 'RC410', TITLE: 'Riser Pipe',                 X1: 469.32, Y1: 671.69, X2: 448.17, Y2: 800.57, X3: 448.17,  Y3: 976.7,  X4: 448.17,  Y4: 976.7, },
        { SN: '37',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS-S',   BLK: 'RC420', TITLE: 'Riser Pipe',                 X1: 612.34, Y1: 610.45, X2: 821.65, Y2: 614.97, X3: 997.22,  Y3: 700.86, X4: 997.22,  Y4: 700.86, },
        { SN: '38',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-EN-S',   BLK: 'SC410', TITLE: 'Sump Pile',                  X1: 446.29, Y1: 589.75, X2: 231.76, Y2: 604.49, X3: 274.71,  Y3: 577,    X4: 274.71,  Y4: 577, },
        { SN: '39',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-ES-S',   BLK: 'SC420', TITLE: 'Sump Pile',                  X1: 540.92, Y1: 530.37, X2: 560.37, Y2: 417.32, X3: 496.25,  Y3: 451.91, X4: 496.25,  Y4: 451.91, },
        { SN: '40',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-UCFS',   BLK: 'SF212', TITLE: 'UCF South',                  X1: 581.27, Y1: 525.06, X2: 555.95, Y2: 567.86, X3: 709.44,  Y3: 282.83, X4: 709.1,   Y4: 282.96, },
        { SN: '41',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-SOUTH',  BLK: 'SP112', TITLE: 'Pontoon (South)',            X1: 606.49, Y1: 642,    X2: 723.73, Y2: 582.6,  X3: 733.16,  Y3: 587.78, X4: 733.16,  Y4: 587.78, },
        { SN: '42',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-SOUTH',  BLK: 'SP151', TITLE: 'Pontoon (South)',            X1: 571.22, Y1: 620.75, X2: 688.07, Y2: 561.11, X3: 672.82,  Y3: 552.9,  X4: 672.82,  Y4: 552.9, },
        { SN: '43',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WC112', TITLE: 'Lower Column (West-North)',  X1: 521.1,  Y1: 719.64, X2: 499.96, Y2: 848.77, X3: 499.96,  Y3: 764.67, X4: 499.96,  Y4: 764.67, },
        { SN: '44',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WC1G2', TITLE: 'Upper Column (West-North)',  X1: 529.15, Y1: 662.05, X2: 508.01, Y2: 790.88, X3: 508.01,  Y3: 599.75, X4: 508.01,  Y4: 599.75, },
        { SN: '45',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WC1K2', TITLE: 'Middle Column (West-North)', X1: 525.18, Y1: 682.45, X2: 503.88, Y2: 811.83, X3: 504.19,  Y3: 676.71, X4: 503.91,  Y4: 677.29, },
        { SN: '46',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS',     BLK: 'WC212', TITLE: 'Lower Column (West-South)',  X1: 667.36, Y1: 641.73, X2: 880.4,  Y2: 635.58, X3: 880.14,  Y3: 573.52, X4: 880.14,  Y4: 573.52, },
        { SN: '47',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS',     BLK: 'WC2G2', TITLE: 'Upper Column (West-South)',  X1: 664.56, Y1: 580.12, X2: 877.61, Y2: 573.97, X3: 878.77,  Y3: 384.08, X4: 878.77,  Y4: 384.08, },
        { SN: '48',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS',     BLK: 'WC2K2', TITLE: 'Middle Column (West-South)', X1: 667.36, Y1: 600.75, X2: 880.13, Y2: 594.6,  X3: 880.13,  Y3: 469.81, X4: 880.13,  Y4: 469.81, },
        { SN: '49',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WC3A0', TITLE: 'Column Support',             X1: 580.75, Y1: 744.13, X2: 559.21, Y2: 873.46, X3: 559.33,  Y3: 788.82, X4: 587.85,  Y4: 805.3, },
        { SN: '50',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS',     BLK: 'WC4A0', TITLE: 'Column Support',             X1: 705.05, Y1: 676.23, X2: 917.83, Y2: 670.5,  X3: 917.87,  Y3: 608.01, X4: 950.61,  Y4: 626.69, },
        { SN: '51',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WCG10', TITLE: 'Middle Column (West-North)', X1: 569.61, Y1: 697.91, X2: 547.78, Y2: 828.23, X3: 547.46,  Y3: 686.83, X4: 576.45,  Y4: 672.17, },
        { SN: '52',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WCG20', TITLE: 'Middle Column (West-North)', X1: 524.39, Y1: 699.31, X2: 503.08, Y2: 829.43, X3: 503.82,  Y3: 690.01, X4: 479.69,  Y4: 682.08, },
        { SN: '53',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WCP10', TITLE: 'Access P/F (West-North)',    X1: 578.19, Y1: 680.78, X2: 556.72, Y2: 809.91, X3: 556.72,  Y3: 618.78, X4: 577.37,  Y4: 632.26, },
        { SN: '54',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WCP20', TITLE: 'Access P/F (West-North)',    X1: 579.9,  Y1: 660.61, X2: 558.75, Y2: 789.79, X3: 558.75,  Y3: 598.99, X4: 577.91,  Y4: 593.83, },
        { SN: '55',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WCP30', TITLE: 'Access P/F (West-North)',    X1: 568.03, Y1: 655.03, X2: 546.9,  Y2: 783.88, X3: 546.88,  Y3: 593.06, X4: 558.61,  Y4: 582.43, },
        { SN: '56',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WCP40', TITLE: 'Access P/F (West-North)',    X1: 523.01, Y1: 656.13, X2: 501.84, Y2: 785.56, X3: 501.86,  Y3: 594.13, X4: 482.12,  Y4: 580.39, },
        { SN: '57',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WCP50', TITLE: 'Access P/F (West-North)',    X1: 511.11, Y1: 659.91, X2: 489.97, Y2: 789.37, X3: 489.97,  Y3: 597.91, X4: 467.58,  Y4: 591.51, },
        { SN: '58',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WCP60', TITLE: 'Access P/F (West-North)',    X1: 524.39, Y1: 680.3,  X2: 503.24, Y2: 809.7,  X3: 503.24,  Y3: 618.57, X4: 484.52,  Y4: 631.84, },
        { SN: '59',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS',     BLK: 'WCP70', TITLE: 'Access P/F (West-South)',    X1: 668.43, Y1: 609.85, X2: 882.25, Y2: 604.37, X3: 883.68,  Y3: 414.58, X4: 858.43,  Y4: 427.04, },
        { SN: '60',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS',     BLK: 'WCP80', TITLE: 'Access P/F (West-South)',    X1: 700.41, Y1: 602.55, X2: 913.45, Y2: 596.19, X3: 913.45,  Y3: 406.3,  X4: 940.74,  Y4: 421.46, },
        { SN: '61',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS',     BLK: 'WCP90', TITLE: 'Access P/F (West-South)',    X1: 709.87, Y1: 579.72, X2: 922.89, Y2: 573.87, X3: 925.91,  Y3: 385.58, X4: 939.89,  Y4: 378.58, },
        { SN: '62',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-UCFW',   BLK: 'WF212', TITLE: 'UCF West',                   X1: 582.7,  Y1: 607.67, X2: 559.84, Y2: 649.95, X3: 690.75,  Y3: 473.5,  X4: 690.9,   Y4: 473.5, },
        { SN: '63',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-UCFW',   BLK: 'WF2B0', TITLE: 'UCF West',                   X1: 577.65, Y1: 657.9,  X2: 553.77, Y2: 700.45, X3: 684.67,  Y3: 524.39, X4: 621.24,  Y4: 606.35, },
        { SN: '64',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-UCFW',   BLK: 'WF2C0', TITLE: 'UCF West',                   X1: 654.97, Y1: 626.6,  X2: 631.13, Y2: 668.94, X3: 762.49,  Y3: 492.27, X4: 872.99,  Y4: 443.97, },
        { SN: '65',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WN112', TITLE: 'Node (West-North)',          X1: 522.96, Y1: 765.46, X2: 502.24, Y2: 894.59, X3: 502.24,  Y3: 894.59, X4: 502.24,  Y4: 894.59, },
        { SN: '66',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WNC',    BLK: 'WN161', TITLE: 'Diagonal Node (West-North)', X1: 527.25, Y1: 756.46, X2: 506.41, Y2: 885.41, X3: 506.41,  Y3: 859.96, X4: 506.41,  Y4: 859.96, },
        { SN: '67',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS',     BLK: 'WN212', TITLE: 'node (West-South)',          X1: 667.31, Y1: 682.46, X2: 880.36, Y2: 676.31, X3: 880.36,  Y3: 676.31, X4: 880.36,  Y4: 676.31, },
        { SN: '68',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WSC',    BLK: 'WN261', TITLE: 'Diagonal Node (West-South)', X1: 657.16, Y1: 686.18, X2: 870.2,  Y2: 680.04, X3: 844.75,  Y3: 680.04, X4: 844.75,  Y4: 680.04, },
        { SN: '69',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WN410', TITLE: 'Push Knee',                  X1: 575.26, Y1: 777.4,  X2: 554.43, Y2: 906.34, X3: 554.43,  Y3: 906.34, X4: 579.45,  Y4: 921.36, },
        { SN: '70',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WN',     BLK: 'WN420', TITLE: 'Push Knee',                  X1: 529.29, Y1: 778.03, X2: 508.69, Y2: 907.03, X3: 508.69,  Y3: 907.03, X4: 481.05,  Y4: 918.12, },
        { SN: '71',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS',     BLK: 'WN510', TITLE: 'Push Knee',                  X1: 709.37, Y1: 698.25, X2: 922.42, Y2: 691.79, X3: 922.42,  Y3: 691.79, X4: 947.59,  Y4: 705.3, },
        { SN: '72',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WS',     BLK: 'WN520', TITLE: 'Push Knee',                  X1: 712.19, Y1: 673.14, X2: 925.47, Y2: 666.9,  X3: 925.47,  Y3: 666.9,  X4: 937.93,  Y4: 660.65, },
        { SN: '73',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WEST',   BLK: 'WP111', TITLE: 'Pontoon (West)',             X1: 568.24, Y1: 748.83, X2: 667.85, Y2: 808.88, X3: 653.83,  Y3: 815.89, X4: 653.83,  Y4: 815.89, },
        { SN: '74',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WEST',   BLK: 'WP112', TITLE: 'Pontoon (West)',             X1: 604.79, Y1: 708.76, X2: 704.13, Y2: 768.77, X3: 724.97,  Y3: 755.22, X4: 724.97,  Y4: 755.22, },
        { SN: '75',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WEST',   BLK: 'WPB10', TITLE: 'Porch Basket',               X1: 608.14, Y1: 766,    X2: 704.13, Y2: 827.04, X3: 692.97,  Y3: 830.9,  X4: 723.01,  Y4: 847.85, },
        { SN: '76',  AREA: 'Hull',    ZONE: '',        M_AREA: 'HULL',    M_ZONE: '',                 KP: 'KP-WEST',   BLK: 'WPG10', TITLE: 'Pontoon (West)',             X1: 578.59, Y1: 748.72, X2: 678,    Y2: 808.44, X3: 664.82,  Y3: 814.93, X4: 663.08,  Y4: 776.71, },
        { SN: '77',  AREA: 'Topside', ZONE: 'Central', M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL',          KP: 'KP-CD11',   BLK: 'Central', TITLE: 'Topside Module (Central)',   X1: 520.08, Y1: 373.31, X2: 462.74, Y2: 422.18, X3: 462.74,  Y3: 422.18, X4: 0,        Y4: 0, },
        { SN: '78',  AREA: 'LQ',      ZONE: 'LQ',      M_AREA: 'LQ',      M_ZONE: 'Living Quarter',   KP: 'KP-LD11',   BLK: 'LQ',    TITLE: 'Living Qauter',              X1: 567.41, Y1: 531.27, X2: 567.41, Y2: 531.27, X3: 0,        Y3: 0,       X4: 0,        Y4: 0, },
        { SN: '79',  AREA: 'LQ',      ZONE: 'Heli',    M_AREA: 'LQ',      M_ZONE: 'Heli Deck',        KP: 'KP-LH11',   BLK: 'Heli',  TITLE: 'Heli Deck',                  X1: 599.5,  Y1: 510.09, X2: 599.5,  Y2: 423.64, X3: 0,        Y3: 0,       X4: 0,        Y4: 0, },
        { SN: '80',  AREA: 'LQ',      ZONE: 'House',   M_AREA: 'LQ',      M_ZONE: 'Stair House',      KP: 'KP-LV51',   BLK: 'House', TITLE: 'Stair House',                X1: 700.99, Y1: 589.05, X2: 700.99, Y2: 534.59, X3: 0,        Y3: 0,       X4: 0,        Y4: 0, },
        { SN: '81',  AREA: 'Topside', ZONE: 'North',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH',            KP: 'KP-ND11',   BLK: 'North', TITLE: 'Topside Module (North)',     X1: 449.49, Y1: 521.02, X2: 236.9,  Y2: 644.39, X3: 236.9,   Y3: 644.39, X4: 0,        Y4: 0, },
        { SN: '82',  AREA: 'Topside', ZONE: 'Flare',   M_AREA: 'TOPSIDE', M_ZONE: 'FLARE',            KP: 'KP-NF11',   BLK: 'Flare', TITLE: 'Flare Tower',                X1: 327.92, Y1: 478.88, X2: 115.33, Y2: 602.25, X3: 115.33,  Y3: 482.66, X4: 0,        Y4: 0, },
        { SN: '83',  AREA: 'Topside', ZONE: 'South',   M_AREA: 'TOPSIDE', M_ZONE: 'SOUTH',            KP: 'KP-SD11',   BLK: 'South', TITLE: 'Topside Module (South)',     X1: 614.73, Y1: 420.1,  X2: 678.79, Y2: 385.65, X3: 678.79,  Y3: 385.65, X4: 0,        Y4: 0, },
        { SN: '84',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-ND11-B', BLK: 'KD110', TITLE: 'Compressor Platform',        X1: 492.05, Y1: 530.34, X2: 491.19, Y2: 406.81, X3: 511.35,  Y3: 288.4,  X4: 0,        Y4: 0, },
        { SN: '85',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-ND11-B', BLK: 'ND110', TITLE: 'Production Deck',            X1: 474.54, Y1: 577.97, X2: 474.54, Y2: 703.28, X3: 494.78,  Y3: 714.74, X4: 0,        Y4: 0, },
        { SN: '86',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-ND11-T', BLK: 'ND120', TITLE: 'Production Deck',            X1: 274.46, Y1: 491.76, X2: 274.46, Y2: 617.07, X3: 274.47,  Y3: 617.08, X4: 0,        Y4: 0, },
        { SN: '87',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-ND11-B', BLK: 'ND210', TITLE: 'Mezz. Deck',                 X1: 568.71, Y1: 637.36, X2: 569.06, Y2: 612.9,  X3: 601.64,  Y3: 621.03, X4: 0,        Y4: 0, },
        { SN: '88',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-ND11-B', BLK: 'ND220', TITLE: 'Mezz. Deck',                 X1: 521.87, Y1: 610.29, X2: 522.23, Y2: 585.82, X3: 541.6,   Y3: 585.9,  X4: 0,        Y4: 0, },
        { SN: '89',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-ND11-T', BLK: 'ND230', TITLE: 'Mezz. Deck',                 X1: 343.77, Y1: 547.97, X2: 344.13, Y2: 523.29, X3: 345.94,  Y3: 533.7,  X4: 0,        Y4: 0, },
        { SN: '90',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-ND11-T', BLK: 'ND250', TITLE: 'Mezz. Deck',                 X1: 432.26, Y1: 614.75, X2: 432.47, Y2: 590.06, X3: 419.64,  Y3: 609.42, X4: 0,        Y4: 0, },
        { SN: '91',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-ND11-B', BLK: 'ND310', TITLE: 'Main Deck',                  X1: 437.2,  Y1: 544.04, X2: 437.37, Y2: 421.85, X3: 458.66,  Y3: 433.66, X4: 0,        Y4: 0, },
        { SN: '92',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-ND11-T', BLK: 'ND330', TITLE: 'Main Deck',                  X1: 319.57, Y1: 477.74, X2: 320.15, Y2: 356.75, X3: 320.54,  Y3: 355.9,  X4: 0,        Y4: 0, },
        { SN: '93',  AREA: 'North',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-ND11-S', BLK: 'NX110', TITLE: 'Stair Tower',                X1: 462.18, Y1: 596.63, X2: 459.58, Y2: 721.48, X3: 378.54,  Y3: 784.06, X4: 0,        Y4: 0, },
        { SN: '94',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-SD11-T', BLK: 'ER310', TITLE: 'Erectrical Building',        X1: 365.74, Y1: 533.42, X2: 365.41, Y2: 468.72, X3: 362.07,  Y3: 258.08, X4: 0,        Y4: 0, },
        { SN: '95',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-SD11-B', BLK: 'GD110', TITLE: 'Generator Building',         X1: 428.18, Y1: 577.4,  X2: 428.18, Y2: 512.57, X3: 450.33,  Y3: 385.02, X4: 0,        Y4: 0, },
        { SN: '96',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-SD11-B', BLK: 'SD110', TITLE: 'Production Deck',            X1: 446.26, Y1: 639.61, X2: 446.26, Y2: 732.41, X3: 468.38,  Y3: 745.8,  X4: 0,        Y4: 0, },
        { SN: '97',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-SD11-T', BLK: 'SD120', TITLE: 'Production Deck',            X1: 267.92, Y1: 553.57, X2: 267.92, Y2: 646.38, X3: 267.74,  Y3: 647.09, X4: 0,        Y4: 0, },
        { SN: '98',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-SD11-B', BLK: 'SD310', TITLE: 'Main Deck',                  X1: 408.37, Y1: 608.01, X2: 408.15, Y2: 543.55, X3: 430.44,  Y3: 557.67, X4: 0,        Y4: 0, },
        { SN: '99',  AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-SD11-T', BLK: 'SD330', TITLE: 'Main Deck',                  X1: 295.8,  Y1: 543.8,  X2: 296.03, Y2: 478.87, X3: 297.28,  Y3: 479.88, X4: 0,        Y4: 0, },
        { SN: '100', AREA: 'South',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-SD11-S', BLK: 'SX110', TITLE: 'Stair Tower',                X1: 587.1,  Y1: 632.26, X2: 587.1,  Y2: 725.06, X3: 660.22,  Y3: 705.39, X4: 0,        Y4: 0, },
        { SN: '101', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-B', BLK: 'CD110', TITLE: 'Production Deck',            X1: 422.01, Y1: 618.14, X2: 422.01, Y2: 786.14, X3: 454.19,  Y3: 803.73, X4: 0,        Y4: 0, },
        { SN: '102', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-T', BLK: 'CD130', TITLE: 'Production Deck',            X1: 271.8,  Y1: 535.15, X2: 271.8,  Y2: 701.64, X3: 271.26,  Y3: 701.23, X4: 0,        Y4: 0, },
        { SN: '103', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-B', BLK: 'CD210', TITLE: 'Mezz. Deck',                 X1: 528.08, Y1: 706.16, X2: 528.18, Y2: 685.03, X3: 562.6,   Y3: 708.74, X4: 0,        Y4: 0, },
        { SN: '104', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-B', BLK: 'CD220', TITLE: 'Mezz. Deck',                 X1: 469.75, Y1: 673.16, X2: 469.75, Y2: 652.15, X3: 482.38,  Y3: 662.24, X4: 0,        Y4: 0, },
        { SN: '105', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-B', BLK: 'CD230', TITLE: 'Mezz. Deck',                 X1: 576.92, Y1: 682.55, X2: 578,    Y2: 661.15, X3: 628.43,  Y3: 675.34, X4: 0,        Y4: 0, },
        { SN: '106', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-B', BLK: 'CD240', TITLE: 'Mezz. Deck',                 X1: 503.24, Y1: 624.67, X2: 503.24, Y2: 603.66, X3: 531.79,  Y3: 604.65, X4: 0,        Y4: 0, },
        { SN: '107', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-T', BLK: 'CD250', TITLE: 'Mezz. Deck',                 X1: 349.7,  Y1: 616.16, X2: 349.7,  Y2: 595.15, X3: 341.43,  Y3: 593.16, X4: 0,        Y4: 0, },
        { SN: '108', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-T', BLK: 'CD260', TITLE: 'Mezz. Deck',                 X1: 466.78, Y1: 662.51, X2: 466.78, Y2: 641.51, X3: 458.28,  Y3: 639.51, X4: 0,        Y4: 0, },
        { SN: '109', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-T', BLK: 'CD270', TITLE: 'Mezz. Deck',                 X1: 495.35, Y1: 646.16, X2: 495.35, Y2: 625.15, X3: 502.86,  Y3: 614.32, X4: 0,        Y4: 0, },
        { SN: '110', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-B', BLK: 'CD310', TITLE: 'Main Deck',                  X1: 417.53, Y1: 581.28, X2: 415.86, Y2: 418.6,  X3: 448.33,  Y3: 433.37, X4: 0,        Y4: 0, },
        { SN: '111', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-T', BLK: 'CD340', TITLE: 'Main Deck',                  X1: 292.94, Y1: 522.17, X2: 292.94, Y2: 358.66, X3: 294.35,  Y3: 355.06, X4: 0,        Y4: 0, },
        { SN: '112', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-B', BLK: 'CK110', TITLE: 'Pedestal',                   X1: 526.87, Y1: 655.14, X2: 526.87, Y2: 491.27, X3: 546.05,  Y3: 323.97, X4: 0,        Y4: 0, },
        { SN: '113', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-T', BLK: 'CK140', TITLE: 'Pedestal',                   X1: 292.68, Y1: 486.78, X2: 292.68, Y2: 322.91, X3: 311.85,  Y3: 239.44, X4: 0,        Y4: 0, },
        { SN: '114', AREA: 'Central', ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-CD11-S', BLK: 'CX110', TITLE: 'Stair Tower',                X1: 298.92, Y1: 508.72, X2: 298.92, Y2: 674.85, X3: 245.17,  Y3: 641.52, X4: 0,        Y4: 0, },
        { SN: '115', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LD11',   BLK: 'LD110', TITLE: 'Level-1 Deck',               X1: 323.25, Y1: 523.29, X2: 323.43, Y2: 728.2,  X3: 323.43,  Y3: 728.2,  X4: 0,        Y4: 0, },
        { SN: '116', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LD11',   BLK: 'LD210', TITLE: 'Level-2 Deck',               X1: 308.23, Y1: 500.68, X2: 308.23, Y2: 584.55, X3: 308.23,  Y3: 584.55, X4: 0,        Y4: 0, },
        { SN: '117', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LD11',   BLK: 'LD310', TITLE: 'Level-3 Deck',               X1: 331.19, Y1: 500.48, X2: 331.19, Y2: 450.06, X3: 331.19,  Y3: 450.06, X4: 0,        Y4: 0, },
        { SN: '118', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LD11',   BLK: 'LD410', TITLE: 'Level-4 Deck',               X1: 328.93, Y1: 496.08, X2: 328.93, Y2: 328.08, X3: 328.93,  Y3: 328.08, X4: 0,        Y4: 0, },
        { SN: '119', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LD11',   BLK: 'LHS10', TITLE: 'Heli Deck Support',          X1: 370.78, Y1: 470.7,  X2: 400.16, Y2: 285.5,  X3: 400.16,  Y3: 285.5,  X4: 0,        Y4: 0, },
        { SN: '120', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LD11',   BLK: 'LN4A0', TITLE: 'Support',                    X1: 498.55, Y1: 623,    X2: 462.69, Y2: 475.82, X3: 462.69,  Y3: 475.82, X4: 0,        Y4: 0, },
        { SN: '121', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LD11',   BLK: 'LN4B0', TITLE: 'Support',                    X1: 449.59, Y1: 594.54, X2: 413.73, Y2: 447.37, X3: 413.73,  Y3: 447.37, X4: 0,        Y4: 0, },
        { SN: '122', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LD11',   BLK: 'LN4C0', TITLE: 'Support',                    X1: 350.52, Y1: 538.29, X2: 314.88, Y2: 391.12, X3: 314.88,  Y3: 391.12, X4: 0,        Y4: 0, },
        { SN: '123', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LD11',   BLK: 'LV520', TITLE: 'Stair',                      X1: 342.62, Y1: 494.78, X2: 339.12, Y2: 254.77, X3: 339.12,  Y3: 254.77, X4: 0,        Y4: 0, },
        { SN: '124', AREA: 'LQ',      ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LD11',   BLK: 'LV530', TITLE: 'Access Platform',            X1: 382.44, Y1: 521.62, X2: 394.13, Y2: 275.26, X3: 394.13,  Y3: 275.26, X4: 0,        Y4: 0, },
        { SN: '125', AREA: 'Flare',   ZONE: '',        M_AREA: 'TOPSIDE', M_ZONE: '',                 KP: 'KP-NF11',   BLK: 'NF110', TITLE: 'Flare Tower',                X1: 327.92, Y1: 478.88, X2: 115.33, Y2: 602.25, X3: 115.33,  Y3: 482.66, X4: 0,        Y4: 0, },
        { SN: '126', AREA: 'House',   ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LV51',   BLK: 'LV510', TITLE: 'Stair House',                X1: 700.99, Y1: 589.05, X2: 700.99, Y2: 534.59, X3: 0,        Y3: 0,       X4: 0,        Y4: 0, },
        { SN: '127', AREA: 'Heli',    ZONE: '',        M_AREA: 'LQ',      M_ZONE: '',                 KP: 'KP-LH11',   BLK: 'LH110', TITLE: 'Heli Deck',                  X1: 599.5,  Y1: 510.09, X2: 599.5,  Y2: 423.64, X3: 0,        Y3: 0,       X4: 0,        Y4: 0, },
        ],
    }

  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}