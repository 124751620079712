import CONST from '../../../../../../includes/primitives/_constant_trion'


export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',
    Props: {},

    //pdf
    selectedItems: null,
    getItems: null,


    canvasForms: null,
    canvasTimeline: null,
    canvasGantte: null,

    rawData: null,
    masterList: null,

    zones: null,
    gantteData: [],
    activity: [
      {name: 'Cutting',   code: 'SC', color: '#bcbcbc', },
      {name: 'Block Fab.',code: 'FB', color: '#FFDA00', },
      {name: 'Assembly',  code: 'AS', color: '#83D2F5', },
      {name: '1st PE',    code: 'PE1',color: '#83D2F5', },
      {name: 'Painting',  code: 'PT', color: '#F7BACF', },
      {name: '2nd PE',    code: 'PE2',color: '#83D2F5', },
      {name: 'Setting',   code: 'ER', color: '#8BC248', },
    ],

    

    
  }),





  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}