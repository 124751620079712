import * as d3 from 'd3'

export default {
  methods: {

    draw_ChartCanvas() {

      this.canvasChart = this.canvasForms
      .append('g')
      .attr('class', `FORMS`)
      .attr('transform', `translate(${this.chart.x +.5}, ${this.chart.y + .5})`)
      
      this.draw_ChartAxis(this.canvasChart) //-----> below
     
    },

    
    draw_ChartAxis(selection) {
      // Draw Chart Border
      selection
      .append('line')
      .attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', 0).attr('x2', this.chart.width).attr('y1', this.chart.height).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', this.chart.width).attr('x2', this.chart.width).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575' ).attr('stroke-width', .5).attr('opacity', 1)

      // Primary Axis
      selection
      .append('text')
      .attr('transform', `translate(${-35}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 11).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Avg FTE per Week')

      // Secoundary Axis
      selection
      .append('text')
      .attr('transform', `translate(${this.chart.width + 35}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 11).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Cumulative Avg FTE')
    },


    
  }
}
           