import * as d3 from 'd3'

export default {
  methods: {
    
    link_Event(selection, _item, _d) {
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
        let dataSource = {} // DO not Remark if possible 
  
        // set for request from A/G jsonProps
        let request_ = this.getRequestColumnProps('request', dataSource, this.ColumnProps)
        if(!request_) return
  
        // set for filterString 
        let filterString_ = ''

        if (_d.ZONE != 'OVERALL') {
          filterString_ += `[ZONE] = '${_d.ZONE}'`
        }

        if (_item != 'TOTAL') {
          if (filterString_ != '') filterString_ += ' AND '

          let col_ = this.linkCol.find(f => f.code == _item).col
          
          if (_item == 'ETA_SRD') {
            filterString_ += `[${col_}] < 0`
          } else {
            filterString_ += `[${col_}] IS NOT NULL`
          }
        }

        if (_d.SVG) {
          filterString_ += ` AND [SVG] = '${_d.SVG}'`
        }
  
        // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
        if(request_.iFilters.filterString) {
          request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
        } else {
          request_.iFilters.filterString = filterString_||''
        }
  
        // combine filterString and inputFilter &&& emit request-action
        request_ = {...request_,
          filters : {
            ...this.FilterValues,
            ...request_.filters,
          },
          iFilters: { 
            filterString : request_.iFilters.filterString||'',
            inputFilter : request_.iFilters.inputFilter,},
        }
        this.$emit('request-action', request_)
      }) 
    },

    
    call_Area(selection, _d) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#${_d.ZONE}`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#${_d.ZONE}`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => { 
        if(clickTimeout) return
        
        this.current.area = _d.ZONE
        this.gmx_removeExeFunc({
          remove: [this.model_Overall,this.canvas_Overall],
          dur: 500, 
          timeout: 1000,
          exeFunc: this.draw_Model_Module,
          data: _d
        })
        clickTimeout = true
      })
    },


    call_Module(selection, _d) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#${_d.ZONE}-${_d.SVG}`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#${_d.ZONE}-${_d.SVG}`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => { 
        if(clickTimeout) return
        
        this.current.area = _d.SVG
        this.gmx_removeExeFunc({
          remove: [this.model_Module,this.canvas_Module],
          dur: 500, 
          timeout: 1000,
          exeFunc: this.draw_Model_Equipment,
          data: _d
        })
        clickTimeout = true
      })
    },


    call_Equipment(selection, _d) {
      selection
      .on('mouseover', () => {
        this.current.sn = _d.SN

        d3.select(`#C-${_d.ID}`).transition().duration(150)
        .attr('r', 9)
        .style('stroke-width', 1.5).style('stroke', '#F35E90')
        let txt = d3.select(`#NO-${_d.ID}`).selectAll('text').nodes()[0]
        d3.select(txt).style('opacity', 0.5)

        this.update_detail(_d)
      })
      .on('mouseout', () => {
        d3.select(`#C-${_d.ID}`).transition().duration(150)
        .attr('r', 7.5)
        .style('stroke-width', 0.5).style('stroke', '#333')
        let txt = d3.select(`#NO-${_d.ID}`).selectAll('text').nodes()[0]
        d3.select(txt).style('opacity', 1)
      })
      .on('click', () => { 
        this.draw_Popup_Window(_d)
      })
    },

  }
}