
import * as d3 from 'd3'

export default {
  data: () => ({  
    pop: {
      x: 450,       y: 300,
      width: 700,   height: 500,  bar: 28,
      moveX: 0,     moveY: 0,
      deltaX: 0,    deltaY: 0,
    },
  }),


  methods: {

    draw_Popup_Window(data_) {

      this.canvas_Popup = this.svg
      .append('svg')
      .attr('width', 1100).attr('height', 700)

      .append('g')
      .attr('id', `POPUP`)
      .attr('transform', `translate(0.5, 0.5)`)
      .attr('font-family', 'roboto').attr('opacity', 0).attr('visibility', 'hidden')

      // Background - Close
      this.canvas_Popup
      .append('rect')
      .attr('x', -.5).attr('y', -.5).attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight).attr('fill', '#000').attr('opacity', .7)
      .on('click', () => {
        this.close_Popup()
      }) 

      let p = this.pop
      let scrollY = (window.scrollY) + p.y
      let this_ = this
     
      let modal = this.canvas_Popup
      .append('g')
      .attr('id', 'MODAL_POPUP')
      .attr('transform', `translate(${this.pop.moveX},${this.pop.moveY})`)
      .call(
        d3.drag()
        .on('start', function() {
          if (d3.event.y >= p.y + p.moveY && d3.event.y <=  p.y + p.moveY + p.bar) {
            let coordinate = this_.getCoordinates(d3.select(this))
            p.deltaX = coordinate[0] - d3.event.x
            p.deltaY = coordinate[1] - d3.event.y
          } else return
        })
        .on('drag', function() {
          if (d3.event.y >= p.y + p.moveY && d3.event.y <=  p.y + p.moveY + p.bar) {
            p.moveX = d3.event.x + p.deltaX
            p.moveY = d3.event.y + p.deltaY
            p.moveX = Math.max(-p.x, Math.min(this_.Canvas.CanvasWidth - p.width - p.x, p.moveX))
            p.moveY = Math.max(-p.y, Math.min(this_.Canvas.CanvasHeight - p.height - p.y, p.moveY))
            modal.attr('transform', `translate(${p.moveX},${p.moveY})`)
          } else return
        })
      )

      modal // Filter Window
      .append('rect')
      .attr('x', p.x).attr('y', scrollY)
      .attr('width', p.width).attr('height', p.height) .attr('rx', 2).attr('stroke', '#757575').attr('stroke-width', 1)
      .attr('fill', '#fff').attr('filter', 'url(#dropshadow)').attr('opacity', 1.5)

      modal // Window bar
      .append('rect')
      .attr('x', p.x).attr('y', scrollY).attr('width', p.width).attr('height', p.bar)
      .attr('fill', 'url(#windowBar)')

      modal // Contents Window 
      .append('rect')
      .attr('x', p.x).attr('y', scrollY + p.bar).attr('width', p.width).attr('height', p.height - p.bar)
      .attr('fill', 'url(#contentWindow)')

      modal
      .append('line')
      .attr('x1', p.x).attr('x2', p.x + p.width)
      .attr('y1', scrollY + p.bar + 1).attr('y2', scrollY + p.bar + 1).attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)

      modal
      .append('line')
      .attr('x1', p.x).attr('x2', p.x + p.width)
      .attr('y1', scrollY + p.bar + 2).attr('y2',scrollY + p.bar + 2).attr('stroke', '#fff').attr('stroke-width', 1)

      this.canvas_Popup.transition().duration(500)
      .attr('visibility', 'display').attr('opacity', 1)



      let close = modal
      .append('g') // Close Button
      .attr('id', 'CLOSE')
      .attr('transform', `translate(${this.pop.x+this.pop.width-23}, ${scrollY +7})`)
      .attr('font-family', 'roboto')
      .style('cursor', 'pointer')
      .on('mouseover', () => {d3.select('#CLOSE').style('opacity', .3)})
      .on('mouseout', () => {d3.select('#CLOSE').style('opacity', 1)})
      .on('click', () => {this.close_Popup()}) 

      close
      .append('rect')
      .attr('x', 0).attr('t', 0).attr('width', 15).attr('height', 15)
      .attr('fill', '#fff').attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      close
      .append('path')
      .attr('transform', `translate(0, 0) scale(.03)`).attr('fill', '#333')
      .attr('d', `M289.94,256l95-95A24,24,0,0,0,351,127l-95,95-95-95A24,24,0,0,0,127,161l95,95-95,95A24,24,0,1,0,161,385l95-95,95,95A24,24,0,0,0,385,351Z`)


      this.draw_Popup_Elements(modal, data_)
      this.set_selectedItems()
    },


    draw_Popup_Elements(selection, data_) {
      // console.log(data_) /////

      this.loadSvg(
        selection, {
        x: this.pop.x, y: 200,  width: 700,
        url : `/Visualization/Layout_Equipment/FORMS.svg`
      }).then(() => {
        d3.selectAll('text').style('font-family', 'roboto')

        selection
        .append('text')
        .attr('transform', `translate(${this.pop.x + 10}, ${318})`).attr('font-size', 13).attr('fill', '#000')
        .text(data_.DESC)

        selection
        .append('image')
        .attr('id', 'IMAGE_EQ')
        .attr('xlink:href', this.HOST + `/Visualization/Layout_Equipment/images/${data_.TAG}.jpg`)
        .attr('x', this.pop.x + 418).attr('y', 423).attr('width', 260)

        let folder = data_.AREA
        let url = null
        let blk = null

        if(data_.ZONE == 'HULL') blk = `A_${data_.BLK}`
        else blk = `C_${data_.G_EREC}`
    
        url = `/Visualization/BlockDivision/BlockImage/${folder}/${blk}.jpg`
        // console.log(url)

        selection
        .append('image')
        .attr('id', 'IMAGE_BLK')
        .attr('xlink:href', this.HOST + url)
        .attr('x', this.pop.x + 22).attr('y', 668).attr('height', 112)

        this.eq_Popup.forEach(d=> {
          selection.select(`#${d.id}`)
          .style('text-anchor', (d.center == 'Y') ? 'middle' : 'start')
          .text((data_[d.code]) ? data_[d.code] : '')
        })

        if(data_.ETA_SRD) {
          d3.select(`#ETA-SRD`).style('fill', (data_.ETA_SRD < 0) ? '#F35E90' : '#44A9DF')
          d3.select(`#BOX-ETA-SRD`).style('stroke', (data_.ETA_SRD < 0) ? '#F35E90' : '#44A9DF')
        }
        if(data_.DT_ETA) {
          d3.select(`#DT-ETA`).style('fill',  (data_.DT_ETA < 0) ?  '#F35E90' : '#44A9DF')
          d3.select(`#BOX-DT-ETA`).style('stroke',  (data_.DT_ETA < 0) ?  '#F35E90' : '#44A9DF')
        }
        if(data_.DT_SRD) {
          d3.select(`#DT-SRD`).style('fill',  (data_.DT_SRD < 0) ?  '#F35E90' : '#44A9DF')
          d3.select(`#BOX-DT-SRD`).style('stroke',  (data_.DT_SRD < 0) ?  '#F35E90' : '#44A9DF')
        } 
      })

    },


    close_Popup() {
      console.log('CLOSE')

      this.canvas_Popup.transition().duration(500).attr('opacity', 0)
      this.canvas_Popup.transition().duration(500).delay(500).attr('visibility', 'hidden')

      setTimeout(() => { 
        this.canvas_Popup.remove()
      }, 500)

      // this.reset_PDF_Data()
    },


  }
}