import * as d3 from 'd3'

export default {
  
  methods: {

    draw_Erection_Canvas() {
      this.canvas_Erection = this.svg
      .append('g')
      .attr('id', `CANVAS_ERECTION`).attr('transform', `translate(0,0)`).attr('font-family', 'roboto')

      this.gmx_btn_Navigation(
        this.canvas_Erection, 
        {type: 'home', x: 450, y: 78, scale: 0.8,}, 
        {
          remove: [this.model_Erection, this.canvas_Erection,],
          exeFunc: this.draw_Model_Overall,
          data: null
        }
      )

      this.gmx_btn_Navigation(
        this.canvas_Erection, 
        {type: 'back', x: 490, y: 78, scale: 0.8,}, 
        {
          remove: [this.model_Erection, this.canvas_Erection,],
          exeFunc: this.draw_Model_Area,
          data: null
        }
      )

      // Load a Legend SVG
      let KeyplanArea = this.current.area
      if(this.current.area == 'LQ') KeyplanArea = 'Topside'

      this.loadSvg(
        this.canvas_Erection, {
        x: 30, y: 140,
        url : `/Visualization/includes/Keyplan_${KeyplanArea}.svg`
      }).then(() => {
        
        this.keyPlan.forEach(d => {
          d3.select(`#${d}`).style('opacity', 0)
        })
        d3.selectAll("text").style('font-family', 'roboto')
      })

      let area_ = this.current.area.toUpperCase()
      this.status_Structure(this.canvas_Erection, { x: 30, y: 380, stage: 'AREA', filter: area_, head: 'Y'})
      this.status_Outfitting(this.canvas_Erection, {x: 30, y: 530, stage: 'AREA', filter: area_,})

    },
  }
}