
export default {
  data: () => ({

    localData: {

      // stepData
      JSON1: [ 
        { SN: '1',  CDATE: '2025-01-31', },
        { SN: '2',  CDATE: '2025-02-28', },
        { SN: '3',  CDATE: '2025-03-28', },
        { SN: '4',  CDATE: '2025-04-25', },
        { SN: '5',  CDATE: '2025-05-30', },
        { SN: '6',  CDATE: '2025-06-27', },
        { SN: '7',  CDATE: '2025-07-25', },
        { SN: '8',  CDATE: '2025-08-29', },
        { SN: '9',  CDATE: '2025-09-26', },
        { SN: '10', CDATE: '2025-10-31', },
        { SN: '11', CDATE: '2025-11-28', },
        { SN: '12', CDATE: '2025-12-26', },
        { SN: '13', CDATE: '2026-01-30', },
        { SN: '14', CDATE: '2026-02-27', },
        { SN: '15', CDATE: '2026-03-27', },
        { SN: '16', CDATE: '2026-04-24', },
        { SN: '17', CDATE: '2026-05-29', },
        { SN: '18', CDATE: '2026-06-26', },
        { SN: '19', CDATE: '2026-07-31', },
        { SN: '20', CDATE: '2026-08-28', },
        { SN: '21', CDATE: '2026-09-25', },
        { SN: '22', CDATE: '2026-10-30', },
        { SN: '23', CDATE: '2026-11-27', },
        { SN: '24', CDATE: '2026-12-25', },
        { SN: '25', CDATE: '2027-01-29', },
        { SN: '26', CDATE: '2027-02-26', },
        { SN: '27', CDATE: '2027-03-26', },
      ],
      
      JSON2: [
        { BLK: 'EN112',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'EP112',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'LD110',   M_AREA: 'LQ',      M_ZONE: 'LivingQuarter', },
        { BLK: 'EN212',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'LD210',   M_AREA: 'LQ',      M_ZONE: 'LivingQuarter', },
        { BLK: 'LD310',   M_AREA: 'LQ',      M_ZONE: 'LivingQuarter', },
        { BLK: 'LD410',   M_AREA: 'LQ',      M_ZONE: 'LivingQuarter', },
        { BLK: 'WN112',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WN212',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'CD110',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'CD130',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'EN161',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'NP112',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'NP151',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'SD110',   M_AREA: 'TOPSIDE', M_ZONE: 'SOUTH', },
        { BLK: 'SD120',   M_AREA: 'TOPSIDE', M_ZONE: 'SOUTH', },
        { BLK: 'CD210',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'CD220',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'CD230',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'CD240',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'WN261',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'CD250',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'CD310',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'LHS10',   M_AREA: 'LQ',      M_ZONE: 'LivingQuarter', },
        { BLK: 'LQ',      M_AREA: 'LQ',      M_ZONE: 'LivingQuarter', },
        { BLK: 'ND110',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH', },
        { BLK: 'ND120',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH', },
        { BLK: 'ND210',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH', },
        { BLK: 'ND220',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH', },
        { BLK: 'ND230',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH', },
        { BLK: 'ND310',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH', },
        { BLK: 'SP112',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'SP151',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WN161',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WP112',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WP111',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'CD340',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'EC112',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'EC1G2',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'EC212',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'EC2G2',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'EN261',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'ER310',   M_AREA: 'TOPSIDE', M_ZONE: 'SOUTH', },
        { BLK: 'WC1G2',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WC2G2',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'North',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH', },
        { BLK: 'South',   M_AREA: 'TOPSIDE', M_ZONE: 'SOUTH', },
        { BLK: 'SD310',   M_AREA: 'TOPSIDE', M_ZONE: 'SOUTH', },
        { BLK: 'SD330',   M_AREA: 'TOPSIDE', M_ZONE: 'SOUTH', },
        { BLK: 'WC112',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WC212',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'CK110',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'Central', M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'ND330',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH', },
        { BLK: 'WPB10',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'CK140',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'EC1K2',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'EC2K2',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'EF212',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'EN420',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'EN520',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'GD110',   M_AREA: 'TOPSIDE', M_ZONE: 'SOUTH', },
        { BLK: 'KD110',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH', },
        { BLK: 'WC1K2',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WC2K2',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WN410',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WN420',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'CX110',   M_AREA: 'TOPSIDE', M_ZONE: 'CENTRAL', },
        { BLK: 'SC410',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'SF212',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WF212',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'EF2B0',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'EF2C0',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WF2B0',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WF2C0',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'WN510',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'CC410',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'CC420',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'CC440',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'CF112',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'NF212',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'NX110',   M_AREA: 'TOPSIDE', M_ZONE: 'NORTH', },
        { BLK: 'RC410',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'RC420',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'SC420',   M_AREA: 'HULL',    M_ZONE: '', },
        { BLK: 'LH110',   M_AREA: 'LQ',      M_ZONE: 'HeliDeck', },
        { BLK: 'LV510',   M_AREA: 'LQ',      M_ZONE: 'StairHouse', },
        { BLK: 'NF110',   M_AREA: 'TOPSIDE', M_ZONE: 'FLARE', },
        ],
    }
  }),
}