import * as d3 from 'd3'

export default {
  methods: {

    call_Overall(selection) {
      // let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#MODEL_AREA`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#MODEL_AREA`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        // if(clickTimeout) return
        this.action_Overall_to_Area()
        // clickTimeout = true
      }) 
    },
 

    call_Area(selection, _area) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#${_area}-PNG`).transition().duration(50).style('opacity', .5)
        this.redraw_Structure({stage: 'AREA', filter: _area.toUpperCase(), head: 'Y'})
        this.redraw_Outfitting({stage: 'AREA', filter: _area.toUpperCase()})
      })
      .on('mouseout', () => {
        d3.select(`#${_area}-PNG`).transition().duration(50).style('opacity', 1)
        this.redraw_Structure({stage: 'OVERALL', filter: 'OVERALL', head: 'Y'})
        this.redraw_Outfitting({stage: 'OVERALL', filter: 'OVERALL'})
      })
      .on('click', () => { 
        if(clickTimeout) return
        
        this.current.area = _area
        this.gmx_removeExeFunc({
            remove: [this.model_Area, this.canvas_Area,],
            exeFunc: this.draw_Model_Erection,
            data: null
        })
        clickTimeout = true
      })
    },


    call_Erection_Block(selection, _d, _func) {
      let clickTimeout = false
      
      selection
      .on('mouseover', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', .5)
        d3.select(`#${_d.KP}`).transition().duration(50).style('opacity', .5) // KeyPlan

        if(_d.AREA == 'Hull') {
          this.redraw_Structure({stage: 'EREC', filter: _d.BLK, head: 'Y'})
          this.redraw_Outfitting({stage: 'EREC', filter: _d.BLK})
        } else if(_d.AREA == 'Topside') {
          this.redraw_Structure({stage: 'ZONE', filter: _d.ZONE.toUpperCase(), head: 'Y'})
          this.redraw_Outfitting({stage: 'ZONE', filter: _d.ZONE.toUpperCase()})
        }
      })
      .on('mouseout', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', 1)
        d3.select(`#${_d.KP}`).transition().duration(50).style('opacity', 0) // KeyPlan

        if(_d.AREA == 'Hull') {
          this.redraw_Structure({stage: 'AREA', filter: 'HULL', head: 'Y'})
          this.redraw_Outfitting({stage: 'AREA', filter: 'HULL'})
        } else if(_d.AREA == 'Topside') {
          this.redraw_Structure({stage: 'AREA', filter: 'TOPSIDE', head: 'Y'})
          this.redraw_Outfitting({stage: 'AREA', filter: 'TOPSIDE'})
        }
      })
      .on('click', () => {
        if(clickTimeout) return

        if (this.current.area == 'Hull') this.current.block = _d.BLK
        else this.current.pe = _d.BLK

        this.current.sn = _d.SN
        this.gmx_removeExeFunc({
          remove: [this.model_Erection, this.canvas_Erection,],
          exeFunc: _func,
          data: _d
        })
        clickTimeout = true
      })
    },

    
    call_2PE_Block(selection, _d) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', .5)

        this.redraw_Structure({stage: 'EREC', filter: _d.BLK, head: 'Y'})
        this.redraw_Outfitting({stage: 'EREC', filter: _d.BLK,})
      })
      .on('mouseout', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', 1)

        this.redraw_Structure({stage: 'ZONE', filter: _d.AREA.toUpperCase(), head: 'Y'})
        this.redraw_Outfitting({stage: 'ZONE', filter: _d.AREA.toUpperCase(),})
      })
      .on('click', () => {
        // console.log(_d)
        if(clickTimeout) return

        this.current.pe = _d.AREA
        this.current.sn = _d.SN
        this.gmx_removeExeFunc({
          remove: [this.model_2PE, this.canvas_2PE,],
          exeFunc: this.draw_Model_Block,
          data: _d
        })
        clickTimeout = true
      })
    },

    call_Equip_Hull(selection, _props, d, _func) {
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('opacity', .3)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        this.model_Erection.remove()
        this.canvas_Erection.remove()
        this.draw_Model_Equipment('Hull', d, _func)
      })
    },

    call_Equip_Topside(selection, _props, d, _func) {
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('opacity', .3)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        this.model_2PE.remove()
        this.canvas_2PE.remove()
        this.draw_Model_Equipment(_props, d, _func)
      })
    },

    call_Equipment(selection, _d) {
      selection
      .on('mouseover', () => {
        this.current.detail = _d.SN

        d3.select(`#C-${_d.ID}`).transition().duration(150)
        .attr('r', 9)
        .style('stroke-width', 1.5).style('stroke', '#F35E90')
        let txt = d3.select(`#NO-${_d.ID}`).selectAll('text').nodes()[0]
        d3.select(txt).style('fill', '#F35E90')

        this.update_detail(_d)
        this.set_selectedItems()
      })
      .on('mouseout', () => {
        d3.select(`#C-${_d.ID}`).transition().duration(150)
        .attr('r', 7.5)
        .style('stroke-width', 0.5).style('stroke', '#333')
        let txt = d3.select(`#NO-${_d.ID}`).selectAll('text').nodes()[0]
        d3.select(txt).style('fill', '#333')
      })
      .on('click', () => { 
        this.draw_Popup_Window(_d)
      })
    },
    
    call_madal_Window(selection, _d) {
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        // console.log({STAGE: _d.STAGE, KEY: _d.KEY, }) // ########## FILTER
        this.link_Modal({
          req: _d.STAGE,
          key: _d.KEY,
          key2: _d.KEY2,
        }, 'dashboard')
      })
    },
    
    call_modal_Index(selection, _d) {
      selection
      .attr('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        // console.log(_d) //////////
        this.link_Modal(_d, 'index')
      })
    },

    link_Modal(d_, type) {

      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {} // DO not Remark if possible
      if (type == 'index') {
        if (d_.stage != 'OVERALL') dataSource = {[d_.stage]: d_.filter}
      } else {
        dataSource = {STAGE: d_.req, KEY1: d_.key}

        if (d_.key2) dataSource = {...dataSource, KEY2: d_.key2}
      }
      console.log('dataSource: ', dataSource)

      let req = d_.req
      if (d_.sep) {
        d_.id == 'S1' ? req = `${d_.req}_${d_.sep}` : d_.req

        if (d_.id.substring(0,1) == 'S') req += '_Actual'
      }
      console.log('request text: ', req)

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(req, dataSource, this.ColumnProps)
      if(!request_) return

      // set for filterString 
      let filterString_ = ''

      if (type == 'index') {
        if (d_.customKey) {
          filterString_ += `${this.filterText[d_.customKey]}`
        } else {
          filterString_ += `[${d_.col}] IS NOT NULL`
        }
      }

      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },

  }
}