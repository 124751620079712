import * as d3 from 'd3'

export default {
  methods: {

    draw_ChartCanvas() {

      this.canvasChart = this.svg
      .append('g')
      .attr('class', `FORMS`)
      .attr('transform', `translate(${this.chart.x +.5}, ${this.chart.y + .5})`)

      this.canvasChart
      .append('text')
      .attr('id', `TITLE`)
      .attr('x', 0) .attr('y', -25)
      .style('font-size', 15)
      .style('fill', '#000')
      .text(`OVERALL`)

      this.canvasChart
      .append('text')
      .attr('x', this.getNode(`#TITLE`, 'width') + 3) .attr('y', -25)
      .style('font-size', 10).style('fill', '#F35E90')
      .text(`Spool Status (Fab. / Install)`)

      this.draw_ChartAxis(this.canvasChart) //-----> below
      this.draw_Legends(this.canvasChart) //-----> below
    },

    
    draw_ChartAxis(selection) {
      // Draw Chart Border
      selection
      .append('line')
      .attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', 0).attr('x2', this.chart.width).attr('y1', this.chart.height).attr('y2', this.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', this.chart.width).attr('x2', this.chart.width).attr('y1', 0).attr('y2', this.chart.height)
      .attr('stroke', '#757575' ).attr('stroke-width', .5).attr('opacity', 1)

      // Primary Axis
      selection
      .append('text')
      .attr('transform', `translate(${-30}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Completed No. of Spool (Weekly)')

      // Secoundary Axis
      selection
      .append('text')
      .attr('transform', `translate(${this.chart.width + 40}, ${this.chart.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Completed No. of Spool (Cumulative)')
    },


    draw_Legends(selection) {

      let item = selection
        .append('g')
        .attr('id', `LEGEND`)
        .attr('transform', `translate(0,-8)`)

      let x_ = 0
      this.chart.style.forEach((d,i)=> {
        item
        .append('rect')
        .attr('x', x_)
        .attr('y', (i>1) ? -4.5 : -1.5)
        .attr('width', 8).attr('height', (i>1) ? 8 : 2).attr('fill', d.sColor)

        item
        .append('text')
        .attr('id', `ITEM_${i}`)
        .attr('x', x_ + 11)
        .attr('y', 0)
        .style('font-size', 9).style('fill', '#333')
        .attr('alignment-baseline', 'middle')
        .text(d.name)
        x_ = x_ + this.getNode(`#ITEM_${i}`, 'width') + 20
      })

      // let legendLen = this.getNode(`#LEGEND`, 'width')
      // d3.select(`#LEGEND`).attr('transform', `translate(${this.chart.width - legendLen},-8)`)
    },


    


  }
}
           