import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'
import moment from 'moment'

export default {

  methods: {

    setDefaultValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))
      this.skyData = JSON.parse(JSON.stringify(this.Queries.SQL1)) // data

      let minDate = d3.min(this.skyData, d => d.ETA)
      let maxDate = d3.max(this.skyData, d => d.ETA)

      minDate = moment(minDate).add(-1, 'weeks').format('YYYY-MM-DD')
      maxDate = moment(maxDate).add(1, 'weeks').format('YYYY-MM-DD')
      
      // Timeline 
      this.timeline.title = this.rawData.TITLE_SUB,
      this.timeline.cutoff = this.rawData.CUTOFF,
      this.timeline.startDate = minDate,
      this.timeline.endDate = maxDate,

      this.filteredData = this.skyData.filter(f => 
        f[this.skyline.refColumn] >= this.timeline.startDate &&
        f[this.skyline.refColumn] <= this.timeline.endDate
      )

      this.set_SkylineData() // ---> below
    },

    set_SkylineData() {
      this.init_gmx_SkylineValues_Week2(this.filteredData)     

      this.filteredData.forEach((d) => {        
        let date__  = this.get_gmx_TimelineCDate_Week(d[this.timeline.refColumn])        
        d.cDate     = date__.eDate
        d.sDate     = date__.sDate
        d.date      = d3.timeParse('%Y-%m-%d')(d[this.timeline.refColumn])
        d.tSize     = this.skyline.skyBox.tSize
        d.tColor    = '#000'

        if (d.STATUS_CODE == 'ETA > SRD') d.bColor = '#FEED57'       
        else if (d.STATUS_CODE == 'ETA > LATE SRD') d.bColor = '#F7BACF' 
        else if (d.STATUS_CODE == 'ATA') d.bColor = '#99ff99'
        else d.bColor = '#fff'
      })

    },
    

    set_Gradients() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 'TL', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 'plan', 'LinearA4', 'LightBlue', [.5, .8, .5, 0], this.localId)
    },

  }
}