import * as d3 from 'd3'

export default {
  methods: {

    draw_ChartCanvas(area_, sn_) {

      this.canvasChart = this.canvasGroup
      .append('g')
      .attr('class', `FORMS`)
      .attr('transform', `translate(${area_.x +.5}, ${area_.y + .5})`)

      this.canvasChart
      .append('text')
      .attr('id', `TITLE_${sn_}`)
      .attr('x', 0) .attr('y', -10)
      .style('font-size', (area_.code == 'OVERALL') ? 15 : 13)
      .style('fill', '#000')
      .text(`${area_.code}`)

      this.canvasChart
      .append('text')
      .attr('x', this.getNode(`#TITLE_${sn_}`, 'width') + 3) .attr('y', -10)
      .style('font-size', 9).style('fill', '#858585')
      .text(`Progress`)

      this.draw_ChartAxis(this.canvasChart, area_) //-----> below
      this.draw_Legends(this.canvasChart, area_, sn_) //-----> below
    },

    
    draw_ChartAxis(selection, area_) {
      // Draw Chart Border
      selection
      .append('line')
      .attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', area_.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', 0).attr('x2', area_.width).attr('y1', area_.height).attr('y2', area_.height)
      .attr('stroke', '#757575').attr('stroke-width', .5).attr('opacity', 1)

      selection
      .append('line')
      .attr('x1', area_.width).attr('x2', area_.width).attr('y1', 0).attr('y2', area_.height)
      .attr('stroke', '#757575' ).attr('stroke-width', .5).attr('opacity', 1)

      // Primary Axis
      selection
      .append('text')
      .attr('transform', `translate(${-23}, ${area_.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Progress(%) (Weekly)')

      // Secoundary Axis
      selection
      .append('text')
      .attr('transform', `translate(${area_.width + 30}, ${area_.height/2}) rotate(-90)`)
      .style('font-size', 10).style('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Progress(%) (Cumulative)')
    },


    draw_Legends(selection, area_, sn_) {

      let item = selection
        .append('g')
        .attr('id', `LEGEND_${sn_}`)
        .attr('transform', `translate(0,-8)`)

      let x_ = 0
      this.ChartStyle.forEach((d,i)=> {
        item
        .append('rect')
        .attr('x', x_)
        .attr('y', -1.5)
        .attr('width', 8).attr('height', 2).attr('fill', d.sColor)

        item
        .append('text')
        .attr('id', `ITEM_${sn_}_${i}`)
        .attr('x', x_ + 11)
        .attr('y', 0)
        .style('font-size', 9).style('fill', '#333')
        .attr('alignment-baseline', 'middle')
        .text(d.name)
        x_ = x_ + this.getNode(`#ITEM_${sn_}_${i}`, 'width') + 20
      })

      let legendLen = this.getNode(`#LEGEND_${sn_}`, 'width')
      d3.select(`#LEGEND_${sn_}`).attr('transform', `translate(${area_.width - legendLen},-8)`)
    },


    


  }
}
           