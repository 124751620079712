import gmx_zMixins            from '../../../../../includes/Jmixins/zMixins'
import fnc_zMixins            from '../../../vFunctions/zMixins'

// Locally declared common Data-Props & SVG Drawing Support
import lmx_localData          from '../primitives/localData'

import lmx_Events             from './Events'
import lmx_Watching           from './Watching'

import lmx_LocalValues        from './set_LocalValues'
import lmx_draw_Forms         from './draw_Forms'
import lmx_draw_Detail        from './draw_Detail'
import lmx_draw_Summary       from './draw_Summary'
import lmx_draw_PDF           from './draw_PDF'

import lmx_Overall            from './draw_Overall'
import lmx_Module             from './draw_Module'
import lmx_Model_Equipment    from './draw_Equipment'
import lmx_draw_Popup         from './draw_Popup'
import lmx_draw_search        from './draw_search'

export default {
  
  mixins: [
    gmx_zMixins, 
    fnc_zMixins,
    lmx_localData,
    
    // Local Mixins
    lmx_Events,
    lmx_Watching,
    
    lmx_LocalValues,
    lmx_draw_Forms,
    lmx_draw_Detail,
    lmx_draw_Summary,
    lmx_draw_PDF,

    lmx_Overall,
    lmx_Module,
    lmx_Model_Equipment,
    lmx_draw_Popup,
    lmx_draw_search,

  ],

}
