<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'   
     
export default {
  name: "j-chart-project-1",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
    localValues: { type: Object, default: () => ({}) },
  },


  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    // console.log('this.id:', this.localId)
  },

  
  mounted() {
    this.register(this.draw)
  },

  methods: {
    
    draw() {
      if (!this.ready2draw) {
        return
      }
      this.clear()
      this.drawCanvas()
      this.drawing() // <------ Drawing Start
      this.drawNotes()
      this.drawTexts()
    },

    
    drawing() {

      this.setDefaultValues()
      this.set_Gradients()
      this.draw_Forms()

      this.draw_Timeline_Week()
      this.draw_SkylineCharts()


   
    },  

  }
}
</script>



// <!-- <style lang="scss" scoped>
//   .svg_wrapper {
//     width: 2500px;
//     height: 1120px;

//     overflow: hidden;
//   }
// </style> -->