import gmx_zMixins              from '../../../../../../includes/Jmixins/zMixins'
import fnc_Schedule             from '../../../vFunction/zMixins'

import fnc_zMixins              from '../../../../../Visualization/vFunctions/zMixins'
import lmx_localData            from '../primitives/localData'
// Locally declared common Data-Props & SVG Drawing Support

import lmx_Events               from './Events'
import lmx_Watching             from './Watching'


import lmx_LocalValues          from './set_LocalValues'
import lmx_draw_Forms           from './draw_Forms'
import lmx_draw_Timeline        from './draw_Timeline'
import lmx_draw_Gantte          from './draw_Gantte'


export default {
  
  mixins: [
    gmx_zMixins,
    fnc_zMixins,
    fnc_Schedule,
    lmx_localData,

    // Local Mixins
    lmx_Events,
    lmx_Watching,
    
    lmx_LocalValues,
    lmx_draw_Forms,
    lmx_draw_Timeline,

    lmx_draw_Gantte,
  ],

}
