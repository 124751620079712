import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_Erection() {
      this.current.stage = 'ERECTION'
      this.current.eq = null
      this.current.detail = null
      console.log('ERECTION STAGE') /////

      this.model_Erection = this.svg
      .append('g')
      .attr('id', `MODEL_ERECTION`)
      .attr('transform', `translate(340,80)`)
      .attr('font-family', 'roboto')
    
      this.loadSvg(
        this.model_Erection, {
        x   : 0,
        y   : -30,
        url : `/Visualization/BlockDivision/Rev1/${this.current.area}.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')

        // ########################################################### HULL ###########################################################
        if (this.current.area == 'Hull') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-700,-1500) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(750).style('opacity', 1).attr('transform', `translate(300,0) scale(1)`)

          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)
            d3.select(`#LINE-4TH`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(500).delay(750).attr('transform', `translate(${d.X2},${d.Y2}) scale(.7)`)
            d3.select(`#LINE-2ND`).transition().duration(300).delay(1500).style('opacity', 1)
          })

          setTimeout(() => { 
            this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#PNG-${d.BLK}`)
              .transition().duration(750).delay(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(.7)`)
              d3.select(`#LINE-3RD`).transition().duration(300).delay(750).style('opacity', 1)
            })
          }, 1000)

          setTimeout(() => { 
            this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#PNG-${d.BLK}`)
              .transition().duration(500).delay(500).attr('transform', `translate(${d.X4},${d.Y4}) scale(.7)`)
              d3.select(`#LINE-4TH`).transition().duration(300).delay(750).style('opacity', 1)
            })
          }, 2000)

          this.draw_Erection_Canvas() // ----->

          setTimeout(() => { 
            this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#${d.BLK}`)
              .attr('opacity', 1)
              .attr('cursor', 'pointer')
              .call(this.call_madal_Window, {STAGE: 'EREC', KEY: d.BLK}) // -----> call Madal Window
              d3.select(`#MASK-${d.BLK}`)
              .attr('cursor', 'pointer').call(this.call_Erection_Block, d, this.draw_Model_Block)
            })
            this.func_blockColoring(this.model_Erection, this.current.area.toUpperCase(), 'EREC') // -----> vFunction/block_Coloring.js

            // Equipment Layout ---------------------------------------------------------------------------
            this.eq_svg.filter(f=> f.ZONE == 'HULL').forEach(d=> {
              d3.select(`#EQ-${d.SVG}`)
              .attr('cursor', 'pointer')
              .call(this.call_Equip_Hull, d, d, this.back_Model_Erection)
            })

          }, 3000)
        }

        // ########################################################### TOPSIDE ###########################################################
        if (this.current.area == 'Topside') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-1000,-1300) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(750).style('opacity', 1).attr('transform', `translate(250,30) scale(1)`)
  
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {

            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(750).delay(750).attr('transform', `translate(${d.X2},${d.Y2}) scale(1)`)
            d3.select(`#LINE-2ND`).transition().duration(300).delay(1500).style('opacity', 1)
          })

          setTimeout(() => { 
            this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#PNG-${d.BLK}`)
              .transition().duration(500).delay(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(1)`)
              d3.select(`#LINE-3RD`).transition().duration(300).delay(1000).style('opacity', 1)
            })
          }, 1000)

          this.draw_Erection_Canvas() // ----->
          setTimeout(() => { 
            this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#${d.BLK}`)
              .attr('opacity', 1)
              .attr('cursor', 'pointer')
              .call(this.call_madal_Window, {STAGE: 'ZONE', KEY: d.M_ZONE,}) // -----> call Madal Window
              d3.select(`#MASK-${d.BLK}`)
              .attr('cursor', 'pointer').call(this.call_Erection_Block, d, this.draw_Model_2PE)
            })
            
          }, 1700)
        }


        // ########################################################### LQ ###########################################################
        if (this.current.area == 'LQ') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-1000,-1300) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(750).style('opacity', 1).attr('transform', `translate(100,30) scale(1)`)
  
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {

            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(750).delay(750).attr('transform', `translate(${d.X2},${d.Y2}) scale(1)`)
            d3.select(`#LINE-3RD`).transition().duration(300).delay(1800).style('opacity', 1)
          })

          this.draw_Erection_Canvas() // ----->
          setTimeout(() => { 
            this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {

              if(d.BLK == 'LQ') {
                d3.select(`#${d.BLK}`)
                .attr('opacity', 1).attr('cursor', 'pointer')
                .call(this.call_madal_Window, {STAGE: 'ZONE', KEY: 'Living Quarter'}) // -----> call Madal Window
              }
              if(d.BLK == 'Heli') {
                d3.select(`#${d.BLK}`)
                .attr('opacity', 1).attr('cursor', 'pointer')
                .call(this.call_madal_Window, {STAGE: 'EREC', KEY: 'LH110'}) // -----> call Madal Window
              }
              if(d.BLK == 'House') {
                d3.select(`#${d.BLK}`)
                .attr('opacity', 1).attr('cursor', 'pointer')
                .call(this.call_madal_Window, {STAGE: 'EREC', KEY: 'LV510'}) // -----> call Madal Window
              }
              d3.select(`#MASK-${d.BLK}`)
              .attr('cursor', 'pointer').call(this.call_Erection_Block, d, this.draw_Model_2PE)
            })
            
          }, 1500)
        }

        
      })
      
      this.set_selectedItems()
    },


    back_Model_Erection() {
      
      this.current.stage = 'ERECTION'
      this.current.eq = null
      this.current.detail = null
      
      this.model_Erection = this.svg
      .append('g')
      .attr('id', `MODEL_ERECTION`)
      .attr('transform', `translate(340,80)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_Erection, {
        x   : 0,
        y   : -30,
        url : `/Visualization/BlockDivision/Rev1/${this.current.area}.svg`
      }).then(() => {

        d3.selectAll("text").style('font-family', 'roboto')

        // --------------------------------------- Hull ----------------------------------------------
        if (this.current.area == 'Hull') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 1).attr('transform', `translate(300,0) scale(1)`)
  
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).attr('visibility', 'hidden').style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)
            d3.select(`#LINE-4TH`).style('opacity', 0) 

            d3.select(`#PNG-${d.BLK}`).transition().duration(500).attr('transform', `translate(${d.X4},${d.Y4}) scale(.7)`)
            d3.select(`#${d.BLK}`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#LINE-3RD`).transition().duration(500).delay(250).style('opacity', 1)
            d3.select(`#LINE-4TH`).transition().duration(500).delay(250).style('opacity', 1) 
            d3.select(`#LINE-4TH`).transition().duration(500).delay(250).style('opacity', 1)
          })

          this.draw_Erection_Canvas() // ----->

          setTimeout(() => { 
            this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#${d.BLK}`)
              .attr('opacity', 1)
              .attr('cursor', 'pointer')
              .call(this.call_madal_Window, {STAGE: 'EREC', KEY: d.BLK}) // -----> call Madal Window
              d3.select(`#MASK-${d.BLK}`)
              .attr('cursor', 'pointer').attr('visibility', 'display')
              .call(this.call_Erection_Block, d, this.draw_Model_Block)
            })
            this.func_blockColoring(this.model_Erection, this.current.area.toUpperCase(), 'EREC') // -----> vFunction/block_Coloring.js

            // Equipment Layout ---------------------------------------------------------------------------
            this.eq_svg.filter(f=> f.ZONE == 'HULL').forEach(d=> {
              d3.select(`#EQ-${d.SVG}`)
              .attr('cursor', 'pointer')
              .call(this.call_Equip_Hull, d, d, this.back_Model_Erection)
            })

          }, 1000)
        }

        // --------------------------------------- TOPSIDE ----------------------------------------------
        if (this.current.area == 'Topside') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 1).attr('transform', `translate(250,30) scale(1)`)
  
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).attr('visibility', 'hidden').style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)
            d3.select(`#TEXTS`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`).transition().duration(750).attr('transform', `translate(${d.X3},${d.Y3}) scale(1)`)
            d3.select(`#${d.BLK}`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#LINE-2ND`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#LINE-3RD`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#TEXTS`).transition().duration(500).delay(500).style('opacity', 1) 
          })

          this.draw_Erection_Canvas() // ----->

          setTimeout(() => { 
            this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#${d.BLK}`)
              .attr('opacity', 1)
              .attr('cursor', 'pointer')
              .call(this.call_madal_Window, {STAGE: 'ZONE', KEY: d.ZONE}) // -----> call Madal Window
              d3.select(`#MASK-${d.BLK}`)
              .attr('cursor', 'pointer').attr('visibility', 'display')
              .call(this.call_Erection_Block, d, this.draw_Model_2PE)
            })
          }, 1000)
        }


        // --------------------------------------- LQ ----------------------------------------------
        
        if (this.current.area == 'LQ') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 1).attr('transform', `translate(100,30) scale(1)`)
  
          this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).attr('visibility', 'hidden').style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)
            d3.select(`#TEXTS`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`).transition().duration(750).attr('transform', `translate(${d.X2},${d.Y2}) scale(1)`)
            d3.select(`#${d.BLK}`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#LINE-3RD`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#TEXTS`).transition().duration(500).delay(500).style('opacity', 1) 
          })

          this.draw_Erection_Canvas() // ----->

          setTimeout(() => { 
            this.erectionList.filter(f=> f.AREA == this.current.area).forEach(d => {
              if(d.BLK == 'LQ') {
                d3.select(`#${d.BLK}`)
                .attr('opacity', 1).attr('cursor', 'pointer')
                .call(this.call_madal_Window, {STAGE: 'ZONE', KEY: d.BLK}) // -----> call Madal Window
              }
              if(d.BLK == 'Heli') {
                d3.select(`#${d.BLK}`)
                .attr('opacity', 1).attr('cursor', 'pointer')
                .call(this.call_madal_Window, {STAGE: 'EREC', KEY: 'LH110'}) // -----> call Madal Window
              }
              if(d.BLK == 'House') {
                d3.select(`#${d.BLK}`)
                .attr('opacity', 1).attr('cursor', 'pointer')
                .call(this.call_madal_Window, {STAGE: 'EREC', KEY: 'LV510'}) // -----> call Madal Window
              }
              d3.select(`#MASK-${d.BLK}`).attr('cursor', 'pointer').attr('visibility', 'display').call(this.call_Erection_Block, d, this.draw_Model_2PE)
            })
          }, 1000)
        }

      })
      
      this.set_selectedItems()
    },
    
  }
}