import CONST from '../../../../includes/primitives/_constant_trion'
import icon from '../../../../includes/primitives/icons'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    rawData: [],
    common: null,
    header: null,
    table: null,
    columns: null,
    filters: null,

    titleGroup: [],
    icon: icon.arrow,
    tableWidth: 0,
    barScale: null,

    canvasArea: null,

    localData: {
      //
    },
  }),
}