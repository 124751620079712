import * as d3 from 'd3'

export default {
  data: () => ({
    
  }),

  methods: {

    draw_Dropdown(data_, id_) {

      let drop = this.dropdown.find(f=> f.id == id_)

      let chartMenu = this.canvasDropdown
      .append('g')
      .attr('id', `DROPDOWN_${drop.id}`)
      .attr('transform', `translate(${drop.x}, ${drop.y})`)
      .attr('font-family','roboto')

      let dropdown = chartMenu
      .append('g')
      .attr('transform', `translate(${0}, ${0})`)

      dropdown
      .append('rect')
      .attr('id', `DROP_BOX_${drop.id}`)
      .attr('x', 0).attr('y', 0)
      .attr('width', drop.boxWidth).attr('height', 18)
      .attr('fill', '#fff').attr('stroke','#757575')
      .attr('stroke-width', 1).attr('stroke-opacity', .5)

      dropdown
      .append('path')
      .attr('id', `ARROW_${drop.id}`)
      .attr('transform', `translate(${drop.boxWidth - 12} ,7)`)
      .attr('d',`M3.9,4.6C4,4.6,7.9,1,7.9,1l-1-1l-3,2.9L1,0L0,1C0,1,3.9,4.6,3.9,4.6z`)
      .attr('fill', '#bcbcbc')

      dropdown
      .append('text')
      .attr('id', `DROP_TITLE_${drop.id}`)
      .attr('x', 5).attr('y', 3.5)
      .attr('fill', '#757575') .attr('font-size', 11)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
      .text(data_[0])

      dropdown
      .on('mouseover', () => { 
        dropdown.attr('cursor', 'pointer')
        d3.select(`#DROP_TITLE_${drop.id}`).attr('fill','#44A9DF')
        d3.select(`#DROP_BOX_${drop.id}`).attr('stroke','#44A9DF')
      })
      .on('mouseout', () => { 
        dropdown.attr('cursor', 'default')
        d3.select(`#DROP_TITLE_${drop.id}`).attr('fill','#333')
        d3.select(`#DROP_BOX_${drop.id}`).attr('stroke','#757575')
      })
      .on('click', () => { 
        if (d3.select(`#DROP_ITEM_${drop.id}`).attr('visibility') == 'hidden') {
          d3.select(`#DROP_ITEM_${drop.id}`).attr('visibility', 'visible')
          d3.select(`#ARROW_${drop.id}`).attr('transform', `translate(${drop.boxWidth - 4} ,11) rotate(180)`)
        } else {
          d3.select(`#DROP_ITEM_${drop.id}`).attr('visibility', 'hidden')
          d3.select(`#ARROW_${drop.id}`).attr('transform', `translate(${drop.boxWidth - 12} ,7) rotate(0)`)
        }
      })

      this.draw_DropdownItem(data_, id_) //-----> below
    },



    draw_DropdownItem(data_, target_) {

      let drop = this.dropdown.find(f=> f.id == target_)

      d3.select(`#DROP_ITEM_${drop.id}`).remove()

      let items = d3.select(`#DROPDOWN_${drop.id}`)
      .append('g')
      .attr('id',`DROP_ITEM_${drop.id}`)
      .attr('visibility', 'hidden')
      .attr('transform', `translate(0, 20)`)

      items
      .append('rect')
      .attr('x', 0).attr('y', 0).attr('width', drop.boxWidth).attr('height', (data_.length * (18))+2)
      .attr('fill', '#fff').attr('stroke','#bcbcbc').attr('stroke-width',0.5)

      data_.forEach((item, i) => {
        items
        .append('rect')
        .attr('id', `ITEM_BOX_${drop.id}_${i}`).attr('fill', '#fff')
        .attr('x', 1).attr('y', (i * 18)+1)
        .attr('width', drop.boxWidth-2).attr('height', 18)
        .attr('opacity', .5)
        
        items
        .append('text')
        .attr('id', `ITEM_TEXT_${drop.id}_${i}`)
        .attr('x', 7).attr('y', (i * 18)+11).attr('fill', '#757575') .attr('font-size', 10)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(item)

        items
        .datum(item)
        .append('rect')
        .attr('id', `optionBtn_${drop.id}_${i}`)
        .attr('x', 1).attr('y', i * 18).attr('width', drop.boxWidth-2).attr('height', 18)
        .attr('fill', 'transparent').attr('stroke','transparent').attr('stroke-width',0.5)
        .attr('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#ITEM_BOX_${drop.id}_${i}`).transition().duration(100).attr('fill','#B4E3FA')
          d3.select(`#ITEM_TEXT_${drop.id}_${i}`).attr('fill','#333')
        })
        .on('mouseout', () => { 
          d3.select(`#ITEM_BOX_${drop.id}_${i}`).transition().duration(100).attr('fill','transparent')
          d3.select(`#ITEM_TEXT_${drop.id}_${i}`).attr('fill','#757575')
        })
        .on('click', (d) => { 
          d3.select(`#DROP_TITLE_${drop.id}`).text(item)
          d3.select(`#DROP_ITEM_${drop.id}`).attr('visibility', 'hidden')
          d3.select(`#ARROW_${drop.id}`).attr('transform', `translate(${drop.boxWidth - 12} ,7) rotate(0)`)
          items.attr('visibility', 'hidden')

          this.dropdown_Controller(target_, item) //-----> Event.js
        })
      })
    },


  } 
}