
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      let title = this.canvasForms
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        this.filter_GantteData('erec', this.Props.key)
      }) 

      title
      .append('text')
      .attr('transform', `translate(20, 25)`)
      .attr('font-size', 16).attr('fill', '#F35E90')
      .text(this.Props.title)

      title
      .append('text')
      .attr('transform', `translate(20, 39)`)
      .attr('font-size', 11).attr('fill', '#757575')
      .text(`${this.dataSet.AREA} / ${this.dataSet.ZONE} / ${this.dataSet.LEVEL} / ${this.dataSet.NAME}`)


      // Load SVG File
      this.loadSvg(
        this.canvasForms, {
        x: 0, y: 0, width: 810,
        url : `${this.HOST}/Visualization/Modal_Window/${this.Props.file}.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')
        
        // BLK
        this.filterData.forEach(d=> {
          d3.select(`#M-MASK-${d.BLK}`).style('opacity', 0).attr('cursor', 'pointer')
          .call(this.call_BLK, d.BLK)
          d3.select(`#M-${d.BLK}`).attr('cursor', 'pointer')
          .call(this.call_BLK, d.BLK)
        })

        // PE1
        this.filterData.filter(f=> f.PE1 != '').forEach(d=> {
          d3.select(`#M-MASK-PE1-${d.PE1}`).style('opacity', 0).attr('cursor', 'pointer')
          .call(this.call_PE1, d.PE1)
          d3.select(`#M-PE1-${d.PE1}`).attr('cursor', 'pointer')
          .call(this.call_PE1, d.PE1)
        })

        // EREC
        d3.select(`#M-MASK-EREC-${this.Props.key}`).style('opacity', 0).attr('cursor', 'pointer')
        .call(this.call_EREC, this.Props.key)
        d3.select(`#M-EREC-${this.Props.key}`).attr('cursor', 'pointer')
        .call(this.call_EREC, this.Props.key)


        this.func_blockColoring(this.canvasForms, this.dataSet.AREA, 'ASSEMBLY', this.FilterValues.KEY) // -----> vFunction/block_Coloring.js
      })



      this.loadSvg(
        this.canvasForms, {
        x: 10, y: 420, width: 810, height: 100,
        url : `${this.HOST}/Visualization/Modal_Window/Legend_Modal.svg`
      }).then(() => {})

      this.draw_Timeline_Month()
      this.filter_GantteData('erec', this.Props.key)
      this.draw_BlockStatus({
        x: 605.5,
        y: 50.5,
        data: this.masterList.filter(f=> f.G_EREC == this.Props.key)
      })
    },




    

   

  }
}