import * as d3 from 'd3'
import SvgService from '../../src/services/svg.service'

export default {
  data: () => ({
    // Services
    svgService: null,
    
    //General data-props
    svg: null,
    chartArea: null,
    cutoff: '2019-10-11',
    timeduration: 100,
    execRequested: [],

    SharedColorSet: [],
    SharedColorSetType: '',

    resizableCanvasWidth: null,
    resizableCanvasHeight: null,
  }),


  computed: {
    cutoffDate() { return this.parseDate(this.cutoff) },
    parseDate() { return d3.timeParse('%Y-%m-%d') },
    canvasWidth() { return this.resizableCanvasWidth == null ? this.Canvas.CanvasWidth : this.resizableCanvasWidth },
    canvasHeight() { return this.resizableCanvasHeight == null ? this.Canvas.CanvasHeight : this.resizableCanvasHeight },
  },


  created() {
    this.svgService = new SvgService()
    d3.selection.prototype.moveToFront = function() {
      return this.each(function(){
        this.parentNode.appendChild(this);
      })
    }
    
    d3.selection.prototype.moveToBack = function() {  
      return this.each(function() { 
          var firstChild = this.parentNode.firstChild; 
          if (firstChild) { 
              this.parentNode.insertBefore(this, firstChild); 
          } 
      })
    }
  },


  methods: {
    /*
      clear()
      complete()
      register()
      toJs()
      toJson()
      toJson()
      toXmlString()
      noDataFound()
      resizeCanvas()
    */

    clear() {
      if(!d3.select(`#${this.localId}`).empty()) d3.select(`#${this.localId}`).html('')
      this.svg = d3.select(`#${this.localId}`).append('svg')
    },

    complete() {
      setTimeout(() => {
        this.$emit('complete', this.localId)
        this.$emit('to-xml-string', this.toXmlString())
      }, 1000)
    },

    exec(requested, name='func') {
      if(requested.num != this.execRequested.length) return

      this.execRequested = []
      requested.func()
    },

    register(func, delay=250, name='func') {
      let requested = {
        name: name,
        num: this.execRequested.length + 1,
        time: new Date().getTime(),
        func: () => { func() }
      }
      this.execRequested.push(requested)
      setTimeout(() => { this.exec(requested, name) }, delay)
    },

    toJs() { return this.svgService.dom2js(d3.select(`#${this.localId}`)) },
    toJson() { return this.svgService.dom2json(d3.select(`#${this.localId}`)) },
    toXmlString() { return this.svgService.dom2xmlString(d3.select(`#${this.localId}`)) },

    // Drawing Common
    noDataFound() {

      // TO DO : Text's attribures are needed from the inputs to set the style for...

      let text_NoDataFound = this.svg
      .append('text')
      .attr('class', '_nodatafound__')
      .style('font-size', 14)
      .style('font-style', 'italic')
      .style('fill', '#f0f')
      .text('___ No data found. ___')

      let boxW_ = this.getNodeElValue('._nodatafound__', 'width')
      let boxH_ = this.getNodeElValue('._nodatafound__', 'height')
      let boxX_ = Math.round(this.Canvas.CanvasWidth / 2 - boxW_ / 2)
      let boxY_ = Math.round(this.Canvas.CanvasHeight / 2 - boxH_ / 2)

      text_NoDataFound
      .attr('transform', `translate(${boxX_},${boxY_})`)
    },

    resizeCanvas() {
      this.svg
      .attr('width', this.canvasWidth)
      .attr('height', this.canvasHeight)

      this.svg
      .select(`._canvas_border_background__${this.localId}`)
      .attr('width', this.canvasWidth)
      .attr('height', this.canvasHeight)
    }
  }
}