import * as d3 from 'd3'

export default {
  methods: {

    call_Link(selection, _d) {
      selection
      .attr('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        console.log(
          _d
        )
      }) 
    },


  


  }
}