import * as d3 from 'd3'

export default {
  methods: {

    chart_Controller() {
      
      // Call Chart
      this.chart.style.filter(f=> f.type == 'BAR').forEach((d,i)=> {
        this.draw_BarChart(d)
      })

      this.chart.style.filter(f=> f.type == 'LINE').forEach((d,i)=> {
        let data = this.chartData.filter(f=> f.CDATE <= this.timeline.cutoff)
        this.draw_LineChart(data, d)
      })

    },


    draw_BarChart(cs) {
      let barWidth = 3

      let barChart = d3.select(`#CHART_${this.chart.id}`)
      .append('g')
      .attr('transform', `translate(0.5,0)`)

      this.chartData.forEach((d,i)=> {
        let value = d[cs.code]
        if(value < 0) value = 0
        
        barChart
        .append('rect')
        .attr('x', (cs.code == 'FAB_WK') ? this.timeline.scale(d.mDate) - barWidth-1 : this.timeline.scale(d.mDate))
        .attr('y', this.chart.height-this.primary.axis(value))
        .attr('width', barWidth).attr('height', this.primary.axis(value))
        .attr('fill', cs.color).attr('opacity', 1)
      })
    },


    draw_LineChart(data, cs,) {
      // let lastOne = data.find(f=> f.CDATE == this.timeline.cutoff)

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(new Date(d.mDate)))
      .y(d => this.secoundary.axis(d[cs.code]))

      let lineChart = d3.select(`#CHART_${this.chart.id}`)
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      lineChart
      .append('path')
      .attr('d', lineFunc(data))
      .style('stroke', cs.color)
      .style('stroke-width', cs.size).style('fill', 'none')

      // // draw the final cumulative value
      // if(cs.type == 'LINE') {
      //   if(!lastOne) return
      //   lineChart
      //   .append('text')
      //   .attr('x', this.timeline.scale(new Date(lastOne.CDATE)))
      //   .attr('y', this.secoundary.axis(lastOne[cs.code]))
      //   .style('font-size', 10).style('fill', '#44A9DF')
      //   .attr('alignment-baseline', 'middle')
      //   .text(lastOne[cs.code])
      // }
    },


  }
}