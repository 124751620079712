import CONST from '../../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',
    rawData:      [],
    
    
    canvasForms:  null,
    canvasGroup:  null,
    canvasChart:  null,



    timeline:     {},
    primary:      {axis: null, max: null},
    secoundary:   {axis: null, max: null},

    chart: {
      width:  340,
      height: 200,
      style: [
        {name: 'Fab.-Cuml.',    code: 'FAB_CUM', type: 'LINE', size: 1, color: '#bcbcbc'},
        {name: 'Install-Cuml.', code: 'INS_CUM', type: 'LINE', size: 1, color: '#83D2F5'},
        {name: 'Fab.-wk.',      code: 'FAB_WK',  type: 'BAR',  size: .5, color: '#bcbcbc'},
        {name: 'Install-wk',    code: 'INS_WK',  type: 'BAR',  size: .5, color: '#83D2F5'},
      ]
    },


    area: [
      {area: 'HULL',    x:60,   y:60,  },
      {area: 'TOPSIDE', x:520,  y:60,  },
      {area: 'LQ',      x:990,  y:60,  },
    ],
    
    module: [
      {area: 'SOUTH',   x:60,   y:350,  },
      {area: 'CENTRAL', x:520,  y:350,  },
      {area: 'NORTH',   x:990,  y:350,  },
    ],

  }),




  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}