
import * as d3 from 'd3'

export default {
  methods: {

    filter_GantteData(type, _group) {

      let chtData = []
      let filterData = null
      let array = []
      let code = null
      let select = null
      
      if(type == 'zone') {
        filterData = this.filterData
        array = [ ...new Set(filterData.map(d => d.MOD)) ]
        code = 'MOD'
        select = 'ZONE'
      } else {
        filterData = this.masterList.filter(f=> f.EREC == _group)
        array = [ ...new Set(filterData.map(d => d.EREC)) ]
        code = 'EREC'
        select = 'EREC'
      }
+
      array.forEach(d=> {
        chtData.push({group: d})
      })
      
      chtData.forEach((d,i)=> {
        let data = filterData.filter(f=>f[code] == d.group)

        this.activity.forEach(act=> {
          d[`${act.code}_PS`] = d3.min(data, m =>  m[`${act.code}_PS`])
          d[`${act.code}_PF`] = d3.max(data, m =>  m[`${act.code}_PF`])
          d[`${act.code}_AS`] = d3.min(data, m =>  m[`${act.code}_AS`])
          d[`${act.code}_AF`] = d3.max(data, m =>  m[`${act.code}_AF`])
          d[`${act.code}_PP`] = (data.map(m=> {return m[`${act.code}_PW`]}).reduce((a, b) => (a + b)) / data.map(m=> {return m[`${act.code}_WF`]}).reduce((a, b) => (a + b)))*100
          d[`${act.code}_AP`] = (data.map(m=> {return m[`${act.code}_AW`]}).reduce((a, b) => (a + b)) / data.map(m=> {return m[`${act.code}_WF`]}).reduce((a, b) => (a + b)))*100
        })

      })
      this.removeSvg(this.canvasGantte, 200, this.draw_Gantte, {data: chtData, select: select})
    },

    filter_GantteData_EREC(type, _blk) {
      
      let chtData = {}
      let data = null
      let desc = null
      let lineH = null

      switch(type) {
        case 'erec': 
          data = this.filterData
          desc = 'Setting Block'
          lineH = 50
          break;

        case 'pe1': 
          data = this.filterData.filter(f=> f.G_1PE == _blk)
          desc = '1st PE Block'
          lineH = 50
          break;

        case 'block': 
          data = this.filterData.filter(f=> f.BLK == _blk)
          desc = 'Fab. Block'
          lineH = 50
          break;
      }

      chtData.BLOCk = _blk
      chtData.DESC = desc

      this.activity.forEach(act=> {
        chtData[`${act.code}_PS`] = d3.min(data, m =>  m[`${act.code}_PS`])
        chtData[`${act.code}_PF`] = d3.max(data, m =>  m[`${act.code}_PF`])
        chtData[`${act.code}_AS`] = d3.min(data, m =>  m[`${act.code}_AS`])
        chtData[`${act.code}_AF`] = d3.max(data, m =>  m[`${act.code}_AF`])
        chtData[`${act.code}_PP`] = (data.map(m=> {return m[`${act.code}_PW`]}).reduce((a, b) => (a + b)) / data.map(m=> {return m[`${act.code}_WF`]}).reduce((a, b) => (a + b)))*100
        chtData[`${act.code}_AP`] = (data.map(m=> {return m[`${act.code}_AW`]}).reduce((a, b) => (a + b)) / data.map(m=> {return m[`${act.code}_WF`]}).reduce((a, b) => (a + b)))*100
      })
      
      this.removeSvg(this.canvasGantte, 200, this.draw_singleGantt, {data: chtData, y: 530, lineH: lineH})
    },



    draw_Gantte(_obj) {

      this.canvasGantte = this.svg
      .append('g')
      .attr('transform', `translate(10.5, 510)`)
      .attr('font-family', 'roboto')

      let gantte = this.canvasGantte
      let desc = this.Props.key

      let y = 0
      _obj.data.forEach((d,i) => {
        if(_obj.select == 'EREC') desc = this.masterList.find(f=> f.EREC == d.group).MOD
        gantte
        .append('text')
        .attr('transform', `translate(5, ${y-15})`)
        .attr('font-size', 12).attr('fill', '#000')
        .text(d.group)

        gantte
        .append('text')
        .attr('transform', `translate(5, ${y-2})`)
        .attr('font-size', 10).attr('fill', '#F35E90')
        .text(desc)

        this.activity.forEach(act=> {
          let ps = this.timeline.scale(new Date(d[`${act.code}_PS`]))
          let pf = this.timeline.scale(new Date(d[`${act.code}_PF`]))

          // PLAN ---------------------------------------------------
          if(!ps) return
          gantte
          .append('rect')
          .attr('x', ps).attr('y', y-1)
          .attr('width', pf - ps)
          .attr('height', 7).attr('fill', '#E0E0DF')

          gantte
          .append('text')
          .attr('transform', `translate(${pf}, ${y-7})`)
          .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text(act.name)

          gantte // Start Date
          .append('text')
          .attr('transform', `translate(${ps-2}, ${y+4})`)
          .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
          .text(this.changeDate('/', d[`${act.code}_PS`]))

          gantte // Finish Date
          .append('text')
          .attr('id', `BAR_${i}_${act.code}`)
          .attr('transform', `translate(${pf+2}, ${y+4})`)
          .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
          .text(this.changeDate('/', d[`${act.code}_PF`]))

          // Progress - Plan
          if(d[`${act.code}_PP`] > 0 && d[`${act.code}_PP`] < 100) {
            gantte 
            .append('text')
            .attr('transform', `translate(${pf + this.getNode(`#BAR_${i}_${act.code}`, 'width') + 5}, ${y+4})`)
            .attr('font-size', 9).attr('fill', '#000').attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            .text(`(${d[`${act.code}_PP`].toFixed(1)}%)`)
          }

          // Actual ---------------------------------------------------
          let as = this.timeline.scale(new Date(d[`${act.code}_AS`]))
          let af = (d[`${act.code}_AP`] == 100) ? this.timeline.scale(new Date(d[`${act.code}_AF`])) : this.timeline.scale(new Date(this.timeline.cutoff))

          let bColor = '#83D2F5'
          let tColor = '#44A9DF'

          if(d[`${act.code}_AP`] == 100 && d[`${act.code}_PF`] < d[`${act.code}_AF`]) {
            bColor = '#F7BACF'
            tColor = '#F35E90'
          } else if(d[`${act.code}_AP`] < d[`${act.code}_PP`]) {
            bColor = '#F35E90'
            tColor = '#F35E90'
          }

          if(as) {
            gantte
            .append('rect')
            .attr('x', as).attr('y', y+10)
            .attr('width', af - as).attr('height', 7)
            .attr('fill', bColor)

            gantte // Start Date
            .append('text')
            .attr('transform', `translate(${as-2}, ${y+15})`)
            .attr('font-size', 9).attr('fill', tColor).attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
            .text(this.changeDate('/', d[`${act.code}_AS`]))
          }

          if(d[`${act.code}_AP`] == 100) {
            gantte // Finish Date
            .append('text')
            .attr('transform', `translate(${af+2}, ${y+15})`)
            .attr('font-size', 9).attr('fill', tColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            .text(this.changeDate('/', d[`${act.code}_AF`]))
          } else if(d[`${act.code}_AP`] > 0) {
            gantte // Finish Date
            .append('text')
            .attr('transform', `translate(${af+2}, ${y+15})`)
            .attr('font-size', 9).attr('fill', tColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
            .text(d[`${act.code}_AP`].toFixed(1)+ '%')
          }

          y = y + 36
        })

        y = y + 8
      })

      gantte
      .append('line')
      .attr('id', 'TIME_NOW')
      .attr('x1', this.timeline.scale(new Date(this.timeline.cutoff))).attr('y1', -38)
      .attr('x2', this.timeline.scale(new Date(this.timeline.cutoff))).attr('y2', 1210)
      .attr('stroke', '#44A9DF').attr('stroke-width', .5).attr('opacity', .5)
    },

  }
}