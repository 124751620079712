
import fnc_block_Coloring             from './block_Coloring'
import fnc_stage_BarChart             from './stage_BarChart'
import fnc_stage_Progress             from './stage_Progress'




export default {
  
  mixins: [
    fnc_block_Coloring,

    fnc_stage_BarChart, 
    fnc_stage_Progress,
    
  ],

}
