import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(15, 40)`) // revised
      .style('font-family', 'roboto')


    }

  }
}