import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .attr('font-family', 'roboto').attr('cursor', 'default')

      this.draw_ChartCanvas() // -----> draw_ChartCanvas.js
      this.draw_Timeline() // -----> draw_Timeline.js
      this.chart_Router() //-----> chart_Router.js
    },

  }
}
           