import * as d3 from 'd3'
import moment from 'moment'

export default {
  data: () => ({
    selectedItems:    null,
    getItems:         null,
  }),

  methods: {
    set_selectedItems() {
      this.selectedItems = { 
        ...this.selectedItems,
        selectedStep: this.selectedStep,
      }
    },

    draw_PDF() {
      if (this.getItems.selectedStep == 0) return

      let i_ = this.getItems.selectedStep

      let d_ = this.rawData.find(f=> f.SN == i_)

      let file = this.canvas_SVG
      .append('g')
      .attr('id', `SVG_FILE_${i_}`)
      .attr('transform', `translate(0,0)`).attr('opacity', 0)
      
      this.loadSvg(
        file, {
        x   : 50,
        y   : 100,
        url : `/Visualization/ErectionTimeline/Rev1/${d_.CDATE.substr(0,7)}.svg`
      }).then(() => {
        
        d3.selectAll("text").style('font-family', 'roboto')
        d3.select(`#SVG_FILE_${i_}`).transition().duration(1000).attr('opacity', 1)

        d3.select(`#SVG_FILE_${0}`).remove()
        
        this.call_SVG(d_.CDATE.substr(0,7))
      })

      // Current Step
      d3.select(`#IN_CIRCLE_${this.selectedStep}`).transition().duration(300)
      .attr('fill', '#fff')
      .attr('stroke', '#bcbcbc')

      d3.select(`#OUT_CIRCLE_${this.selectedStep}`).transition().duration(300)
      .attr('r', 0)

      // Selected Step
      d3.select(`#LINE`).transition().duration(300).delay(200)
      .attr('x2', this.timeline.scale(moment(d_.CDATE).toDate()))

      d3.select(`#IN_CIRCLE_${i_}`).transition().duration(200).delay(400)
      .attr('fill', '#83D2F5')
      .attr('stroke', '#83D2F5')

      d3.select(`#OUT_CIRCLE_${i_}`).transition().duration(200).delay(400)
      .attr('r', 20).attr('opacity', 0.3)

      d3.select(`#OUT_CIRCLE_${i_}`).transition().duration(200).delay(600)
      .attr('r', 5).attr('opacity', 1)

      d3.select('#BTN_BACK').attr('visibility', (i_ == 0) ? 'hidden' : 'display')
      d3.select('#BTN_NEXT').attr('visibility', (i_ == 26) ? 'hidden': 'display')
      
      // Progress bar Animation
      this.codes.forEach((d) => {

        let P_Pro = d_[`${d.code}_EP`]
        let A_Pro = d_[`${d.code}_A`]

        let cP_Pro = this.currentPro[`${d.code}_EP`]
        let cA_Pro = this.currentPro[`${d.code}_A`]

        let barLen = d.bar/100

        let P_Y = 16 + (this.area.barH-((P_Pro/100) * this.area.barH))
        let A_Y = 16 + (this.area.barH-((A_Pro/100) * this.area.barH))
    
        if(d.type == 'OV') {
          // PLAN
          d3.select(`#BAR_${d.code}_EP`).transition().duration(500)
          .attr('width', P_Pro * barLen)

          d3.select(`#POINT_${d.code}_EP`).transition().duration(500)
          .attr('transform', `translate(${P_Pro * barLen + 7}, ${d.y + 26})`)

          d3.select(`#TEXT_${d.code}_EP`).transition().duration(500)
          .attr('width', P_Pro * barLen)
          .attr('x', P_Pro * barLen + 12)
          .tween("text", function() {
            var i = d3.interpolateNumber(Number(cP_Pro.toFixed(1)), Number(P_Pro).toFixed(1))
            return function(t) {
            d3.select(this).text(`${i(t).toFixed(1)}%`)
            }
          })

          // ACTUAL
          d3.select(`#BAR_${d.code}_A`).transition().duration(500)
          .attr('width', A_Pro * barLen)

          d3.select(`#TEXT_${d.code}_A`).transition().duration(500)
          .attr('width', A_Pro * barLen)
          .attr('x', A_Pro * barLen + 12)
          .tween("text", function() {
            var i = d3.interpolateNumber(Number(cP_Pro.toFixed(1)), Number(A_Pro).toFixed(1))
            return function(t) {
            d3.select(this).text(`${i(t).toFixed(1)}%`)
            }
          })

        } else {

          // PLAN
          d3.select(`#BAR_${d.code}_EP`).transition().duration(500)
          .attr('y', P_Y)
          .attr('height', (P_Pro/100) * this.area.barH)
          
          d3.select(`#POINT_${d.code}_EP`).transition().duration(500)
          .attr('transform', `translate(${this.area.barW},${P_Y-2}) scale(.7)`)

          d3.select(`#TEXT_${d.code}_EP`).transition().duration(500)
          .attr('y', P_Y+3)
          .tween("text", function() {
            var i = d3.interpolateNumber(Number(cP_Pro.toFixed(1)), Number(P_Pro).toFixed(1))
            return function(t) {
            d3.select(this).text(`${i(t).toFixed(1)}%`)
            }
          })

          // ACTUAL
          d3.select(`#BAR_${d.code}_A`).transition().duration(500)
          .attr('y', A_Y)
          .attr('height', (A_Pro/100) * this.area.barH)

          d3.select(`#TEXT_${d.code}_A`).transition().duration(500)
          .attr('y', A_Y-2)
          .tween("text", function() {
            var i = d3.interpolateNumber(Number(cA_Pro.toFixed(1)), Number(A_Pro).toFixed(1))
            return function(t) {
            d3.select(this).text(`${i(t).toFixed(1)}%`)
            }
          })
        }
      })
    },
  }
}