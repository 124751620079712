import * as d3 from 'd3'

export default {
  
  methods: {

    set_LocalValues(){
      // raw data
      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))
      this.schList = JSON.parse(JSON.stringify(this.Queries.SQL1))
      
        
      
    },
  }
}