import * as d3 from 'd3'
import moment from 'moment'


export default {

  methods: {

    set_LocalValues(){
      // raw data
      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))

      this.chartData = JSON.parse(JSON.stringify(this.Queries.SQL1))
      this.chartData.forEach(d=> {
        let eDate = moment(`${d.CDATE} 23:59:59`).toDate()
        let sDate = moment(d.CDATE).add(-6, 'days').toDate()
        d.mDate = new Date(d3.mean([sDate, eDate]))
      })

   
      this.timeline = {
        cutoff    : this.rawData.CUTOFF,
        startDate : this.rawData.START_DATE,
        endDate   : this.rawData.END_DATE,
        weekEnd   : 'friday',

        day        : [],
        week       : [],
        month      : [],
        year       : [],
        scale      : null,
        length     : this.chart.width,
      }
      this.init_gmx_TimelineValues(this.timeline, 'day')



      
    },


  }
}