import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_2PE(_d) {
      this.current.stage = '2PE'
      this.current.sn = _d.SN
      this.current.eq = null
      this.current.detail = null
      this.pe_sn = _d.SN
      console.log('2PE STAGE->', this.current.pe) /////

      this.model_2PE = this.svg
      .append('g')
      .attr('id', `MODEL_2PE`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_2PE, {
        x   : 250,
        y   : 0,
        url : `/Visualization/BlockDivision/Rev1/Topside/${this.current.pe}.svg`
      }).then(() => {
        // console.log(`/Visualization/BlockDivision/Rev1/Topside/${this.current.pe}.svg`)
        d3.selectAll("text").style('font-family', 'roboto')

        d3.select(`#TEXTS`).style('opacity', 0)
        d3.select(`#LINES`).style('opacity', 0)
        d3.select(`#MODEL_2PE`).style('opacity', 0).attr('transform', `translate(0,0) scale(1)`)
        d3.select(`#MODEL_2PE`).transition().duration(500).style('opacity', 1)

        this.erectionList.filter(f=> f.AREA == this.current.pe).forEach(d => {

          d3.select(`#${d.BLK}`).attr('opacity', 0)
          d3.select(`#MASK-${d.BLK}`).style('opacity', 0).attr('visibility', 'hidden')
         
          d3.select(`#PNG-${d.BLK}`)
          .transition().duration(500).delay(500).attr('transform', `translate(${d.X2},${d.Y2}) scale(.75)`)
        })

        setTimeout(() => { 
          this.erectionList.filter(f=> f.AREA == this.current.pe).forEach(d => {
            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(.75)`)
            d3.select(`#LINES`).transition().duration(300).style('opacity', 1)
          })
          this.draw_2PE_Canvas(_d) // ----->
        }, 500)

        this.func_blockColoring(this.model_2PE, this.current.area.toUpperCase(), 'EREC') // -----> vFunction/block_Coloring.js
        setTimeout(() => { 
          
          if (_d.BLK == 'Flare') this.set_Modal_Blocks('NF110')
          else {
            this.erectionList.filter(f=> f.AREA == this.current.pe).forEach(d => {
              d3.select(`#${d.BLK}`)
              .attr('opacity', 1)
              .attr('cursor', 'pointer').call(this.call_madal_Window, {STAGE: 'EREC', KEY: d.BLK}) // -----> call Madal Window
              d3.select(`#MASK-${d.BLK}`).attr('cursor', 'pointer').attr('visibility', 'display').call(this.call_2PE_Block, d)
            })
            d3.select(`#TEXTS`).transition().duration(500).style('opacity', 1)
          }

          // Equipment Layout ---------------------------------------------------------------------------
          let mod = this.current.pe.toUpperCase()

          this.eq_svg.filter(f=> f.ZONE == mod).forEach(d=> {
            d3.select(`#EQ-${d.SVG}`)
            .attr('cursor', 'pointer')
            .call(this.call_Equip_Topside, _d, d, this.draw_Model_2PE)
          })

        }, 1000)
      })

      this.set_selectedItems()
    },



    back_Model_2PE(_d) {
      
      this.current.stage = '2PE'
      this.current.sn = this.pe_sn
      this.current.eq = null
      this.current.detail = null
      
      this.model_2PE = this.svg
      .append('g')
      .attr('id', `MODEL_2PE`)
      .attr('transform', `translate(340,80)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_2PE, {
        x   : 250,
        y   : 0,
        url : `/Visualization/BlockDivision/Rev1/Topside/${this.current.pe}.svg`
      }).then(() => {

        d3.selectAll("text").style('font-family', 'roboto')
        d3.select(`#LINES`).style('opacity', 0)
        d3.select(`#TEXTS`).style('opacity', 0)
        d3.select(`#MODEL_2PE`).style('opacity', 1).attr('transform', `translate(0,0) scale(1)`)
 
        this.erectionList.filter(f=> f.AREA == this.current.pe).forEach(d => {
          d3.select(`#MASK-${d.BLK}`).style('opacity', 0).attr('visibility', 'hidden')
          d3.select(`#PNG-${d.BLK}`).transition().duration(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(0.75)`)
          d3.select(`#${d.BLK}`).style('opacity', 0)
        })
        
        setTimeout(() => { 
          this.erectionList.filter(f=> f.AREA == this.current.pe).forEach(d => {
            d3.select(`#${d.BLK}`).transition().duration(500).style('opacity', 1)
            d3.select(`#LINES`).transition().duration(500).style('opacity', 1)
            d3.select(`#TEXTS`).transition().duration(500).style('opacity', 1)
          })
        }, 500)

        this.func_blockColoring(this.model_2PE, this.current.area.toUpperCase(), 'EREC') // -----> vFunction/block_Coloring.js
        setTimeout(() => { 
          this.erectionList.filter(f=> f.AREA == this.current.pe).forEach(d => {
            d3.select(`#${d.BLK}`)
            .attr('opacity', 1)
            .attr('cursor', 'pointer').call(this.call_madal_Window, {STAGE: 'EREC', KEY: d.BLK}) // -----> call Madal Window
            d3.select(`#MASK-${d.BLK}`)
            .attr('visibility', 'display').attr('cursor', 'pointer')
            .call(this.call_2PE_Block, d)
          }) 
          
          // Equipment Layout ---------------------------------------------------------------------------
          let mod = this.current.pe.toUpperCase()

          this.eq_svg.filter(f=> f.ZONE == mod).forEach(d=> {
            d3.select(`#EQ-${d.SVG}`)
            .attr('cursor', 'pointer')
            .call(this.call_Equip_Topside, _d, d, this.draw_Model_2PE)
          })
        }, 750)
      })
      
      this.draw_2PE_Canvas(_d) // ----->
      this.set_selectedItems()
    },


  }
}