
import icon from '../../../../../../includes/primitives/icons'

export default {
  data: () => ({

    rawData:        null,
    chartData:      null,

    canvasForms:    null,
    canvasDropdown: null,
    canvasChart:    null,

    chart: {
      x: 75, 
      y: 205,
      width: 1250,
      height: 400,

      line: [
        {code: 'P_CUM', type: 'PLAN',sColor: '#757575', sWidth: .5},
        {code: 'A_CUM', type: 'ACT', sColor: '#44A9DF', sWidth: 1.5},
      ],

      colors: ['#FFDA00', '#83D2F5', '#F35E90', '#757575',]
    },

    timeline:     {
      month: [],
      year: [],
      scale: null,
      month_count: 0,
      monthWidth: 0,
    },

    icon:         icon.arrow,
    scaleX: null,
    primary:      {},
    secoundary:   {},

    dropdown: [
      {idx: 0, id: 'PHASE',  x: 0,   y: 0, boxWidth: 115,},
      {idx: 1, id: 'GROUP',  x: 130, y: 0, boxWidth: 110,},
      {idx: 2, id: 'SUBCONT', x: 250, y: 0, boxWidth: 120,},
    ],

    combine: [],
    current:{ phase: false, group: false, subcont: false },

    legends: [
      {title: 'Total',  code: 'TOTAL', width: 50},
      {title: 'Plan',   code: 'P_WK',  width: 45},
      {title: 'Actual', code: 'A_WK',  width: 45},
      {title: 'Delta',  code: 'D_WK',  width: 45},
      {title: 'Plan',   code: 'P_CUM', width: 45},
      {title: 'Actual', code: 'A_CUM', width: 45},
      {title: 'Delta',  code: 'D_CUM', width: 45},
    ],











  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}