import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .style('font-family', 'roboto')

      this.draw_SummaryTable() 
      this.chart_Router() 


    },

  }
}
           