export default {

  methods: {

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))
      this.dataSet = JSON.parse(JSON.stringify(this.Queries.SQL1))

      // to be deleted - start
      // this.FilterValues.STAGE = 'EREC' 
      // this.FilterValues.KEY = 'EN112'
      // to be deleted - end

      this.Props.stage = this.FilterValues.STAGE
      this.Props.key = this.FilterValues.KEY

      
    },



    set_Gradients() {
      let defs = this.svg.append('defs')
  
        let shadow = defs.append('linearGradient')
        .attr('id', `BAR`).attr('x1', 0).attr('y1', 0).attr('x2', 1).attr('y2', 1.8)
        shadow.append('stop').attr('stop-color', '#888888').attr('offset', '0.1' ).attr('stop-opacity', 1.)
        shadow.append('stop').attr('stop-color', '#B7B7B7').attr('offset', '0.15' ).attr('stop-opacity', 1)
        shadow.append('stop').attr('stop-color', '#DADADA').attr('offset', '0.2' ).attr('stop-opacity', 1)
        shadow.append('stop').attr('stop-color', '#EBEBEB').attr('offset', '0.25').attr('stop-opacity', 1)
        shadow.append('stop').attr('stop-color', '#F6F6F6').attr('offset', '0.4').attr('stop-opacity', 1)
        shadow.append('stop').attr('stop-color', '#FDFDFD').attr('offset', '0.6').attr('stop-opacity', 1)
        shadow.append('stop').attr('stop-color', '#FFFFFF').attr('offset', '0.9').attr('stop-opacity', .5)
    },
  },
}