import gmx_zMixins         from '../../../../../../includes/Jmixins/zMixins'


// Locally declared common Data-Props & SVG Drawing Support
import lmx_localData            from '../primitives/localData'
import func_box_Summary         from '../../../../vFunctions/box_Summary'
import func_barChart_Summary    from '../../../../vFunctions/barChart_Summary'

import lmx_LocalValues          from './set_LocalValues'
import lmx_Watching             from './Watching'

import lmx_Events               from './Events'
import lmx_Dropdown_Draw        from './Dropdown_Draw'
import lmx_Dropdown_Controller  from './Dropdown_Controller'

import lmx_draw_Forms           from './draw_Forms'
import lmx_chart_Router         from './chart_Router'
import lmx_ChartCanvas          from './draw_ChartCanvas'
import lmx_Timeline             from './draw_Timeline'
import lmx_Chart                from './draw_Chart'
import lmx_Legends              from './draw_Legends'

import lmx_draw_PDF             from './draw_PDF'
import lmx_Tooltip              from './draw_Tooltip'



export default {
  mixins: [
    gmx_zMixins, 
    lmx_localData,

    func_box_Summary,
    func_barChart_Summary,
    
    // Local Mixins
    lmx_Watching,
    lmx_Events,
    lmx_Dropdown_Draw,
    lmx_Dropdown_Controller,
    
    lmx_LocalValues,
    
    lmx_draw_Forms,
    lmx_chart_Router,
    lmx_ChartCanvas,
    lmx_Timeline,
    lmx_Chart,
    lmx_Legends,

    lmx_draw_PDF,
    lmx_Tooltip,
  ],
}
