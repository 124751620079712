import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'

export default {
  
  methods: {

    set_LocalValues(){

      this.eqList = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))

      this.source = sql1.SOURCE

      this.eqList.forEach(d=> {
        d.ATA = undefined
        d.INSTALL = undefined
      })

      this.svgFile.forEach(d=>{
        d.TOTAL = this.eqList.filter(f=> f.ZONE == d.ZONE && f.SVG == d.SVG).length
        d.PO = this.eqList.filter(f=> (f.ZONE == d.ZONE && f.SVG == d.SVG) && f.PO_DATE != undefined).length
        d.FAT = this.eqList.filter(f=> (f.ZONE == d.ZONE && f.SVG == d.SVG) && f.FAT_ACT != undefined).length
        d.ATA = this.eqList.filter(f=> (f.ZONE == d.ZONE && f.SVG == d.SVG) && f.ATA != undefined).length
        d.INSTALL = this.eqList.filter(f=> (f.ZONE == d.ZONE && f.SVG == d.SVG) && f.INSTALL != undefined).length
        d.ETA_SRD = this.eqList.filter(f=> (f.ZONE == d.ZONE && f.SVG == d.SVG) && f.ETA_SRD < 0).length
      })

      this.areaSummary.forEach(d=>{
        if(d.ZONE == 'OVERALL') {
          d.TOTAL = this.eqList.length
          d.PO = this.eqList.filter(f=> f.PO_DATE != undefined).length
          d.FAT = this.eqList.filter(f=> f.FAT_ACT != undefined).length
          d.ATA = this.eqList.filter(f=> f.ATA != undefined).length
          d.INSTALL = this.eqList.filter(f=> f.INSTALL != undefined).length
          d.ETA_SRD = this.eqList.filter(f=> f.ETA_SRD < 0).length
        } else if(d.ZONE == 'TOPSIDE') {
          d.TOTAL = this.eqList.filter(f=> f.AREA == d.ZONE).length
          d.PO = this.eqList.filter(f=> f.AREA == d.ZONE && f.PO_DATE != undefined).length
          d.FAT = this.eqList.filter(f=> f.AREA == d.ZONE && f.FAT_ACT != undefined).length
          d.ATA = this.eqList.filter(f=> f.AREA == d.ZONE && f.ATA != undefined).length
          d.INSTALL = this.eqList.filter(f=> f.AREA == d.ZONE && f.INSTALL != undefined).length
          d.ETA_SRD = this.eqList.filter(f=> f.AREA == d.ZONE && f.ETA_SRD < 0).length
        } else {
          d.TOTAL = this.eqList.filter(f=> f.ZONE == d.ZONE).length
          d.PO = this.eqList.filter(f=> f.ZONE == d.ZONE && f.PO_DATE != undefined).length
          d.FAT = this.eqList.filter(f=> f.ZONE == d.ZONE && f.FAT_ACT != undefined).length
          d.ATA = this.eqList.filter(f=> f.ZONE == d.ZONE && f.ATA != undefined).length
          d.INSTALL = this.eqList.filter(f=> f.ZONE == d.ZONE && f.INSTALL != undefined).length
          d.ETA_SRD = this.eqList.filter(f=> f.ZONE == d.ZONE && f.ETA_SRD < 0).length
        }
      })
      // console.log(this.areaSummary) /////
    },


    loadSvg(selection, data) {
      return d3.xml(`${this.HOST}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)
        svg_ = d3.select(svg_)
        .attr('x', data.x).attr('y', data.y).attr('width', data.width)
        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },

    
    set_Gradients() {
      let defs = this.svg.append('defs')
      // Window bar
      let windowBar = defs.append('linearGradient')
      .attr('id', `windowBar`).attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', 1)
      windowBar.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      windowBar.append('stop').attr('stop-color', '#F4F5F5').attr('offset', '.4').attr('stop-opacity', 1)
      windowBar.append('stop').attr('stop-color', '#F4F5F5').attr('offset', '.6').attr('stop-opacity', 1)
      windowBar.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 1)

      // // Contents Window
      let contentWindow = defs.append('linearGradient')
      .attr('id', `contentWindow`).attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', .7)
      contentWindow.append('stop').attr('stop-color', '#F4F5F5').attr('offset', '0').attr('stop-opacity', 1)
      contentWindow.append('stop').attr('stop-color', '#fff').attr('offset', '.1').attr('stop-opacity', 1)

      let filter = defs
      .append('filter').attr('id', 'dropshadow')

      filter.append('feGaussianBlur') .attr('in', 'SourceAlpha').attr('stdDeviation', 7).attr('result', 'blur')
      filter.append('feOffset').attr('in', 'blur').attr('dx', 2).attr('dy', 2).attr('result', 'offsetBlur')

      let feMerge = filter.append('feMerge')
      feMerge.append('feMergeNode').attr('in', 'offsetBlur')
      feMerge.append('feMergeNode').attr('in', 'SourceGraphic')
    },

  }
}