export default {
  data() {
    return {
      tooltip: {
        width: 150,
        height: 65,
        offset: 5,
        radius: 5,
      }
    }
  },
  methods: {
    draw_Tooltip(selection) {
      let lastOne = this.chartData.find(f => f.month == this.timeline.cutoff.substr(0,7) && f.DISC == 'OVERALL')
      let refCol = 'P_WK'
      if (lastOne.P_WK < lastOne.A_WK) refCol = 'A_WK'

      let tooltip = selection
      .append('g')
      .attr('id', `TOOLTIP_${this.localId}`)
      .attr('transform', `translate(
        ${this.timeline.scale(lastOne.month) + this.timeline.monthWidth/2},
        ${this.chart.height - this.primary.axis(lastOne[refCol]) - 5}
      )`)

      tooltip
      .append('path')
      .attr('d', this.topTooltipPath(this.tooltip.width,this.tooltip.height,this.tooltip.offset,this.tooltip.radius))
      .attr('fill', 'white')
      .attr('stroke', '#757575')
      .attr('opacity', 0.75)

      tooltip
      .append('text')
      .attr('x', -25 + 25).attr('y', -55)
      .attr('font-size', 11)
      .attr('text-anchor', 'middle')
      .attr('fill', '#757575')
      .text('Plan')

      tooltip
      .append('text')
      .attr('x', 25 + 25).attr('y', -55)
      .attr('font-size', 11)
      .attr('text-anchor', 'middle')
      .attr('fill', '#757575')
      .text('Actual')

      tooltip
      .append('text')
      .attr('x', -70).attr('y', -35)
      .attr('font-size', 11)
      .attr('fill', '#757575')
      .text('Monthly')

      tooltip
      .append('text')
      .attr('x', -70).attr('y', -15)
      .attr('font-size', 11)
      .attr('fill', '#757575')
      .text('Cum.')

      tooltip
      .append('text')
      .attr('x', -25 + 45).attr('y', -35)
      .attr('font-size', 11)
      .attr('fill', '#333')
      .attr('text-anchor', 'end')
      .text(Math.round(lastOne.P_WK * 100) / 100)

      tooltip
      .append('text')
      .attr('x', 25 + 45).attr('y', -35)
      .attr('font-size', 11)
      .attr('text-anchor', 'end')
      .attr('fill', lastOne.P_WK > lastOne.A_WK ? '#f790b2' : lastOne.P_WK < lastOne.A_WK ? '#4aace0' : '#969696')
      .text(Math.round(lastOne.A_WK * 100) / 100)

      tooltip
      .append('text')
      .attr('x', -25 + 45).attr('y', -15)
      .attr('font-size', 11)
      .attr('text-anchor', 'end')
      .text(Math.round(lastOne.P_CUM * 100) / 100)

      tooltip
      .append('text')
      .attr('x', 25 + 45).attr('y', -15)
      .attr('font-size', 11)
      .attr('text-anchor', 'end')
      .attr('fill', lastOne.P_CUM > lastOne.A_CUM ? '#f790b2' : lastOne.P_CUM < lastOne.A_CUM ? '#4aace0' : '#969696')
      .text(Math.round(lastOne.A_CUM * 100) / 100)
    }
  }
}