import * as d3 from 'd3'

export default {
  
  methods: {
    draw_Model_Equipment(props, _d, _func) {
      this.current.stage = 'EQ'
      this.current.eq = {ZONE: _d.ZONE, SVG: _d.SVG}
      this.current.search = null

      this.model_Equipment = this.svg
      .append('g')
      .attr('id', `MODEL_EQUIPMENT`)
      .attr('transform', `translate(0,0)`)


      this.loadSvg(
      this.model_Equipment, {
      x: 30, y: -360, width: 500,
      url : `/Visualization/Layout_Equipment/EQ_Legends.svg`
      }).then(() => {
        d3.selectAll('text').style('font-family', 'roboto')
      })

      // Load a Legend SVG
      this.loadSvg(
        this.model_Equipment, {
        x: 0, y: 0,
        url : `/Visualization/Layout_Equipment/SVGs/${_d.ZONE}_${_d.SVG}.svg`
      }).then(() => {
        d3.selectAll('text').style('font-family', 'roboto')
        d3.select(`#TAGs`).style('opacity', 0)
        d3.select(`#IMAGE`).style('opacity', 0)
        d3.select(`#LINE`).style('opacity', 0)

        this.eqList.filter(f=> f.ZONE == _d.ZONE && f.SVG == _d.SVG).forEach(d=>{
          d3.select(`#NO-${d.ID}`)
          .attr('cursor', 'pointer').call(this.call_Equipment, d)
          d3.select(`#C-${d.ID}`)
          .style('fill', ()=> {
            let color = '#fff'
            if(d.PO_DATE) color = '#85C7E5'
            if(d.FAT_ACT) color = '#F7BACE'
            if(d.INSTALL) color = '#8DBB3F'
            return color
          })
          if(d.ETA_SRD < 0) {
            let txt = d3.select(`#NO-${d.ID}`).selectAll('text').nodes()[1]
            d3.select(txt).style('fill', 'red')
          }
        })
        
        setTimeout(() => { 
          d3.select(`#TAGs`).transition().duration(300).delay(300).style('opacity', 1)
          d3.select(`#IMAGE`).transition().duration(500).style('opacity', 1)
          d3.select(`#LINE`).transition().duration(300).delay(300).style('opacity', 1)
          this.draw_Equipment_Canvas(props, _d, _func) // ----->> below
        }, 500)
        
        this.set_selectedItems()
      }) 
    },
    

    draw_Equipment_Canvas(props, _d, _func) {
      this.canvas_Equipment = this.svg
      .append('g')
      .attr('id', `CANVAS_EQUIPMENT`)
      .attr('transform', `translate(0,0)`)

      this.gmx_btn_Navigation(
        this.canvas_Equipment, 
        {type: 'back', x: 490, y: 83, scale: 0.8,}, 
        {
          remove: [this.model_Equipment, this.canvas_Equipment],
          dur: 600, 
          timeout: 1000,
          exeFunc: _func,
          data: props
        }
      )

      this.canvas_Equipment
      .append('text')
      .attr('id', `TITLE_MAIN`)
      .attr('transform', `translate(30, 155)`)
      .attr('font-size', 17).attr('fill', '#D71638').attr('alignment-baseline', 'bottom')
      .text(`${_d.ZONE} MODULE -`)

      this.canvas_Equipment
      .append('text')
      .attr('id', `TITLE_SUB`)
      .attr('x', this.getNode('#TITLE_MAIN','width') + 35)
      .attr('y', 155)
      .attr('font-size', 14).attr('fill', '#D71638').attr('alignment-baseline', 'bottom').attr('opacity', .5)
      .text(_d.NAME)

      this.bar_Summary(this.canvas_Equipment, this.eq_svg.find(f=> f.ZONE == _d.ZONE && f.SVG == _d.SVG), {idx: 'OV', x: 90,   y: 280, bar: 250})

      this.draw_detail()

      // ---------------------------------------- Toggle ----------------------------------------
      this.gmx_btn_Toggle(
        this.canvas_Equipment, 
        {
          variable:   'Toggle', // variable for the value of toggle status
          id:         'TOG',
          x:          540,
          y:          97,
          width:      55,
          height:     18,
          title:       'Tag/Name',
          cColor:     '#fff',

          on: {
            value:    'Tag #',
            bColor:   '#44A9DF',
            tColor:   '#fff',
          },
          off: {
            value:    'Name',
            bColor:   '#D92129',
            tColor:   '#fff',
          }      
        },
        this.toggle_action
      )

      // ---------------------------------------- Search ----------------------------------------
      this.search.mod = _d.ZONE
      this.search.lev = _d.SVG
      let data__ = {
        x         : 40,
        y         : 240,
        width     : 200,
        height    : 60.5,
        label     : 'Search',
        labelWidth: 40,
        targets: [
          { text: 'TAG No / ' },
          { text: 'Description'},
        ],
        callFunc: this.call_Search// --->  in Event.js
      }
      this.drawSearchToolFree(this.canvas_Equipment, data__) //---> Global function

    },



  }
}