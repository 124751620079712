import Defs from '../../../../../includes/primitives/colorDefs'

export default {
  // Initial Data Set
  data: () => ({
    ContainerGroup: null, // main group of svg
    tabSelected: 0,
    EmptyArr:[]
   
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      // console.log('Object.keys(this.ModalDraw).length', Object.keys(this.ModalDraw).length)
      // console.log('Object.keys(this.ModalTaps).length', Object.keys(this.ModalTaps).length)

      return  this.DataItems.length > 0 &&
              Object.keys(this.Canvas).length > 0 &&
              Object.keys(this.ModalDraw).length > 0 &&
              Object.keys(this.ModalTaps).length > 0 
    }
  },
  methods: {
    init() {
      if(this.tableGroup) this.tableGroup.remove()
    },

    setDefaultValues() {
      this.Module = this.FilterValues.MOD

      // Set the Defs_Gradients for using the color of Gradients
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', this.ModalTaps.Bullet.outColor, [0.5, 1.7, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', this.ModalTaps.Bullet.overColor, [0.5, 1.7, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', this.ModalTaps.Bullet.selectedColor, [0.5, 1.7, 0.5, -0.2], this.localId)
    },
    tabable(idx) {
        if(this.TabStatus.length == 0) return false
        if(!this.TabStatus[idx]) return false
        return true
    },
    



 






  }
}