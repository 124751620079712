
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForms = this.svg
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      let title = this.canvasForms
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        this.filter_GantteData('zone', this.Props.key)
      }) 

      title
      .append('text')
      .attr('transform', `translate(20, 25)`)
      .attr('font-size', 16).attr('fill', '#F35E90')
      .text(this.Props.title)

      this.loadSvg(
        this.canvasForms, {
        x: 0, y: -50, width: 810,
        url : `${this.HOST}/Visualization/Modal_Window/${this.Props.file}.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')

        if(this.Props.key == 'FLARE') {
          // BLK
          this.filterData.forEach(d=> {
            d3.select(`#M-MASK-${d.BLK}`).style('opacity', 0).attr('cursor', 'pointer')
            .call(this.call_BLK, d.BLK)
            d3.select(`#M-${d.BLK}`).attr('cursor', 'pointer')
            .call(this.call_BLK, d.BLK)
          })
  
          // PE1
          this.filterData.filter(f=> f.PE1 != '').forEach(d=> {
            d3.select(`#M-MASK-PE1-${d.PE1}`).style('opacity', 0).attr('cursor', 'pointer')
            .call(this.call_PE1, d.PE1)
            d3.select(`#M-PE1-${d.PE1}`).attr('cursor', 'pointer')
            .call(this.call_PE1, d.PE1)
          })
  
          // EREC
          this.filterData.filter(f=> f.EREC != '').forEach(d=> {
            d3.select(`#M-MASK-EREC-${d.EREC}`).style('opacity', 0).attr('cursor', 'pointer')
            .call(this.call_EREC, d.EREC)
            d3.select(`#M-EREC-${d.EREC}`).attr('cursor', 'pointer')
            .call(this.call_EREC, d.EREC)
          })
        } else {
          let filterData = this.masterList.filter(f=> f.ZONE == this.Props.key)
          let masks = [ ...new Set(filterData.map(d => d.EREC)) ]

          masks.forEach(d=> {
            let id = d.replace('/', '_x2F_').replace(' ', '_')
            d3.select(`#M-MASK-${id}`).style('opacity', 0).attr('cursor', 'pointer')
            .call(this.call_Gantte, d)
          })
        }

        this.func_blockColoring(this.canvasForms, 'TOPSIDE', 'EREC') // -----> vFunction/block_Coloring.js
      })

      this.loadSvg(
        this.canvasForms, {
        x: 10, y: 370, width: 810, height: 100,
        url : `${this.HOST}/Visualization/Modal_Window/Legend_Modal.svg`
      }).then(() => {})


      this.draw_Timeline_Month()
      this.filter_GantteData('zone', this.Props.key)
      this.draw_BlockStatus({
        x: 21.5,
        y: 70.5,
        data: this.masterList.filter(f=> f.ZONE == this.Props.key)
      })
    },




    

   

  }
}