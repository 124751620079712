import CONST from '../../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    masterList    : null,
  
    canvasForms   : null,
    canvas_Status : null,

    Props : {},

    bar: {x: 300, len: 300},

    rawData: null,
    dataSet: null,
    barColor:[ '#29A9E1', '#FFDA00', '', ''],

  }),


  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
}