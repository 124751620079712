// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Texts from '../../../../../includes/mixins/Text_Draw.mixin'
import gmx_Legens from '../../../../../includes/mixins/Draw_Legends.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../includes/mixins/Movable.mixin'

// -------------------------------------------------------------------------------------- Local
// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares from './Declares.mixin'  
import lmx_Elements from './Elements.mixin'          // Common functions for Drawing the Charts & Tables

import lmx_Header from './Draw_Header.mixin'
import lmx_Taps from './Draw_Taps.mixin'


export default {
  mixins: [
    SafeIdMixin,
    
    gmx_Controller,
    gmx_Canvas,
    gmx_Texts,
    gmx_Legens,
    gmx_Miscellaneous,
    gmx_Movable,

    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Elements,

    lmx_Header,
    lmx_Taps,
  
  ],
}
